"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class NotificationControllerApi extends runtime.BaseAPI {
  /**
   * Check stato notifica per POC ON BOARD
   */
  checkNotifichePOCOnBoardRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.ndgtoken === null || requestParameters.ndgtoken === undefined) {
        throw new runtime.RequiredError('ndgtoken', 'Required parameter requestParameters.ndgtoken was null or undefined when calling checkNotifichePOCOnBoard.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/notifiche/onboard/{ndgtoken}/polling`.replace(`{${"ndgtoken"}}`, encodeURIComponent(String(requestParameters.ndgtoken))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OnBoardSessionEntityFromJSON(jsonValue));
    });
  }
  /**
   * Check stato notifica per POC ON BOARD
   */
  checkNotifichePOCOnBoard(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.checkNotifichePOCOnBoardRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Invia notifica per Net Insurance
   */
  sendNotificheNetInsuranceRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.sendRequest === null || requestParameters.sendRequest === undefined) {
        throw new runtime.RequiredError('sendRequest', 'Required parameter requestParameters.sendRequest was null or undefined when calling sendNotificheNetInsurance.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/notifiche/send`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.SendRequestToJSON(requestParameters.sendRequest)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.SubscriptionOkFromJSON(jsonValue));
    });
  }
  /**
   * Invia notifica per Net Insurance
   */
  sendNotificheNetInsurance(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.sendNotificheNetInsuranceRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Invia notifica per POC ON BOARD
   */
  sendNotifichePOCOnBoardRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.ndg === null || requestParameters.ndg === undefined) {
        throw new runtime.RequiredError('ndg', 'Required parameter requestParameters.ndg was null or undefined when calling sendNotifichePOCOnBoard.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/notifiche/onboard/{ndg}/send`.replace(`{${"ndg"}}`, encodeURIComponent(String(requestParameters.ndg))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OnBoardOutDtoFromJSON(jsonValue));
    });
  }
  /**
   * Invia notifica per POC ON BOARD
   */
  sendNotifichePOCOnBoard(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.sendNotifichePOCOnBoardRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * aggiorna stato notifica per POC ON BOARD
   */
  updatePOCOnBoardRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.ndgtoken === null || requestParameters.ndgtoken === undefined) {
        throw new runtime.RequiredError('ndgtoken', 'Required parameter requestParameters.ndgtoken was null or undefined when calling updatePOCOnBoard.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/notifiche/onboard/{ndgtoken}/update`.replace(`{${"ndgtoken"}}`, encodeURIComponent(String(requestParameters.ndgtoken))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OnBoardSessionEntityFromJSON(jsonValue));
    });
  }
  /**
   * aggiorna stato notifica per POC ON BOARD
   */
  updatePOCOnBoard(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.updatePOCOnBoardRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.NotificationControllerApi = NotificationControllerApi;