"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CalendarioControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class CalendarioControllerApi extends runtime.BaseAPI {
  /**
   * Aggiorna un appuntamento esistente.
   */
  aggiornaAppuntamentoRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.dettaglioAppuntamentoDto === null || requestParameters.dettaglioAppuntamentoDto === undefined) {
        throw new runtime.RequiredError('dettaglioAppuntamentoDto', 'Required parameter requestParameters.dettaglioAppuntamentoDto was null or undefined when calling aggiornaAppuntamento.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/calendario/appuntamenti/aggiorna`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.DettaglioAppuntamentoDtoToJSON(requestParameters.dettaglioAppuntamentoDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DettaglioAppuntamentoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Aggiorna un appuntamento esistente.
   */
  aggiornaAppuntamento(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.aggiornaAppuntamentoRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Elimina un appuntamento.
   */
  cancellaAppuntamentoRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.eliminaAppuntamentoIn === null || requestParameters.eliminaAppuntamentoIn === undefined) {
        throw new runtime.RequiredError('eliminaAppuntamentoIn', 'Required parameter requestParameters.eliminaAppuntamentoIn was null or undefined when calling cancellaAppuntamento.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/calendario/appuntamenti/cancella`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.EliminaAppuntamentoInToJSON(requestParameters.eliminaAppuntamentoIn)
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Elimina un appuntamento.
   */
  cancellaAppuntamento(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.cancellaAppuntamentoRaw(requestParameters);
    });
  }
  /**
   * Elimina una notifica.
   */
  cancellaNotificaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling cancellaNotifica.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/calendario/notifiche/cancella/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Elimina una notifica.
   */
  cancellaNotifica(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.cancellaNotificaRaw(requestParameters);
    });
  }
  /**
   * Cerca un appuntamento in base alle parole chiave.
   */
  cercaAppuntamentiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.ricercaAppuntamentoIn === null || requestParameters.ricercaAppuntamentoIn === undefined) {
        throw new runtime.RequiredError('ricercaAppuntamentoIn', 'Required parameter requestParameters.ricercaAppuntamentoIn was null or undefined when calling cercaAppuntamenti.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/calendario/appuntamenti/ricerca`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.RicercaAppuntamentoInToJSON(requestParameters.ricercaAppuntamentoIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.AppuntamentoDtoFromJSON));
    });
  }
  /**
   * Cerca un appuntamento in base alle parole chiave.
   */
  cercaAppuntamenti(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.cercaAppuntamentiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Crea un nuovo appuntamento.
   */
  creaAppuntamentoRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.dettaglioAppuntamentoDto === null || requestParameters.dettaglioAppuntamentoDto === undefined) {
        throw new runtime.RequiredError('dettaglioAppuntamentoDto', 'Required parameter requestParameters.dettaglioAppuntamentoDto was null or undefined when calling creaAppuntamento.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/calendario/appuntamenti/crea`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.DettaglioAppuntamentoDtoToJSON(requestParameters.dettaglioAppuntamentoDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DettaglioAppuntamentoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Crea un nuovo appuntamento.
   */
  creaAppuntamento(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.creaAppuntamentoRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce il dettaglio di un appuntamento.
   */
  dettaglioAppuntamentoRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.dettaglioAppuntamentoIn === null || requestParameters.dettaglioAppuntamentoIn === undefined) {
        throw new runtime.RequiredError('dettaglioAppuntamentoIn', 'Required parameter requestParameters.dettaglioAppuntamentoIn was null or undefined when calling dettaglioAppuntamento.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/calendario/appuntamenti/dettaglio`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.DettaglioAppuntamentoInToJSON(requestParameters.dettaglioAppuntamentoIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DettaglioAppuntamentoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce il dettaglio di un appuntamento.
   */
  dettaglioAppuntamento(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.dettaglioAppuntamentoRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce l\'elenco degli appuntamenti.
   */
  elencoAppuntamentiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.elencoAppuntamentiIn === null || requestParameters.elencoAppuntamentiIn === undefined) {
        throw new runtime.RequiredError('elencoAppuntamentiIn', 'Required parameter requestParameters.elencoAppuntamentiIn was null or undefined when calling elencoAppuntamenti.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/calendario/appuntamenti/elenco`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.ElencoAppuntamentiInToJSON(requestParameters.elencoAppuntamentiIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.AppuntamentoDtoFromJSON));
    });
  }
  /**
   * Restituisce l\'elenco degli appuntamenti.
   */
  elencoAppuntamenti(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.elencoAppuntamentiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce l\'elenco delle notifiche.
   */
  elencoNotificheRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.elencoNotificheIn === null || requestParameters.elencoNotificheIn === undefined) {
        throw new runtime.RequiredError('elencoNotificheIn', 'Required parameter requestParameters.elencoNotificheIn was null or undefined when calling elencoNotifiche.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/calendario/notifiche/elenco`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.ElencoNotificheInToJSON(requestParameters.elencoNotificheIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.NotificaDtoFromJSON));
    });
  }
  /**
   * Restituisce l\'elenco delle notifiche.
   */
  elencoNotifiche(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.elencoNotificheRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce il numero degli appuntamenti di oggi.
   */
  numeroAppuntamentiOggiRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/calendario/appuntamenti/oggi`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.TextApiResponse(response);
    });
  }
  /**
   * Restituisce il numero degli appuntamenti di oggi.
   */
  numeroAppuntamentiOggi() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.numeroAppuntamentiOggiRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce i parametri del calendario.
   */
  parametriRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/calendario/parametri`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriCalendarioDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce i parametri del calendario.
   */
  parametri() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce i prossimi eventi.
   */
  prossimiEventiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.numero === null || requestParameters.numero === undefined) {
        throw new runtime.RequiredError('numero', 'Required parameter requestParameters.numero was null or undefined when calling prossimiEventi.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/calendario/appuntamenti/prossimieventi/{numero}`.replace(`{${"numero"}}`, encodeURIComponent(String(requestParameters.numero))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.AppuntamentoDtoFromJSON));
    });
  }
  /**
   * Restituisce i prossimi eventi.
   */
  prossimiEventi(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.prossimiEventiRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.CalendarioControllerApi = CalendarioControllerApi;