"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ElencoAssegniItaliaPdfTypeEnum = exports.AssegniControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AssegniControllerApi extends runtime.BaseAPI {
  /**
   * Export in PDF del dettaglio dell\'assegno in base ai criteri inviati per il rapporto indicato
   */
  dettaglioAssegnoItaliaPdfRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filtroAssegniItaliaDto === null || requestParameters.filtroAssegniItaliaDto === undefined) {
        throw new runtime.RequiredError('filtroAssegniItaliaDto', 'Required parameter requestParameters.filtroAssegniItaliaDto was null or undefined when calling dettaglioAssegnoItaliaPdf.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/assegni/export/pdf`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroAssegniItaliaDtoToJSON(requestParameters.filtroAssegniItaliaDto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Export in PDF del dettaglio dell\'assegno in base ai criteri inviati per il rapporto indicato
   */
  dettaglioAssegnoItaliaPdf(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.dettaglioAssegnoItaliaPdfRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Export in PDF o Excel degli assegni in base ai criteri inviati per il rapporto indicato
   */
  elencoAssegniItaliaPdfRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.type === null || requestParameters.type === undefined) {
        throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling elencoAssegniItaliaPdf.');
      }
      if (requestParameters.filtroAssegniItaliaDto === null || requestParameters.filtroAssegniItaliaDto === undefined) {
        throw new runtime.RequiredError('filtroAssegniItaliaDto', 'Required parameter requestParameters.filtroAssegniItaliaDto was null or undefined when calling elencoAssegniItaliaPdf.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/assegni/export/elenco/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroAssegniItaliaDtoToJSON(requestParameters.filtroAssegniItaliaDto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Export in PDF o Excel degli assegni in base ai criteri inviati per il rapporto indicato
   */
  elencoAssegniItaliaPdf(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.elencoAssegniItaliaPdfRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce l\'elenco degli assegni Italia
   */
  getAssegniItaliaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.filtroAssegniItaliaDto === null || requestParameters.filtroAssegniItaliaDto === undefined) {
        throw new runtime.RequiredError('filtroAssegniItaliaDto', 'Required parameter requestParameters.filtroAssegniItaliaDto was null or undefined when calling getAssegniItalia.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/assegni/italia/elenco`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.FiltroAssegniItaliaDtoToJSON(requestParameters.filtroAssegniItaliaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.AssegniItaliaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce l\'elenco degli assegni Italia
   */
  getAssegniItalia(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getAssegniItaliaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura parametri assegno
   */
  parametersAssegnoRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/assegni/parametri`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriAssegniDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri assegno
   */
  parametersAssegno() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametersAssegnoRaw();
      return yield response.value();
    });
  }
}
exports.AssegniControllerApi = AssegniControllerApi;
/**
    * @export
    * @enum {string}
    */
var ElencoAssegniItaliaPdfTypeEnum;
(function (ElencoAssegniItaliaPdfTypeEnum) {
  ElencoAssegniItaliaPdfTypeEnum["pdf"] = "pdf";
  ElencoAssegniItaliaPdfTypeEnum["excel"] = "excel";
})(ElencoAssegniItaliaPdfTypeEnum = exports.ElencoAssegniItaliaPdfTypeEnum || (exports.ElencoAssegniItaliaPdfTypeEnum = {}));