"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EReduxActionTypes = void 0;
var EReduxActionTypes;
(function (EReduxActionTypes) {
  // PRELOGIN
  EReduxActionTypes["PRELOGIN_GET_PINNING_INFO_START"] = "PRELOGIN_GET_PINNING_INFO_START";
  EReduxActionTypes["PRELOGIN_GET_PINNING_INFO_SUCCESS"] = "PRELOGIN_GET_PINNING_INFO_SUCCESS";
  EReduxActionTypes["PRELOGIN_GET_PARAMETRI_START"] = "PRELOGIN_GET_PARAMETRI_START";
  EReduxActionTypes["PRELOGIN_GET_PARAMETRI_SUCCESS"] = "PRELOGIN_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["PRELOGIN_BLOCCO_CARTE_GET_RIFERIMENTI_START"] = "PRELOGIN_BLOCCO_CARTE_GET_RIFERIMENTI_START";
  EReduxActionTypes["PRELOGIN_BLOCCO_CARTE_GET_RIFERIMENTI_SUCCESS"] = "PRELOGIN_BLOCCO_CARTE_GET_RIFERIMENTI_SUCCESS";
  EReduxActionTypes["PRELOGIN_NUMERI_UTILI_START"] = "PRELOGIN_NUMERI_UTILI_START";
  EReduxActionTypes["PRELOGIN_NUMERI_UTILI_SUCCESS"] = "PRELOGIN_NUMERI_UTILI_SUCCESS";
  // POSTLOGIN
  EReduxActionTypes["POSTLOGIN_GET_PARAMETRI_START"] = "POSTLOGIN_GET_PARAMETRI_START";
  EReduxActionTypes["POSTLOGIN_GET_PARAMETRI_SUCCESS"] = "POSTLOGIN_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["POSTLOGIN_GET_LISTA_COMUNI_START"] = "POSTLOGIN_GET_LISTA_COMUNI_START";
  EReduxActionTypes["POSTLOGIN_GET_LISTA_COMUNI_SUCCESS"] = "POSTLOGIN_GET_LISTA_COMUNI_SUCCESS";
  EReduxActionTypes["POSTLOGIN_GET_TIPO_DOCUMENTO_START"] = "POSTLOGIN_GET_TIPO_DOCUMENTO_START";
  EReduxActionTypes["POSTLOGIN_GET_TIPO_DOCUMENTO_SUCCESS"] = "POSTLOGIN_GET_TIPO_DOCUMENTO_SUCCESS";
  // LOGIN
  EReduxActionTypes["LOGIN_START"] = "LOGIN_START";
  EReduxActionTypes["LOGIN_SUCCESS"] = "LOGIN_SUCCESS";
  EReduxActionTypes["LOGIN_ERROR"] = "LOGIN_ERROR";
  EReduxActionTypes["LOGOUT_START"] = "LOGOUT_START";
  EReduxActionTypes["LOGOUT_SUCCESS"] = "LOGOUT_SUCCESS";
  EReduxActionTypes["LOGOUT_ERROR"] = "LOGOUT_ERROR";
  // GENERAL
  EReduxActionTypes["RESET_ALL"] = "RESET_ALL";
  EReduxActionTypes["SET_GENERAL_LOADER"] = "SET_GENERAL_LOADER";
  EReduxActionTypes["SET_GENERAL_WAITING_SECTION"] = "SET_GENERAL_WAITING_SECTION";
  EReduxActionTypes["SET_SESSION_EXPIRED"] = "SET_SESSION_EXPIRED";
  EReduxActionTypes["SET_GENERAL_ERROR"] = "SET_GENERAL_ERROR";
  EReduxActionTypes["RESET_GENERAL_ERROR"] = "RESET_GENERAL_ERROR";
  EReduxActionTypes["SET_GENERAL_ROUTE"] = "SET_GENERAL_ROUTE";
  EReduxActionTypes["SET_GENERAL_WEBMENU"] = "SET_GENERAL_WEBMENU";
  EReduxActionTypes["SET_SUBMENU_MOBILE"] = "SET_SUBMENU_MOBILE";
  EReduxActionTypes["SET_GENERAL_BLURTEXT"] = "SET_GENERAL_BLURTEXT";
  EReduxActionTypes["SET_GENERAL_HOME_BONIFICO"] = "SET_GENERAL_HOME_BONIFICO";
  EReduxActionTypes["SET_CURRENT_TAB"] = "SET_CURRENT_TAB";
  EReduxActionTypes["SET_GENERAL_BREAD_CRUMBS"] = "SET_GENERAL_BREAD_CRUMBS";
  EReduxActionTypes["SET_GENERAL_ISCONNECTED"] = "SET_GENERAL_ISCONNECTED";
  EReduxActionTypes["SHOW_TOOLTIP_MOBILE"] = "SHOW_TOOLTIP_MOBILE";
  EReduxActionTypes["SET_BOTTOM_TAB_BAR_VISIBLE"] = "SET_BOTTOM_TAB_BAR_VISIBLE";
  EReduxActionTypes["SET_MOBILE_THEME"] = "SET_MOBILE_THEME";
  EReduxActionTypes["GENERAL_ADD_SNACKBAR"] = "GENERAL_ADD_SNACKBAR";
  EReduxActionTypes["GENERAL_ADD_OR_REPLACE_SNACKBAR"] = "GENERAL_ADD_OR_REPLACE_SNACKBAR";
  EReduxActionTypes["GENERAL_DEL_SNACKBAR"] = "GENERAL_DEL_SNACKBAR";
  EReduxActionTypes["SET_GENERAL_CALLER_OBJECT"] = "SET_GENERAL_CALLER_OBJECT";
  EReduxActionTypes["RESET_GENERAL_CALLER_OBJECT"] = "RESET_GENERAL_CALLER_OBJECT";
  EReduxActionTypes["SET_GENERAL_HOME_RAV"] = "SET_GENERAL_HOME_RAV";
  EReduxActionTypes["SET_GENERAL_HOME_MAV"] = "SET_GENERAL_HOME_MAV";
  EReduxActionTypes["SET_GENERAL_NO_RAPPORTI"] = "SET_GENERAL_NO_RAPPORTI";
  EReduxActionTypes["RESET_GENERAL_NO_RAPPORTI"] = "RESET_GENERAL_NO_RAPPORTI";
  EReduxActionTypes["RESET_GENERAL_POST_LOGIN_ERROR"] = "RESET_GENERAL_POST_LOGIN_ERROR";
  EReduxActionTypes["SET_GENERAL_POST_LOGIN_ERROR"] = "SET_GENERAL_POST_LOGIN_ERROR";
  EReduxActionTypes["SET_ASSISTENTE_OPENED"] = "SET_ASSISTENTE_OPENED";
  EReduxActionTypes["ENROLLMENT_PSD2_ATTIVA_SUCCESS"] = "ENROLLMENT_PSD2_ATTIVA_SUCCESS";
  EReduxActionTypes["GENERAL_REFRESH_WIDGETS"] = "GENERAL_REFRESH_WIDGETS";
  EReduxActionTypes["GENERAL_RESET_CACHE_POST_DISPO"] = "GENERAL_RESET_CACHE_POST_DISPO";
  EReduxActionTypes["GENERAL_RESET_POST_CAMBIO_LINGUA"] = "GENERAL_RESET_POST_CAMBIO_LINGUA";
  EReduxActionTypes["GENERAL_SET_ERRORE_ADEGUATA_VERIFICA"] = "GENERAL_SET_ERRORE_ADEGUATA_VERIFICA";
  EReduxActionTypes["GENERAL_RESET_ERRORE_ADEGUATA_VERIFICA"] = "GENERAL_RESET_ERRORE_ADEGUATA_VERIFICA";
  EReduxActionTypes["GENERAL_EXT_LOGGING_OPERATION_START"] = "GENERAL_EXT_LOGGING_OPERATION_START";
  EReduxActionTypes["GENERAL_EXT_LOGGING_OPERATION_SUCCESS"] = "GENERAL_EXT_LOGGING_OPERATION_SUCCESS";
  EReduxActionTypes["GENERAL_LOGGING_OPERATION_START"] = "GENERAL_LOGGING_OPERATION_START";
  EReduxActionTypes["GENERAL_LOGGING_OPERATION_SUCCESS"] = "GENERAL_LOGGING_OPERATION_SUCCESS";
  EReduxActionTypes["GENERAL_SET_CHAT_GENESYS_OPENED"] = "GENERAL_SET_CHAT_GENESYS_OPENED";
  EReduxActionTypes["GENERAL_SET_IN_APP_BROWSER_OPENED"] = "GENERAL_SET_IN_APP_BROWSER_OPENED";
  EReduxActionTypes["SET_ACS_LOGIN_ASKED"] = "SET_ACS_LOGIN_ASKED";
  EReduxActionTypes["SET_DOWNLOAD_PDF"] = "SET_DOWNLOAD_PDF";
  EReduxActionTypes["SET_ANDROID_PERMISSION_GRANTED"] = "SET_ANDROID_PERMISSION_GRANTED";
  EReduxActionTypes["GENERAL_LOGGING_ENROLLMENT_START"] = "GENERAL_LOGGING_ENROLLMENT_START";
  EReduxActionTypes["GENERAL_LOGGING_ENROLLMENT_SUCCESS"] = "GENERAL_LOGGING_ENROLLMENT_SUCCESS";
  // WIDGETS
  EReduxActionTypes["GET_WIDGETS_UTENTE_START"] = "GET_WIDGETS_UTENTE_START";
  EReduxActionTypes["GET_WIDGETS_UTENTE_SUCCESS"] = "GET_WIDGETS_UTENTE_SUCCESS";
  EReduxActionTypes["SALVA_WIDGETS_START"] = "SALVA_WIDGETS_START";
  EReduxActionTypes["SALVA_WIDGETS_SUCCESS"] = "SALVA_WIDGETS_SUCCESS";
  EReduxActionTypes["GET_PARAMETRI_WIDGET_START"] = "GET_PARAMETRI_WIDGET_START";
  EReduxActionTypes["GET_PARAMETRI_WIDGET_SUCCESS"] = "GET_PARAMETRI_WIDGET_SUCCESS";
  EReduxActionTypes["WIDGETS_RESET"] = "WIDGETS_RESET";
  EReduxActionTypes["PUSH_GENERAL_POPUP"] = "PUSH_GENERAL_POPUP";
  EReduxActionTypes["REMOVE_GENERAL_POPUP"] = "REMOVE_GENERAL_POPUP";
  EReduxActionTypes["SET_DISABLE_BUTTON_OK_CURRENT_GENERAL_POPUP"] = "SET_DISABLE_BUTTON_OK_CURRENT_GENERAL_POPUP";
  EReduxActionTypes["GENERAL_ADD_BANNER"] = "GENERAL_ADD_BANNER";
  EReduxActionTypes["GENERAL_DEL_BANNER"] = "GENERAL_DEL_BANNER";
  EReduxActionTypes["SET_NEOSURANCE_LOGIN_ASKED"] = "SET_NEOSURANCE_LOGIN_ASKED";
  EReduxActionTypes["SET_AZIONI_SMART_VISIBLE"] = "SET_AZIONI_SMART_VISIBLE";
  // MENU
  EReduxActionTypes["MENU_GET_MENU_START"] = "MENU_GET_MENU_START";
  EReduxActionTypes["MENU_GET_MENU_SUCCESS"] = "MENU_GET_MENU_SUCCESS";
  EReduxActionTypes["MENU_GET_MENU_SUCCESS_MOBILE"] = "MENU_GET_MENU_SUCCESS_MOBILE";
  EReduxActionTypes["MENU_GET_TAGS_START"] = "MENU_GET_TAGS_START";
  EReduxActionTypes["MENU_GET_TAGS_SUCCESS"] = "MENU_GET_TAGS_SUCCESS";
  EReduxActionTypes["MENU_GET_NOTIFICHE_MENU_START"] = "MENU_GET_NOTIFICHE_MENU_START";
  EReduxActionTypes["MENU_GET_NOTIFICHE_MENU_SUCCESS"] = "MENU_GET_NOTIFICHE_MENU_SUCCESS";
  EReduxActionTypes["MENU_SET_TEXT_SEARCH"] = "MENU_SET_TEXT_SEARCH";
  EReduxActionTypes["MENU_RESET_TEXT_SEARCH"] = "MENU_RESET_TEXT_SEARCH";
  EReduxActionTypes["MENU_SET_SMART_OTP_ENROLLED_USERNAME"] = "MENU_SET_SMART_OTP_ENROLLED_USERNAME";
  // BONIFICO
  EReduxActionTypes["BONIFICO_SEPA_GET_PARAMETRI_START"] = "BONIFICO_SEPA_GET_PARAMETRI_START";
  EReduxActionTypes["BONIFICO_SEPA_GET_PARAMETRI_SUCCESS"] = "BONIFICO_SEPA_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_GET_PARAMETRI_ERROR"] = "BONIFICO_SEPA_GET_PARAMETRI_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "BONIFICO_SEPA_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_CONTROLLO_START"] = "BONIFICO_SEPA_CONTROLLO_START";
  EReduxActionTypes["BONIFICO_SEPA_CONTROLLO_SUCCESS"] = "BONIFICO_SEPA_CONTROLLO_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_CONTROLLO_ERROR"] = "BONIFICO_SEPA_CONTROLLO_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_GET_INFO_DISPOSITIVA_START"] = "BONIFICO_SEPA_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["BONIFICO_SEPA_GET_INFO_DISPOSITIVA_SUCCESS"] = "BONIFICO_SEPA_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_GET_INFO_DISPOSITIVA_ERROR"] = "BONIFICO_SEPA_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_AUTORIZZA_START"] = "BONIFICO_SEPA_AUTORIZZA_START";
  EReduxActionTypes["BONIFICO_SEPA_AUTORIZZA_SUCCESS"] = "BONIFICO_SEPA_AUTORIZZA_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_AUTORIZZA_ERROR"] = "BONIFICO_SEPA_AUTORIZZA_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_RESET"] = "BONIFICO_SEPA_RESET";
  EReduxActionTypes["BONIFICO_SEPA_RESET_RIEPILOGO"] = "BONIFICO_SEPA_RESET_RIEPILOGO";
  EReduxActionTypes["BONIFICO_SEPA_CF_PI_ORDINANTI"] = "BONIFICO_SEPA_CF_PI_ORDINANTI";
  EReduxActionTypes["BONIFICO_SEPA_CF_PI_BENEFICIARIO"] = "BONIFICO_SEPA_CF_PI_BENEFICIARIO";
  EReduxActionTypes["BONIFICO_SEPA_CF_PI_AMMINISTRATORE"] = "BONIFICO_SEPA_CF_PI_AMMINISTRATORE";
  EReduxActionTypes["BONIFICO_SEPA_MOTIVAZIONE_PAGAMENTO"] = "BONIFICO_SEPA_MOTIVAZIONE_PAGAMENTO";
  EReduxActionTypes["BONIFICO_SEPA_BENEFICIARIO"] = "BONIFICO_SEPA_BENEFICIARIO";
  EReduxActionTypes["BONIFICO_SEPA_IBAN"] = "BONIFICO_SEPA_IBAN";
  EReduxActionTypes["BONIFICO_SEPA_ADD_RUBRICA"] = "BONIFICO_SEPA_ADD_RUBRICA";
  EReduxActionTypes["BONIFICO_SEPA_ADD_WHITELIST"] = "BONIFICO_SEPA_ADD_WHITELIST";
  EReduxActionTypes["BONIFICO_SEPA_SET_ID_CONTATTO_BENEFICIARIO"] = "BONIFICO_SEPA_SET_ID_CONTATTO_BENEFICIARIO";
  EReduxActionTypes["BONIFICO_SEPA_IMPORTO"] = "BONIFICO_SEPA_IMPORTO";
  EReduxActionTypes["BONIFICO_SEPA_DATA_ESEC"] = "BONIFICO_SEPA_DATA_ESEC";
  EReduxActionTypes["BONIFICO_SEPA_DESCRIZIONE"] = "BONIFICO_SEPA_DESCRIZIONE";
  EReduxActionTypes["BONIFICO_SEPA_SAVE_1CLICK"] = "BONIFICO_SEPA_SAVE_1CLICK";
  EReduxActionTypes["BONIFICO_SEPA_ALIAS_1CLICK"] = "BONIFICO_SEPA_ALIAS_1CLICK";
  EReduxActionTypes["BONIFICO_SEPA_ORDINANTE_EFF"] = "BONIFICO_SEPA_ORDINANTE EFF";
  EReduxActionTypes["BONIFICO_SEPA_STEP"] = "BONIFICO_SEPA_STEP";
  EReduxActionTypes["BONIFICO_SEPA_INTESTATARIO"] = "BONIFICO_SEPA_INTESTATARIO";
  EReduxActionTypes["BONIFICO_SEPA_IBAN_INT"] = "BONIFICO_SEPA_IBAN_INT";
  EReduxActionTypes["BONIFICO_SEPA_SET_RAPPORTO_SELEZIONATO"] = "BONIFICO_SEPA_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["BONIFICO_FORM_SUBMITTED"] = "BONIFICO_FORM_SUBMITTED";
  EReduxActionTypes["BONIFICO_ACCORDION_DATI_AGGIUNTIVI_OPENED"] = "BONIFICO_ACCORDION_DATI_AGGIUNTIVI_OPENED";
  EReduxActionTypes["BONIFICO_SEPA_GET_ONECLICK_START"] = "BONIFICO_SEPA_GET_ONECLICK_START";
  EReduxActionTypes["BONIFICO_SEPA_GET_ONECLICK_SUCCESS"] = "BONIFICO_SEPA_GET_ONECLICK_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_GET_ONECLICK_ERROR"] = "BONIFICO_SEPA_GET_ONECLICK_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_SET_RIEPILOGO_REVOCA"] = "BONIFICO_SEPA_SET_RIEPILOGO_REVOCA";
  EReduxActionTypes["BONIFICO_SEPA_SET_PROVENIENZA"] = "BONIFICO_SEPA_SET_PROVENIENZA";
  EReduxActionTypes["BONIFICO_SEPA_STEP_REVOCA"] = "BONIFICO_SEPA_STEP_REVOCA";
  EReduxActionTypes["BONIFICO_SEPA_GET_INFO_REVOCA_START"] = "BONIFICO_SEPA_GET_INFO_REVOCA_START";
  EReduxActionTypes["BONIFICO_SEPA_GET_INFO_REVOCA_SUCCESS"] = "BONIFICO_SEPA_GET_INFO_REVOCA_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_GET_INFO_REVOCA_ERROR"] = "BONIFICO_SEPA_GET_INFO_REVOCA_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_AUTORIZZA_REVOCA_START"] = "BONIFICO_SEPA_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["BONIFICO_SEPA_AUTORIZZA_REVOCA_SUCCESS"] = "BONIFICO_SEPA_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_AUTORIZZA_REVOCA_ERROR"] = "BONIFICO_SEPA_AUTORIZZA_REVOCA_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_RESET_BOZZE"] = "BONIFICO_SEPA_RESET_BOZZE";
  EReduxActionTypes["BONIFICO_SEPA_GET_BOZZE_START"] = "BONIFICO_SEPA_GET_BOZZE_START";
  EReduxActionTypes["BONIFICO_SEPA_GET_BOZZE_SUCCESS"] = "BONIFICO_SEPA_GET_BOZZE_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_GET_BOZZE_ERROR"] = "BONIFICO_SEPA_GET_BOZZE_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_GET_BOZZA_START"] = "BONIFICO_SEPA_GET_BOZZA_START";
  EReduxActionTypes["BONIFICO_SEPA_GET_BOZZA_SUCCESS"] = "BONIFICO_SEPA_GET_BOZZA_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_GET_BOZZA_ERROR"] = "BONIFICO_SEPA_GET_BOZZA_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_GET_BONIFICO_FROM_BOZZA"] = "BONIFICO_SEPA_GET_BONIFICO_FROM_BOZZA";
  EReduxActionTypes["BONIFICO_SEPA_ADD_BOZZA_START"] = "BONIFICO_SEPA_ADD_BOZZA_START";
  EReduxActionTypes["BONIFICO_SEPA_ADD_BOZZA_SUCCESS"] = "BONIFICO_SEPA_ADD_BOZZA_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_ADD_BOZZA_ERROR"] = "BONIFICO_SEPA_ADD_BOZZA_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_DEL_BOZZA_START"] = "BONIFICO_SEPA_DEL_BOZZA_START";
  EReduxActionTypes["BONIFICO_SEPA_DEL_BOZZA_SUCCESS"] = "BONIFICO_SEPA_DEL_BOZZA_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_DEL_BOZZA_ERROR"] = "BONIFICO_SEPA_DEL_BOZZA_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_UPDATE_BOZZA_START"] = "BONIFICO_SEPA_UPDATE_BOZZA_START";
  EReduxActionTypes["BONIFICO_SEPA_UPDATE_BOZZA_SUCCESS"] = "BONIFICO_SEPA_UPDATE_BOZZA_SUCCESS";
  EReduxActionTypes["BONIFICO_SEPA_UPDATE_BOZZA_ERROR"] = "BONIFICO_SEPA_UPDATE_BOZZA_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_RESET_ESITO"] = "BONIFICO_SEPA_RESET_ESITO";
  EReduxActionTypes["BONIFICO_SEPA_SET_ISTANTANEO"] = "BONIFICO_SEPA_SET_ISTANTANEO";
  EReduxActionTypes["BONIFICO_SEPA_SET_MOSTRA_ISTANTANEO"] = "BONIFICO_SEPA_SET_MOSTRA_ISTANTANEO";
  EReduxActionTypes["BONIFICO_SEPA_ISTANTANEO_ERROR"] = "BONIFICO_SEPA_ISTANTANEO_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_ISTANTANEO_RESET_ERROR"] = "BONIFICO_SEPA_ISTANTANEO_RESET_ERROR";
  EReduxActionTypes["BONIFICO_SEPA_SET_CONTATTO_SELEZIONATO"] = "BONIFICO_SEPA_SET_CONTATTO_SELEZIONATO";
  EReduxActionTypes["BONIFICO_SEPA_PSD2_WEBVIEW_LINK_CONTROLLO"] = "BONIFICO_SEPA_PSD2_WEBVIEW_LINK_CONTROLLO";
  EReduxActionTypes["BONIFICO_SEPA_RESET_REVOCA"] = "BONIFICO_SEPA_RESET_REVOCA";
  EReduxActionTypes["BONIFICO_SEPA_SET_USERNAME_PSD2"] = "BONIFICO_SEPA_SET_USERNAME_PSD2";
  EReduxActionTypes["BONIFICO_SEPA_RESET_INFO_DISPOSITIVA"] = "BONIFICO_SEPA_RESET_INFO_DISPOSITIVA";
  EReduxActionTypes["BONIFICO_DF_SET_CHECKBOX_PAGAMENTO_SPESE_SU_PARTI_COMUNI_CONDOMINIALI"] = "BONIFICO_DF_SET_CHECKBOX_PAGAMENTO_SPESE_SU_PARTI_COMUNI_CONDOMINIALI";
  EReduxActionTypes["MODIFICA_MASSIMALE_BONIFICO_ISTA_START"] = "MODIFICA_MASSIMALE_BONIFICO_ISTA_START";
  EReduxActionTypes["MODIFICA_MASSIMALE_BONIFICO_ISTA_SUCCESS"] = "MODIFICA_MASSIMALE_BONIFICO_ISTA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_PARAMETRI_START"] = "BONIFICO_RIPETITIVO_GET_PARAMETRI_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_PARAMETRI_SUCCESS"] = "BONIFICO_RIPETITIVO_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_PARAMETRI_ELENCO_START"] = "BONIFICO_RIPETITIVO_GET_PARAMETRI_ELENCO_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_PARAMETRI_ELENCO_SUCCESS"] = "BONIFICO_RIPETITIVO_GET_PARAMETRI_ELENCO_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_PARAMETRI_ERROR"] = "BONIFICO_RIPETITIVO_GET_PARAMETRI_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "BONIFICO_RIPETITIVO_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_PARAMETRI_PER_MODIFICA_SUCCESS"] = "BONIFICO_RIPETITIVO_GET_PARAMETRI_PER_MODIFICA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_CONTROLLO_START"] = "BONIFICO_RIPETITIVO_CONTROLLO_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_CONTROLLO_SUCCESS"] = "BONIFICO_RIPETITIVO_CONTROLLO_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_CONTROLLO_ERROR"] = "BONIFICO_RIPETITIVO_CONTROLLO_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_CONTROLLO_MODIFICA_START"] = "BONIFICO_RIPETITIVO_CONTROLLO_MODIFICA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_CONTROLLO_MODIFICA_SUCCESS"] = "BONIFICO_RIPETITIVO_CONTROLLO_MODIFICA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_CONTROLLO_MODIFICA_ERROR"] = "BONIFICO_RIPETITIVO_CONTROLLO_MODIFICA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_DISPOSITIVA_START"] = "BONIFICO_RIPETITIVO_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_DISPOSITIVA_SUCCESS"] = "BONIFICO_RIPETITIVO_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_DISPOSITIVA_ERROR"] = "BONIFICO_RIPETITIVO_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_MODIFICA_DISPOSITIVA_START"] = "BONIFICO_RIPETITIVO_GET_INFO_MODIFICA_DISPOSITIVA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_MODIFICA_DISPOSITIVA_SUCCESS"] = "BONIFICO_RIPETITIVO_GET_INFO_MODIFICA_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_MODIFICA_DISPOSITIVA_ERROR"] = "BONIFICO_RIPETITIVO_GET_INFO_MODIFICA_DISPOSITIVA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_START"] = "BONIFICO_RIPETITIVO_AUTORIZZA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_SUCCESS"] = "BONIFICO_RIPETITIVO_AUTORIZZA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_ERROR"] = "BONIFICO_RIPETITIVO_AUTORIZZA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_MODIFICA_START"] = "BONIFICO_RIPETITIVO_AUTORIZZA_MODIFICA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_MODIFICA_SUCCESS"] = "BONIFICO_RIPETITIVO_AUTORIZZA_MODIFICA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_MODIFICA_ERROR"] = "BONIFICO_RIPETITIVO_AUTORIZZA_MODIFICA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_RESET"] = "BONIFICO_RIPETITIVO_RESET";
  EReduxActionTypes["BONIFICO_RIPETITIVO_RESET_RIEPILOGO"] = "BONIFICO_RIPETITIVO_RESET_RIEPILOGO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_CF_PI_ORDINANTI"] = "BONIFICO_RIPETITIVO_CF_PI_ORDINANTI";
  EReduxActionTypes["BONIFICO_RIPETITIVO_CF_PI_BENEFICIARIO"] = "BONIFICO_RIPETITIVO_CF_PI_BENEFICIARIO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_CF_PI_AMMINISTRATORE"] = "BONIFICO_RIPETITIVO_CF_PI_AMMINISTRATORE";
  EReduxActionTypes["BONIFICO_RIPETITIVO_BENEFICIARIO"] = "BONIFICO_RIPETITIVO_BENEFICIARIO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_IBAN"] = "BONIFICO_RIPETITIVO_IBAN";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ID_CONTATTO_BENEFICIARIO"] = "BONIFICO_RIPETITIVO_ID_CONTATTO_BENEFICIARIO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ADD_RUBRICA"] = "BONIFICO_RIPETITIVO_ADD_RUBRICA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ADD_WHITELIST"] = "BONIFICO_RIPETITIVO_ADD_WHITELIST";
  EReduxActionTypes["BONIFICO_RIPETITIVO_IMPORTO"] = "BONIFICO_RIPETITIVO_IMPORTO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_DATA_ESEC"] = "BONIFICO_RIPETITIVO_DATA_ESEC";
  EReduxActionTypes["BONIFICO_RIPETITIVO_DESCRIZIONE"] = "BONIFICO_RIPETITIVO_DESCRIZIONE";
  EReduxActionTypes["BONIFICO_RIPETITIVO_DATA_DA"] = "BONIFICO_RIPETITIVO_DATA_DA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_DATA_A"] = "BONIFICO_RIPETITIVO_DATA_A";
  EReduxActionTypes["BONIFICO_RIPETITIVO_TIPO_IMPORTO"] = "BONIFICO_RIPETITIVO_TIPO_IMPORTO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_TIPO_DATA_PAGAMENTO"] = "BONIFICO_RIPETITIVO_TIPO_DATA_PAGAMENTO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_PERIODO"] = "BONIFICO_RIPETITIVO_PERIODO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_TIPO_DISPOSIZIONE"] = "BONIFICO_RIPETITIVO_TIPO_DISPOSIZIONE";
  EReduxActionTypes["BONIFICO_RIPETITIVO_DATA_SCADENZA"] = "BONIFICO_RIPETITIVO_DATA_SCADENZA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_NUMERO_RATE"] = "BONIFICO_RIPETITIVO_NUMERO_RATE";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GIORNO"] = "BONIFICO_RIPETITIVO_GIORNO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_FINE_MESE"] = "BONIFICO_RIPETITIVO_FINE_MESE";
  EReduxActionTypes["BONIFICO_RIPETITIVO_DATA_PROSSIMO_PAGAMENTO"] = "BONIFICO_RIPETITIVO_DATA_PROSSIMO_PAGAMENTO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_RATA_SELECTED"] = "BONIFICO_RIPETITIVO_RATA_SELECTED";
  EReduxActionTypes["BONIFICO_RIPETITIVO_RATA_GIORNO"] = "BONIFICO_RIPETITIVO_RATA_GIORNO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_RATA_IMPORTO"] = "BONIFICO_RIPETITIVO_RATA_IMPORTO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_STEP"] = "BONIFICO_RIPETITIVO_STEP";
  EReduxActionTypes["BONIFICO_RIPETITIVO_INTESTATARIO"] = "BONIFICO_RIPETITIVO_INTESTATARIO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_IBAN_INT"] = "BONIFICO_RIPETITIVO_IBAN_INT";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_RAPPORTO_SELEZIONATO"] = "BONIFICO_RIPETITIVO_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["BONIFICO_RIPETITIVO_FORM_SUBMITTED"] = "BONIFICO_RIPETITIVO_FORM_SUBMITTED";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_RIEPILOGO_REVOCA"] = "BONIFICO_RIPETITIVO_SET_RIEPILOGO_REVOCA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_PROVENIENZA"] = "BONIFICO_RIPETITIVO_SET_PROVENIENZA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_STEP_REVOCA"] = "BONIFICO_RIPETITIVO_STEP_REVOCA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_REVOCA_START"] = "BONIFICO_RIPETITIVO_GET_INFO_REVOCA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_REVOCA_SUCCESS"] = "BONIFICO_RIPETITIVO_GET_INFO_REVOCA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_REVOCA_ERROR"] = "BONIFICO_RIPETITIVO_GET_INFO_REVOCA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_REVOCA_START"] = "BONIFICO_RIPETITIVO_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_REVOCA_SUCCESS"] = "BONIFICO_RIPETITIVO_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_REVOCA_ERROR"] = "BONIFICO_RIPETITIVO_AUTORIZZA_REVOCA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_BOZZASALVATA"] = "BONIFICO_RIPETITIVO_SET_BOZZASALVATA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_BOZZE_START"] = "BONIFICO_RIPETITIVO_GET_BOZZE_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_BOZZE_SUCCESS"] = "BONIFICO_RIPETITIVO_GET_BOZZE_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_BOZZE_ERROR"] = "BONIFICO_RIPETITIVO_GET_BOZZE_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_BOZZA_START"] = "BONIFICO_RIPETITIVO_GET_BOZZA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_BOZZA_SUCCESS"] = "BONIFICO_RIPETITIVO_GET_BOZZA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_BOZZA_ERROR"] = "BONIFICO_RIPETITIVO_GET_BOZZA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_BONIFICO_FROM_BOZZA"] = "BONIFICO_RIPETITIVO_GET_BONIFICO_FROM_BOZZA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_BONIFICO_FOR_MODIFICA"] = "BONIFICO_RIPETITIVO_GET_BONIFICO_FOR_MODIFICA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ADD_BOZZA_START"] = "BONIFICO_RIPETITIVO_ADD_BOZZA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ADD_BOZZA_SUCCESS"] = "BONIFICO_RIPETITIVO_ADD_BOZZA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ADD_BOZZA_ERROR"] = "BONIFICO_RIPETITIVO_ADD_BOZZA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_DEL_BOZZA_START"] = "BONIFICO_RIPETITIVO_DEL_BOZZA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_DEL_BOZZA_SUCCESS"] = "BONIFICO_RIPETITIVO_DEL_BOZZA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_DEL_BOZZA_ERROR"] = "BONIFICO_RIPETITIVO_DEL_BOZZA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_UPDATE_BOZZA_START"] = "BONIFICO_RIPETITIVO_UPDATE_BOZZA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_UPDATE_BOZZA_SUCCESS"] = "BONIFICO_RIPETITIVO_UPDATE_BOZZA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_UPDATE_BOZZA_ERROR"] = "BONIFICO_RIPETITIVO_UPDATE_BOZZA_ERROR";
  EReduxActionTypes["BONIFICI_RIPETITIVI_GET_ELENCO_START"] = "BONIFICI_RIPETITIVI_GET_ELENCO_START";
  EReduxActionTypes["BONIFICI_RIPETITIVI_GET_ELENCO_SUCCESS"] = "BONIFICI_RIPETITIVI_GET_ELENCO_SUCCESS";
  EReduxActionTypes["BONIFICI_RIPETITIVI_GET_ELENCO_ERROR"] = "BONIFICI_RIPETITIVI_GET_ELENCO_ERROR";
  EReduxActionTypes["BONIFICI_RIPETITIVI_GET_DETTAGLIO_BONIFICO_RIPETITIVO_START"] = "BONIFICI_RIPETITIVI_GET_DETTAGLIO_BONIFICO_RIPETITIVO_START";
  EReduxActionTypes["BONIFICI_RIPETITIVI_GET_DETTAGLIO_BONIFICO_RIPETITIVO_SUCCESS"] = "BONIFICI_RIPETITIVI_GET_DETTAGLIO_BONIFICO_RIPETITIVO_SUCCESS";
  EReduxActionTypes["BONIFICI_RIPETITIVI_GET_DETTAGLIO_BONIFICO_RIPETITIVO_ERROR"] = "BONIFICI_RIPETITIVI_GET_DETTAGLIO_BONIFICO_RIPETITIVO_ERROR";
  EReduxActionTypes["BONIFICI_RIPETITIVI_SET_PAGAMENTO_RIPETIVIVO_SELEZIONATO"] = "BONIFICI_RIPETITIVI_SET_PAGAMENTO_RIPETIVIVO_SELEZIONATO";
  EReduxActionTypes["BONIFICI_RIPETITIVI_SET_RAPPORTO_SELEZIONATO"] = "BONIFICI_RIPETITIVI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["BONIFICI_RIPETITIVI_SET_BENEFICIARIO"] = "BONIFICI_RIPETITIVI_SET_BENEFICIARIO";
  EReduxActionTypes["BONIFICI_RIPETITIVI_SET_STATI"] = "BONIFICI_RIPETITIVI_SET_STATI";
  EReduxActionTypes["BONIFICI_RIPETITIVI_SET_CAUSALE"] = "BONIFICI_RIPETITIVI_SET_CAUSALE";
  EReduxActionTypes["BONIFICI_RIPETITIVI_SET_IMPORTO"] = "BONIFICI_RIPETITIVI_SET_IMPORTO";
  EReduxActionTypes["BONIFICI_RIPETITIVI_SET_FORM_SUBMITTED"] = "BONIFICI_RIPETITIVI_SET_FORM_SUBMITTED";
  EReduxActionTypes["BONIFICI_RIPETITIVI_SET_ACCORDION_FILTRI_OPENED"] = "BONIFICI_RIPETITIVI_SET_ACCORDION_FILTRI_OPENED";
  EReduxActionTypes["BONIFICI_RIPETITIVI_RESET_FORM_ELENCO"] = "BONIFICI_RIPETITIVI_RESET_FORM_ELENCO";
  EReduxActionTypes["BONIFICI_RIPETITIVI_RESET_DETTAGLIO_BONIFICO_RIPETITIVO"] = "BONIFICI_RIPETITIVI_RESET_DETTAGLIO_BONIFICO_RIPETITIVO";
  EReduxActionTypes["BONIFICI_RIPETITIVI_SET_OFFSET"] = "BONIFICI_RIPETITIVI_SET_OFFSET";
  EReduxActionTypes["BONIFICI_RIPETITIVI_SET_FORM_DIRTY"] = "BONIFICI_RIPETITIVI_SET_FORM_DIRTY";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_RIEPILOGO_SOSPENDI"] = "BONIFICO_RIPETITIVO_SET_RIEPILOGO_SOSPENDI";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_PROVENIENZA_SOSPENDI"] = "BONIFICO_RIPETITIVO_SET_PROVENIENZA_SOSPENDI";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_DATA_INIZIO_SOSPENSIONE_SOSPENDI"] = "BONIFICO_RIPETITIVO_SET_DATA_INIZIO_SOSPENSIONE_SOSPENDI";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_DATA_FINE_SOSPENSIONE_SOSPENDI"] = "BONIFICO_RIPETITIVO_SET_DATA_FINE_SOSPENSIONE_SOSPENDI";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_STEP_SOSPENDI"] = "BONIFICO_RIPETITIVO_SET_STEP_SOSPENDI";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_SOSPENDI_START"] = "BONIFICO_RIPETITIVO_GET_INFO_SOSPENDI_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_SOSPENDI_SUCCESS"] = "BONIFICO_RIPETITIVO_GET_INFO_SOSPENDI_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_SOSPENDI_ERROR"] = "BONIFICO_RIPETITIVO_GET_INFO_SOSPENDI_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_SOSPENDI_START"] = "BONIFICO_RIPETITIVO_AUTORIZZA_SOSPENDI_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_SOSPENDI_SUCCESS"] = "BONIFICO_RIPETITIVO_AUTORIZZA_SOSPENDI_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_SOSPENDI_ERROR"] = "BONIFICO_RIPETITIVO_AUTORIZZA_SOSPENDI_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_RIEPILOGO_RIATTIVA"] = "BONIFICO_RIPETITIVO_SET_RIEPILOGO_RIATTIVA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_PROVENIENZA_RIATTIVA"] = "BONIFICO_RIPETITIVO_SET_PROVENIENZA_RIATTIVA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_DATA_RIATTIVAZIONE_RIATTIVA"] = "BONIFICO_RIPETITIVO_SET_DATA_RIATTIVAZIONE_RIATTIVA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_SET_STEP_RIATTIVA"] = "BONIFICO_RIPETITIVO_SET_STEP_RIATTIVA";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_RIATTIVA_START"] = "BONIFICO_RIPETITIVO_GET_INFO_RIATTIVA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_RIATTIVA_SUCCESS"] = "BONIFICO_RIPETITIVO_GET_INFO_RIATTIVA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_GET_INFO_RIATTIVA_ERROR"] = "BONIFICO_RIPETITIVO_GET_INFO_RIATTIVA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_RIATTIVA_START"] = "BONIFICO_RIPETITIVO_AUTORIZZA_RIATTIVA_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_RIATTIVA_SUCCESS"] = "BONIFICO_RIPETITIVO_AUTORIZZA_RIATTIVA_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_AUTORIZZA_RIATTIVA_ERROR"] = "BONIFICO_RIPETITIVO_AUTORIZZA_RIATTIVA_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ESPORTA_PDF_ELENCO_RIPETITIVI_START"] = "BONIFICO_RIPETITIVO_ESPORTA_PDF_ELENCO_RIPETITIVI_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ESPORTA_PDF_ELENCO_RIPETITIVI_SUCCESS"] = "BONIFICO_RIPETITIVO_ESPORTA_PDF_ELENCO_RIPETITIVI_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ESPORTA_PDF_ELENCO_RIPETITIVI_ERROR"] = "BONIFICO_RIPETITIVO_ESPORTA_PDF_ELENCO_RIPETITIVI_ERROR";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ESPORTA_EXCEL_ELENCO_RIPETITIVI_START"] = "BONIFICO_RIPETITIVO_ESPORTA_EXCEL_ELENCO_RIPETITIVI_START";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ESPORTA_EXCEL_ELENCO_RIPETITIVI_SUCCESS"] = "BONIFICO_RIPETITIVO_ESPORTA_EXCEL_ELENCO_RIPETITIVI_SUCCESS";
  EReduxActionTypes["BONIFICO_RIPETITIVO_ESPORTA_EXCEL_ELENCO_RIPETITIVI_ERROR"] = "BONIFICO_RIPETITIVO_ESPORTA_EXCEL_ELENCO_RIPETITIVI_ERROR";
  // BONIFICO DEPOSITO
  EReduxActionTypes["BONIFICO_DEPOSITO_GET_PARAMETRI_START"] = "BONIFICO_DEPOSITO_GET_PARAMETRI_START";
  EReduxActionTypes["BONIFICO_DEPOSITO_GET_PARAMETRI_SUCCESS"] = "BONIFICO_DEPOSITO_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["BONIFICO_DEPOSITO_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "BONIFICO_DEPOSITO_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["BONIFICO_DEPOSITO_GET_PARAMETRI_ERROR"] = "BONIFICO_DEPOSITO_GET_PARAMETRI_ERROR";
  EReduxActionTypes["BONIFICO_DEPOSITO_CONTROLLO_START"] = "BONIFICO_DEPOSITO_CONTROLLO_START";
  EReduxActionTypes["BONIFICO_DEPOSITO_CONTROLLO_SUCCESS"] = "BONIFICO_DEPOSITO_CONTROLLO_SUCCESS";
  EReduxActionTypes["BONIFICO_DEPOSITO_CONTROLLO_ERROR"] = "BONIFICO_DEPOSITO_CONTROLLO_ERROR";
  EReduxActionTypes["BONIFICO_DEPOSITO_GET_INFO_DISPOSITIVA_START"] = "BONIFICO_DEPOSITO_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["BONIFICO_DEPOSITO_GET_INFO_DISPOSITIVA_SUCCESS"] = "BONIFICO_DEPOSITO_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["BONIFICO_DEPOSITO_GET_INFO_DISPOSITIVA_ERROR"] = "BONIFICO_DEPOSITO_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["BONIFICO_DEPOSITO_AUTORIZZA_START"] = "BONIFICO_DEPOSITO_AUTORIZZA_START";
  EReduxActionTypes["BONIFICO_DEPOSITO_AUTORIZZA_SUCCESS"] = "BONIFICO_DEPOSITO_AUTORIZZA_SUCCESS";
  EReduxActionTypes["BONIFICO_DEPOSITO_AUTORIZZA_ERROR"] = "BONIFICO_DEPOSITO_AUTORIZZA_ERROR";
  EReduxActionTypes["BONIFICO_DEPOSITO_RESET"] = "BONIFICO_DEPOSITO_RESET";
  EReduxActionTypes["BONIFICO_DEPOSITO_RESET_RIEPILOGO"] = "BONIFICO_DEPOSITO_RESET_RIEPILOGO";
  EReduxActionTypes["BONIFICO_DEPOSITO_CONTO_ACCREDITO"] = "BONIFICO_DEPOSITO_CONTO_ACCREDITO";
  EReduxActionTypes["BONIFICO_DEPOSITO_IMPORTO"] = "BONIFICO_DEPOSITO_IMPORTO";
  EReduxActionTypes["BONIFICO_DEPOSITO_DATA_ESECUZIONE"] = "BONIFICO_DEPOSITO_DATA_ESECUZIONE";
  EReduxActionTypes["BONIFICO_DEPOSITO_CAUSALE"] = "BONIFICO_DEPOSITO_CAUSALE";
  EReduxActionTypes["BONIFICO_DEPOSITO_STEP"] = "BONIFICO_DEPOSITO_STEP";
  EReduxActionTypes["BONIFICO_DEPOSITO_INTESTATARIO"] = "BONIFICO_DEPOSITO_INTESTATARIO";
  EReduxActionTypes["BONIFICO_DEPOSITO_SET_RAPPORTO_SELEZIONATO"] = "BONIFICO_DEPOSITO_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["BONIFICO_DEPOSITO_FORM_SUBMITTED"] = "BONIFICO_DEPOSITO_FORM_SUBMITTED";
  EReduxActionTypes["BONIFICO_DEPOSITO_SET_RIEPILOGO_REVOCA"] = "BONIFICO_DEPOSITO_SET_RIEPILOGO_REVOCA";
  EReduxActionTypes["BONIFICO_DEPOSITO_SET_PROVENIENZA"] = "BONIFICO_DEPOSITO_SET_PROVENIENZA";
  EReduxActionTypes["BONIFICO_DEPOSITO_STEP_REVOCA"] = "BONIFICO_DEPOSITO_STEP_REVOCA";
  EReduxActionTypes["BONIFICO_DEPOSITO_GET_INFO_REVOCA_START"] = "BONIFICO_DEPOSITO_GET_INFO_REVOCA_START";
  EReduxActionTypes["BONIFICO_DEPOSITO_GET_INFO_REVOCA_SUCCESS"] = "BONIFICO_DEPOSITO_GET_INFO_REVOCA_SUCCESS";
  EReduxActionTypes["BONIFICO_DEPOSITO_GET_INFO_REVOCA_ERROR"] = "BONIFICO_DEPOSITO_GET_INFO_REVOCA_ERROR";
  EReduxActionTypes["BONIFICO_DEPOSITO_AUTORIZZA_REVOCA_START"] = "BONIFICO_DEPOSITO_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["BONIFICO_DEPOSITO_AUTORIZZA_REVOCA_SUCCESS"] = "BONIFICO_DEPOSITO_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["BONIFICO_DEPOSITO_AUTORIZZA_REVOCA_ERROR"] = "BONIFICO_DEPOSITO_AUTORIZZA_REVOCA_ERROR";
  EReduxActionTypes["BONIFICO_DEPOSITO_RESET_ESITO"] = "BONIFICO_DEPOSITO_RESET_ESITO";
  // RAPPORTI
  EReduxActionTypes["RAPPORTI_GET_SALDO_COMPLESSIVO_START"] = "RAPPORTI_GET_SALDO_COMPLESSIVO_START";
  EReduxActionTypes["RAPPORTI_GET_SALDO_COMPLESSIVO_SUCCESS"] = "RAPPORTI_GET_SALDO_COMPLESSIVO_SUCCESS";
  EReduxActionTypes["RAPPORTI_GET_RAPPORTI_ADEGUATA_VERIFICA_START"] = "RAPPORTI_GET_RAPPORTI_ADEGUATA_VERIFICA_START";
  EReduxActionTypes["RAPPORTI_GET_RAPPORTI_ADEGUATA_VERIFICA_SUCCESS"] = "RAPPORTI_GET_RAPPORTI_ADEGUATA_VERIFICA_SUCCESS";
  EReduxActionTypes["PFM_GET_WIDGET_PATRIMONIO_START"] = "PFM_GET_WIDGET_PATRIMONIO_START";
  EReduxActionTypes["PFM_GET_WIDGET_PATRIMONIO_SUCCESS"] = "PFM_GET_WIDGET_PATRIMONIO_SUCCESS";
  EReduxActionTypes["PFM_GET_WIDGET_PATRIMONIO_ERROR"] = "PFM_GET_WIDGET_PATRIMONIO_ERROR";
  EReduxActionTypes["PFM_GET_WIDGET_BILANCIO_SINTETICO_START"] = "PFM_GET_WIDGET_BILANCIO_SINTETICO_START";
  EReduxActionTypes["PFM_GET_WIDGET_BILANCIO_SINTETICO_SUCCESS"] = "PFM_GET_WIDGET_BILANCIO_SINTETICO_SUCCESS";
  EReduxActionTypes["PFM_GET_WIDGET_BILANCIO_SINTETICO_ERROR"] = "PFM_GET_WIDGET_BILANCIO_SINTETICO_ERROR";
  EReduxActionTypes["PFM_GET_CATEGORIE_START"] = "PFM_GET_CATEGORIE_START";
  EReduxActionTypes["PFM_GET_CATEGORIE_SUCCESS"] = "PFM_GET_CATEGORIE_SUCCESS";
  EReduxActionTypes["PFM_GET_CATEGORIE_ERROR"] = "PFM_GET_CATEGORIE_ERROR";
  EReduxActionTypes["PAGAMENTI_GET_PARAMETRI_START"] = "PAGAMENTI_GET_PARAMETRI_START";
  EReduxActionTypes["PAGAMENTI_GET_PARAMETRI_SUCCESS"] = "PAGAMENTI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["PAGAMENTI_GET_PARAMETRI_ERROR"] = "PAGAMENTI_GET_PARAMETRI_ERROR";
  EReduxActionTypes["PAGAMENTI_GET_PAGAMENTI_START"] = "PAGAMENTI_GET_PAGAMENTI_START";
  EReduxActionTypes["PAGAMENTI_GET_PAGAMENTI_SUCCESS"] = "PAGAMENTI_GET_PAGAMENTI_SUCCESS";
  EReduxActionTypes["PAGAMENTI_GET_PAGAMENTI_ERROR"] = "PAGAMENTI_GET_PAGAMENTI_ERROR";
  EReduxActionTypes["PAGAMENTI_GET_DETTAGLIO_PAGAMENTO_START"] = "PAGAMENTI_GET_DETTAGLIO_PAGAMENTO_START";
  EReduxActionTypes["PAGAMENTI_GET_DETTAGLIO_PAGAMENTO_SUCCESS"] = "PAGAMENTI_GET_DETTAGLIO_PAGAMENTO_SUCCESS";
  EReduxActionTypes["PAGAMENTI_ESPORTA_PDF_START"] = "PAGAMENTI_ESPORTA_PDF_START";
  EReduxActionTypes["PAGAMENTI_ESPORTA_PDF_SUCCESS"] = "PAGAMENTI_ESPORTA_PDF_SUCCESS";
  EReduxActionTypes["PAGAMENTI_ESPORTA_EXCEL_START"] = "PAGAMENTI_ESPORTA_EXCEL_START";
  EReduxActionTypes["PAGAMENTI_ESPORTA_EXCEL_SUCCESS"] = "PAGAMENTI_ESPORTA_EXCEL_SUCCESS";
  EReduxActionTypes["PAGAMENTI_RESET_DETTAGLIO_PAGAMENTO"] = "PAGAMENTI_RESET_DETTAGLIO_PAGAMENTO";
  EReduxActionTypes["PAGAMENTI_SET_RAPPORTO_SELEZIONATO"] = "PAGAMENTI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["PAGAMENTI_RESET_FILTRO"] = "PAGAMENTI_RESET_FILTRO";
  EReduxActionTypes["PAGAMENTI_DESCRIZIONE"] = "PAGAMENTI_DESCRIZIONE";
  EReduxActionTypes["PAGAMENTI_DATA_DA"] = "PAGAMENTI_DATA_DA";
  EReduxActionTypes["PAGAMENTI_DATA_A"] = "PAGAMENTI_DATA_A";
  EReduxActionTypes["PAGAMENTI_TIPOLOGIE"] = "PAGAMENTI_TIPOLOGIE";
  EReduxActionTypes["PAGAMENTI_STATI"] = "PAGAMENTI_STATI";
  EReduxActionTypes["PAGAMENTI_IMPORTO"] = "PAGAMENTI_IMPORTO";
  EReduxActionTypes["PAGAMENTI_CODICE_FISCALE"] = "PAGAMENTI_CODICE_FISCALE";
  EReduxActionTypes["PAGAMENTI_PERIODO"] = "PAGAMENTI_PERIODO";
  EReduxActionTypes["PAGAMENTI_SET_PAGAMENTO_SELEZIONATO"] = "PAGAMENTI_SET_PAGAMENTO_SELEZIONATO";
  EReduxActionTypes["PAGAMENTI_SET_ACCORDION_FILTRI_OPENED"] = "PAGAMENTI_SET_ACCORDION_FILTRI_OPENED";
  EReduxActionTypes["PAGAMENTI_ESPORTA_PDF_DETAGLIO_START"] = "PAGAMENTI_ESPORTA_PDF_DETAGLIO_START";
  EReduxActionTypes["PAGAMENTI_ESPORTA_PDF_DETAGLIO_SUCCESS"] = "PAGAMENTI_ESPORTA_PDF_DETAGLIO_SUCCESS";
  EReduxActionTypes["PAGAMENTI_RESET_ELENCO"] = "PAGAMENTI_RESET_ELENCO";
  EReduxActionTypes["PAGAMENTI_SET_OFFSET"] = "PAGAMENTI_SET_OFFSET";
  EReduxActionTypes["PAGAMENTI_LIST_LOADER"] = "PAGAMENTI_LIST_LOADER";
  EReduxActionTypes["PAGAMENTI_GET_RAPPORTI_ESTINTI_START"] = "PAGAMENTI_GET_RAPPORTI_ESTINTI_START";
  EReduxActionTypes["PAGAMENTI_GET_RAPPORTI_ESTINTI_SUCCESS"] = "PAGAMENTI_GET_RAPPORTI_ESTINTI_SUCCESS";
  EReduxActionTypes["PAGAMENTI_RAPPORTI_ESTINTI_DATA_DA"] = "PAGAMENTI_RAPPORTI_ESTINTI_DATA_DA";
  EReduxActionTypes["PAGAMENTI_RAPPORTI_ESTINTI_DATA_A"] = "PAGAMENTI_RAPPORTI_ESTINTI_DATA_A";
  EReduxActionTypes["PAGAMENTI_RAPPORTI_ESTINTI_SET_TIPOLOGIA"] = "PAGAMENTI_RAPPORTI_ESTINTI_SET_TIPOLOGIA";
  EReduxActionTypes["PAGAMENTI_RAPPORTI_ESTINTI_SET_RAPPORTO_SELEZIONATO"] = "PAGAMENTI_RAPPORTI_ESTINTI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["PAGAMENTI_RESET_FILTRI_RAPPORTI_ESTINTI"] = "PAGAMENTI_RESET_FILTRI_RAPPORTI_ESTINTI";
  EReduxActionTypes["PAGAMENTI_GET_PAGAMENTI_RAPPORTI_ESTINTI_START"] = "PAGAMENTI_GET_PAGAMENTI_RAPPORTI_ESTINTI_START";
  EReduxActionTypes["PAGAMENTI_GET_PAGAMENTI_RAPPORTI_ESTINTI_SUCCESS"] = "PAGAMENTI_GET_PAGAMENTI_RAPPORTI_ESTINTI_SUCCESS";
  EReduxActionTypes["PAGAMENTI_RAPPORTI_ESTINTI_SET_OFFSET"] = "PAGAMENTI_RAPPORTI_ESTINTI_SET_OFFSET";
  EReduxActionTypes["PAGAMENTI_RAPPORTI_ESTINTI_SET_LIST_LOADER"] = "PAGAMENTI_RAPPORTI_ESTINTI_SET_LIST_LOADER";
  EReduxActionTypes["PAGAMENTI_RAPPORTI_ESTINTI_RESET_RAPPORTO_SELEZIONATO"] = "PAGAMENTI_RAPPORTI_ESTINTI_RESET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["PAGAMENTI_SET_DETTAGLIO_PAGAMENTO_SELEZIONATO"] = "PAGAMENTI_SET_DETTAGLIO_PAGAMENTO_SELEZIONATO";
  EReduxActionTypes["PAGAMENTI_RESET_ELENCO_RAPPORTI_ESTINTI"] = "PAGAMENTI_RESET_ELENCO_RAPPORTI_ESTINTI";
  EReduxActionTypes["PAGAMENTI_SET_ACCORDION_FILTRI_RAPPORTI_ESTINTI_OPENED"] = "PAGAMENTI_SET_ACCORDION_FILTRI_RAPPORTI_ESTINTI_OPENED";
  EReduxActionTypes["PAGAMENTI_GET_DETTAGLIO_PAGAMENTO_ESTINTO_SUCCESS"] = "PAGAMENTI_GET_DETTAGLIO_PAGAMENTO_ESTINTO_SUCCESS";
  EReduxActionTypes["PAGAMENTI_GET_DETTAGLIO_PAGAMENTO_ESTINTO_START"] = "PAGAMENTI_GET_DETTAGLIO_PAGAMENTO_ESTINTO_START";
  EReduxActionTypes["MOVIMENTI_GET_PARAMETRI_START"] = "MOVIMENTI_GET_PARAMETRI_START";
  EReduxActionTypes["MOVIMENTI_GET_PARAMETRI_SUCCESS"] = "MOVIMENTI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["MOVIMENTI_SWITCH_TO_TEMP_PARAMETRI"] = "MOVIMENTI_SWITCH_TO_TEMP_PARAMETRI";
  EReduxActionTypes["MOVIMENTI_GET_PARAMETRI_CONTO_DEPOSITO_START"] = "MOVIMENTI_GET_PARAMETRI_CONTO_DEPOSITO_START";
  EReduxActionTypes["MOVIMENTI_GET_PARAMETRI_CONTO_DEPOSITO_SUCCESS"] = "MOVIMENTI_GET_PARAMETRI_CONTO_DEPOSITO_SUCCESS";
  EReduxActionTypes["MOVIMENTI_GET_MOVIMENTI_HOMEPAGE_START"] = "MOVIMENTI_GET_MOVIMENTI_HOMEPAGE_START";
  EReduxActionTypes["MOVIMENTI_GET_MOVIMENTI_HOMEPAGE_SUCCESS"] = "MOVIMENTI_GET_MOVIMENTI_HOMEPAGE_SUCCESS";
  EReduxActionTypes["MOVIMENTI_GET_MOVIMENTI_START"] = "MOVIMENTI_GET_MOVIMENTI_START";
  EReduxActionTypes["MOVIMENTI_GET_MOVIMENTI_SUCCESS"] = "MOVIMENTI_GET_MOVIMENTI_SUCCESS";
  EReduxActionTypes["MOVIMENTI_ESPORTA_ALL_FORMATS_RESET"] = "MOVIMENTI_ESPORTA_ALL_FORMATS_RESET";
  EReduxActionTypes["MOVIMENTI_ESPORTA_PDF_START"] = "MOVIMENTI_ESPORTA_PDF_START";
  EReduxActionTypes["MOVIMENTI_ESPORTA_PDF_SUCCESS"] = "MOVIMENTI_ESPORTA_PDF_SUCCESS";
  EReduxActionTypes["MOVIMENTI_ESPORTA_PDF_ERROR"] = "MOVIMENTI_ESPORTA_PDF_ERROR";
  EReduxActionTypes["MOVIMENTI_ESPORTA_EXCEL_START"] = "MOVIMENTI_ESPORTA_EXCEL_START";
  EReduxActionTypes["MOVIMENTI_ESPORTA_EXCEL_SUCCESS"] = "MOVIMENTI_ESPORTA_EXCEL_SUCCESS";
  EReduxActionTypes["MOVIMENTI_ESPORTA_EXCEL_ERROR"] = "MOVIMENTI_ESPORTA_EXCEL_ERROR";
  EReduxActionTypes["MOVIMENTI_ESPORTA_DETTAGLIO_PDF_START"] = "MOVIMENTI_ESPORTA_DETTAGLIO_PDF_START";
  EReduxActionTypes["MOVIMENTI_ESPORTA_DETTAGLIO_PDF_SUCCESS"] = "MOVIMENTI_ESPORTA_DETTAGLIO_PDF_SUCCESS";
  EReduxActionTypes["MOVIMENTI_ESPORTA_DETTAGLIO_PDF_ERROR"] = "MOVIMENTI_ESPORTA_DETTAGLIO_PDF_ERROR";
  EReduxActionTypes["SALDO_E_MOVIMENTI_RESET_FILTRO"] = "SALDO_E_MOVIMENTI_RESET_FILTRO";
  EReduxActionTypes["SALDO_MOVIMENTI_RIMUOVI_FILTRI"] = "SALDO_MOVIMENTI_RIMUOVI_FILTRI";
  EReduxActionTypes["CARTA_CONTO_RIMUOVI_FILTRI"] = "CARTA_CONTO_RIMUOVI_FILTRI";
  EReduxActionTypes["SALDO_E_MOVIMENTI_RESET_FILTRI_MOVIMENTI"] = "SALDO_E_MOVIMENTI_RESET_FILTRI_MOVIMENTI";
  EReduxActionTypes["SALDO_E_MOVIMENTI_SET_RAPPORTO_SELEZIONATO"] = "SALDO_E_MOVIMENTI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["SALDO_E_MOVIMENTI_SET_RAPPORTO_SELEZIONATO_CONTO_DEPOSITO"] = "SALDO_E_MOVIMENTI_SET_RAPPORTO_SELEZIONATO_CONTO_DEPOSITO";
  EReduxActionTypes["SALDO_E_MOVIMENTI_CONTO"] = "SALDO_E_MOVIMENTI_CONTO";
  EReduxActionTypes["SALDO_E_MOVIMENTI_OFFSET"] = "SALDO_E_MOVIMENTI_OFFSET";
  EReduxActionTypes["SALDO_E_MOVIMENTI_LIST_LOADER"] = "SALDO_E_MOVIMENTI_LIST_LOADER";
  EReduxActionTypes["SALDO_E_MOVIMENTI_DESCRIZIONE"] = "SALDO_E_MOVIMENTI_DESCRIZIONE";
  EReduxActionTypes["SALDO_E_MOVIMENTI_MOVIMENTI_NON_CONTABILIZZATI"] = "  SALDO_E_MOVIMENTI_MOVIMENTI_NON_CONTABILIZZATI";
  EReduxActionTypes["SALDO_E_MOVIMENTI_PERIODO"] = "SALDO_E_MOVIMENTI_PERIODO";
  EReduxActionTypes["SALDO_E_MOVIMENTI_DATA_DA"] = "SALDO_E_MOVIMENTI_DATA_DA";
  EReduxActionTypes["SALDO_E_MOVIMENTI_DATA_A"] = "SALDO_E_MOVIMENTI_DATA_A";
  EReduxActionTypes["SALDO_E_MOVIMENTI_IMPORTO"] = "SALDO_E_MOVIMENTI_IMPORTO";
  EReduxActionTypes["SALDO_E_MOVIMENTI_CATEGORIE"] = "SALDO_E_MOVIMENTI_CATEGORIE";
  EReduxActionTypes["SALDO_E_MOVIMENTI_ENTRATE"] = "SALDO_E_MOVIMENTI_ENTRATE";
  EReduxActionTypes["SALDO_E_MOVIMENTI_USCITE"] = "SALDO_E_MOVIMENTI_USCITE";
  EReduxActionTypes["SALDO_E_MOVIMENTI_ORDINAMENTO"] = "SALDO_E_MOVIMENTI_ORDINAMENTO";
  EReduxActionTypes["SALDO_E_MOVIMENTI_ENTRATE_USCITE"] = "SALDO_E_MOVIMENTI_ENTRATE_USCITE";
  EReduxActionTypes["SALDO_E_MOVIMENTI_ENTRATA_USCITA"] = "SALDO_E_MOVIMENTI_ENTRATA_USCITA";
  EReduxActionTypes["SALDO_E_MOVIMENTI_SET_ACCORDION_FILTRI_OPENED"] = "SALDO_E_MOVIMENTI_SET_ACCORDION_FILTRI_OPENED";
  EReduxActionTypes["SWITCH_SALDO_CONTABILE_SALDO_DISPONIBILE"] = "SWITCH_SALDO_CONTABILE_SALDO_DISPONIBILE";
  EReduxActionTypes["MOVIMENTI_GET_PARAMETRI_CARTA_CONTO_START"] = "MOVIMENTI_GET_PARAMETRI_CARTA_CONTO_START";
  EReduxActionTypes["MOVIMENTI_GET_PARAMETRI_CARTA_CONTO_SUCCESS"] = "MOVIMENTI_GET_PARAMETRI_CARTA_CONTO_SUCCESS";
  EReduxActionTypes["MOVIMENTI_GET_PARAMETRI_CARTA_CONTO_ERROR"] = "MOVIMENTI_GET_PARAMETRI_CARTA_CONTO_ERROR";
  EReduxActionTypes["MOVIMENTI_RESET_MOVIMENTI"] = "MOVIMENTI_RESET_MOVIMENTI";
  EReduxActionTypes["MOVIMENTI_RESET_MOVIMENTI_HOMEPAGE"] = "MOVIMENTI_RESET_MOVIMENTI_HOMEPAGE";
  EReduxActionTypes["MOVIMENTI_SET_TIPO_ELENCO_CARTA_CONTO"] = "MOVIMENTI_SET_TIPO_ELENCO_CARTA_CONTO";
  EReduxActionTypes["MOVIMENTI_SET_TIPO_DIVISA_SELEZIONATA"] = "MOVIMENTI_SET_TIPO_DIVISA_SELEZIONATA";
  EReduxActionTypes["MOVIMENTI_RESET_SALDO_E_MOVIMENTI"] = "MOVIMENTI_RESET_SALDO_E_MOVIMENTI";
  EReduxActionTypes["POSIZIONE_GLOBALE_GET_PATRIMONIO_START"] = "POSIZIONE_GLOBALE_GET_PATRIMONIO_START";
  EReduxActionTypes["POSIZIONE_GLOBALE_GET_PATRIMONIO_SUCCESS"] = "POSIZIONE_GLOBALE_GET_PATRIMONIO_SUCCESS";
  EReduxActionTypes["POSIZIONE_GLOBALE_GET_PATRIMONIO_ERROR"] = "POSIZIONE_GLOBALE_GET_PATRIMONIO_ERROR";
  // OPERAZIONI (ONECLICK HOMEPAGE e FUNZIONALITA' PREFERITE)
  EReduxActionTypes["OPERAZIONI_GET_OPERAZIONI_HOMEPAGE_START"] = "OPERAZIONI_GET_OPERAZIONI_HOMEPAGE_START";
  EReduxActionTypes["OPERAZIONI_GET_OPERAZIONI_HOMEPAGE_SUCCESS"] = "OPERAZIONI_GET_OPERAZIONI_HOMEPAGE_SUCCESS";
  EReduxActionTypes["OPERAZIONI_GET_OPERAZIONI_HOMEPAGE_ERROR"] = "OPERAZIONI_GET_OPERAZIONI_HOMEPAGE_ERROR";
  EReduxActionTypes["OPERAZIONI_GET_OPERAZIONI_ONECLICK_HOMEPAGE_START"] = "OPERAZIONI_GET_OPERAZIONI_ONECLICK_HOMEPAGE_START";
  EReduxActionTypes["OPERAZIONI_GET_OPERAZIONI_ONECLICK_HOMEPAGE_SUCCESS"] = "OPERAZIONI_GET_OPERAZIONI_ONECLICK_HOMEPAGE_SUCCESS";
  EReduxActionTypes["OPERAZIONI_GET_OPERAZIONI_ONECLICK_HOMEPAGE_ERROR"] = "OPERAZIONI_GET_OPERAZIONI_ONECLICK_HOMEPAGE_ERROR";
  EReduxActionTypes["OPERAZIONI_GET_PREFERITI_START"] = "OPERAZIONI_GET_PREFERITI_START";
  EReduxActionTypes["OPERAZIONI_GET_PREFERITI_SUCCESS"] = "OPERAZIONI_GET_PREFERITI_SUCCESS";
  EReduxActionTypes["OPERAZIONI_SET_PREFERITI_START"] = "OPERAZIONI_SET_PREFERITI_START";
  EReduxActionTypes["OPERAZIONI_SET_PREFERITI_SUCCESS"] = "OPERAZIONI_SET_PREFERITI_SUCCESS";
  EReduxActionTypes["OPERAZIONI_RESET_PREFERITI"] = "OPERAZIONI_RESET_PREFERITI";
  // ONE CLICK
  EReduxActionTypes["GET_OPERAZIONI_ONECLICK_START"] = "GET_OPERAZIONI_ONECLICK_START";
  EReduxActionTypes["GET_OPERAZIONI_ONECLICK_SUCCESS"] = "GET_OPERAZIONI_ONECLICK_SUCCESS";
  EReduxActionTypes["SALVA_ONECLICK_START"] = "SALVA_ONECLICK_START";
  EReduxActionTypes["SALVA_ONECLICK_SUCCESS"] = "SALVA_ONECLICK_SUCCESS";
  EReduxActionTypes["ELIMINA_ONECLICK_START"] = "ELIMINA_ONECLICK_START";
  EReduxActionTypes["ELIMINA_ONECLICK_SUCCESS"] = "ELIMINA_ONECLICK_SUCCESS";
  EReduxActionTypes["ONECLICK_CONTENUTO"] = "ONECLICK_CONTENUTO";
  EReduxActionTypes["ONECLICK_TIPO_OPERAZIONE"] = "ONECLICK_TIPO_OPERAZIONE";
  EReduxActionTypes["ONECLICK_TIPO_OPERAZIONE_RICERCA"] = "ONECLICK_TIPO_OPERAZIONE_RICERCA";
  EReduxActionTypes["ONECLICK_OPERAZIONE_SELEZIONATA"] = "ONECLICK_OPERAZIONE_SELEZIONATA";
  EReduxActionTypes["ONECLICK_RESET_OPERAZIONE_SELEZIONATA"] = "ONECLICK_RESET_OPERAZIONE_SELEZIONATA";
  EReduxActionTypes["ONECLICK_GET_PARAMETRI_START"] = "ONECLICK_GET_PARAMETRI_START";
  EReduxActionTypes["ONECLICK_GET_PARAMETRI_SUCCESS"] = "ONECLICK_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["ONECLICK_GET_PARAMETRI_ERROR"] = "ONECLICK_GET_PARAMETRI_ERROR";
  EReduxActionTypes["ONECLICK_SET_ACCORDION_FILTRI_OPENED"] = "ONECLICK_SET_ACCORDION_FILTRI_OPENED";
  EReduxActionTypes["ONECLICK_RESET_FILTRO"] = "ONECLICK_RESET_FILTRO";
  EReduxActionTypes["ONECLICK_GET_INFO_AUTENTICAZIONE_START"] = "ONECLICK_GET_INFO_AUTENTICAZIONE_START";
  EReduxActionTypes["ONECLICK_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "ONECLICK_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxActionTypes["ONECLICK_GET_INFO_AUTENTICAZIONE_ERROR"] = "ONECLICK_GET_INFO_AUTENTICAZIONE_ERROR";
  EReduxActionTypes["ONECLICK_CONTROLLO_START"] = "ONECLICK_CONTROLLO_START";
  EReduxActionTypes["ONECLICK_CONTROLLO_SUCCESS"] = "ONECLICK_CONTROLLO_SUCCESS";
  EReduxActionTypes["ONECLICK_CONTROLLO_ERROR"] = "ONECLICK_CONTROLLO_ERROR";
  EReduxActionTypes["ONECLICK_AUTORIZZA_START"] = "ONECLICK_AUTORIZZA_START";
  EReduxActionTypes["ONECLICK_AUTORIZZA_SUCCESS"] = "ONECLICK_AUTORIZZA_SUCCESS";
  EReduxActionTypes["ONECLICK_AUTORIZZA_ERROR"] = "ONECLICK_AUTORIZZA_ERROR";
  EReduxActionTypes["ONECLICK_SET_TIPO_OPERAZIONE"] = "ONECLICK_SET_TIPO_OPERAZIONE";
  EReduxActionTypes["ONECLICK_SET_ALIAS"] = "ONECLICK_SET_ALIAS";
  EReduxActionTypes["ONECLICK_FORM_SUBMITTED"] = "ONECLICK_FORM_SUBMITTED";
  EReduxActionTypes["ONECLICK_SET_STEP"] = "ONECLICK_SET_STEP";
  EReduxActionTypes["ONECLICK_SET_RAPPORTO_SELEZIONATO"] = "ONECLICK_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["ONECLICK_SET_BENEFICIARIO"] = "ONECLICK_SET_BENEFICIARIO";
  EReduxActionTypes["ONECLICK_SET_IBAN"] = "ONECLICK_SET_IBAN";
  EReduxActionTypes["ONECLICK_SET_IMPORTO"] = "ONECLICK_SET_IMPORTO";
  EReduxActionTypes["ONECLICK_SET_CAUSALE"] = "ONECLICK_SET_CAUSALE";
  EReduxActionTypes["ONECLICK_SET_STEPSCA"] = "ONECLICK_SET_STEPSCA";
  EReduxActionTypes["ONECLICK_SET_CREA"] = "ONECLICK_SET_CREA";
  EReduxActionTypes["ONECLICK_SET_MODIFICA"] = "ONECLICK_SET_MODIFICA";
  EReduxActionTypes["ONECLICK_RESET_FORM"] = "ONECLICK_RESET_FORM";
  EReduxActionTypes["ONECLICK_RESET_RIEPILOGO"] = "ONECLICK_RESET_RIEPILOGO";
  EReduxActionTypes["ONECLICK_CONTROLLO_MODIFICA_START"] = "ONECLICK_CONTROLLO_MODIFICA_START";
  EReduxActionTypes["ONECLICK_CONTROLLO_MODIFICA_SUCCESS"] = "ONECLICK_CONTROLLO_MODIFICA_SUCCESS";
  EReduxActionTypes["ONECLICK_GET_INFO_AUTENTICAZIONE_MODIFICA_START"] = "ONECLICK_GET_INFO_AUTENTICAZIONE_MODIFICA_START";
  EReduxActionTypes["ONECLICK_GET_INFO_AUTENTICAZIONE_MODIFICA_SUCCESS"] = "ONECLICK_GET_INFO_AUTENTICAZIONE_MODIFICA_SUCCESS";
  EReduxActionTypes["ONECLICK_AUTORIZZA_MODIFICA_START"] = "ONECLICK_AUTORIZZA_MODIFICA_START";
  EReduxActionTypes["ONECLICK_AUTORIZZA_MODIFICA_SUCCESS"] = "ONECLICK_AUTORIZZA_MODIFICA_SUCCESS";
  EReduxActionTypes["ONECLICK_RESET"] = "ONECLICK_RESET";
  EReduxActionTypes["BOZZE_GET_PARAMETRI_START"] = "BOZZE_GET_PARAMETRI_START";
  EReduxActionTypes["BOZZE_GET_PARAMETRI_SUCCESS"] = "BOZZE_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["BOZZE_GET_PARAMETRI_ERROR"] = "BOZZE_GET_PARAMETRI_ERROR";
  // RAV
  EReduxActionTypes["RAV_GET_PARAMETRI_START"] = "RAV_GET_PARAMETRI_START";
  EReduxActionTypes["RAV_GET_PARAMETRI_SUCCESS"] = "RAV_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["RAV_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "RAV_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["RAV_GET_PARAMETRI_ERROR"] = "RAV_GET_PARAMETRI_ERROR";
  EReduxActionTypes["RAV_RESET"] = "RAV_RESET";
  EReduxActionTypes["RAV_IMPORTO"] = "RAV_IMPORTO";
  EReduxActionTypes["RAV_DATA_ESEC"] = "RAV_DATA_ESEC";
  EReduxActionTypes["RAV_CODICE_RAV"] = "RAV_CODICE_RAV";
  EReduxActionTypes["RAV_ORDINANTE_EFF"] = "RAV_ORDINANTE_EFF";
  EReduxActionTypes["RAV_STEP"] = "RAV_STEP";
  EReduxActionTypes["RAV_SET_RAPPORTO_SELEZIONATO"] = "RAV_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["RAV_FORM_SUBMITTED"] = "RAV_FORM_SUBMITTED";
  EReduxActionTypes["RAV_SET_BOZZA_SALVATA"] = "RAV_SET_BOZZA_SALVATA";
  EReduxActionTypes["RAV_AUTORIZZA_START"] = "RAV_AUTORIZZA_START";
  EReduxActionTypes["RAV_AUTORIZZA_SUCCESS"] = "RAV_AUTORIZZA_SUCCESS";
  EReduxActionTypes["RAV_AUTORIZZA_ERROR"] = "RAV_AUTORIZZA_ERROR";
  EReduxActionTypes["RAV_CONTROLLO_START"] = "RAV_CONTROLLO_START";
  EReduxActionTypes["RAV_CONTROLLO_SUCCESS"] = "RAV_CONTROLLO_SUCCESS";
  EReduxActionTypes["RAV_CONTROLLO_ERROR"] = "RAV_CONTROLLO_ERROR";
  EReduxActionTypes["RAV_GET_BOZZE_START"] = "RAV_GET_BOZZE_START";
  EReduxActionTypes["RAV_GET_BOZZE_SUCCESS"] = "RAV_GET_BOZZE_SUCCESS";
  EReduxActionTypes["RAV_GET_BOZZE_ERROR"] = "RAV_GET_BOZZE_ERROR";
  EReduxActionTypes["RAV_GET_BOZZA_START"] = "RAV_GET_BOZZA_START";
  EReduxActionTypes["RAV_GET_BOZZA_SUCCESS"] = "RAV_GET_BOZZA_SUCCESS";
  EReduxActionTypes["RAV_GET_BOZZA_ERROR"] = "RAV_GET_BOZZA_ERROR";
  EReduxActionTypes["RAV_GET_RAV_FROM_BOZZA"] = "RAV_GET_RAV_FROM_BOZZA";
  EReduxActionTypes["RAV_ADD_BOZZA_START"] = "RAV_ADD_BOZZA_START";
  EReduxActionTypes["RAV_ADD_BOZZA_SUCCESS"] = "RAV_ADD_BOZZA_SUCCESS";
  EReduxActionTypes["RAV_ADD_BOZZA_ERROR"] = "RAV_ADD_BOZZA_ERROR";
  EReduxActionTypes["RAV_DEL_BOZZA_START"] = "RAV_DEL_BOZZA_START";
  EReduxActionTypes["RAV_DEL_BOZZA_SUCCESS"] = "RAV_DEL_BOZZA_SUCCESS";
  EReduxActionTypes["RAV_DEL_BOZZA_ERROR"] = "RAV_DEL_BOZZA_ERROR";
  EReduxActionTypes["RAV_STEP_REVOCA"] = "RAV_STEP_REVOCA";
  EReduxActionTypes["RAV_SET_RIEPILOGO_REVOCA"] = "RAV_SET_RIEPILOGO_REVOCA";
  EReduxActionTypes["RAV_GET_INFO_REVOCA_START"] = "RAV_GET_INFO_REVOCA_START";
  EReduxActionTypes["RAV_GET_INFO_REVOCA_SUCCESS"] = "RAV_GET_INFO_REVOCA_SUCCESS";
  EReduxActionTypes["RAV_GET_INFO_REVOCA_ERROR"] = "RAV_GET_INFO_REVOCA_ERROR";
  EReduxActionTypes["RAV_AUTORIZZA_REVOCA_START"] = "RAV_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["RAV_AUTORIZZA_REVOCA_SUCCESS"] = "RAV_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["RAV_AUTORIZZA_REVOCA_ERROR"] = "RAV_AUTORIZZA_REVOCA_ERROR";
  EReduxActionTypes["RAV_SET_PROVENIENZA"] = "RAV_SET_PROVENIENZA";
  EReduxActionTypes["RAV_GET_INFO_AUTENTICAZIONE_RAV_START"] = "RAV_GET_INFO_AUTENTICAZIONE_RAV_START";
  EReduxActionTypes["RAV_GET_INFO_AUTENTICAZIONE_RAV_SUCCESS"] = "RAV_GET_INFO_AUTENTICAZIONE_RAV_SUCCESS";
  EReduxActionTypes["RAV_GET_INFO_AUTENTICAZIONE_RAV_ERROR"] = "RAV_GET_INFO_AUTENTICAZIONE_RAV_ERROR";
  EReduxActionTypes["RAV_UPDATE_BOZZA_START"] = "RAV_UPDATE_BOZZA_START";
  EReduxActionTypes["RAV_UPDATE_BOZZA_SUCCESS"] = "RAV_UPDATE_BOZZA_SUCCESS";
  EReduxActionTypes["RAV_UPDATE_BOZZA_ERROR"] = "RAV_UPDATE_BOZZA_ERROR";
  // Bozze
  EReduxActionTypes["BOZZE_SET_RAPPORTO_SELEZIONATO"] = "BOZZE_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["BOZZE_SET_DESCRIZIONE"] = "BOZZE_SET_DESCRIZIONE";
  EReduxActionTypes["BOZZE_SET_TIPOLOGIA"] = "BOZZE_SET_TIPOLOGIA";
  EReduxActionTypes["BOZZE_GET_BOZZE_START"] = "BOZZE_GET_BOZZE_START";
  EReduxActionTypes["BOZZE_GET_BOZZE_SUCCESS"] = "BOZZE_GET_BOZZE_SUCCESS";
  EReduxActionTypes["BOZZE_SET_ACCORDION_FILTRI_OPENED"] = "BOZZE_SET_ACCORDION_FILTRI_OPENED";
  EReduxActionTypes["BOZZE_SET_TUTTI_RAPPORTI"] = "BOZZE_SET_TUTTI_RAPPORTI";
  EReduxActionTypes["BOZZE_RESET"] = "BOZZE_RESET";
  EReduxActionTypes["BOZZE_ELIMINA_BOZZA_ARCHIVIO_START"] = "BOZZE_ELIMINA_BOZZA_ARCHIVIO_START";
  EReduxActionTypes["BOZZE_ELIMINA_BOZZA_ARCHIVIO_SUCCESS"] = "BOZZE_ELIMINA_BOZZA_ARCHIVIO_SUCCESS";
  // MAV
  EReduxActionTypes["MAV_GET_PARAMETRI_START"] = "MAV_GET_PARAMETRI_START";
  EReduxActionTypes["MAV_GET_PARAMETRI_SUCCESS"] = "MAV_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["MAV_GET_PARAMETRI_ERROR"] = "MAV_GET_PARAMETRI_ERROR";
  EReduxActionTypes["MAV_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "MAV_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["MAV_CONTROLLO_START"] = "MAV_CONTROLLO_START";
  EReduxActionTypes["MAV_CONTROLLO_SUCCESS"] = "MAV_CONTROLLO_SUCCESS";
  EReduxActionTypes["MAV_CONTROLLO_ERROR"] = "MAV_CONTROLLO_ERROR";
  EReduxActionTypes["MAV_GET_INFO_DISPOSITIVA_START"] = "MAV_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["MAV_GET_INFO_DISPOSITIVA_SUCCESS"] = "MAV_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["MAV_AUTORIZZA_START"] = "MAV_AUTORIZZA_START";
  EReduxActionTypes["MAV_GET_INFO_DISPOSITIVA_ERROR"] = "MAV_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["MAV_AUTORIZZA_SUCCESS"] = "MAV_AUTORIZZA_SUCCESS";
  EReduxActionTypes["MAV_AUTORIZZA_ERROR"] = "MAV_AUTORIZZA_ERROR";
  EReduxActionTypes["MAV_IMPORTO"] = "MAV_IMPORTO";
  EReduxActionTypes["MAV_CODICE_MAV"] = "MAV_CODICE_MAV";
  EReduxActionTypes["MAV_SET_RAPPORTO_SELEZIONATO"] = "MAV_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["MAV_FORM_SUBMITTED"] = "MAV_FORM_SUBMITTED";
  EReduxActionTypes["MAV_RESET"] = "MAV_RESET";
  EReduxActionTypes["MAV_STEP"] = "MAV_STEP";
  EReduxActionTypes["MAV_DATA_ESEC"] = "MAV_DATA_ESEC";
  EReduxActionTypes["MAV_SET_PROVENIENZA"] = "MAV_SET_PROVENIENZA";
  EReduxActionTypes["MAV_STEP_REVOCA"] = "MAV_STEP_REVOCA";
  EReduxActionTypes["MAV_SET_RIEPILOGO_REVOCA"] = "MAV_SET_RIEPILOGO_REVOCA";
  EReduxActionTypes["MAV_GET_INFO_REVOCA_START"] = "MAV_GET_INFO_REVOCA_START";
  EReduxActionTypes["MAV_GET_INFO_REVOCA_SUCCESS"] = "MAV_GET_INFO_REVOCA_SUCCESS";
  EReduxActionTypes["MAV_GET_INFO_REVOCA_ERROR"] = "MAV_GET_INFO_REVOCA_ERROR";
  EReduxActionTypes["MAV_AUTORIZZA_REVOCA_START"] = "MAV_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["MAV_AUTORIZZA_REVOCA_SUCCESS"] = "MAV_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["MAV_AUTORIZZA_REVOCA_ERROR"] = "MAV_AUTORIZZA_REVOCA_ERROR";
  EReduxActionTypes["MAV_SET_BOZZASALVATA"] = "MAV_SET_BOZZASALVATA";
  EReduxActionTypes["MAV_GET_BOZZE_START"] = "MAV_GET_BOZZE_START";
  EReduxActionTypes["MAV_GET_BOZZE_SUCCESS"] = "MAV_GET_BOZZE_SUCCESS";
  EReduxActionTypes["MAV_GET_BOZZE_ERROR"] = "MAV_GET_BOZZE_ERROR";
  EReduxActionTypes["MAV_GET_BOZZA_START"] = "MAV_GET_BOZZA_START";
  EReduxActionTypes["MAV_GET_BOZZA_SUCCESS"] = "MAV_GET_BOZZA_SUCCESS";
  EReduxActionTypes["MAV_GET_BOZZA_ERROR"] = "MAV_GET_BOZZA_ERROR";
  EReduxActionTypes["MAV_GET_MAV_FROM_BOZZA"] = "MAV_GET_MAV_FROM_BOZZA";
  EReduxActionTypes["MAV_ADD_BOZZA_START"] = "MAV_ADD_BOZZA_START";
  EReduxActionTypes["MAV_ADD_BOZZA_SUCCESS"] = "MAV_ADD_BOZZA_SUCCESS";
  EReduxActionTypes["MAV_ADD_BOZZA_ERROR"] = "MAV_ADD_BOZZA_ERROR";
  EReduxActionTypes["MAV_DEL_BOZZA_START"] = "MAV_DEL_BOZZA_START";
  EReduxActionTypes["MAV_DEL_BOZZA_SUCCESS"] = "MAV_DEL_BOZZA_SUCCESS";
  EReduxActionTypes["MAV_DEL_BOZZA_ERROR"] = "MAV_DEL_BOZZA_ERROR";
  EReduxActionTypes["MAV_DEL_BOZZA"] = "MAV_DEL_BOZZA";
  EReduxActionTypes["MAV_ADD_BOZZA"] = "MAV_ADD_BOZZA";
  EReduxActionTypes["MAV_GET_ONECLICK_START"] = "MAV_GET_ONECLICK_START";
  EReduxActionTypes["MAV_GET_ONECLICK_SUCCESS"] = "MAV_GET_ONECLICK_SUCCESS";
  EReduxActionTypes["MAV_GET_ONECLICK_ERROR"] = "MAV_GET_ONECLICK_ERROR";
  EReduxActionTypes["MAV_GET_INFO_AUTENTICAZIONE_MAV_START"] = "MAV_GET_INFO_AUTENTICAZIONE_MAV_START";
  EReduxActionTypes["MAV_GET_INFO_AUTENTICAZIONE_MAV_SUCCESS"] = "MAV_GET_INFO_AUTENTICAZIONE_MAV_SUCCESS";
  EReduxActionTypes["MAV_GET_INFO_AUTENTICAZIONE_MAV_ERROR"] = "MAV_GET_INFO_AUTENTICAZIONE_MAV_ERROR";
  EReduxActionTypes["MAV_UPDATE_BOZZA_START"] = "MAV_UPDATE_BOZZA_START";
  EReduxActionTypes["MAV_UPDATE_BOZZA_SUCCESS"] = "MAV_UPDATE_BOZZA_SUCCESS";
  EReduxActionTypes["MAV_UPDATE_BOZZA_ERROR"] = "MAV_UPDATE_BOZZA_ERROR";
  EReduxActionTypes["MAV_RESET_CURRENT_BOZZA"] = "MAV_RESET_CURRENT_BOZZA";
  // F24
  EReduxActionTypes["F24_ORDINARIO_SET_RAPPORTO_SELEZIONATO"] = "F24_ORDINARIO_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["F24_ORDINARIO_SET_STEP"] = "F24_ORDINARIO_SET_STEP";
  EReduxActionTypes["F24_ORDINARIO_SET_FORM_SUBMITTED"] = "F24_ORDINARIO_SET_FORM_SUBMITTED";
  EReduxActionTypes["F24_ORDINARIO_RESET"] = "F24_ORDINARIO_RESET";
  EReduxActionTypes["F24_ORDINARIO_RESET_RIEPILOGO"] = "F24_ORDINARIO_RESET_RIEPILOGO";
  EReduxActionTypes["F24_ORDINARIO_SET_CONTRIBUENTE_CODICE_FISCALE"] = "F24_ORDINARIO_SET_CONTRIBUENTE_CODICE_FISCALE";
  EReduxActionTypes["F24_ORDINARIO_SET_CONTRIBUENTE_NON_COINCIDENTE_ANNO_SOLARE"] = "F24_ORDINARIO_SET_CONTRIBUENTE_NON_COINCIDENTE_ANNO_SOLARE";
  EReduxActionTypes["F24_ORDINARIO_SET_CONTRIBUENTE_COGNOME"] = "F24_ORDINARIO_SET_CONTRIBUENTE_COGNOME";
  EReduxActionTypes["F24_ORDINARIO_SET_CONTRIBUENTE_NOME"] = "F24_ORDINARIO_SET_CONTRIBUENTE_NOME";
  EReduxActionTypes["F24_ORDINARIO_SET_CONTRIBUENTE_DATA_NASCITA"] = "F24_ORDINARIO_SET_CONTRIBUENTE_DATA_NASCITA";
  EReduxActionTypes["F24_ORDINARIO_SET_CONTRIBUENTE_SESSO"] = "F24_ORDINARIO_SET_CONTRIBUENTE_SESSO";
  EReduxActionTypes["F24_ORDINARIO_SET_CONTRIBUENTE_COMUNE"] = "F24_ORDINARIO_SET_CONTRIBUENTE_COMUNE";
  EReduxActionTypes["F24_ORDINARIO_SET_CONTRIBUENTE_PROVINCIA"] = "F24_ORDINARIO_SET_CONTRIBUENTE_PROVINCIA";
  EReduxActionTypes["F24_ORDINARIO_SET_DOMICILIO_FISCALE_COMUNE"] = "F24_ORDINARIO_SET_DOMICILIO_FISCALE_COMUNE";
  EReduxActionTypes["F24_ORDINARIO_SET_DOMICILIO_FISCALE_PROVINCIA"] = "F24_ORDINARIO_SET_DOMICILIO_FISCALE_PROVINCIA";
  EReduxActionTypes["F24_ORDINARIO_SET_DOMICILIO_FISCALE_VIA_E_CIVICO"] = "F24_ORDINARIO_SET_DOMICILIO_FISCALE_VIA_E_CIVICO";
  EReduxActionTypes["F24_ORDINARIO_SET_CODICE_FISCALE_COOBLIGATO"] = "F24_ORDINARIO_SET_CODICE_FISCALE_COOBLIGATO";
  EReduxActionTypes["F24_ORDINARIO_SET_CODICE_IDENTIFICATIVO_COOBLIGATO"] = "F24_ORDINARIO_SET_CODICE_IDENTIFICATIVO_COOBLIGATO";
  EReduxActionTypes["F24_ORDINARIO_SET_ERARIO_CODICE_ATTO"] = "F24_ORDINARIO_SET_ERARIO_CODICE_ATTO";
  EReduxActionTypes["F24_ORDINARIO_SET_ERARIO_CODICE_UFFICIO"] = "F24_ORDINARIO_SET_ERARIO_CODICE_UFFICIO";
  EReduxActionTypes["F24_ORDINARIO_SET_ERARIO_RIGA_CODICE_TRIBUTO"] = "F24_ORDINARIO_SET_ERARIO_RIGA_CODICE_TRIBUTO";
  EReduxActionTypes["F24_ORDINARIO_SET_ERARIO_RIGA_RATEAZIONE"] = "F24_ORDINARIO_SET_ERARIO_RIGA_RATEAZIONE";
  EReduxActionTypes["F24_ORDINARIO_SET_ERARIO_RIGA_ANNO_RIFERIMENTO"] = "F24_ORDINARIO_SET_ERARIO_RIGA_ANNO_RIFERIMENTO";
  EReduxActionTypes["F24_ORDINARIO_SET_ERARIO_RIGA_DEBITO"] = "F24_ORDINARIO_SET_ERARIO_RIGA_DEBITO";
  EReduxActionTypes["F24_ORDINARIO_SET_ERARIO_RIGA_CREDITO"] = "F24_ORDINARIO_SET_ERARIO_RIGA_CREDITO";
  EReduxActionTypes["F24_ORDINARIO_SET_INPS_RIGA_CODICE_SEDE"] = "F24_ORDINARIO_SET_INPS_RIGA_CODICE_SEDE";
  EReduxActionTypes["F24_ORDINARIO_SET_INPS_RIGA_CAUSALE_CONTRIBUTO"] = "F24_ORDINARIO_SET_INPS_RIGA_CAUSALE_CONTRIBUTO";
  EReduxActionTypes["F24_ORDINARIO_SET_INPS_RIGA_MATRICOLA"] = "F24_ORDINARIO_SET_INPS_RIGA_MATRICOLA";
  EReduxActionTypes["F24_ORDINARIO_SET_INPS_RIGA_PERIODO_DA"] = "F24_ORDINARIO_SET_INPS_RIGA_PERIODO_DA";
  EReduxActionTypes["F24_ORDINARIO_SET_INPS_RIGA_PERIODO_A"] = "F24_ORDINARIO_SET_INPS_RIGA_PERIODO_A";
  EReduxActionTypes["F24_ORDINARIO_SET_INPS_RIGA_DEBITO"] = "F24_ORDINARIO_SET_INPS_RIGA_DEBITO";
  EReduxActionTypes["F24_ORDINARIO_SET_INPS_RIGA_CREDITO"] = "F24_ORDINARIO_SET_INPS_RIGA_CREDITO";
  EReduxActionTypes["F24_ORDINARIO_SET_REGIONE_RIGA_CODICE_REGIONE"] = "F24_ORDINARIO_SET_REGIONE_RIGA_CODICE_REGIONE";
  EReduxActionTypes["F24_ORDINARIO_SET_REGIONE_RIGA_CODICE_TRIBUTO"] = "F24_ORDINARIO_SET_REGIONE_RIGA_CODICE_TRIBUTO";
  EReduxActionTypes["F24_ORDINARIO_SET_REGIONE_RIGA_RATEAZIONE"] = "F24_ORDINARIO_SET_REGIONE_RIGA_RATEAZIONE";
  EReduxActionTypes["F24_ORDINARIO_SET_REGIONE_RIGA_ANNO_RIFERIMENTO"] = "F24_ORDINARIO_SET_REGIONE_RIGA_ANNO_RIFERIMENTO";
  EReduxActionTypes["F24_ORDINARIO_SET_REGIONE_RIGA_DEBITO"] = "F24_ORDINARIO_SET_REGIONE_RIGA_DEBITO";
  EReduxActionTypes["F24_ORDINARIO_SET_REGIONE_RIGA_CREDITO"] = "F24_ORDINARIO_SET_REGIONE_RIGA_CREDITO";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_DETRAZIONE"] = "F24_ORDINARIO_SET_IMU_DETRAZIONE";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_RIGA_CODICE_REGIONE"] = "F24_ORDINARIO_SET_IMU_RIGA_CODICE_REGIONE";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_RIGA_R"] = "F24_ORDINARIO_SET_IMU_RIGA_R";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_RIGA_IV"] = "F24_ORDINARIO_SET_IMU_RIGA_IV";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_RIGA_A"] = "F24_ORDINARIO_SET_IMU_RIGA_A";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_RIGA_S"] = "F24_ORDINARIO_SET_IMU_RIGA_S";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_RIGA_NI"] = "F24_ORDINARIO_SET_IMU_RIGA_NI";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_RIGA_CODICE_TRIBUTO"] = "F24_ORDINARIO_SET_IMU_RIGA_CODICE_TRIBUTO";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_RIGA_RATEAZIONE"] = "F24_ORDINARIO_SET_IMU_RIGA_RATEAZIONE";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_RIGA_ANNO_RIFERIMENTO"] = "F24_ORDINARIO_SET_IMU_RIGA_ANNO_RIFERIMENTO";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_RIGA_DEBITO"] = "F24_ORDINARIO_SET_IMU_RIGA_DEBITO";
  EReduxActionTypes["F24_ORDINARIO_SET_IMU_RIGA_CREDITO"] = "F24_ORDINARIO_SET_IMU_RIGA_CREDITO";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CODICE_SEDE"] = "F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CODICE_SEDE";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CODICE_DITTA"] = "F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CODICE_DITTA";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CC"] = "F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CC";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_NUMERO_RIFERIMENTO"] = "F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_NUMERO_RIFERIMENTO";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CAUSALE"] = "F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CAUSALE";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_DEBITO"] = "F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_DEBITO";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CREDITO"] = "F24_ORDINARIO_SET_ALTRI_ENTI_IL_RIGA_CREDITO";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CODICE_ENTE"] = "F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CODICE_ENTE";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CODICE_SEDE"] = "F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CODICE_SEDE";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CAUSALE_CONTRIBUTO"] = "F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CAUSALE_CONTRIBUTO";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CODICE_POSIZIONE"] = "F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CODICE_POSIZIONE";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_PERIODO_DA"] = "F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_PERIODO_DA";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_PERIODO_A"] = "F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_PERIODO_A";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_DEBITO"] = "F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_DEBITO";
  EReduxActionTypes["F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CREDITO"] = "F24_ORDINARIO_SET_ALTRI_ENTI_MN_RIGA_CREDITO";
  EReduxActionTypes["F24_ORDINARIO_SET_DATA"] = "F24_ORDINARIO_SET_DATA";
  EReduxActionTypes["F24_ORDINARIO_GET_PARAMETRI_START"] = "F24_ORDINARIO_GET_PARAMETRI_START";
  EReduxActionTypes["F24_ORDINARIO_GET_PARAMETRI_SUCCESS"] = "F24_ORDINARIO_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["F24_ORDINARIO_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "F24_ORDINARIO_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["F24_ORDINARIO_GET_PARAMETRI_ERROR"] = "F24_ORDINARIO_GET_PARAMETRI_ERROR";
  EReduxActionTypes["F24_ORDINARIO_CHECK_F24_ORDINARIO_START"] = "F24_ORDINARIO_CHECK_F24_ORDINARIO_START";
  EReduxActionTypes["F24_ORDINARIO_CHECK_F24_ORDINARIO_SUCCESS"] = "F24_ORDINARIO_CHECK_F24_ORDINARIO_SUCCESS";
  EReduxActionTypes["F24_ORDINARIO_CHECK_F24_ORDINARIO_ERROR"] = "F24_ORDINARIO_CHECK_F24_ORDINARIO_ERROR";
  EReduxActionTypes["F24_ORDINARIO_GET_INFO_DISPOSITIVA_START"] = "F24_ORDINARIO_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["F24_ORDINARIO_GET_INFO_DISPOSITIVA_SUCCESS"] = "F24_ORDINARIO_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["F24_ORDINARIO_GET_INFO_DISPOSITIVA_ERROR"] = "F24_ORDINARIO_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["F24_ORDINARIO_AUTORIZZA_START"] = "F24_ORDINARIO_AUTORIZZA_START";
  EReduxActionTypes["F24_ORDINARIO_AUTORIZZA_SUCCESS"] = "F24_ORDINARIO_AUTORIZZA_SUCCESS";
  EReduxActionTypes["F24_ORDINARIO_AUTORIZZA_ERROR"] = "F24_ORDINARIO_AUTORIZZA_ERROR";
  EReduxActionTypes["F24_ORDINARIO_SET_PROVENIENZA"] = "F24_ORDINARIO_SET_PROVENIENZA";
  EReduxActionTypes["F24_ORDINARIO_STEP_REVOCA"] = "F24_ORDINARIO_STEP_REVOCA";
  EReduxActionTypes["F24_ORDINARIO_SET_RIEPILOGO_REVOCA"] = "F24_ORDINARIO_SET_RIEPILOGO_REVOCA";
  EReduxActionTypes["F24_ORDINARIO_GET_INFO_REVOCA_START"] = "F24_ORDINARIO_GET_INFO_REVOCA_START";
  EReduxActionTypes["F24_ORDINARIO_GET_INFO_REVOCA_SUCCESS"] = "F24_ORDINARIO_GET_INFO_REVOCA_SUCCESS";
  EReduxActionTypes["F24_ORDINARIO_GET_INFO_REVOCA_ERROR"] = "F24_ORDINARIO_GET_INFO_REVOCA_ERROR";
  EReduxActionTypes["F24_ORDINARIO_AUTORIZZA_REVOCA_START"] = "F24_ORDINARIO_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["F24_ORDINARIO_AUTORIZZA_REVOCA_SUCCESS"] = "F24_ORDINARIO_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["F24_ORDINARIO_AUTORIZZA_REVOCA_ERROR"] = "F24_ORDINARIO_AUTORIZZA_REVOCA_ERROR";
  EReduxActionTypes["F24_ORDINARIO_GET_BOZZE_START"] = "F24_ORDINARIO_GET_BOZZE_START";
  EReduxActionTypes["F24_ORDINARIO_GET_BOZZE_SUCCESS"] = "F24_ORDINARIO_GET_BOZZE_SUCCESS";
  EReduxActionTypes["F24_ORDINARIO_GET_BOZZE_ERROR"] = "F24_ORDINARIO_GET_BOZZE_ERROR";
  EReduxActionTypes["F24_ORDINARIO_ADD_BOZZA_START"] = "F24_ORDINARIO_ADD_BOZZA_START";
  EReduxActionTypes["F24_ORDINARIO_ADD_BOZZA_SUCCESS"] = "F24_ORDINARIO_ADD_BOZZA_SUCCESS";
  EReduxActionTypes["F24_ORDINARIO_ADD_BOZZA_ERROR"] = "F24_ORDINARIO_ADD_BOZZA_ERROR";
  EReduxActionTypes["F24_ORDINARIO_DEL_BOZZA_START"] = "F24_ORDINARIO_DEL_BOZZA_START";
  EReduxActionTypes["F24_ORDINARIO_DEL_BOZZA_SUCCESS"] = "F24_ORDINARIO_DEL_BOZZA_SUCCESS";
  EReduxActionTypes["F24_ORDINARIO_DEL_BOZZA_ERROR"] = "F24_ORDINARIO_DEL_BOZZA_ERROR";
  EReduxActionTypes["F24_ORDINARIO_SET_CONTRIBUENTE_AGGIUNGI_BENEFICIARIO_RUBRICA"] = "F24_ORDINARIO_SET_CONTRIBUENTE_AGGIUNGI_BENEFICIARIO_RUBRICA";
  EReduxActionTypes["F24_ORDINARIO_SET_CONTRIBUENTE_AGGIUNGI_BENEFICIARIO_WHITE_LIST"] = "F24_ORDINARIO_SET_CONTRIBUENTE_AGGIUNGI_BENEFICIARIO_WHITE_LIST";
  EReduxActionTypes["F24_ORDINARIO_SET_ID_CONTATTO_BENEFICIARIO"] = "F24_ORDINARIO_SET_ID_CONTATTO_BENEFICIARIO";
  EReduxActionTypes["F24_GET_BOZZA_START"] = "F24_GET_BOZZA_START";
  EReduxActionTypes["F24_GET_BOZZA_SUCCESS"] = "F24_GET_BOZZA_SUCCESS";
  EReduxActionTypes["F24_GET_BOZZA_ERROR"] = "F24_GET_BOZZA_ERROR";
  EReduxActionTypes["F24_ORDINARIO_GET_F24_FROM_BOZZA"] = "F24_ORDINARIO_GET_F24_FROM_BOZZA";
  EReduxActionTypes["F24_GET_F24_FROM_SCANNER"] = "F24_GET_F24_FROM_SCANNER";
  EReduxActionTypes["F24_UPDATE_BOZZA_START"] = "F24_UPDATE_BOZZA_START";
  EReduxActionTypes["F24_UPDATE_BOZZA_SUCCESS"] = "F24_UPDATE_BOZZA_SUCCESS";
  EReduxActionTypes["F24_UPDATE_BOZZA_ERROR"] = "F24_UPDATE_BOZZA_ERROR";
  EReduxActionTypes["F24_SET_BOZZA_SALVATA"] = "F24_SET_BOZZA_SALVATA";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_SEZIONE"] = "F24_SEMPLIFICATO_SET_SEZIONE";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_CODICE_TRIBUTO"] = "F24_SEMPLIFICATO_SET_CODICE_TRIBUTO";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_CODICE_ENTE"] = "F24_SEMPLIFICATO_SET_CODICE_ENTE";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_RAVVEDIMENTO"] = "F24_SEMPLIFICATO_SET_RAVVEDIMENTO";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_IMMOBILIARI_VARIATI"] = "F24_SEMPLIFICATO_SET_IMMOBILIARI_VARIATI";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_ACCONTO"] = "F24_SEMPLIFICATO_SET_ACCONTO";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_SALDO"] = "F24_SEMPLIFICATO_SET_SALDO";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_NUMERO_IMMOBILIARI"] = "F24_SEMPLIFICATO_SET_NUMERO_IMMOBILIARI";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_RATE"] = "F24_SEMPLIFICATO_SET_RATE";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_ANNO"] = "F24_SEMPLIFICATO_SET_ANNO";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_DETRAZIONI"] = "F24_SEMPLIFICATO_SET_DETRAZIONI";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_IMPORTO_DEBITO"] = "F24_SEMPLIFICATO_SET_IMPORTO_DEBITO";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_IMPORTO_CREDITO"] = "F24_SEMPLIFICATO_SET_IMPORTO_CREDITO";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_DATA"] = "F24_SEMPLIFICATO_SET_DATA";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_SALDO_EURO"] = "F24_SEMPLIFICATO_SET_SALDO_EURO";
  EReduxActionTypes["F24_SEMPLIFICATO_GET_CODICI_ENTE_START"] = "F24_SEMPLIFICATO_GET_CODICI_ENTE_START";
  EReduxActionTypes["F24_SEMPLIFICATO_GET_CODICI_ENTE_SUCCESS"] = "F24_SEMPLIFICATO_GET_CODICI_ENTE_SUCCESS";
  EReduxActionTypes["F24_SEMPLIFICATO_GET_CODICI_ENTE_ERROR"] = "F24_SEMPLIFICATO_GET_CODICI_ENTE_ERROR";
  EReduxActionTypes["F24_SEMPLIFICATO_GET_CODICI_ENTE"] = "F24_SEMPLIFICATO_GET_CODICI_ENTE";
  EReduxActionTypes["F24_SEMPLIFICATO_AGGIUNGI_MOTIVO"] = "F24_SEMPLIFICATO_AGGIUNGI_MOTIVO";
  EReduxActionTypes["F24_SEMPLIFICATO_RIMUOVI_MOTIVO"] = "F24_SEMPLIFICATO_RIMUOVI_MOTIVO";
  EReduxActionTypes["F24_SEMPLIFICATO_RESET_MOTIVI_MOBILE"] = "F24_SEMPLIFICATO_RESET_MOTIVI_MOBILE";
  EReduxActionTypes["F24_SEMPLIFICATO_SET_NUMERO_IDENTIFICATIVO"] = "F24_SEMPLIFICATO_SET_NUMERO_IDENTIFICATIVO";
  EReduxActionTypes["F24_ACCISE_SET_CODICE_UFFICIO"] = "F24_ACCISE_SET_CODICE_UFFICIO";
  EReduxActionTypes["F24_ACCISE_SET_CODICE_ATTO"] = "F24_ACCISE_SET_CODICE_ATTO";
  EReduxActionTypes["F24_ACCISE_SET_TOTAL"] = "F24_ACCISE_SET_TOTAL";
  EReduxActionTypes["F24_ACCISE_SET_RIGA_CODICE_ENTE"] = "F24_ACCISE_SET_RIGA_CODICE_ENTE";
  EReduxActionTypes["F24_ACCISE_SET_RIGA_PROVINCIA"] = "F24_ACCISE_SET_RIGA_PROVINCIA";
  EReduxActionTypes["F24_ACCISE_SET_RIGA_CODICE_TRIBUTO"] = "F24_ACCISE_SET_RIGA_CODICE_TRIBUTO";
  EReduxActionTypes["F24_ACCISE_SET_RIGA_CODICE_IDENTIFICATIVO"] = "F24_ACCISE_SET_RIGA_CODICE_IDENTIFICATIVO";
  EReduxActionTypes["F24_ACCISE_SET_RIGA_RATEAZIONE"] = "F24_ACCISE_SET_RIGA_RATEAZIONE";
  EReduxActionTypes["F24_ACCISE_SET_RIGA_MESE"] = "F24_ACCISE_SET_RIGA_MESE";
  EReduxActionTypes["F24_ACCISE_SET_RIGA_ANNO_RIFERIMENTO"] = "F24_ACCISE_SET_RIGA_ANNO_RIFERIMENTO";
  EReduxActionTypes["F24_ACCISE_SET_RIGA_IMPORTO_DEBITO"] = "F24_ACCISE_SET_RIGA_IMPORTO_DEBITO";
  EReduxActionTypes["F24_ELIDE_SET_CODICE_UFFICIO"] = "F24_ELIDE_SET_CODICE_UFFICIO";
  EReduxActionTypes["F24_ELIDE_SET_CODICE_ATTO"] = "F24_ELIDE_SET_CODICE_ATTO";
  EReduxActionTypes["F24_ELIDE_SET_TOTAL"] = "F24_ELIDE_SET_TOTAL";
  EReduxActionTypes["F24_ELIDE_SET_RIGA_TIPO_VEICOLO"] = "F24_ELIDE_SET_RIGA_TIPO_VEICOLO";
  EReduxActionTypes["F24_ELIDE_SET_RIGA_ELEMENTO_ID"] = "F24_ELIDE_SET_RIGA_ELEMENTO_ID";
  EReduxActionTypes["F24_ELIDE_SET_RIGA_CODICE_TRIBUTO"] = "F24_ELIDE_SET_RIGA_CODICE_TRIBUTO";
  EReduxActionTypes["F24_ELIDE_SET_RIGA_ANNO_RIFERIMENTO"] = "F24_ELIDE_SET_RIGA_ANNO_RIFERIMENTO";
  EReduxActionTypes["F24_ELIDE_SET_RIGA_IMPORTO_DEBITO"] = "F24_ELIDE_SET_RIGA_IMPORTO_DEBITO";
  EReduxActionTypes["F24_ESPORTA_F24_PDF_START"] = "F24_ESPORTA_F24_PDF_START";
  EReduxActionTypes["F24_ESPORTA_F24_PDF_SUCCESS"] = "F24_ESPORTA_F24_PDF_SUCCESS";
  EReduxActionTypes["F24_ESPORTA_F24_PDF_ERROR"] = "F24_ESPORTA_F24_PDF_ERROR";
  EReduxActionTypes["F24_ESPORTA_QUIETANZA_PDF_START"] = "F24_ESPORTA_QUIETANZA_PDF_START";
  EReduxActionTypes["F24_ESPORTA_QUIETANZA_PDF_SUCCESS"] = "F24_ESPORTA_QUIETANZA_PDF_SUCCESS";
  EReduxActionTypes["F24_ESPORTA_QUIETANZA_PDF_ERROR"] = "F24_ESPORTA_QUIETANZA_PDF_ERROR";
  EReduxActionTypes["F24_ORDINARIO_GET_CODICI_UFFICIO_START"] = "F24_ORDINARIO_GET_CODICI_UFFICIO_START";
  EReduxActionTypes["F24_ORDINARIO_GET_CODICI_UFFICIO_SUCCESS"] = "F24_ORDINARIO_GET_CODICI_UFFICIO_SUCCESS";
  EReduxActionTypes["F24_ORDINARIO_GET_CODICI_UFFICIO_ERROR"] = "F24_ORDINARIO_GET_CODICI_UFFICIO_ERROR";
  EReduxActionTypes["F24_INIT_DOCUMENT_SESSION_F24_START"] = "F24_INIT_DOCUMENT_SESSION_F24_START";
  EReduxActionTypes["F24_INIT_DOCUMENT_SESSION_F24_SUCCESS"] = "F24_INIT_DOCUMENT_SESSION_F24_SUCCESS";
  EReduxActionTypes["F24_GET_INFO_DOCUMENT_SESSION_TOKEN_F24_START"] = "F24_GET_INFO_DOCUMENT_SESSION_TOKEN_F24_START";
  EReduxActionTypes["F24_GET_INFO_DOCUMENT_SESSION_TOKEN_F24_SUCCESS"] = "F24_GET_INFO_DOCUMENT_SESSION_TOKEN_F24_SUCCESS";
  EReduxActionTypes["F24_GET_DOWNLOAD_DOCUMENT_SESSION_TOKEN_F24_START"] = "F24_GET_DOWNLOAD_DOCUMENT_SESSION_TOKEN_F24_START";
  EReduxActionTypes["F24_GET_DOWNLOAD_DOCUMENT_SESSION_TOKEN_F24_SUCCESS"] = "F24_GET_DOWNLOAD_DOCUMENT_SESSION_TOKEN_F24_SUCCESS";
  EReduxActionTypes["F24_RESET_DOCUMENT_SESSION_F24"] = "F24_RESET_DOCUMENT_SESSION_F24";
  // RICARICA CELLULARE
  EReduxActionTypes["RICARICA_CELLULARE_NUMERO_CELLULARE"] = "RICARICA_CELLULARE_NUMERO_CELLULARE";
  EReduxActionTypes["RICARICA_CELLULARE_AGGIUNGI_BENEFICIARIO_RUBRICA"] = "RICARICA_CELLULARE_AGGIUNGI_BENEFICIARIO_RUBRICA";
  EReduxActionTypes["RICARICA_CELLULARE_NOME_BENEFICIARIO"] = "RICARICA_CELLULARE_NOME_BENEFICIARIO";
  EReduxActionTypes["RICARICA_CELLULARE_SET_ID_CONTATTO_BENEFICIARIO"] = "RICARICA_CELLULARE_SET_ID_CONTATTO_BENEFICIARIO";
  EReduxActionTypes["RICARICA_CELLULARE_BENEFICIARIO_AFFIDABILE"] = "RICARICA_CELLULARE_BENEFICIARIO_AFFIDABILE";
  EReduxActionTypes["RICARICA_CELLULARE_NOME_GESTORE"] = "RICARICA_CELLULARE_NOME_GESTORE";
  EReduxActionTypes["RICARICA_CELLULARE_CODICE_SIA"] = "RICARICA_CELLULARE_CODICE_SIA";
  EReduxActionTypes["RICARICA_CELLULARE_RAPPORTO_SELEZIONATO"] = "RICARICA_CELLULARE_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["RICARICA_CELLULARE_TAGLIO_RICARICA"] = "RICARICA_CELLULARE_TAGLIO_RICARICA";
  EReduxActionTypes["RICARICA_CELLULARE_STEP"] = "RICARICA_CELLULARE_STEP";
  EReduxActionTypes["RICARICA_CELLULARE_SALVA_1CLICK"] = "RICARICA_CELLULARE_SALVA_1CLICK";
  EReduxActionTypes["RICARICA_CELLULARE_ALIAS_1CLICK"] = "RICARICA_CELLULARE_ALIAS_1CLICK";
  EReduxActionTypes["RICARICA_CELLULARE_RESET"] = "RICARICA_CELLULARE_RESET";
  EReduxActionTypes["RICARICA_CELLULARE_FORM_SUBMITTED"] = "RICARICA_CELLULARE_FORM_SUBMITTED";
  EReduxActionTypes["RICARICA_CELLULARE_SET_RAPPORTO_SELEZIONATO"] = "RICARICA_CELLULARE_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["RICARICA_CELLULARE_GET_PARAMETRI_START"] = "RICARICA_CELLULARE_GET_PARAMETRI_START";
  EReduxActionTypes["RICARICA_CELLULARE_GET_PARAMETRI_SUCCESS"] = "RICARICA_CELLULARE_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "RICARICA_CELLULARE_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_GET_PARAMETRI_ERROR"] = "RICARICA_CELLULARE_GET_PARAMETRI_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_GET_INFO_DISPOSITIVA_START"] = "RICARICA_CELLULARE_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["RICARICA_CELLULARE_GET_INFO_DISPOSITIVA_SUCCESS"] = "RICARICA_CELLULARE_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_GET_INFO_DISPOSITIVA_ERROR"] = "RICARICA_CELLULARE_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_CONTROLLO_START"] = "RICARICA_CELLULARE_CONTROLLO_START";
  EReduxActionTypes["RICARICA_CELLULARE_CONTROLLO_SUCCESS"] = "RICARICA_CELLULARE_CONTROLLO_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_CONTROLLO_ERROR"] = "RICARICA_CELLULARE_CONTROLLO_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_AUTORIZZA_START"] = "RICARICA_CELLULARE_AUTORIZZA_START";
  EReduxActionTypes["RICARICA_CELLULARE_AUTORIZZA_SUCCESS"] = "RICARICA_CELLULARE_AUTORIZZA_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_AUTORIZZA_ERROR"] = "RICARICA_CELLULARE_AUTORIZZA_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_GET_GESTORI_START"] = "RICARICA_CELLULARE_GET_GESTORI_START";
  EReduxActionTypes["RICARICA_CELLULARE_GET_GESTORI_SUCCESS"] = "RICARICA_CELLULARE_GET_GESTORI_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_GET_GESTORI_ERROR"] = "RICARICA_CELLULARE_GET_GESTORI_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_GET_TAGLI_GESTORE_START"] = "RICARICA_CELLULARE_GET_TAGLI_GESTORE_START";
  EReduxActionTypes["RICARICA_CELLULARE_GET_TAGLI_GESTORE_SUCCESS"] = "RICARICA_CELLULARE_GET_TAGLI_GESTORE_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_GET_TAGLI_GESTORE_ERROR"] = "RICARICA_CELLULARE_GET_TAGLI_GESTORE_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_SET_BOZZASALVATA"] = " RICARICA_CELLULARE_SET_BOZZASALVATA";
  EReduxActionTypes["RICARICA_CELLULARE_GET_BOZZE_START"] = "RICARICA_CELLULARE_GET_BOZZE_START";
  EReduxActionTypes["RICARICA_CELLULARE_GET_BOZZE_SUCCESS"] = "RICARICA_CELLULARE_GET_BOZZE_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_GET_BOZZE_ERROR"] = "RICARICA_CELLULARE_GET_BOZZE_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_GET_BOZZA_START"] = "RICARICA_CELLULARE_GET_BOZZA_START";
  EReduxActionTypes["RICARICA_CELLULARE_GET_BOZZA_SUCCESS"] = "RICARICA_CELLULARE_GET_BOZZA_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_GET_BOZZA_ERROR"] = "RICARICA_CELLULARE_GET_BOZZA_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_GET_RICARICA_CELLULARE_FROM_BOZZA"] = "RICARICA_CELLULARE_GET_RICARICA_CELLULARE_FROM_BOZZA";
  EReduxActionTypes["RICARICA_CELLULARE_ADD_BOZZA_START"] = "RICARICA_CELLULARE_ADD_BOZZA_START";
  EReduxActionTypes["RICARICA_CELLULARE_ADD_BOZZA_SUCCESS"] = "RICARICA_CELLULARE_ADD_BOZZA_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_ADD_BOZZA_ERROR"] = "RICARICA_CELLULARE_ADD_BOZZA_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_DEL_BOZZA_START"] = "RICARICA_CELLULARE_DEL_BOZZA_START";
  EReduxActionTypes["RICARICA_CELLULARE_DEL_BOZZA_SUCCESS"] = "RICARICA_CELLULARE_DEL_BOZZA_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_DEL_BOZZA_ERROR"] = "RICARICA_CELLULARE_DEL_BOZZA_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_DEL_BOZZA"] = "RICARICA_CELLULARE_DEL_BOZZA";
  EReduxActionTypes["RICARICA_CELLULARE_ADD_BOZZA"] = "RICARICA_CELLULARE_ADD_BOZZA";
  EReduxActionTypes["RICARICA_CELLULARE_UPDATE_BOZZA_START"] = "RICARICA_CELLULARE_UPDATE_BOZZA_START";
  EReduxActionTypes["RICARICA_CELLULARE_UPDATE_BOZZA_SUCCESS"] = "RICARICA_CELLULARE_UPDATE_BOZZA_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_UPDATE_BOZZA_ERROR"] = "RICARICA_CELLULARE_UPDATE_BOZZA_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_SET_CONTATTO_SELEZIONATO"] = "RICARICA_CELLULARE_SET_CONTATTO_SELEZIONATO";
  EReduxActionTypes["RICARICA_CELLULARE_GET_ONECLICK_START"] = "RICARICA_CELLULARE_GET_ONECLICK_START";
  EReduxActionTypes["RICARICA_CELLULARE_GET_ONECLICK_SUCCESS"] = "RICARICA_CELLULARE_GET_ONECLICK_SUCCESS";
  EReduxActionTypes["RICARICA_CELLULARE_GET_ONECLICK_ERROR"] = "RICARICA_CELLULARE_GET_ONECLICK_ERROR";
  EReduxActionTypes["RICARICA_CELLULARE_CODICE_RAPPORTO"] = "RICARICA_CELLULARE_CODICE_RAPPORTO";
  EReduxActionTypes["RICARICA_CELLULARE_RESET_RIEPILOGO"] = "RICARICA_CELLULARE_RESET_RIEPILOGO";
  EReduxActionTypes["RICARICA_CELLULARE_SET_SALVA_BOZZA_VALIDATION"] = "RICARICA_CELLULARE_SET_SALVA_BOZZA_VALIDATION";
  EReduxActionTypes["RICARICA_CELLULARE_SET_AVANTI_VALIDATION"] = "RICARICA_CELLULARE_SET_AVANTI_VALIDATION";
  EReduxActionTypes["RICARICA_CELLULARE_RESET_TAGLIO_RICARICA"] = "RICARICA_CELLULARE_RESET_TAGLIO_RICARICA";
  EReduxActionTypes["RICARICA_CELLULARE_RESET_CURRENT_BOZZA_AND_FORM"] = "RICARICA_CELLULARE_RESET_CURRENT_BOZZA_AND_FORM";
  EReduxActionTypes["RICARICA_CELLULARE_RESET_FORM"] = "RICARICA_CELLULARE_RESET_FORM";
  // RUBRICA
  EReduxActionTypes["RUBRICA_GET_CONTATTI_START"] = "RUBRICA_GET_CONTATTI_START";
  EReduxActionTypes["RUBRICA_GET_CONTATTI_SUCCESS"] = "RUBRICA_GET_CONTATTI_SUCCESS";
  EReduxActionTypes["RUBRICA_LIGHT_GET_CONTATTI_START"] = "RUBRICA_LIGHT_GET_CONTATTI_START";
  EReduxActionTypes["RUBRICA_LIGHT_GET_CONTATTI_SUCCESS"] = "RUBRICA_LIGHT_GET_CONTATTI_SUCCESS";
  EReduxActionTypes["RUBRICA_GET_CONTATTI_ERROR"] = "RUBRICA_GET_CONTATTI_ERROR";
  EReduxActionTypes["RUBRICA_TIPO_BENEFICIARIO"] = "RUBRICA_TIPO_BENEFICIARIO";
  EReduxActionTypes["RUBRICA_SET_ACCORDION_FILTRI_OPENED"] = "RUBRICA_SET_ACCORDION_FILTRI_OPENED";
  EReduxActionTypes["RUBRICA_CONTENUTO"] = "RUBRICA_CONTENUTO";
  EReduxActionTypes["RUBRICA_CONTATTO_SELEZIONATO"] = "RUBRICA_CONTATTO_SELEZIONATO";
  EReduxActionTypes["RUBRICA_RESET_CONTATTO_SELEZIONATO"] = "RUBRICA_RESET_CONTATTO_SELEZIONATO";
  EReduxActionTypes["RUBRICA_GET_PARAMETRI_START"] = "RUBRICA_GET_PARAMETRI_START";
  EReduxActionTypes["RUBRICA_GET_PARAMETRI_SUCCESS"] = "RUBRICA_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["RUBRICA_GET_PARAMETRI_ERROR"] = "RUBRICA_GET_PARAMETRI_ERROR";
  EReduxActionTypes["RUBRICA_RESET_FILTRO"] = "RUBRICA_RESET_FILTRO";
  EReduxActionTypes["RUBRICA_ELIMINA_CONTATTO_START"] = "RUBRICA_ELIMINA_CONTATTO_START";
  EReduxActionTypes["RUBRICA_ELIMINA_CONTATTO_SUCCESS"] = "RUBRICA_ELIMINA_CONTATTO_SUCCESS";
  EReduxActionTypes["RUBRICA_ELIMINA_CONTATTO_ERROR"] = "RUBRICA_ELIMINA_CONTATTO_ERROR";
  EReduxActionTypes["RUBRICA_RESET_CONTATTI"] = "RUBRICA_RESET_CONTATTI";
  EReduxActionTypes["RUBRICA_CONTROLLO_CONTATTO_START"] = "RUBRICA_CONTROLLO_CONTATTO_START";
  EReduxActionTypes["RUBRICA_CONTROLLO_CONTATTO_SUCCESS"] = "RUBRICA_CONTROLLO_CONTATTO_SUCCESS";
  EReduxActionTypes["RUBRICA_CONTROLLO_CONTATTO_ERROR"] = "RUBRICA_CONTROLLO_CONTATTO_ERROR";
  EReduxActionTypes["RUBRICA_GET_INFO_DISPOSITIVA_AGGIUNGI_CONTATTO_START"] = "RUBRICA_GET_INFO_DISPOSITIVA_AGGIUNGI_CONTATTO_START";
  EReduxActionTypes["RUBRICA_GET_INFO_DISPOSITIVA_AGGIUNGI_CONTATTO_SUCCESS"] = "RUBRICA_GET_INFO_DISPOSITIVA_AGGIUNGI_CONTATTO_SUCCESS";
  EReduxActionTypes["RUBRICA_GET_INFO_DISPOSITIVA_AGGIUNGI_CONTATTO_ERROR"] = "RUBRICA_GET_INFO_DISPOSITIVA_AGGIUNGI_CONTATTO_ERROR";
  EReduxActionTypes["RUBRICA_AUTORIZZA_AGGIUNGI_CONTATTO_START"] = "RUBRICA_AUTORIZZA_AGGIUNGI_CONTATTO_START";
  EReduxActionTypes["RUBRICA_AUTORIZZA_AGGIUNGI_CONTATTO_SUCCESS"] = "RUBRICA_AUTORIZZA_AGGIUNGI_CONTATTO_SUCCESS";
  EReduxActionTypes["RUBRICA_AUTORIZZA_AGGIUNGI_CONTATTO_ERROR"] = "RUBRICA_AUTORIZZA_AGGIUNGI_CONTATTO_ERROR";
  EReduxActionTypes["RUBRICA_AGGIUNGI_CONTATTO_SET_STEP"] = "RUBRICA_AGGIUNGI_CONTATTO_SET_STEP";
  EReduxActionTypes["RUBRICA_SET_ACCORDION_DATI_PERSONALI_OPENED"] = "RUBRICA_SET_ACCORDION_DATI_PERSONALI_OPENED";
  EReduxActionTypes["RUBRICA_SET_ACCORDION_DATI_BONIFICO_OPENED"] = "RUBRICA_SET_ACCORDION_DATI_BONIFICO_OPENED";
  EReduxActionTypes["RUBRICA_SET_ACCORDION_DATI_RICARICA_OPENED"] = "RUBRICA_SET_ACCORDION_DATI_RICARICA_OPENED";
  EReduxActionTypes["RUBRICA_SET_ACCORDION_DATI_BOLLO_OPENED"] = "RUBRICA_SET_ACCORDION_DATI_BOLLO_OPENED";
  EReduxActionTypes["RUBRICA_SET_ACCORDION_DATI_ALTOADIGEPASS_OPENED"] = "RUBRICA_SET_ACCORDION_DATI_ALTOADIGEPASS_OPENED";
  EReduxActionTypes["RUBRICA_SET_ACCORDION_DATI_F24_OPENED"] = "RUBRICA_SET_ACCORDION_DATI_F24_OPENED";
  EReduxActionTypes["RUBRICA_SET_ALIAS_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_ALIAS_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_DISPENSA_CREDENZIALE_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_DISPENSA_CREDENZIALE_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_EMAIL_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_EMAIL_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_AGGIUNGI_EMAIL_AGGIUNGI_CONTATTO"] = "RUBRICA_AGGIUNGI_EMAIL_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_RIMUOVI_EMAIL_AGGIUNGI_CONTATTO"] = "RUBRICA_RIMUOVI_EMAIL_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_IBAN_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_IBAN_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_IBAN_ALIAS_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_IBAN_ALIAS_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_IBAN_DISPENSA_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_IBAN_DISPENSA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_AGGIUNGI_IBAN_AGGIUNGI_CONTATTO"] = "RUBRICA_AGGIUNGI_IBAN_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_RIMUOVI_IBAN_AGGIUNGI_CONTATTO"] = "RUBRICA_RIMUOVI_IBAN_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_TELEFONO_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_TELEFONO_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_TELEFONO_ALIAS_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_TELEFONO_ALIAS_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_TELEFONO_DISPENSA_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_TELEFONO_DISPENSA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_AGGIUNGI_TELEFONO_AGGIUNGI_CONTATTO"] = "RUBRICA_AGGIUNGI_TELEFONO_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_RIMUOVI_TELEFONO_AGGIUNGI_CONTATTO"] = "RUBRICA_RIMUOVI_TELEFONO_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_TARGA_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_TARGA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_TARGA_ALIAS_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_TARGA_ALIAS_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_TARGA_DISPENSA_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_TARGA_DISPENSA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_AGGIUNGI_TARGA_AGGIUNGI_CONTATTO"] = "RUBRICA_AGGIUNGI_TARGA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_RIMUOVI_TARGA_AGGIUNGI_CONTATTO"] = "RUBRICA_RIMUOVI_TARGA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_ALTOADIGEPASS_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_ALTOADIGEPASS_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_ALTOADIGEPASS_ALIAS_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_ALTOADIGEPASS_ALIAS_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_ALTOADIGEPASS_DISPENSA_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_ALTOADIGEPASS_DISPENSA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_AGGIUNGI_ALTOADIGEPASS_AGGIUNGI_CONTATTO"] = "RUBRICA_AGGIUNGI_ALTOADIGEPASS_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_RIMUOVI_ALTOADIGEPASS_AGGIUNGI_CONTATTO"] = "RUBRICA_RIMUOVI_ALTOADIGEPASS_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_NOME_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_NOME_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_COGNOME_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_COGNOME_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_DATADINASCITA_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_DATADINASCITA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_SESSO_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_SESSO_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_LUOGONASCITA_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_LUOGONASCITA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_PROVINCIANASCITA_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_PROVINCIANASCITA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_INDIRIZZO_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_INDIRIZZO_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_CAP_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_CAP_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_COMUNE_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_COMUNE_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_PROVINCIA_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_PROVINCIA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_CODICEFISCALE_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_CODICEFISCALE_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_DISPENSA_CODICEFISCALE_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_DISPENSA_CODICEFISCALE_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_PARTITAIVA_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_PARTITAIVA_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_FORM_SUBMITTED_AGGIUNGI_CONTATTO"] = "RUBRICA_SET_FORM_SUBMITTED_AGGIUNGI_CONTATTO";
  EReduxActionTypes["RUBRICA_SET_MODIFICA_CONTATTO"] = "RUBRICA_SET_MODIFICA_CONTATTO";
  EReduxActionTypes["RUBRICA_RESET_RIEPILOGO"] = "RUBRICA_RESET_RIEPILOGO";
  EReduxActionTypes["RUBRICA_RESET_FORM"] = "RUBRICA_RESET_FORM";
  // BONIFICO FRECCIA
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_PARAMETRI_START"] = "BOLLETTINO_FRECCIA_GET_PARAMETRI_START";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_PARAMETRI_SUCCESS"] = "BOLLETTINO_FRECCIA_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "BOLLETTINO_FRECCIA_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_PARAMETRI_ERROR"] = "BOLLETTINO_FRECCIA_GET_PARAMETRI_ERROR";
  EReduxActionTypes["BOLLETTINO_FRECCIA_CHECK_FRECCIA_START"] = "BOLLETTINO_FRECCIA_CHECK_FRECCIA_START";
  EReduxActionTypes["BOLLETTINO_FRECCIA_CHECK_FRECCIA_SUCCESS"] = "BOLLETTINO_FRECCIA_CHECK_FRECCIA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_FRECCIA_CHECK_FRECCIA_ERROR"] = "BOLLETTINO_FRECCIA_CHECK_FRECCIA_ERROR";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_INFO_DISPOSITIVA_START"] = "BOLLETTINO_FRECCIA_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_INFO_DISPOSITIVA_SUCCESS"] = "BOLLETTINO_FRECCIA_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_INFO_DISPOSITIVA_ERROR"] = "BOLLETTINO_FRECCIA_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["BOLLETTINO_FRECCIA_AUTORIZZA_START"] = "BOLLETTINO_FRECCIA_AUTORIZZA_START";
  EReduxActionTypes["BOLLETTINO_FRECCIA_AUTORIZZA_SUCCESS"] = "BOLLETTINO_FRECCIA_AUTORIZZA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_FRECCIA_AUTORIZZA_ERROR"] = "BOLLETTINO_FRECCIA_AUTORIZZA_ERROR";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_STEP"] = "BOLLETTINO_FRECCIA_SET_STEP";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_RAPPORTO_SELEZIONATO"] = "BOLLETTINO_FRECCIA_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_CODICE_SIA"] = "BOLLETTINO_FRECCIA_SET_CODICE_SIA";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_RIFERIMENTO_CREDITORE"] = "BOLLETTINO_FRECCIA_SET_RIFERIMENTO_CREDITORE";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_IBAN_CREDITORE"] = "BOLLETTINO_FRECCIA_SET_IBAN_CREDITORE";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_IMPORTO"] = "BOLLETTINO_FRECCIA_SET_IMPORTO";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_DATA_SCADENZA"] = "BOLLETTINO_FRECCIA_SET_DATA_SCADENZA";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_NOTE"] = "BOLLETTINO_FRECCIA_SET_NOTE";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_CIN_IMPORTO"] = "BOLLETTINO_FRECCIA_SET_CIN_IMPORTO";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_CIN_INTERMEDIO"] = "BOLLETTINO_FRECCIA_SET_CIN_INTERMEDIO";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_CIN_COMPLESSO"] = "BOLLETTINO_FRECCIA_SET_CIN_COMPLESSO";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_CARATTERE_ESENZIONE"] = "BOLLETTINO_FRECCIA_SET_CARATTERE_ESENZIONE";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_FORM_SUBMITTED"] = "BOLLETTINO_FRECCIA_SET_FORM_SUBMITTED";
  EReduxActionTypes["BOLLETTINO_FRECCIA_RESET"] = "BOLLETTINO_FRECCIA_RESET";
  EReduxActionTypes["BOLLETTINO_FRECCIA_RESET_RIEPILOGO"] = "BOLLETTINO_FRECCIA_RESET_RIEPILOGO";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_RIEPILOGO_REVOCA"] = "BOLLETTINO_FRECCIA_SET_RIEPILOGO_REVOCA";
  EReduxActionTypes["BOLLETTINO_FRECCIA_SET_PROVENIENZA"] = "BOLLETTINO_FRECCIA_SET_PROVENIENZA";
  EReduxActionTypes["BOLLETTINO_FRECCIA_STEP_REVOCA"] = "BOLLETTINO_FRECCIA_STEP_REVOCA";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_INFO_REVOCA_START"] = "BOLLETTINO_FRECCIA_GET_INFO_REVOCA_START";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_INFO_REVOCA_SUCCESS"] = "BOLLETTINO_FRECCIA_GET_INFO_REVOCA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_INFO_REVOCA_ERROR"] = "BOLLETTINO_FRECCIA_GET_INFO_REVOCA_ERROR";
  EReduxActionTypes["BOLLETTINO_FRECCIA_AUTORIZZA_REVOCA_START"] = "BOLLETTINO_FRECCIA_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["BOLLETTINO_FRECCIA_AUTORIZZA_REVOCA_SUCCESS"] = "BOLLETTINO_FRECCIA_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_FRECCIA_AUTORIZZA_REVOCA_ERROR"] = "BOLLETTINO_FRECCIA_AUTORIZZA_REVOCA_ERROR";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_BOZZE_START"] = "BOLLETTINO_FRECCIA_GET_BOZZE_START";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_BOZZE_SUCCESS"] = "BOLLETTINO_FRECCIA_GET_BOZZE_SUCCESS";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_BOZZE_ERROR"] = "BOLLETTINO_FRECCIA_GET_BOZZE_ERROR";
  EReduxActionTypes["BOLLETTINO_FRECCIA_ADD_BOZZA_START"] = "BOLLETTINO_FRECCIA_ADD_BOZZA_START";
  EReduxActionTypes["BOLLETTINO_FRECCIA_ADD_BOZZA_SUCCESS"] = "BOLLETTINO_FRECCIA_ADD_BOZZA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_FRECCIA_ADD_BOZZA_ERROR"] = "BOLLETTINO_FRECCIA_ADD_BOZZA_ERROR";
  EReduxActionTypes["BOLLETTINO_FRECCIA_GET_FROM_BOZZA"] = "BOLLETTINO_FRECCIA_GET_FROM_BOZZA";
  EReduxActionTypes["BOLLETTINO_FRECCIA_DEL_BOZZA_START"] = "BOLLETTINO_FRECCIA_DEL_BOZZA_START";
  EReduxActionTypes["BOLLETTINO_FRECCIA_DEL_BOZZA_SUCCESS"] = "BOLLETTINO_FRECCIA_DEL_BOZZA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_FRECCIA_DEL_BOZZA_ERROR"] = "BOLLETTINO_FRECCIA_DEL_BOZZA_ERROR";
  EReduxActionTypes["BOLLETTINO_FRECCIA_UPDATE_BOZZA_START"] = "BOLLETTINO_FRECCIA_UPDATE_BOZZA_START";
  EReduxActionTypes["BOLLETTINO_FRECCIA_UPDATE_BOZZA_SUCCESS"] = "BOLLETTINO_FRECCIA_UPDATE_BOZZA_SUCCESS";
  // BOLLETTINO POSTALE
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_PARAMETRI_START"] = "BOLLETTINO_POSTALE_GET_PARAMETRI_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_PARAMETRI_SUCCESS"] = "BOLLETTINO_POSTALE_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "BOLLETTINO_POSTALE_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_PARAMETRI_ERROR"] = "BOLLETTINO_POSTALE_GET_PARAMETRI_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_CONTROLLO_START"] = "BOLLETTINO_POSTALE_CONTROLLO_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_CONTROLLO_SUCCESS"] = "BOLLETTINO_POSTALE_CONTROLLO_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_CONTROLLO_ERROR"] = "BOLLETTINO_POSTALE_CONTROLLO_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_AUTORIZZA_START"] = "BOLLETTINO_POSTALE_AUTORIZZA_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_AUTORIZZA_SUCCESS"] = "BOLLETTINO_POSTALE_AUTORIZZA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_AUTORIZZA_ERROR"] = "BOLLETTINO_POSTALE_AUTORIZZA_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_INFO_AUTENTICAZIONE_START"] = "BOLLETTINO_POSTALE_GET_INFO_AUTENTICAZIONE_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "BOLLETTINO_POSTALE_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_INFO_AUTENTICAZIONE_ERROR"] = "BOLLETTINO_POSTALE_GET_INFO_AUTENTICAZIONE_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_BOZZE_START"] = "BOLLETTINO_POSTALE_GET_BOZZE_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_BOZZE_SUCCESS"] = "BOLLETTINO_POSTALE_GET_BOZZE_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_BOZZE_ERROR"] = "BOLLETTINO_POSTALE_GET_BOZZE_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_BOZZA_START"] = "BOLLETTINO_POSTALE_GET_BOZZA_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_BOZZA_SUCCESS"] = "BOLLETTINO_POSTALE_GET_BOZZA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_BOZZA_ERROR"] = "BOLLETTINO_POSTALE_GET_BOZZA_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_ADD_BOZZA_START"] = "BOLLETTINO_POSTALE_ADD_BOZZA_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_ADD_BOZZA_SUCCESS"] = "BOLLETTINO_POSTALE_ADD_BOZZA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_ADD_BOZZA_ERROR"] = "BOLLETTINO_POSTALE_ADD_BOZZA_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_DELETE_BOZZA_START"] = "BOLLETTINO_POSTALE_DELETE_BOZZA_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_DELETE_BOZZA_SUCCESS"] = "BOLLETTINO_POSTALE_DELETE_BOZZA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_DELETE_BOZZA_ERROR"] = "BOLLETTINO_POSTALE_DELETE_BOZZA_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_ONECLICK_START"] = "BOLLETTINO_POSTALE_GET_ONECLICK_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_ONECLICK_SUCCESS"] = "BOLLETTINO_POSTALE_GET_ONECLICK_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_ONECLICK_ERROR"] = "BOLLETTINO_POSTALE_GET_ONECLICK_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_PROVENIENZA"] = "BOLLETTINO_POSTALE_SET_PROVENIENZA";
  EReduxActionTypes["BOLLETTINO_POSTALE_STEP_REVOCA"] = "BOLLETTINO_POSTALE_STEP_REVOCA";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_RIEPILOGO_REVOCA"] = "BOLLETTINO_POSTALE_SET_RIEPILOGO_REVOCA";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_INFO_REVOCA_START"] = "BOLLETTINO_POSTALE_GET_INFO_REVOCA_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_INFO_REVOCA_SUCCESS"] = "BOLLETTINO_POSTALE_GET_INFO_REVOCA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_INFO_REVOCA_ERROR"] = "BOLLETTINO_POSTALE_GET_INFO_REVOCA_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_AUTORIZZA_REVOCA_START"] = "BOLLETTINO_POSTALE_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_AUTORIZZA_REVOCA_SUCCESS"] = "BOLLETTINO_POSTALE_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_AUTORIZZA_REVOCA_ERROR"] = "BOLLETTINO_POSTALE_AUTORIZZA_REVOCA_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_GET_BOLLETTINO_FROM_BOZZA"] = "BOLLETTINO_POSTALE_GET_BOLLETTINO_FROM_BOZZA";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_RAPPORTO_SELEZIONATO"] = "BOLLETTINO_POSTALE_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_ID_UTENZA"] = "BOLLETTINO_POSTALE_SET_ID_UTENZA";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_IMPORTO"] = "BOLLETTINO_POSTALE_SET_IMPORTO";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_ESECUTORE"] = "BOLLETTINO_POSTALE_SET_ESECUTORE";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_INDIRIZZO"] = "BOLLETTINO_POSTALE_SET_INDIRIZZO";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_PROVINCIA"] = "BOLLETTINO_POSTALE_SET_PROVINCIA";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_CITTA"] = "BOLLETTINO_POSTALE_SET_CITTA";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_CAP"] = "BOLLETTINO_POSTALE_SET_CAP";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_NUMERO_BOLLETTINO"] = "BOLLETTINO_POSTALE_SET_NUMERO_BOLLETTINO";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_TIPO_DOCUMENTO"] = "BOLLETTINO_POSTALE_SET_TIPO_DOCUMENTO";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_DATA_PAGAMENTO"] = "BOLLETTINO_POSTALE_SET_DATA_PAGAMENTO";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_STEP"] = "BOLLETTINO_POSTALE_SET_STEP";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_BOZZA_SALVATA"] = "BOLLETTINO_POSTALE_SET_BOZZA_SALVATA";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_FORM_SUBMITTED"] = "BOLLETTINO_POSTALE_SET_FORM_SUBMITTED";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_RIPETI"] = "BOLLETTINO_POSTALE_SET_RIPETI";
  EReduxActionTypes["BOLLETTINO_POSTALE_RESET"] = "BOLLETTINO_POSTALE_RESET";
  EReduxActionTypes["BOLLETTINO_POSTALE_RESET_RIEPILOGO"] = "BOLLETTINO_POSTALE_RESET_RIEPILOGO";
  EReduxActionTypes["BOLLETTINO_POSTALE_RESET_ESITO"] = "BOLLETTINO_POSTALE_RESET_ESITO";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_CAUSALE"] = "BOLLETTINO_POSTALE_SET_CAUSALE";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_INTESTATARIO"] = "BOLLETTINO_POSTALE_SET_INTESTATARIO";
  EReduxActionTypes["BOLLETTINO_POSTALE_UPDATE_BOZZA_START"] = "BOLLETTINO_POSTALE_UPDATE_BOZZA_START";
  EReduxActionTypes["BOLLETTINO_POSTALE_UPDATE_BOZZA_SUCCESS"] = "BOLLETTINO_POSTALE_UPDATE_BOZZA_SUCCESS";
  EReduxActionTypes["BOLLETTINO_POSTALE_UPDATE_BOZZA_ERROR"] = "BOLLETTINO_POSTALE_UPDATE_BOZZA_ERROR";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_SALVA_BOZZA_VALIDATION"] = "BOLLETTINO_POSTALE_SET_SALVA_BOZZA_VALIDATION";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_SALVA_1CLICK"] = "BOLLETTINO_POSTALE_SET_SALVA_1CLICK";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_ALIAS_1CLICK"] = "BOLLETTINO_POSTALE_SET_ALIAS_1CLICK";
  EReduxActionTypes["BOLLETTINO_POSTALE_SET_TIPO_BOLLETTINO_SELECTED"] = "BOLLETTINO_POSTALE_SET_TIPO_BOLLETTINO_SELECTED";
  EReduxActionTypes["SET_GENERAL_HOME_BOLLETTINO"] = "SET_GENERAL_HOME_BOLLETTINO";
  // CBILL
  EReduxActionTypes["CBILL_GET_PARAMETRI_START"] = "CBILL_GET_PARAMETRI_START";
  EReduxActionTypes["CBILL_GET_PARAMETRI_SUCCESS"] = "CBILL_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["CBILL_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "CBILL_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["CBILL_GET_PARAMETRI_ERROR"] = "CBILL_GET_PARAMETRI_ERROR";
  EReduxActionTypes["CBILL_CONTROLLO_START"] = "CBILL_CONTROLLO_START";
  EReduxActionTypes["CBILL_CONTROLLO_SUCCESS"] = "CBILL_CONTROLLO_SUCCESS";
  EReduxActionTypes["CBILL_CONTROLLO_ERROR"] = "CBILL_CONTROLLO_ERROR";
  EReduxActionTypes["CBILL_AUTORIZZA_START"] = "CBILL_AUTORIZZA_START";
  EReduxActionTypes["CBILL_AUTORIZZA_SUCCESS"] = "CBILL_AUTORIZZA_SUCCESS";
  EReduxActionTypes["CBILL_AUTORIZZA_ERROR"] = "CBILL_AUTORIZZA_ERROR";
  EReduxActionTypes["CBILL_GET_INFO_AUTENTICAZIONE_START"] = "CBILL_GET_INFO_AUTENTICAZIONE_START";
  EReduxActionTypes["CBILL_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "CBILL_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxActionTypes["CBILL_GET_INFO_AUTENTICAZIONE_ERROR"] = "CBILL_GET_INFO_AUTENTICAZIONE_ERROR";
  EReduxActionTypes["CBILL_SET_RAPPORTO_SELEZIONATO"] = "CBILL_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["CBILL_SET_CODICE_BILLER"] = "CBILL_SET_CODICE_BILLER";
  EReduxActionTypes["CBILL_SET_IMPORTO"] = "CBILL_SET_IMPORTO";
  EReduxActionTypes["CBILL_SET_FACILITATORE"] = "CBILL_SET_FACILITATORE";
  EReduxActionTypes["CBILL_SET_INTESTAZIONE_BILLER"] = "CBILL_SET_INTESTAZIONE_BILLER";
  EReduxActionTypes["CBILL_SET_NOMINATIVO_CLIENTE"] = "CBILL_SET_NOMINATIVO_CLIENTE";
  EReduxActionTypes["CBILL_SET_ALIAS_1CLICK"] = "CBILL_SET_ALIAS_1CLICK";
  EReduxActionTypes["CBILL_SET_SALVA_1CLICK"] = "CBILL_SET_SALVA_1CLICK";
  EReduxActionTypes["CBILL_SET_BOZZA_SALVATA"] = "CBILL_SET_BOZZA_SALVATA";
  EReduxActionTypes["CBILL_SET_FROM_SUBMITTED"] = "CBILL_SET_FROM_SUBMITTED";
  EReduxActionTypes["CBILL_SET_STEP"] = "CBILL_SET_STEP";
  EReduxActionTypes["CBILL_SET_TIPO_VEICOLO"] = "CBILL_SET_TIPO_VEICOLO";
  EReduxActionTypes["CBILL_SET_TARGA"] = "CBILL_SET_TARGA";
  EReduxActionTypes["CBILL_SET_NOME"] = "CBILL_SET_NOME";
  EReduxActionTypes["CBILL_SET_AGIUNGI_RUBRICA"] = "CBILL_SET_AGIUNGI_RUBRICA";
  EReduxActionTypes["CBILL_SET_AFFIDABILE"] = "CBILL_SET_AFFIDABILE";
  EReduxActionTypes["CBILL_RESET"] = "CBILL_RESET";
  EReduxActionTypes["CBILL_GET_BOZZE_START"] = "CBILL_GET_BOZZE_START";
  EReduxActionTypes["CBILL_GET_BOZZE_SUCCESS"] = "CBILL_GET_BOZZE_SUCCESS";
  EReduxActionTypes["CBILL_GET_BOZZE_ERROR"] = "CBILL_GET_BOZZE_ERROR";
  EReduxActionTypes["CBILL_GET_BOZZA_START"] = "CBILL_GET_BOZZA_START";
  EReduxActionTypes["CBILL_GET_BOZZA_SUCCESS"] = "CBILL_GET_BOZZA_SUCCESS";
  EReduxActionTypes["CBILL_GET__BOZZA_ERROR"] = "CBILL_GET__BOZZA_ERROR";
  EReduxActionTypes["CBILL_GET_CBILL_FROM_BOZZA"] = "CBILL_GET_CBILL_FROM_BOZZA";
  EReduxActionTypes["CBILL_ADD_BOZZA_START"] = "CBILL_ADD_BOZZA_START";
  EReduxActionTypes["CBILL_ADD_BOZZA_SUCCESS"] = "CBILL_ADD_BOZZA_SUCCESS";
  EReduxActionTypes["CBILL_ADD_BOZZA_ERROR"] = "CBILL_ADD_BOZZA_ERROR";
  EReduxActionTypes["CBILL_DEL_BOZZA_START"] = "CBILL_DEL_BOZZA_START";
  EReduxActionTypes["CBILL_DEL_BOZZA_SUCCESS"] = "CBILL_DEL_BOZZA_SUCCESS";
  EReduxActionTypes["CBILL_DEL_BOZZA_ERROR"] = "CBILL_DEL_BOZZA_ERROR";
  EReduxActionTypes["CBILL_DEL_BOZZA"] = "CBILL_DEL_BOZZA";
  EReduxActionTypes["CBILL_ADD_BOZZA"] = "CBILL_ADD_BOZZA";
  EReduxActionTypes["CBILL_UPDATE_BOZZA_START"] = "CBILL_UPDATE_BOZZA_START";
  EReduxActionTypes["CBILL_UPDATE_BOZZA_SUCCESS"] = "CBILL_UPDATE_BOZZA_SUCCESS";
  EReduxActionTypes["CBILL_UPDATE_BOZZA_ERROR"] = "CBILL_UPDATE_BOZZA_ERROR";
  EReduxActionTypes["CBILL_SET_SALVA_BOZZA_VALIDATION"] = "CBILL_SET_SALVA_BOZZA_VALIDATION";
  EReduxActionTypes["CBILL_RESET_CURRENT_BOZZA_AND_FORM"] = "CBILL_RESET_CURRENT_BOZZA_AND_FORM";
  EReduxActionTypes["CBILL_GET_CODICI_FISCALI_START"] = "CBILL_GET_CODICI_FISCALI_START";
  EReduxActionTypes["CBILL_GET_CODICI_FISCALI_SUCCESS"] = "CBILL_GET_CODICI_FISCALI_SUCCESS";
  EReduxActionTypes["CBILL_GET_CODICI_FISCALI_ERROR"] = "CBILL_GET_CODICI_FISCALI_ERROR";
  EReduxActionTypes["CBILL_GET_BILLERS_START"] = "CBILL_GET_BILLERS_START";
  EReduxActionTypes["CBILL_GET_BILLERS_SUCCESS"] = "CBILL_GET_BILLERS_SUCCESS";
  EReduxActionTypes["CBILL_GET_BILLERS_ERROR"] = "CBILL_GET_BILLERS_ERROR";
  EReduxActionTypes["CBILL_RESET_BILLERS"] = "CBILL_RESET_BILLERS";
  EReduxActionTypes["CBILL_IMPORTO"] = "CBILL_IMPORTO";
  EReduxActionTypes["CBILL_CODICE_BILLER"] = "CBILL_CODICE_BILLER";
  EReduxActionTypes["CBILL_DESCRIZIONE_BILLER"] = "CBILL_DESCRIZIONE_BILLER";
  EReduxActionTypes["CBILL_NUMERO_FATTURA"] = "CBILL_NUMERO_FATTURA";
  EReduxActionTypes["CBILL_RAPPORTO_SELEZIONATO"] = "CBILL_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["CBILL_STEP"] = "CBILL_STEP";
  EReduxActionTypes["CBILL_FORM_SUBMITTED"] = "CBILL_FORM_SUBMITTED";
  EReduxActionTypes["CBILL_GET_INFO_AUTENTICAZIONE_CBILL_START"] = "CBILL_GET_INFO_AUTENTICAZIONE_CBILL_START";
  EReduxActionTypes["CBILL_GET_INFO_AUTENTICAZIONE_CBILL_ERROR"] = "CBILL_GET_INFO_AUTENTICAZIONE_CBILL_ERROR";
  EReduxActionTypes["CBILL_GET_INFO_AUTENTICAZIONE_CBILL_SUCCESS"] = "CBILL_GET_INFO_AUTENTICAZIONE_CBILL_SUCCESS";
  EReduxActionTypes["CBILL_GET_CODICI_FISCALI_CBILL_START"] = "CBILL_GET_CODICI_FISCALI_CBILL_START";
  EReduxActionTypes["CBILL_GET_CODICI_FISCALI_CBILL_ERROR"] = "CBILL_GET_CODICI_FISCALI_CBILL_ERROR";
  EReduxActionTypes["CBILL_GET_CODICI_FISCALI_CBILL_SUCCESS"] = "CBILL_GET_CODICI_FISCALI_CBILL_SUCCESS";
  EReduxActionTypes["CBILL_SET_CONTATTO_SELEZIONATO"] = "CBILL_SET_CONTATTO_SELEZIONATO";
  EReduxActionTypes["CBILL_SET_SELECTED_BILLER"] = "CBILL_SET_SELECTED_BILLER";
  // ASSEGNI
  EReduxActionTypes["ASSEGNI_GET_PARAMETRI_START"] = "ASSEGNI_GET_PARAMETRI_START";
  EReduxActionTypes["ASSEGNI_GET_PARAMETRI_SUCCESS"] = "ASSEGNI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["ASSEGNI_GET_PARAMETRI_ERROR"] = "ASSEGNI_GET_PARAMETRI_ERROR";
  EReduxActionTypes["ASSEGNI_MOSTRA_CARNET_ESAURITI"] = "ASSEGNI_MOSTRA_CARNET_ESAURITI";
  EReduxActionTypes["GET_ASSEGNI_START"] = "GET_ASSEGNI_START";
  EReduxActionTypes["GET_ASSEGNI_ERROR"] = "GET_ASSEGNI_ERROR";
  EReduxActionTypes["GET_ASSEGNI_SUCCESS"] = "GET_ASSEGNI_SUCCESS";
  EReduxActionTypes["ASSEGNI_CARNET_SELEZIONATO"] = "ASSEGNI_CARNET_SELEZIONATO";
  EReduxActionTypes["ASSEGNI_RESET_CARNET_SELEZIONATO"] = "ASSEGNI_RESET_CARNET_SELEZIONATO";
  EReduxActionTypes["ASSEGNI_RAPPORTO_SELEZIONATO"] = "ASSEGNI_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["ASSEGNI_RESET_FILTRI"] = "ASSEGNI_RESET_FILTRI";
  EReduxActionTypes["ASSEGNI_ESPORTA_PDF_START"] = "ASSEGNI_ESPORTA_PDF_START";
  EReduxActionTypes["ASSEGNI_ESPORTA_PDF_SUCCESS"] = "ASSEGNI_ESPORTA_PDF_SUCCESS";
  EReduxActionTypes["ASSEGNI_ESPORTA_PDF_ERROR"] = "ASSEGNI_ESPORTA_PDF_ERROR";
  EReduxActionTypes["ASSEGNI_ESPORTA_DETTAGLIO_PDF_START"] = "ASSEGNI_ESPORTA_DETTAGLIO_PDF_START";
  EReduxActionTypes["ASSEGNI_ESPORTA_DETTAGLIO_PDF_SUCCESS"] = "ASSEGNI_ESPORTA_DETTAGLIO_PDF_SUCCESS";
  EReduxActionTypes["ASSEGNI_ESPORTA_DETTAGLIO_PDF_ERROR"] = "ASSEGNI_ESPORTA_DETTAGLIO_PDF_ERROR";
  EReduxActionTypes["ASSEGNI_ESPORTA_EXCEL_START"] = "ASSEGNI_ESPORTA_EXCEL_START";
  EReduxActionTypes["ASSEGNI_ESPORTA_EXCEL_SUCCESS"] = "ASSEGNI_ESPORTA_EXCEL_SUCCESS";
  EReduxActionTypes["ASSEGNI_ESPORTA_EXCEL_ERROR"] = "ASSEGNI_ESPORTA_EXCEL_ERROR";
  EReduxActionTypes["ASSEGNI_RESET"] = "ASSEGNI_RESET";
  // ANATOCISMO
  EReduxActionTypes["ANATOCISMO_CONTROLLO_START"] = "ANATOCISMO_CONTROLLO_START";
  EReduxActionTypes["ANATOCISMO_CONTROLLO_SUCCESS"] = "ANATOCISMO_CONTROLLO_SUCCESS";
  EReduxActionTypes["ANATOCISMO_GET_INFO_DISPOSITIVA_START"] = "ANATOCISMO_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["ANATOCISMO_GET_INFO_DISPOSITIVA_SUCCESS"] = "ANATOCISMO_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["ANATOCISMO_GET_INFO_DISPOSITIVA_ERROR"] = "ANATOCISMO_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["ANATOCISMO_AUTORIZZA_START"] = "ANATOCISMO_AUTORIZZA_START";
  EReduxActionTypes["ANATOCISMO_AUTORIZZA_SUCCESS"] = "ANATOCISMO_AUTORIZZA_SUCCESS";
  EReduxActionTypes["ANATOCISMO_AUTORIZZA_ERROR"] = "ANATOCISMO_AUTORIZZA_ERROR";
  EReduxActionTypes["ANATOCISMO_ESPORTA_PDF_START"] = "ANATOCISMO_ESPORTA_PDF_START";
  EReduxActionTypes["ANATOCISMO_ESPORTA_PDF_SUCCESS"] = "ANATOCISMO_ESPORTA_PDF_SUCCESS";
  EReduxActionTypes["ANATOCISMO_STEP"] = "ANATOCISMO_STEP";
  EReduxActionTypes["ANATOCISMO_RESET"] = "ANATOCISMO_RESET";
  EReduxActionTypes["ANATOCISMO_ACCETTATO"] = "ANATOCISMO_ACCETTATO";
  EReduxActionTypes["ANATOCISMO_GET_CONSENSI_START"] = "ANATOCISMO_GET_CONSENSI_START";
  EReduxActionTypes["ANATOCISMO_GET_CONSENSI_SUCCESS"] = "ANATOCISMO_GET_CONSENSI_SUCCESS";
  // BOLLO ACI
  EReduxActionTypes["BOLLO_ACI_PARAMETRI_START"] = "BOLLO_ACI_PARAMETRI_START";
  EReduxActionTypes["BOLLO_ACI_GET_PARAMETRI_SUCCESS"] = "BOLLO_ACI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["BOLLO_ACI_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "BOLLO_ACI_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["BOLLO_ACI_GET_PARAMETRI_ERROR"] = "BOLLO_ACI_GET_PARAMETRI_ERROR";
  EReduxActionTypes["BOLLO_ACI_CONTROLLO_START"] = "BOLLO_ACI_CONTROLLO_START";
  EReduxActionTypes["BOLLO_ACI_CONTROLLO_SUCCESS"] = "BOLLO_ACI_CONTROLLO_SUCCESS";
  EReduxActionTypes["BOLLO_ACI_CONTROLLO_ERROR"] = "BOLLO_ACI_CONTROLLO_ERROR";
  EReduxActionTypes["BOLLO_ACI_AUTORIZZA_START"] = "BOLLO_ACI_AUTORIZZA_START";
  EReduxActionTypes["BOLLO_ACI_AUTORIZZA_SUCCESS"] = "BOLLO_ACI_AUTORIZZA_SUCCESS";
  EReduxActionTypes["BOLLO_ACI_AUTORIZZA_ERROR"] = "BOLLO_ACI_AUTORIZZA_ERROR";
  EReduxActionTypes["BOLLO_ACI_GET_INFO_AUTENTICAZIONE_START"] = "BOLLO_ACI_GET_INFO_AUTENTICAZIONE_START";
  EReduxActionTypes["BOLLO_ACI_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "BOLLO_ACI_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxActionTypes["BOLLO_ACI_GET_INFO_AUTENTICAZIONE_ERROR"] = "BOLLO_ACI_GET_INFO_AUTENTICAZIONE_ERROR";
  EReduxActionTypes["BOLLO_ACI_GET_BOZZE_START"] = "BOLLO_ACI_GET_BOZZE_START";
  EReduxActionTypes["BOLLO_ACI_GET_BOZZE_SUCCESS"] = "BOLLO_ACI_GET_BOZZE_SUCCESS";
  EReduxActionTypes["BOLLO_ACI_GET_BOZZE_ERROR"] = "BOLLO_ACI_GET_BOZZE_ERROR";
  EReduxActionTypes["BOLLO_ACI_GET_BOZZA_START"] = "BOLLO_ACI_GET_BOZZA_START";
  EReduxActionTypes["BOLLO_ACI_GET_BOZZA_SUCCESS"] = "BOLLO_ACI_GET_BOZZA_SUCCESS";
  EReduxActionTypes["BOLLO_ACI_GET_BOZZA_ERROR"] = "BOLLO_ACI_GET_BOZZA_ERROR";
  EReduxActionTypes["BOLLO_ACI_GET_BOLLO_ACI_FROM_BOZZA"] = "BOLLO_ACI_GET_BOLLO_ACI_FROM_BOZZA";
  EReduxActionTypes["BOLLO_ACI_ADD_BOZZA_START"] = "BOLLO_ACI_ADD_BOZZA_START";
  EReduxActionTypes["BOLLO_ACI_ADD_BOZZA_SUCCESS"] = "BOLLO_ACI_ADD_BOZZA_SUCCESS";
  EReduxActionTypes["BOLLO_ACI_ADD_BOZZA_ERROR"] = "BOLLO_ACI_ADD_BOZZA_ERROR";
  EReduxActionTypes["BOLLO_ACI_DEL_BOZZA_START"] = "BOLLO_ACI_DEL_BOZZA_START";
  EReduxActionTypes["BOLLO_ACI_DEL_BOZZA_SUCCESS"] = "BOLLO_ACI_DEL_BOZZA_SUCCESS";
  EReduxActionTypes["BOLLO_ACI_DEL_BOZZA_ERROR"] = "BOLLO_ACI_DEL_BOZZA_ERROR";
  EReduxActionTypes["BOLLO_ACI_DEL_BOZZA"] = "BOLLO_ACI_DEL_BOZZA";
  EReduxActionTypes["BOLLO_ACI_ADD_BOZZA"] = "BOLLO_ACI_ADD_BOZZA";
  EReduxActionTypes["BOLLO_ACI_UPDATE_BOZZA_START"] = "BOLLO_ACI_UPDATE_BOZZA_START";
  EReduxActionTypes["BOLLO_ACI_UPDATE_BOZZA_SUCCESS"] = "BOLLO_ACI_UPDATE_BOZZA_SUCCESS";
  EReduxActionTypes["BOLLO_ACI_UPDATE_BOZZA_ERROR"] = "BOLLO_ACI_UPDATE_BOZZA_ERROR";
  EReduxActionTypes["BOLLO_ACI_SET_SALVA_BOZZA_VALIDATION"] = "BOLLO_ACI_SET_SALVA_BOZZA_VALIDATION";
  EReduxActionTypes["BOLLO_ACI_RESET_CURRENT_BOZZA_AND_FORM"] = "BOLLO_ACI_RESET_CURRENT_BOZZA_AND_FORM";
  EReduxActionTypes["BOLLO_ACI_SET_RAPPORTO_SELEZIONATO"] = "BOLLO_ACI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["BOLLO_ACI_SET_ALIAS_1CLICK"] = "BOLLO_ACI_SET_ALIAS_1CLICK";
  EReduxActionTypes["BOLLO_ACI_SET_SALVA_1CLICK"] = "BOLLO_ACI_SET_SALVA_1CLICK";
  EReduxActionTypes["BOLLO_ACI_SET_BOZZA_SALVATA"] = "BOLLO_ACI_SET_BOZZA_SALVATA";
  EReduxActionTypes["BOLLO_ACI_SET_FROM_SUBMITTED"] = "BOLLO_ACI_SET_FROM_SUBMITTED";
  EReduxActionTypes["BOLLO_ACI_SET_STEP"] = "BOLLO_ACI_SET_STEP";
  EReduxActionTypes["BOLLO_ACI_SET_TIPO_VEICOLO"] = "BOLLO_ACI_SET_TIPO_VEICOLO";
  EReduxActionTypes["BOLLO_ACI_SET_TARGA"] = "BOLLO_ACI_SET_TARGA";
  EReduxActionTypes["BOLLO_ACI_SET_NOME"] = "BOLLO_ACI_SET_NOME";
  EReduxActionTypes["BOLLO_ACI_SET_AGIUNGI_RUBRICA"] = "BOLLO_ACI_SET_AGIUNGI_RUBRICA";
  EReduxActionTypes["BOLLO_ACI_SET_AFFIDABILE"] = "BOLLO_ACI_SET_AFFIDABILE";
  EReduxActionTypes["BOLLO_ACI_SET_ID_CONTATTO_BENEFICIARIO"] = "BOLLO_ACI_SET_ID_CONTATTO_BENEFICIARIO";
  EReduxActionTypes["BOLLO_ACI_RESET"] = "BOLLO_ACI_RESET";
  EReduxActionTypes["BOLLO_ACI_SET_CONTATTO_SELEZIONATO"] = "BOLLO_ACI_SET_CONTATTO_SELEZIONATO";
  // MANDATI SDD
  EReduxActionTypes["MANDATI_SDD_GET_PARAMETRI_START"] = "MANDATI_SDD_GET_PARAMETRI_START";
  EReduxActionTypes["MANDATI_SDD_GET_PARAMETRI_SUCCESS"] = "MANDATI_SDD_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["MANDATI_SDD_GET_PARAMETRI_ERROR"] = "MANDATI_SDD_GET_PARAMETRI_ERROR";
  EReduxActionTypes["GET_MANDATI_SDD_START"] = "GET_MANDATI_SDD_START";
  EReduxActionTypes["GET_MANDATI_SDD_ERROR"] = "GET_MANDATI_SDD_ERROR";
  EReduxActionTypes["GET_MANDATI_SDD_SUCCESS"] = "GET_MANDATI_SDD_SUCCESS";
  EReduxActionTypes["MANDATI_SDD_RAPPORTO_SELEZIONATO"] = "MANDATI_SDD_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["MANDATI_SDD_STATI_MANDATI"] = "MANDATI_SDD_STATI_MANDATI";
  EReduxActionTypes["MANDATI_SDD_RESET_FILTRI"] = "MANDATI_SDD_RESET_FILTRI";
  EReduxActionTypes["MANDATI_SDD_MANDATO_SELEZIONATO"] = "MANDATI_SDD_MANDATO_SELEZIONATO";
  EReduxActionTypes["MANDATI_SDD_ESPORTA_PDF_START"] = "MANDATI_SDD_ESPORTA_PDF_START";
  EReduxActionTypes["MANDATI_SDD_ESPORTA_PDF_SUCCESS"] = "MANDATI_SDD_ESPORTA_PDF_SUCCESS";
  EReduxActionTypes["MANDATI_SDD_ESPORTA_PDF_ERROR"] = "MANDATI_SDD_ESPORTA_PDF_ERROR";
  EReduxActionTypes["MANDATI_SDD_AUTORIZZA_REVOCA_START"] = "MANDATI_SDD_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["MANDATI_SDD_AUTORIZZA_REVOCA_SUCCESS"] = "MANDATI_SDD_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["MANDATI_SDD_AUTORIZZA_REVOCA_ERROR"] = "MANDATI_SDD_AUTORIZZA_REVOCA_ERROR";
  EReduxActionTypes["MANDATI_SDD_GET_INFO_REVOCA_START"] = "MANDATI_SDD_GET_INFO_REVOCA_START";
  EReduxActionTypes["MANDATI_SDD_GET_INFO_REVOCA_SUCCESS"] = "MANDATI_SDD_GET_INFO_REVOCA_SUCCESS";
  EReduxActionTypes["MANDATI_SDD_GET_INFO_REVOCA_ERROR"] = "MANDATI_SDD_GET_INFO_REVOCA_ERROR";
  EReduxActionTypes["MANDATI_SDD_STEP_REVOCA"] = "MANDATI_SDD_STEP_REVOCA";
  EReduxActionTypes["MANDATI_SDD_RESET_MANDATO_SELEZIONATO"] = "MANDATI_SDD_RESET_MANDATO_SELEZIONATO";
  EReduxActionTypes["MANDATI_SDD_ESPORTA_DETTAGLIO_PDF_START"] = "MANDATI_SDD_ESPORTA_DETTAGLIO_PDF_START";
  EReduxActionTypes["MANDATI_SDD_ESPORTA_DETTAGLIO_PDF_SUCCESS"] = "MANDATI_SDD_ESPORTA_DETTAGLIO_PDF_SUCCESS";
  EReduxActionTypes["MANDATI_SDD_ESPORTA_DETTAGLIO_PDF_ERROR"] = "MANDATI_SDD_ESPORTA_DETTAGLIO_PDF_ERROR";
  EReduxActionTypes["MANDATI_SDD_ESPORTA_EXCEL_START"] = "MANDATI_SDD_ESPORTA_EXCEL_START";
  EReduxActionTypes["MANDATI_SDD_ESPORTA_EXCEL_SUCCESS"] = "MANDATI_SDD_ESPORTA_EXCEL_SUCCESS";
  EReduxActionTypes["MANDATI_SDD_ESPORTA_EXCEL_ERROR"] = "MANDATI_SDD_ESPORTA_EXCEL_ERROR";
  EReduxActionTypes["MANDATI_SDD_REVOCA_SELEZIONATA"] = "MANDATI_SDD_REVOCA_SELEZIONATA";
  EReduxActionTypes["MANDATI_SDD_RESET"] = "MANDATI_SDD_RESET";
  EReduxActionTypes["MANDATI_SDD_RESET_TUTTO"] = "MANDATI_SDD_RESET_TUTTO";
  // DEPOSITO RISPARMI (LIBRETTI)
  EReduxActionTypes["DEPOSITO_RISPARMI_GET_PARAMETRI_START"] = "DEPOSITO_RISPARMI_GET_PARAMETRI_START";
  EReduxActionTypes["DEPOSITO_RISPARMI_GET_PARAMETRI_SUCCESS"] = "DEPOSITO_RISPARMI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["DEPOSITO_RISPARMI_GET_PARAMETRI_ERROR"] = "DEPOSITO_RISPARMI_GET_PARAMETRI_ERROR";
  EReduxActionTypes["DEPOSITO_RISPARMI_GET_ELENCO_START"] = "DEPOSITO_RISPARMI_GET_ELENCO_START";
  EReduxActionTypes["DEPOSITO_RISPARMI_GET_ELENCO_SUCCESS"] = "DEPOSITO_RISPARMI_GET_ELENCO_SUCCESS";
  EReduxActionTypes["DEPOSITO_RISPARMI_GET_ELENCO_ERROR"] = "DEPOSITO_RISPARMI_GET_ELENCO_ERROR";
  EReduxActionTypes["DEPOSITO_RISPARMI_RESET_FILTRO"] = "DEPOSITO_RISPARMI_RESET_FILTRO";
  EReduxActionTypes["DEPOSITO_RISPARMI_SET_RAPPORTO_SELEZIONATO"] = "DEPOSITO_RISPARMI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["DEPOSITO_RISPARMI_SET_DESCRIZIONE"] = "DEPOSITO_RISPARMI_SET_DESCRIZIONE";
  EReduxActionTypes["DEPOSITO_RISPARMI_SET_DATA_DA"] = "DEPOSITO_RISPARMI_SET_DATA_DA";
  EReduxActionTypes["DEPOSITO_RISPARMI_SET_DATA_A"] = "DEPOSITO_RISPARMI_SET_DATA_A";
  EReduxActionTypes["DEPOSITO_RISPARMI_SET_IMPORTO"] = "DEPOSITO_RISPARMI_SET_IMPORTO";
  EReduxActionTypes["DEPOSITO_RISPARMI_SET_PERIODO"] = "DEPOSITO_RISPARMI_SET_PERIODO";
  EReduxActionTypes["DEPOSITO_RISPARMI_SET_ENTRATE"] = "DEPOSITO_RISPARMI_SET_ENTRATE";
  EReduxActionTypes["DEPOSITO_RISPARMI_SET_USCITE"] = "DEPOSITO_RISPARMI_SET_USCITE";
  EReduxActionTypes["DEPOSITO_RISPARMI_SET_ENTRATE_USCITE"] = "DEPOSITO_RISPARMI_SET_ENTRATE_USCITE";
  EReduxActionTypes["DEPOSITO_RISPARMI_SET_ACCORDITION_FILTRI_OPENED"] = "DEPOSITO_RISPARMI_SET_ACCORDITION_FILTRI_OPENED";
  EReduxActionTypes["DEPOSITO_RISPARMI_ESPORTA_PDF_START"] = "DEPOSITO_RISPARMI_ESPORTA_PDF_START";
  EReduxActionTypes["DEPOSITO_RISPARMI_ESPORTA_PDF_SUCCESS"] = "DEPOSITO_RISPARMI_ESPORTA_PDF_SUCCESS";
  EReduxActionTypes["DEPOSITO_RISPARMI_ESPORTA_PDF_ERROR"] = "DEPOSITO_RISPARMI_ESPORTA_PDF_ERROR";
  EReduxActionTypes["DEPOSITO_RISPARMI_ESPORTA_EXCEL_START"] = "DEPOSITO_RISPARMI_ESPORTA_EXCEL_START";
  EReduxActionTypes["DEPOSITO_RISPARMI_ESPORTA_EXCEL_SUCCESS"] = "DEPOSITO_RISPARMI_ESPORTA_EXCEL_SUCCESS";
  EReduxActionTypes["DEPOSITO_RISPARMI_ESPORTA_EXCEL_ERROR"] = "DEPOSITO_RISPARMI_ESPORTA_EXCEL_ERROR";
  EReduxActionTypes["DEPOSITO_RISPARMI_ESPORTA_DETTAGLIO_PDF_START"] = "DEPOSITO_RISPARMI_ESPORTA_DETTAGLIO_PDF_START";
  EReduxActionTypes["DEPOSITO_RISPARMI_ESPORTA_DETTAGLIO_PDF_SUCCESS"] = "DEPOSITO_RISPARMI_ESPORTA_DETTAGLIO_PDF_SUCCESS";
  EReduxActionTypes["DEPOSITO_RISPARMI_ESPORTA_DETTAGLIO_PDF_ERROR"] = "DEPOSITO_RISPARMI_ESPORTA_DETTAGLIO_PDF_ERROR";
  EReduxActionTypes["DEPOSITO_RISPARMI_RESET"] = "DEPOSITO_RISPARMI_RESET";
  // CARTA CONTO
  EReduxActionTypes["CARTA_CONTO_ESPORTA_PDF_START"] = "CARTA_CONTO_ESPORTA_PDF_START";
  EReduxActionTypes["CARTA_CONTO_ESPORTA_PDF_SUCCESS"] = "CARTA_CONTO_ESPORTA_PDF_SUCCESS";
  EReduxActionTypes["CARTA_CONTO_ESPORTA_PDF_ERROR"] = "CARTA_CONTO_ESPORTA_PDF_ERROR";
  EReduxActionTypes["CARTA_CONTO_GET_PARAMETRI_START"] = "CARTA_CONTO_GET_PARAMETRI_START";
  EReduxActionTypes["CARTA_CONTO_GET_PARAMETRI_SUCCESS"] = "CARTA_CONTO_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["CARTA_CONTO_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "CARTA_CONTO_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  // RIBA
  EReduxActionTypes["RIBA_GET_PARAMETRI_START"] = "RIBA_GET_PARAMETRI_START";
  EReduxActionTypes["RIBA_GET_PARAMETRI_SUCCESS"] = "RIBA_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["RIBA_GET_PARAMETRI_ERROR"] = "RIBA_GET_PARAMETRI_ERROR";
  EReduxActionTypes["RIBA_GET_INFO_AUTENTICAZIONE_START"] = "RIBA_GET_INFO_AUTENTICAZIONE_START";
  EReduxActionTypes["RIBA_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "RIBA_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxActionTypes["RIBA_GET_INFO_AUTENTICAZIONE_ERROR"] = "RIBA_GET_INFO_AUTENTICAZIONE_ERROR";
  EReduxActionTypes["RIBA_CONTROLLO_START"] = "RIBA_CONTROLLO_START";
  EReduxActionTypes["RIBA_CONTROLLO_SUCCESS"] = "RIBA_CONTROLLO_SUCCESS";
  EReduxActionTypes["RIBA_CONTROLLO_ERROR"] = "RIBA_CONTROLLO_ERROR";
  EReduxActionTypes["RIBA_AUTORIZZA_START"] = "RIBA_AUTORIZZA_START";
  EReduxActionTypes["RIBA_AUTORIZZA_SUCCESS"] = "RIBA_AUTORIZZA_SUCCESS";
  EReduxActionTypes["RIBA_AUTORIZZA_ERROR"] = "RIBA_AUTORIZZA_ERROR";
  EReduxActionTypes["RIBA_GET_ELENCO_START"] = "RIBA_GET_ELENCO_START";
  EReduxActionTypes["RIBA_GET_ELENCO_SUCCESS"] = "RIBA_GET_ELENCO_SUCCESS";
  EReduxActionTypes["RIBA_GET_ELENCO_ERROR"] = "RIBA_GET_ELENCO_ERROR";
  EReduxActionTypes["RIBA_GET_INFO_AUTENTICAZIONE_REVOCA_START"] = "RIBA_GET_INFO_AUTENTICAZIONE_REVOCA_START";
  EReduxActionTypes["RIBA_GET_INFO_AUTENTICAZIONE_REVOCA_SUCCESS"] = "RIBA_GET_INFO_AUTENTICAZIONE_REVOCA_SUCCESS";
  EReduxActionTypes["RIBA_GET_INFO_AUTENTICAZIONE_REVOCA_ERROR"] = "RIBA_GET_INFO_AUTENTICAZIONE_REVOCA_ERROR";
  EReduxActionTypes["RIBA_AUTORIZZA_REVOCA_START"] = "RIBA_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["RIBA_AUTORIZZA_REVOCA_SUCCESS"] = "RIBA_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["RIBA_AUTORIZZA_REVOCA_ERROR"] = "RIBA_AUTORIZZA_REVOCA_ERROR";
  EReduxActionTypes["RIBA_ESPORTA_PDF_ELENCO_START"] = "RIBA_ESPORTA_PDF_ELENCO_START";
  EReduxActionTypes["RIBA_ESPORTA_PDF_ELENCO_SUCCESS"] = "RIBA_ESPORTA_PDF_ELENCO_SUCCESS";
  EReduxActionTypes["RIBA_ESPORTA_PDF_ELENCO_ERROR"] = "RIBA_ESPORTA_PDF_ELENCO_ERROR";
  EReduxActionTypes["RIBA_ESPORTA_EXCEL_ELENCO_START"] = "RIBA_ESPORTA_EXCEL_ELENCO_START";
  EReduxActionTypes["RIBA_ESPORTA_EXCEL_ELENCO_SUCCESS"] = "RIBA_ESPORTA_EXCEL_ELENCO_SUCCESS";
  EReduxActionTypes["RIBA_ESPORTA_EXCEL_ELENCO_ERROR"] = "RIBA_ESPORTA_EXCEL_ELENCO_ERROR";
  EReduxActionTypes["RIBA_SET_RAPPORTO_SELEZIONATO"] = "RIBA_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["RIBA_SET_STEP"] = "RIBA_SET_STEP";
  EReduxActionTypes["RIBA_SET_FORM_SUBMITTED"] = "RIBA_SET_FORM_SUBMITTED";
  EReduxActionTypes["RIBA_SET_SELECTED_AVVISI"] = "RIBA_SET_SELECTED_AVVISI";
  EReduxActionTypes["RIBA_SET_SELECTED_ALL_AVVISI"] = "RIBA_SET_SELECTED_ALL_AVVISI";
  EReduxActionTypes["RIBA_RESET"] = "RIBA_RESET";
  EReduxActionTypes["RIBA_ESPORTA_PDF_DETAGLIO_START"] = "RIBA_ESPORTA_PDF_DETAGLIO_START";
  EReduxActionTypes["RIBA_ESPORTA_PDF_DETAGLIO_SUCCESS"] = "RIBA_ESPORTA_PDF_DETAGLIO_SUCCESS";
  EReduxActionTypes["RIBA_ESPORTA_PDF_DETAGLIO_ERROR"] = "RIBA_ESPORTA_PDF_DETAGLIO_ERROR";
  EReduxActionTypes["RIBA_SET_COD_FISCALE_PARTITA_IVA"] = "RIBA_SET_COD_FISCALE_PARTITA_IVA";
  // MUTUI
  EReduxActionTypes["MUTUI_GET_PARAMETRI_START"] = "MUTUI_GET_PARAMETRI_START";
  EReduxActionTypes["MUTUI_GET_PARAMETRI_SUCCESS"] = "MUTUI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["MUTUI_GET_PARAMETRI_ERROR"] = "MUTUI_GET_PARAMETRI_ERROR";
  EReduxActionTypes["MUTUI_GET_ELENCO_PROGERSSIVI_START"] = "MUTUI_GET_ELENCO_PROGERSSIVI_START";
  EReduxActionTypes["MUTUI_GET_ELENCO_PROGERSSIVI_SUCCESS"] = "MUTUI_GET_ELENCO_PROGERSSIVI_SUCCESS";
  EReduxActionTypes["MUTUI_GET_ELENCO_PROGERSSIVI_ERROR"] = "MUTUI_GET_ELENCO_PROGERSSIVI_ERROR";
  EReduxActionTypes["MUTUI_SET_RAPPORTO_SELEZIONATO"] = "MUTUI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["MUTUI_GET_RATE_MORA_START"] = "MUTUI_GET_RATE_MORA_START";
  EReduxActionTypes["MUTUI_GET_RATE_MORA_SUCCESS"] = "MUTUI_GET_RATE_MORA_SUCCESS";
  EReduxActionTypes["MUTUI_GET_RATE_MORA_ERROR"] = "MUTUI_GET_RATE_MORA_ERROR";
  EReduxActionTypes["MUTUI_GET_PIANO_AMMORTAMENTO_START"] = "MUTUI_GET_PIANO_AMMORTAMENTO_START";
  EReduxActionTypes["MUTUI_GET_PIANO_AMMORTAMENTO_SUCCESS"] = "MUTUI_GET_PIANO_AMMORTAMENTO_SUCCESS";
  EReduxActionTypes["MUTUI_GET_PIANO_AMMORTAMENTO_ERROR"] = "MUTUI_GET_PIANO_AMMORTAMENTO_ERROR";
  EReduxActionTypes["MUTUI_GET_RATE_PAGATE_START"] = "MUTUI_GET_RATE_PAGATE_START";
  EReduxActionTypes["MUTUI_GET_RATE_PAGATE_SUCCESS"] = "MUTUI_GET_RATE_PAGATE_SUCCESS";
  EReduxActionTypes["MUTUI_GET_RATE_PAGATE_ERROR"] = "MUTUI_GET_RATE_PAGATE_ERROR";
  EReduxActionTypes["MUTUI_RATE_RESIDUE_START"] = "MUTUI_RATE_RESIDUE_START";
  EReduxActionTypes["MUTUI_RATE_RESIDUE_SUCCESS"] = "MUTUI_RATE_RESIDUE_SUCCESS";
  EReduxActionTypes["MUTUI_RATE_RESIDUE_ERROR"] = "MUTUI_RATE_RESIDUE_ERROR";
  EReduxActionTypes["MUTUI_GET_DASHBOARD_START"] = "MUTUI_GET_DASHBOARD_START";
  EReduxActionTypes["MUTUI_GET_DASHBOARD_SUCCESS"] = "MUTUI_GET_DASHBOARD_SUCCESS";
  EReduxActionTypes["MUTUI_GET_DASHBOARD_ERROR"] = "MUTUI_GET_DASHBOARD_ERROR";
  EReduxActionTypes["MUTUI_RESET_RATE"] = "MUTUI_RESET_RATE";
  EReduxActionTypes["MUTUI_RESET_DASHBOARD"] = "MUTUI_RESET_DASHBOARD";
  EReduxActionTypes["MUTUI_ESPORTA_PDF_ELENCO_START"] = "MUTUI_ESPORTA_PDF_ELENCO_START";
  EReduxActionTypes["MUTUI_ESPORTA_PDF_ELENCO_SUCCESS"] = "MUTUI_ESPORTA_PDF_ELENCO_SUCCESS";
  EReduxActionTypes["MUTUI_ESPORTA_PDF_ELENCO_ERROR"] = "MUTUI_ESPORTA_PDF_ELENCO_ERROR";
  EReduxActionTypes["MUTUI_ESPORTA_EXCEL_ELENCO_START"] = "MUTUI_ESPORTA_EXCEL_ELENCO_START";
  EReduxActionTypes["MUTUI_ESPORTA_EXCEL_ELENCO_SUCCESS"] = "MUTUI_ESPORTA_EXCEL_ELENCO_SUCCESS";
  EReduxActionTypes["MUTUI_ESPORTA_EXCEL_ELENCO_ERROR"] = "MUTUI_ESPORTA_EXCEL_ELENCO_ERROR";
  EReduxActionTypes["MUTUI_ESPORTA_PDF_DETTAGLIO_START"] = "MUTUI_ESPORTA_PDF_DETTAGLIO_START";
  EReduxActionTypes["MUTUI_ESPORTA_PDF_DETTAGLIO_SUCCESS"] = "MUTUI_ESPORTA_PDF_DETTAGLIO_SUCCESS";
  EReduxActionTypes["MUTUI_ESPORTA_PDF_DETTAGLIO_ERROR"] = "MUTUI_ESPORTA_PDF_DETTAGLIO_ERROR";
  EReduxActionTypes["MUTUI_ESPORTA_EXCEL_DETTAGLIO_START"] = "MUTUI_ESPORTA_EXCEL_DETTAGLIO_START";
  EReduxActionTypes["MUTUI_ESPORTA_EXCEL_DETTAGLIO_SUCCESS"] = "MUTUI_ESPORTA_EXCEL_DETTAGLIO_SUCCESS";
  EReduxActionTypes["MUTUI_ESPORTA_EXCEL_DETTAGLIO_ERROR"] = "MUTUI_ESPORTA_EXCEL_DETTAGLIO_ERROR";
  EReduxActionTypes["MUTUI_RESET"] = "MUTUI_RESET";
  // PRESTITI
  EReduxActionTypes["PRESTITI_GET_PARAMETRI_START"] = "PRESTITI_GET_PARAMETRI_START";
  EReduxActionTypes["PRESTITI_GET_PARAMETRI_SUCCESS"] = "PRESTITI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["PRESTITI_GET_PARAMETRI_ERROR"] = "PRESTITI_GET_PARAMETRI_ERROR";
  EReduxActionTypes["PRESTITI_GET_RATE_RESIDUE_START"] = "PRESTITI_GET_RATE_RESIDUE_START";
  EReduxActionTypes["PRESTITI_GET_RATE_RESIDUE_SUCCESS"] = "PRESTITI_GET_RATE_RESIDUE_SUCCESS";
  EReduxActionTypes["PRESTITI_GET_RATE_RESIDUE_ERROR"] = "PRESTITI_GET_RATE_RESIDUE_ERROR";
  EReduxActionTypes["PRESTITI_GET_RATE_PAGATE_START"] = "PRESTITI_GET_RATE_PAGATE_START";
  EReduxActionTypes["PRESTITI_GET_RATE_PAGATE_SUCCESS"] = "PRESTITI_GET_RATE_PAGATE_SUCCESS";
  EReduxActionTypes["PRESTITI_GET_RATE_PAGATE_ERROR"] = "PRESTITI_GET_RATE_PAGATE_ERROR";
  EReduxActionTypes["PRESTITI_GET_RATE_MORA_START"] = "PRESTITI_GET_RATE_MORA_START";
  EReduxActionTypes["PRESTITI_GET_RATE_MORA_SUCCESS"] = "PRESTITI_GET_RATE_MORA_SUCCESS";
  EReduxActionTypes["PRESTITI_GET_RATE_MORA_ERROR"] = "PRESTITI_GET_RATE_MORA_ERROR";
  EReduxActionTypes["PRESTITI_GET_PIANO_AMMORTAMENTO_START"] = "PRESTITI_GET_PIANO_AMMORTAMENTO_START";
  EReduxActionTypes["PRESTITI_GET_PIANO_AMMORTAMENTO_SUCCESS"] = "PRESTITI_GET_PIANO_AMMORTAMENTO_SUCCESS";
  EReduxActionTypes["PRESTITI_GET_PIANO_AMMORTAMENTO_ERROR"] = "PRESTITI_GET_PIANO_AMMORTAMENTO_ERROR";
  EReduxActionTypes["PRESTITI_SET_RAPPORTO_SELEZIONATO"] = "PRESTITI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["PRESTITI_GET_DASHBOARD_START"] = "PRESTITI_GET_DASHBOARD_START";
  EReduxActionTypes["PRESTITI_GET_DASHBOARD_SUCCESS"] = "PRESTITI_GET_DASHBOARD_SUCCESS";
  EReduxActionTypes["PRESTITI_GET_DASHBOARD_ERROR"] = "PRESTITI_GET_DASHBOARD_ERROR";
  EReduxActionTypes["PRESTITI_RESET_RATE"] = "PRESTITI_RESET_RATE";
  EReduxActionTypes["PRESTITI_RESET_DASHBOARD"] = "PRESTITI_RESET_DASHBOARD";
  EReduxActionTypes["PRESTITI_RESET"] = "PRESTITI_RESET";
  // PROFILO
  EReduxActionTypes["PROFILO_GET_PROFILO_START"] = "PROFILO_GET_PROFILO_START";
  EReduxActionTypes["PROFILO_GET_PROFILO_SUCCESS"] = "PROFILO_GET_PROFILO_SUCCESS";
  EReduxActionTypes["PROFILO_GET_TOUR_START"] = "PROFILO_GET_TOUR_START";
  EReduxActionTypes["PROFILO_GET_TOUR_SUCCESS"] = "PROFILO_GET_TOUR_SUCCESS";
  EReduxActionTypes["PROFILO_RESET_CURRENT_CODICE_FUNZIONE"] = "PROFILO_RESET_CURRENT_CODICE_FUNZIONE";
  EReduxActionTypes["PROFILO_SET_HIDDEN_TOUR_HASHES"] = "PROFILO_SET_HIDDEN_TOUR_HASHES";
  EReduxActionTypes["PROFILO_GET_INFO_DISPOSITIVA_START"] = "PROFILO_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["PROFILO_GET_INFO_DISPOSITIVA_SUCCESS"] = "PROFILO_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["PROFILO_GET_INFO_DISPOSITIVA_ERROR"] = "PROFILO_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["PROFILO_AUTORIZZA_BLOCCO_START"] = "PROFILO_AUTORIZZA_BLOCCO_START";
  EReduxActionTypes["PROFILO_AUTORIZZA_BLOCCO_SUCCESS"] = "PROFILO_AUTORIZZA_BLOCCO_SUCCESS";
  EReduxActionTypes["PROFILO_AUTORIZZA_BLOCCO_ERROR"] = "PROFILO_AUTORIZZA_BLOCCO_ERROR";
  EReduxActionTypes["PROFILO_GESTIONE_CREDENZIALI_SET_ID_VIRTUALE"] = "PROFILO_GESTIONE_CREDENZIALI_SET_ID_VIRTUALE";
  EReduxActionTypes["PROFILO_GESTIONE_CREDENZIALI_SET_CODICE_ALIAS"] = "PROFILO_GESTIONE_CREDENZIALI_SET_CODICE_ALIAS";
  EReduxActionTypes["PROFILO_GESTIONE_CREDENZIALI_SET_MODO_AUTENTICAZIONE"] = "PROFILO_GESTIONE_CREDENZIALI_SET_MODO_AUTENTICAZIONE";
  EReduxActionTypes["PROFILO_GESTIONE_CREDENZIALI_SET_CELULLARE"] = "PROFILO_GESTIONE_CREDENZIALI_SET_CELULLARE";
  EReduxActionTypes["PROFILO_GESTIONE_CREDENZIALI_SET_EMAIL"] = "PROFILO_GESTIONE_CREDENZIALI_SET_EMAIL";
  EReduxActionTypes["PROFILO_GESTIONE_CREDENZIALI_SET_TELEFONO_FISSO"] = "PROFILO_GESTIONE_CREDENZIALI_SET_TELEFONO_FISSO";
  EReduxActionTypes["PROFILO_GESTIONE_CREDENZIALI_SET_INDIRIZZO_RESIDENZA"] = "PROFILO_GESTIONE_CREDENZIALI_SET_INDIRIZZO_RESIDENZA";
  EReduxActionTypes["PROFILO_GESTIONE_CREDENZIALI_SET_INDIRIZZO_DOMICILIO"] = "PROFILO_GESTIONE_CREDENZIALI_SET_INDIRIZZO_DOMICILIO";
  EReduxActionTypes["PROFILO_GESTIONE_CREDENZIALI_SET_RAPPORTI"] = "PROFILO_GESTIONE_CREDENZIALI_SET_RAPPORTI";
  EReduxActionTypes["PROFILO_GESTIONE_CREDENZIALI_FORM_SUBMITTED"] = "PROFILO_GESTIONE_CREDENZIALI_FORM_SUBMITTED";
  EReduxActionTypes["PROFILO_GESTIONE_CREDENZIALI_RESET"] = "PROFILO_GESTIONE_CREDENZIALI_RESET";
  EReduxActionTypes["PROFILO_IMPOSTA_ALIAS_STEP"] = "PROFILO_IMPOSTA_ALIAS_STEP";
  EReduxActionTypes["PROFILO_IMPOSTA_ALIAS_CONTROLLO_START"] = "PROFILO_IMPOSTA_ALIAS_CONTROLLO_START";
  EReduxActionTypes["PROFILO_IMPOSTA_ALIAS_CONTROLLO_SUCCESS"] = "PROFILO_IMPOSTA_ALIAS_CONTROLLO_SUCCESS";
  EReduxActionTypes["PROFILO_IMPOSTA_ALIAS_CONTROLLO_ERROR"] = "PROFILO_IMPOSTA_ALIAS_CONTROLLO_ERROR";
  EReduxActionTypes["PROFILO_IMPOSTA_ALIAS_GET_INFO_DISPOSITIVA_START"] = "PROFILO_IMPOSTA_ALIAS_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["PROFILO_IMPOSTA_ALIAS_GET_INFO_DISPOSITIVA_SUCCESS"] = "PROFILO_IMPOSTA_ALIAS_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["PROFILO_IMPOSTA_ALIAS_GET_INFO_DISPOSITIVA_ERROR"] = "PROFILO_IMPOSTA_ALIAS_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["PROFILO_IMPOSTA_ALIAS_AUTORIZZA_START"] = "PROFILO_IMPOSTA_ALIAS_AUTORIZZA_START";
  EReduxActionTypes["PROFILO_IMPOSTA_ALIAS_AUTORIZZA_SUCCESS"] = "PROFILO_IMPOSTA_ALIAS_AUTORIZZA_SUCCESS";
  EReduxActionTypes["PROFILO_IMPOSTA_ALIAS_AUTORIZZA_ERROR"] = "PROFILO_IMPOSTA_ALIAS_AUTORIZZA_ERROR";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_STEP"] = "PROFILO_MODIFICA_PASSWORD_STEP";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_SET_VECCHIA_PASSWORD"] = "PROFILO_MODIFICA_PASSWORD_SET_VECCHIA_PASSWORD";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_SET_NUOVO_PASSWORD"] = "PROFILO_MODIFICA_PASSWORD_SET_NUOVO_PASSWORD";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_SET_CONFERMA_PASSWORD"] = "PROFILO_MODIFICA_PASSWORD_SET_CONFERMA_PASSWORD";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_CREDENZIALI_MODIFICABILI_START"] = "PROFILO_MODIFICA_PASSWORD_CREDENZIALI_MODIFICABILI_START";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_CREDENZIALI_MODIFICABILI_SUCCESS"] = "PROFILO_MODIFICA_PASSWORD_CREDENZIALI_MODIFICABILI_SUCCESS";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_CREDENZIALI_MODIFICABILI_ERROR"] = "PROFILO_MODIFICA_PASSWORD_CREDENZIALI_MODIFICABILI_ERROR";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_GET_INFO_DISPOSITIVA_START"] = "PROFILO_MODIFICA_PASSWORD_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_GET_INFO_DISPOSITIVA_SUCCESS"] = "PROFILO_MODIFICA_PASSWORD_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_GET_INFO_DISPOSITIVA_ERROR"] = "PROFILO_MODIFICA_PASSWORD_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_AUTORIZZA_START"] = "PROFILO_MODIFICA_PASSWORD_AUTORIZZA_START";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_AUTORIZZA_SUCCESS"] = "PROFILO_MODIFICA_PASSWORD_AUTORIZZA_SUCCESS";
  EReduxActionTypes["PROFILO_MODIFICA_PASSWORD_AUTORIZZA_ERROR"] = "PROFILO_MODIFICA_PASSWORD_AUTORIZZA_ERROR";
  EReduxActionTypes["PROFILO_SET_PREFERENZE_UTENTE_START"] = "PROFILO_SET_PREFERENZE_UTENTE_START";
  EReduxActionTypes["PROFILO_SET_PREFERENZE_UTENTE_SUCCESS"] = "PROFILO_SET_PREFERENZE_UTENTE_SUCCESS";
  EReduxActionTypes["PROFILO_SET_PREFERENZE_UTENTE_ERROR"] = "PROFILO_SET_PREFERENZE_UTENTE_ERROR";
  EReduxActionTypes["PROFILO_MODIFICA_PIN_SET_VECCHIO_PIN"] = "PROFILO_MODIFICA_PIN_SET_VECCHIO_PIN";
  EReduxActionTypes["PROFILO_MODIFICA_PIN_SET_NUOVO_PIN"] = "PROFILO_MODIFICA_PIN_SET_NUOVO_PIN";
  EReduxActionTypes["PROFILO_MODIFICA_PIN_SET_CONFERMA_PIN"] = "PROFILO_MODIFICA_PIN_SET_CONFERMA_PIN";
  EReduxActionTypes["PROFILO_MODIFICA_PIN_CREDENZIALI_MODIFICABILI_START"] = "PROFILO_MODIFICA_PIN_CREDENZIALI_MODIFICABILI_START";
  EReduxActionTypes["PROFILO_MODIFICA_PIN_CREDENZIALI_MODIFICABILI_SUCCESS"] = "PROFILO_MODIFICA_PIN_CREDENZIALI_MODIFICABILI_SUCCESS";
  EReduxActionTypes["PROFILO_MODIFICA_PIN_CREDENZIALI_MODIFICABILI_ERROR"] = "PROFILO_MODIFICA_PIN_CREDENZIALI_MODIFICABILI_ERROR";
  EReduxActionTypes["PROFILO_IMPOSTA_PIN_STEP"] = "PROFILO_IMPOSTA_PIN_STEP";
  EReduxActionTypes["PROFILO_GET_PARAMETRI_PROFILO_START"] = "PROFILO_GET_PROFILO_PARAMETRI_START";
  EReduxActionTypes["PROFILO_GET_PARAMETRI_PROFILO_SUCCESS"] = "PROFILO_GET_PROFILO_PARAMETRI_SUCCESS";
  EReduxActionTypes["PROFILO_ESTENDI_SESSIONE_START"] = "PROFILO_ESTENDI_SESSIONE_START";
  EReduxActionTypes["PROFILO_ESTENDI_SESSIONE_SUCCESS"] = "PROFILO_ESTENDI_SESSIONE_SUCCESS";
  EReduxActionTypes["PROFILO_INCREMENTA_INTERVALLI"] = "PROFILO_INCREMENTA_INTERVALLI";
  EReduxActionTypes["PROFILO_SET_INTERVALLI_SESSIONE"] = "PROFILO_SET_INTERVALLI_SESSIONE";
  EReduxActionTypes["PROFILO_RESET_INTERVALLI_INATTIVI"] = "PROFILO_RESET_INTERVALLI_INATTIVI";
  EReduxActionTypes["PROFILO_MODIFICA_PIN_HUB_START"] = "PROFILO_MODIFICA_PIN_HUB_START";
  EReduxActionTypes["PROFILO_MODIFICA_PIN_HUB_SUCCESS"] = "PROFILO_MODIFICA_PIN_HUB_SUCCESS";
  EReduxActionTypes["PROFILO_GET_TOKEN_HUB_START"] = "PROFILO_GET_TOKEN_HUB_START";
  EReduxActionTypes["PROFILO_GET_TOKEN_HUB_SUCCESS"] = "PROFILO_GET_TOKEN_HUB_SUCCESS";
  EReduxActionTypes["RESET_RIEPILOGO_MODIFICA_PIN"] = "RESET_RIEPILOGO_MODIFICA_PIN";
  EReduxActionTypes["PROFILO_SET_BACKGROUND_DATE"] = "PROFILO_SET_BACKGROUND_DATE";
  // NEXI
  EReduxActionTypes["NEXI_GET_TOKEN_SSO_START"] = "NEXI_GET_TOKEN_SSO_START";
  EReduxActionTypes["NEXI_GET_TOKEN_SSO_SUCCESS"] = "NEXI_GET_TOKEN_SSO_SUCCESS";
  EReduxActionTypes["NEXI_GET_TOKEN_SSO_ERROR"] = "NEXI_GET_TOKEN_SSO_ERROR";
  EReduxActionTypes["NEXI_VARIA_CONSENSO_START"] = "NEXI_VARIA_CONSENSO_START";
  EReduxActionTypes["NEXI_VARIA_CONSENSO_SUCCESS"] = "NEXI_VARIA_CONSENSO_SUCCESS";
  EReduxActionTypes["NEXI_VARIA_CONSENSO_ERROR"] = "NEXI_VARIA_CONSENSO_ERROR";
  EReduxActionTypes["NEXI_VERIFICA_CONSENSO_START"] = "NEXI_VERIFICA_CONSENSO_START";
  EReduxActionTypes["NEXI_VERIFICA_CONSENSO_SUCCESS"] = "NEXI_VERIFICA_CONSENSO_SUCCESS";
  EReduxActionTypes["NEXI_VERIFICA_CONSENSO_ERROR"] = "NEXI_VERIFICA_CONSENSO_ERROR";
  EReduxActionTypes["NEXI_GET_PARAMETRI_START"] = "NEXI_GET_PARAMETRI_START";
  EReduxActionTypes["NEXI_GET_PARAMETRI_SUCCESS"] = "NEXI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["NEXI_RESET_TOKEN_SSO"] = "NEXI_RESET_TOKEN_SSO";
  EReduxActionTypes["NEXI_CALL_SERVLET_START"] = "NEXI_CALL_SERVLET_START";
  EReduxActionTypes["NEXI_CALL_SERVLET_SUCCESS"] = "NEXI_CALL_SERVLET_SUCCESS";
  EReduxActionTypes["NEXI_CALL_SERVLET_RESET"] = "NEXI_CALL_SERVLET_RESET";
  EReduxActionTypes["NEXI_SET_RAPPORTO_SELEZIONATO"] = "NEXI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["NEXI_SET_FORM_RICERCA"] = "NEXI_SET_FORM_RICERCA";
  EReduxActionTypes["NEXI_DATA_A"] = "NEXI_DATA_A";
  EReduxActionTypes["NEXI_DATA_DA"] = "NEXI_DATA_DA";
  EReduxActionTypes["NEXI_PERIODO"] = "NEXI_PERIODO";
  EReduxActionTypes["NEXI_IMPORTO"] = "NEXI_IMPORTO";
  EReduxActionTypes["NEXI_ESERCENTE"] = "NEXI_ESERCENTE";
  EReduxActionTypes["NEXI_OFFSET"] = "NEXI_OFFSET";
  EReduxActionTypes["NEXI_ORDINAMENTO"] = "NEXI_ORDINAMENTO";
  EReduxActionTypes["NEXI_RESET_FILTRO"] = "NEXI_RESET_FILTRO";
  EReduxActionTypes["NEXI_GET_MOVIMENTI_START"] = "NEXI_GET_MOVIMENTI_START";
  EReduxActionTypes["NEXI_GET_MOVIMENTI_SUCCESS"] = "NEXI_GET_MOVIMENTI_SUCCESS";
  EReduxActionTypes["NEXI_ESPORTA_DETTAGLIO_PDF_SUCCESS"] = "NEXI_ESPORTA_DETTAGLIO_PDF_SUCCESS";
  EReduxActionTypes["NEXI_ESPORTA_DETTAGLIO_PDF_START"] = "NEXI_ESPORTA_DETTAGLIO_PDF_START";
  EReduxActionTypes["NEXI_ESPORTA_EXCEL_START"] = "NEXI_ESPORTA_EXCEL_START";
  EReduxActionTypes["NEXI_ESPORTA_EXCEL_SUCCESS"] = "NEXI_ESPORTA_EXCEL_SUCCESS";
  EReduxActionTypes["NEXI_RESET"] = "NEXI_RESET";
  EReduxActionTypes["NEXI_START_PIN_VIEW_SCA_START"] = "NEXI_START_PIN_VIEW_SCA_START";
  EReduxActionTypes["NEXI_START_PIN_VIEW_SCA_SUCCESS"] = "NEXI_START_PIN_VIEW_SCA_SUCCESS";
  EReduxActionTypes["NEXI_GET_PIN_VIEW_SCA_START"] = "NEXI_GET_PIN_VIEW_SCA_START";
  EReduxActionTypes["NEXI_GET_PIN_VIEW_SCA_SUCCESS"] = "NEXI_GET_PIN_VIEW_SCA_SUCCESS";
  EReduxActionTypes["NEXI_RESET_PIN_VIEW"] = "NEXI_RESET_PIN_VIEW";
  EReduxActionTypes["NEXI_START_PAN_VIEW__SCA_START"] = "NEXI_START_PAN_VIEW__SCA_START";
  EReduxActionTypes["NEXI_START_PAN_VIEW__SCA_SUCCESS"] = "NEXI_START_PAN_VIEW__SCA_SUCCESS";
  EReduxActionTypes["NEXI_GET_PAN_VIEW_START"] = "NEXI_GET_PAN_VIEW_START";
  EReduxActionTypes["NEXI_GET_PAN_VIEW_SUCCESS"] = "NEXI_GET_PAN_VIEW_SUCCESS";
  EReduxActionTypes["NEXI_RESET_PAN_VIEW"] = "NEXI_RESET_PAN_VIEW";
  EReduxActionTypes["NEXI_COPY_PAN_VIEW"] = "NEXI_COPY_PAN_VIEW";
  // TIME DEPOSIT
  EReduxActionTypes["TIME_DEPOSIT_GET_PARAMETRI_START"] = "TIME_DEPOSIT_GET_PARAMETRI_START";
  EReduxActionTypes["TIME_DEPOSIT_GET_PARAMETRI_SUCCESS"] = "TIME_DEPOSIT_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_GET_PARAMETRI_ERROR"] = "TIME_DEPOSIT_GET_PARAMETRI_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_GET_PARAMETRI_BOX_START"] = "TIME_DEPOSIT_GET_PARAMETRI_BOX_START";
  EReduxActionTypes["TIME_DEPOSIT_GET_PARAMETRI_BOX_SUCCESS"] = "TIME_DEPOSIT_GET_PARAMETRI_BOX_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_GET_PARAMETRI_BOX_ERROR"] = "TIME_DEPOSIT_GET_PARAMETRI_BOX_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_GET_TIME_DEPOSIT_START"] = "TIME_DEPOSIT_GET_TIME_DEPOSIT_START";
  EReduxActionTypes["TIME_DEPOSIT_GET_TIME_DEPOSIT_SUCCESS"] = "TIME_DEPOSIT_GET_TIME_DEPOSIT_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_GET_TIME_DEPOSIT_ERROR"] = "TIME_DEPOSIT_GET_TIME_DEPOSIT_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_SELEZIONATO"] = "TIME_DEPOSIT_SELEZIONATO";
  EReduxActionTypes["TIME_DEPOSIT_RESET_TIME_DEPOSIT_SELEZIONATO"] = "TIME_DEPOSIT_RESET_TIME_DEPOSIT_SELEZIONATO";
  EReduxActionTypes["TIME_DEPOSIT_RESET_FILTRI"] = "TIME_DEPOSIT_RESET_FILTRI";
  EReduxActionTypes["TIME_DEPOSIT_BOX_RESET_FILTRI"] = "TIME_DEPOSIT_BOX_RESET_FILTRI";
  EReduxActionTypes["TIME_DEPOSIT_RAPPORTO_SELEZIONATO"] = "TIME_DEPOSIT_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["TIME_DEPOSIT_STATO"] = "TIME_DEPOSIT_STATO";
  EReduxActionTypes["TIME_DEPOSIT_DATA_DECORRENZA_DA"] = "TIME_DEPOSIT_DATA_DECORRENZA_DA";
  EReduxActionTypes["TIME_DEPOSIT_DATA_DECORRENZA_A"] = "TIME_DEPOSIT_DATA_DECORRENZA_A";
  EReduxActionTypes["TIME_DEPOSIT_DATA_SCADENZA_DA"] = "TIME_DEPOSIT_DATA_SCADENZA_DA";
  EReduxActionTypes["TIME_DEPOSIT_DATA_SCADENZA_A"] = "TIME_DEPOSIT_DATA_SCADENZA_A";
  EReduxActionTypes["TIME_DEPOSIT_ESPORTA_PDF_START"] = "TIME_DEPOSIT_ESPORTA_PDF_START";
  EReduxActionTypes["TIME_DEPOSIT_ESPORTA_PDF_SUCCESS"] = "TIME_DEPOSIT_ESPORTA_PDF_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_ESPORTA_PDF_ERROR"] = "TIME_DEPOSIT_ESPORTA_PDF_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_ESPORTA_EXCEL_START"] = "TIME_DEPOSIT_ESPORTA_EXCEL_START";
  EReduxActionTypes["TIME_DEPOSIT_ESPORTA_EXCEL_SUCCESS"] = "TIME_DEPOSIT_ESPORTA_EXCEL_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_ESPORTA_EXCEL_ERROR"] = "TIME_DEPOSIT_ESPORTA_EXCEL_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_ESPORTA_DETTAGLIO_PDF_START"] = "TIME_DEPOSIT_ESPORTA_DETTAGLIO_PDF_START";
  EReduxActionTypes["TIME_DEPOSIT_ESPORTA_DETTAGLIO_PDF_SUCCESS"] = "TIME_DEPOSIT_ESPORTA_DETTAGLIO_PDF_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_ESPORTA_DETTAGLIO_PDF_ERROR"] = "TIME_DEPOSIT_ESPORTA_DETTAGLIO_PDF_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_PERIODO"] = "TIME_DEPOSIT_PERIODO";
  EReduxActionTypes["TIME_DEPOSIT_GET_LISTINI_START"] = "TIME_DEPOSIT_GET_LISTINI_START";
  EReduxActionTypes["TIME_DEPOSIT_GET_LISTINI_SUCCESS"] = "TIME_DEPOSIT_GET_LISTINI_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_GET_LISTINI_ERROR"] = "TIME_DEPOSIT_GET_LISTINI_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_LISTINO_SELEZIONATO"] = "TIME_DEPOSIT_LISTINO_SELEZIONATO";
  EReduxActionTypes["TIME_DEPOSIT_RESET_FORM"] = "TIME_DEPOSIT_RESET_FORM";
  EReduxActionTypes["TIME_DEPOSIT_IMPORTO_DEPOSITO"] = "TIME_DEPOSIT_IMPORTO_DEPOSITO";
  EReduxActionTypes["TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_START"] = "TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_START";
  EReduxActionTypes["TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_ERROR"] = "TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_SIMULA_APERTURA_START"] = "TIME_DEPOSIT_SIMULA_APERTURA_START";
  EReduxActionTypes["TIME_DEPOSIT_SIMULA_APERTURA_SUCCESS"] = "TIME_DEPOSIT_SIMULA_APERTURA_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_SIMULA_APERTURA_ERROR"] = "TIME_DEPOSIT_SIMULA_APERTURA_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_AUTORIZZA_TIME_DEPOSIT_START"] = "TIME_DEPOSIT_AUTORIZZA_TIME_DEPOSIT_START";
  EReduxActionTypes["TIME_DEPOSIT_AUTORIZZA_TIME_DEPOSIT_SUCCESS"] = "TIME_DEPOSIT_AUTORIZZA_TIME_DEPOSIT_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_AUTORIZZA_TIME_DEPOSIT_ERROR"] = "TIME_DEPOSIT_AUTORIZZA_TIME_DEPOSIT_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_REVOCA_START"] = "TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_REVOCA_START";
  EReduxActionTypes["TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_REVOCA_SUCCESS"] = "TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_REVOCA_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_REVOCA_ERROR"] = "TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_REVOCA_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_SIMULA_REVOCA_START"] = "TIME_DEPOSIT_SIMULA_REVOCA_START";
  EReduxActionTypes["TIME_DEPOSIT_SIMULA_REVOCA_SUCCESS"] = "TIME_DEPOSIT_SIMULA_REVOCA_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_SIMULA_REVOCA_ERROR"] = "TIME_DEPOSIT_SIMULA_REVOCA_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_AUTORIZZA_REVOCA_START"] = "TIME_DEPOSIT_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["TIME_DEPOSIT_AUTORIZZA_REVOCA_SUCCESS"] = "TIME_DEPOSIT_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_AUTORIZZA_REVOCA_ERROR"] = "TIME_DEPOSIT_AUTORIZZA_REVOCA_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_GET_DETTAGLIO_START"] = "TIME_DEPOSIT_GET_DETTAGLIO_START";
  EReduxActionTypes["TIME_DEPOSIT_GET_DETTAGLIO_SUCCESS"] = "TIME_DEPOSIT_GET_DETTAGLIO_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_GET_DETTAGLIO_ERROR"] = "TIME_DEPOSIT_GET_DETTAGLIO_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_STEP"] = "TIME_DEPOSIT_STEP";
  EReduxActionTypes["TIME_DEPOSIT_RESET_STEP"] = "TIME_DEPOSIT_RESET_STEP";
  EReduxActionTypes["TIME_DEPOSIT_RESET_LISTINI"] = "TIME_DEPOSIT_RESET_LISTINI";
  EReduxActionTypes["TIME_DEPOSIT_FORM_REVOCA_STEP"] = "TIME_DEPOSIT_FORM_REVOCA_STEP";
  EReduxActionTypes["TIME_DEPOSIT_RESET_REVOCA"] = "TIME_DEPOSIT_RESET_REVOCA";
  EReduxActionTypes["TIME_DEPOSIT_GET_PIANO_CEDOLE_START"] = "TIME_DEPOSIT_GET_PIANO_CEDOLE_START";
  EReduxActionTypes["TIME_DEPOSIT_GET_PIANO_CEDOLE_SUCCESS"] = "TIME_DEPOSIT_GET_PIANO_CEDOLE_SUCCESS";
  EReduxActionTypes["TIME_DEPOSIT_GET_PIANO_CEDOLE_ERROR"] = "TIME_DEPOSIT_GET_PIANO_CEDOLE_ERROR";
  EReduxActionTypes["TIME_DEPOSIT_RESET_RIEPILOGO"] = "TIME_DEPOSIT_RESET_RIEPILOGO";
  EReduxActionTypes["TIME_DEPOSIT_RESET_RIEPILOGO_REVOCA"] = "TIME_DEPOSIT_RESET_RIEPILOGO_REVOCA";
  EReduxActionTypes["TIME_DEPOSIT_RESET"] = "TIME_DEPOSIT_RESET";
  EReduxActionTypes["TIME_DEPOSIT_RESET_CEDOLE"] = "TIME_DEPOSIT_RESET_CEDOLE";
  EReduxActionTypes["TIME_DEPOSIT_GET_TIME_DEPOSIT_SINGLE_LIST_START"] = "TIME_DEPOSIT_GET_TIME_DEPOSIT_SINGLE_LIST_START";
  EReduxActionTypes["TIME_DEPOSIT_GET_TIME_DEPOSIT_SINGLE_LIST_SUCCESS"] = "TIME_DEPOSIT_GET_TIME_DEPOSIT_SINGLE_LIST_SUCCESS";
  // LIMITI OPERATIVI
  EReduxActionTypes["LIMITI_OPERATIVI_RESET"] = "LIMITI_OPERATIVI_RESET";
  EReduxActionTypes["LIMITI_OPERATIVI_GET_PARAMETRI_START"] = "LIMITI_OPERATIVI_GET_PARAMETRI_START";
  EReduxActionTypes["LIMITI_OPERATIVI_GET_PARAMETRI_SUCCESS"] = "LIMITI_OPERATIVI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["LIMITI_OPERATIVI_GET_PARAMETRI_ERROR"] = "LIMITI_OPERATIVI_GET_PARAMETRI_ERROR";
  EReduxActionTypes["LIMITI_OPERATIVI_GET_LIMITI_OPERATIVI_START"] = "LIMITI_OPERATIVI_GET_LIMITI_OPERATIVI_START";
  EReduxActionTypes["LIMITI_OPERATIVI_GET_LIMITI_OPERATIVI_SUCCESS"] = "LIMITI_OPERATIVI_GET_LIMITI_OPERATIVI_SUCCESS";
  EReduxActionTypes["LIMITI_OPERATIVI_GET_LIMITI_OPERATIVI_ERROR"] = "LIMITI_OPERATIVI_GET_LIMITI_OPERATIVI_ERROR";
  // FINANZA
  EReduxActionTypes["FINANZA_GET_PARAMETRI_TRADING_START"] = "FINANZA_GET_PARAMETRI_TRADING_START";
  EReduxActionTypes["FINANZA_GET_PARAMETRI_TRADING_SUCCESS"] = "FINANZA_GET_PARAMETRI_TRADING_SUCCESS";
  EReduxActionTypes["FINANZA_GET_PARAMETRI_START"] = "FINANZA_GET_PARAMETRI_START";
  EReduxActionTypes["FINANZA_GET_PARAMETRI_SUCCESS"] = "FINANZA_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["FINANZA_GET_PARAMETRI_ERROR"] = "FINANZA_GET_PARAMETRI_ERROR";
  EReduxActionTypes["FINANZA_GET_PARAMETRI_AVANZATI_START"] = "FINANZA_GET_PARAMETRI_AVANZATI_START";
  EReduxActionTypes["FINANZA_GET_PARAMETRI_AVANZATI_SUCCESS"] = "FINANZA_GET_PARAMETRI_AVANZATI_SUCCESS";
  EReduxActionTypes["FINANZA_GET_PARAMETRI_AVANZATI_ERROR"] = "FINANZA_GET_PARAMETRI_AVANZATI_ERROR";
  EReduxActionTypes["FINANZA_GET_TITOLI_IN_SCADENZA_START"] = "FINANZA_GET_TITOLI_IN_SCADENZA_START";
  EReduxActionTypes["FINANZA_GET_TITOLI_IN_SCADENZA_SUCCESS"] = "FINANZA_GET_TITOLI_IN_SCADENZA_SUCCESS";
  EReduxActionTypes["FINANZA_GET_TITOLI_IN_SCADENZA_ERROR"] = "FINANZA_GET_TITOLI_IN_SCADENZA_ERROR";
  EReduxActionTypes["FINANZA_GET_MFURL_START"] = "FINANZA_GET_MFURL_START";
  EReduxActionTypes["FINANZA_GET_MFURL_SUCCESS"] = "FINANZA_GET_MFURL_SUCCESS";
  EReduxActionTypes["FINANZA_GET_DATI_INTRADAY_TITOLO_START"] = "FINANZA_GET_DATI_INTRADAY_TITOLO_START";
  EReduxActionTypes["FINANZA_GET_DATI_INTRADAY_TITOLO_SUCCESS"] = "FINANZA_GET_DATI_INTRADAY_TITOLO_SUCCESS";
  EReduxActionTypes["FINANZA_GET_DATI_INTRADAY_TITOLO_ERROR"] = "FINANZA_GET_DATI_INTRADAY_TITOLO_ERROR";
  EReduxActionTypes["FINANZA_GET_DATI_STORICI_TITOLO_ERROR"] = "FINANZA_GET_DATI_STORICI_TITOLO_ERROR";
  EReduxActionTypes["FINANZA_GET_DATI_STORICI_TITOLO_START"] = "FINANZA_GET_DATI_STORICI_TITOLO_START";
  EReduxActionTypes["FINANZA_GET_DATI_STORICI_TITOLO_SUCCESS"] = "FINANZA_GET_DATI_STORICI_TITOLO_SUCCESS";
  EReduxActionTypes["FINANZA_SET_TIPO_GRAFICO_TITOLI"] = "FINANZA_SET_TIPO_GRAFICO_TITOLI";
  EReduxActionTypes["FINANZA_SET_TITOLO_SELEZIONATO"] = "FINANZA_SET_TITOLO_SELEZIONATO";
  EReduxActionTypes["FINANZA_SET_RAPPORTO_SELEZIONATO"] = "FINANZA_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["FINANZA_SET_DATA_FINALE"] = "FINANZA_SET_DATA_FINALE";
  EReduxActionTypes["FINANZA_SET_CAUSALE"] = "FINANZA_SET_CAUSALE";
  EReduxActionTypes["FINANZA_SET_PERIODO"] = "FINANZA_SET_PERIODO";
  EReduxActionTypes["FINANZA_RESET_CAUSALE"] = "FINANZA_RESET_CAUSALE";
  EReduxActionTypes["FINANZA_RESET_ALL"] = "FINANZA_RESET_ALL";
  EReduxActionTypes["FINANZA_ESPORTA_PDF_ELENCO_START"] = "FINANZA_ESPORTA_PDF_ELENCO_START";
  EReduxActionTypes["FINANZA_ESPORTA_PDF_ELENCO_SUCCESS"] = "FINANZA_ESPORTA_PDF_ELENCO_SUCCESS";
  EReduxActionTypes["FINANZA_ESPORTA_PDF_ELENCO_ERROR"] = "FINANZA_ESPORTA_PDF_ELENCO_ERROR";
  EReduxActionTypes["FINANZA_ESPORTA_EXCEL_ELENCO_START"] = "FINANZA_ESPORTA_EXCEL_ELENCO_START";
  EReduxActionTypes["FINANZA_ESPORTA_EXCEL_ELENCO_SUCCESS"] = "FINANZA_ESPORTA_EXCEL_ELENCO_SUCCESS";
  EReduxActionTypes["FINANZA_ESPORTA_EXCEL_ELENCO_ERROR"] = "FINANZA_ESPORTA_EXCEL_ELENCO_ERROR";
  EReduxActionTypes["FINANZA_SET_DATA_MASSIMA"] = "FINANZA_SET_DATA_MASSIMA";
  EReduxActionTypes["FINANZA_SET_ACCORDION_FILTRI_OPENED"] = "FINANZA_SET_ACCORDION_FILTRI_OPENED";
  // POLIZZE
  EReduxActionTypes["POLIZZE_SET_RAPPORTO_SELEZIONATO"] = "POLIZZE_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["POLIZZE_GET_PARAMETRI_START"] = "POLIZZE_GET_PARAMETRI_START";
  EReduxActionTypes["POLIZZE_GET_PARAMETRI_SUCCESS"] = "POLIZZE_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["POLIZZE_GET_DETTAGLIO_START"] = "POLIZZE_GET_DETTAGLIO_START";
  EReduxActionTypes["POLIZZE_GET_DETTAGLIO_SUCCESS"] = "POLIZZE_GET_DETTAGLIO_SUCCESS";
  EReduxActionTypes["POLIZZE_ESPORTA_PDF_DETTAGLIO_START"] = "POLIZZE_ESPORTA_PDF_DETTAGLIO_START";
  EReduxActionTypes["POLIZZE_ESPORTA_PDF_DETTAGLIO_SUCCESS"] = "POLIZZE_ESPORTA_PDF_DETTAGLIO_SUCCESS";
  EReduxActionTypes["POLIZZE_ESPORTA_PDF_DETTAGLIO_ERROR"] = "POLIZZE_ESPORTA_PDF_DETTAGLIO_ERROR";
  EReduxActionTypes["POLIZZE_GET_POLIZZE_ASSICURAZIONE_START"] = "POLIZZE_GET_POLIZZE_ASSICURAZIONE_START";
  EReduxActionTypes["POLIZZE_GET_POLIZZE_ASSICURAZIONE_SUCCESS"] = "POLIZZE_GET_POLIZZE_ASSICURAZIONE_SUCCESS";
  EReduxActionTypes["POLIZZE_GET_POLIZZE_INVESTIMENTO_START"] = "POLIZZE_GET_POLIZZE_INVESTIMENTO_START";
  EReduxActionTypes["POLIZZE_GET_POLIZZE_INVESTIMENTO_SUCCESS"] = "POLIZZE_GET_POLIZZE_INVESTIMENTO_SUCCESS";
  EReduxActionTypes["POLIZZE_SET_POLIZZA_SELEZIONATA"] = "POLIZZE_SET_POLIZZA_SELEZIONATA";
  EReduxActionTypes["POLIZZE_RESET_POLIZZA_SELEZIONATA"] = "POLIZZE_RESET_POLIZZA_SELEZIONATA";
  // ALTO ADIGE PASS
  EReduxActionTypes["ALTO_ADIGE_PASS_GET_PARAMETRI_START"] = "ALTO_ADIGE_PASS_GET_PARAMETRI_START";
  EReduxActionTypes["ALTO_ADIGE_PASS_GET_PARAMETRI_SUCCESS"] = "ALTO_ADIGE_PASS_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["ALTO_ADIGE_PASS_GET_PARAMETRI_ERROR"] = "ALTO_ADIGE_PASS_GET_PARAMETRI_ERROR";
  EReduxActionTypes["ALTO_ADIGE_PASS_RECUPERA_STORICO_RICARICHE_START"] = "ALTO_ADIGE_PASS_RECUPERA_STORICO_RICARICHE_START";
  EReduxActionTypes["ALTO_ADIGE_PASS_RECUPERA_STORICO_RICARICHE_SUCCESS"] = "ALTO_ADIGE_PASS_RECUPERA_STORICO_RICARICHE_SUCCESS";
  EReduxActionTypes["ALTO_ADIGE_PASS_RECUPERA_STORICO_RICARICHE_ERROR"] = "ALTO_ADIGE_PASS_RECUPERA_STORICO_RICARICHE_ERROR";
  // ALTO ADIGE PASS - RICARICA
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_NUMERO_ALTO_ADIGE_PASS"] = "RICARICA_ALTO_ADIGE_PASS_NUMERO_ALTO_ADIGE_PASS";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_NOME_BENIFICIARIO"] = "RICARICA_ALTO_ADIGE_PASS_NOME_BENIFICIARIO";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_AGGIUNGI_BENEFICIARIO_RUBRICA"] = "RICARICA_ALTO_ADIGE_PASS_AGGIUNGI_BENEFICIARIO_RUBRICA";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_BENEFICIARIO_AFFIDABILE"] = "RICARICA_ALTO_ADIGE_PASS_BENEFICIARIO_AFFIDABILE";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_ID_CONTATTO_BENEFICIARIO"] = "RICARICA_ALTO_ADIGE_PASS_ID_CONTATTO_BENEFICIARIO";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_TAGLIO_RICARICA"] = "RICARICA_ALTO_ADIGE_PASS_TAGLIO_RICARICA";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_TIPO_OPERAZIONE"] = "RICARICA_ALTO_ADIGE_PASS_TIPO_OPERAZIONE";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_STEP"] = "RICARICA_ALTO_ADIGE_PASS_STEP";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_RESET"] = "RICARICA_ALTO_ADIGE_PASS_RESET";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_FORM_SUBMITTED"] = "RICARICA_ALTO_ADIGE_PASS_FORM_SUBMITTED";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_SET_RAPPORTO_SELEZIONATO"] = "RICARICA_ALTO_ADIGE_PASS_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "RICARICA_ALTO_ADIGE_PASS_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_GET_INFO_DISPOSITIVA_START"] = "RICARICA_ALTO_ADIGE_PASS_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_GET_INFO_DISPOSITIVA_SUCCESS"] = "RICARICA_ALTO_ADIGE_PASS_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_GET_INFO_DISPOSITIVA_ERROR"] = "RICARICA_ALTO_ADIGE_PASS_GET_INFO_DISPOSITIVA_ERROR";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_CONTROLLO_START"] = "RICARICA_ALTO_ADIGE_PASS_CONTROLLO_START";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_CONTROLLO_SUCCESS"] = "RICARICA_ALTO_ADIGE_PASS_CONTROLLO_SUCCESS";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_CONTROLLO_ERROR"] = "RICARICA_ALTO_ADIGE_PASS_CONTROLLO_ERROR";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_AUTORIZZA_START"] = "RICARICA_ALTO_ADIGE_PASS_AUTORIZZA_START";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_AUTORIZZA_SUCCESS"] = "RICARICA_ALTO_ADIGE_PASS_AUTORIZZA_SUCCESS";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_AUTORIZZA_ERROR"] = "RICARICA_ALTO_ADIGE_PASS_AUTORIZZA_ERROR";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_VERIFICA_START"] = "RICARICA_ALTO_ADIGE_PASS_VERIFICA_START";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_VERIFICA_SUCCESS"] = "RICARICA_ALTO_ADIGE_PASS_VERIFICA_SUCCESS";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_VERIFICA_ERROR"] = "RICARICA_ALTO_ADIGE_PASS_VERIFICA_ERROR";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_RESET_RIEPILOGO"] = "RICARICA_ALTO_ADIGE_PASS_RESET_RIEPILOGO";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_RESET_TAGLIO_RICARICA"] = "RICARICA_ALTO_ADIGE_PASS_RESET_TAGLIO_RICARICA";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_RESET_FORM"] = "RICARICA_ALTO_ADIGE_PASS_RESET_FORM";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_GET_RICARICA_ALTO_ADIGE_PASS_FROM_BOZZA"] = "RICARICA_ALTO_ADIGE_PASS_GET_RICARICA_ALTO_ADIGE_PASS_FROM_BOZZA";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_1CLICK"] = "RICARICA_ALTO_ADIGE_PASS_1CLICK";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_ALIAS_1CLICK"] = "RICARICA_ALTO_ADIGE_PASS_ALIAS_1CLICK";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_GET_ONECLICK_START"] = "RICARICA_ALTO_ADIGE_PASS_GET_ONECLICK_START";
  EReduxActionTypes["RICARICA_ALTO_ADIGE_PASS_GET_ONECLICK_SUCCESS"] = "RICARICA_ALTO_ADIGE_PASS_GET_ONECLICK_SUCCESS";
  // I18N
  EReduxActionTypes["I18N_INIT"] = "I18N_INIT";
  EReduxActionTypes["I18N_RESET"] = "I18N_RESET";
  EReduxActionTypes["I18N_SET_CURRENT_LANGUAGE"] = "I18N_SET_CURRENT_LANGUAGE";
  EReduxActionTypes["I18N_SET_RESOURCES"] = "I18N_SET_RESOURCES";
  EReduxActionTypes["I18N_GET_MULTILINGUA_START"] = "I18N_GET_MULTILINGUA_START";
  EReduxActionTypes["I18N_GET_MULTILINGUA_SUCCESS"] = "I18N_GET_MULTILINGUA_SUCCESS";
  EReduxActionTypes["I18N_GET_MULTILINGUA_GENESYS_SUCCESS"] = "I18N_GET_MULTILINGUA_GENESYS_SUCCESS";
  EReduxActionTypes["I18N_GET_MULTILINGUA_GENESYS_START"] = "I18N_GET_MULTILINGUA_GENESYS_START";
  // BLOCCO CARTE
  EReduxActionTypes["BLOCCO_CARTE_GET_RIFERIMENTI_START"] = "BLOCCO_CARTE_GET_RIFERIMENTI_START";
  EReduxActionTypes["BLOCCO_CARTE_GET_RIFERIMENTI_SUCCESS"] = "BLOCCO_CARTE_GET_RIFERIMENTI_SUCCESS";
  EReduxActionTypes["BLOCCO_CARTE_GET_RIFERIMENTI_ERROR"] = "BLOCCO_CARTE_GET_RIFERIMENTI_ERROR";
  // Variazione Canali
  EReduxActionTypes["VARIAZIONE_CANALI_SET_STEP"] = "VARIAZIONE_CANALI_SET_STEP";
  EReduxActionTypes["VARIAZIONE_CANALI_SET_ACCETTATO"] = "VARIAZIONE_CANALI_SET_ACCETTATO";
  EReduxActionTypes["VARIAZIONE_CANALI_SET_SELECTED_ALL"] = "VARIAZIONE_CANALI_SET_SELECTED_ALL";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_RAPPORTI_PREDEFINITI_START"] = "VARIAZIONE_CANALI_GET_RAPPORTI_PREDEFINITI_START";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_RAPPORTI_PREDEFINITI_SUCCESS"] = "VARIAZIONE_CANALI_GET_RAPPORTI_PREDEFINITI_SUCCESS";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_RAPPORTI_PREDEFINITI_ERROR"] = "VARIAZIONE_CANALI_GET_RAPPORTI_PREDEFINITI_ERROR";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_INFO_AUTENTICAZIONE_START"] = "VARIAZIONE_CANALI_GET_INFO_AUTENTICAZIONE_START";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "VARIAZIONE_CANALI_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_INFO_AUTENTICAZIONE_ERROR"] = "VARIAZIONE_CANALI_GET_INFO_AUTENTICAZIONE_ERROR";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_START"] = "VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_START";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_SUCCESS"] = "VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_SUCCESS";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_ERROR"] = "VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_ERROR";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_CORRISPONDEZE_START"] = "VARIAZIONE_CANALI_GET_CORRISPONDEZE_START";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_CORRISPONDEZE_SUCCESS"] = "VARIAZIONE_CANALI_GET_CORRISPONDEZE_SUCCESS";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_CORRISPONDEZE_ERROR"] = "VARIAZIONE_CANALI_GET_CORRISPONDEZE_ERROR";
  EReduxActionTypes["VARIAZIONE_CANALI_AUTORIZZA_START"] = "VARIAZIONE_CANALI_AUTORIZZA_START";
  EReduxActionTypes["VARIAZIONE_CANALI_AUTORIZZA_SUCCESS"] = "VARIAZIONE_CANALI_AUTORIZZA_SUCCESS";
  EReduxActionTypes["VARIAZIONE_CANALI_AUTORIZZA_ERROR"] = "VARIAZIONE_CANALI_AUTORIZZA_ERROR";
  EReduxActionTypes["VARIAZIONE_CANALI_ESPORTA_PDF_ELENCO_START"] = "VARIAZIONE_CANALI_ESPORTA_PDF_ELENCO_START";
  EReduxActionTypes["VARIAZIONE_CANALI_ESPORTA_PDF_ELENCO_SUCCESS"] = "VARIAZIONE_CANALI_ESPORTA_PDF_ELENCO_SUCCESS";
  EReduxActionTypes["VARIAZIONE_CANALI_ESPORTA_PDF_ELENCO_ERROR"] = "VARIAZIONE_CANALI_ESPORTA_PDF_ELENCO_ERROR";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_CONDIZIONI_ECONIMICHE_START"] = "VARIAZIONE_CANALI_GET_CONDIZIONI_ECONIMICHE_START";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_CONDIZIONI_ECONIMICHE_SUCCESS"] = "VARIAZIONE_CANALI_GET_CONDIZIONI_ECONIMICHE_SUCCESS";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_CONDIZIONI_ECONIMICHE_ERROR"] = "VARIAZIONE_CANALI_GET_CONDIZIONI_ECONIMICHE_ERROR";
  EReduxActionTypes["VARIAZIONE_CANALI_SET_SELECTED_ITEMS"] = "VARIAZIONE_CANALI_SET_SELECTED_ITEMS";
  EReduxActionTypes["VARIAZIONE_CANALI_RESET"] = "VARIAZIONE_CANALI_RESET";
  EReduxActionTypes["VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_PER_MODIFICA_SUCCESS"] = "VARIAZIONE_CANALI_GET_PARAMETRI_CORRISPONDEZA_PER_MODIFICA_SUCCESS";
  // FONDI PAPERLESS
  EReduxActionTypes["FONDI_PAPERLESS_SET_FORM_CONDIZIONI"] = "FONDI_PAPERLESS_SET_FORM_CONDIZIONI";
  EReduxActionTypes["FONDI_PAPERLESS_GET_CONDIZIONI_FONDI_START"] = "FONDI_PAPERLESS_GET_CONDIZIONI_FONDI_START";
  EReduxActionTypes["FONDI_PAPERLESS_GET_CONDIZIONI_FONDI_SUCCESS"] = "FONDI_PAPERLESS_GET_CONDIZIONI_FONDI_SUCCESS";
  EReduxActionTypes["FONDI_PAPERLESS_GET_CORRISPONDENZE_FONDI_START"] = "FONDI_PAPERLESS_GET_CORRISPONDENZE_FONDI_START";
  EReduxActionTypes["FONDI_PAPERLESS_GET_CORRISPONDENZE_FONDI_SUCCESS"] = "FONDI_PAPERLESS_GET_CORRISPONDENZE_FONDI_SUCCESS";
  EReduxActionTypes["FONDI_PAPERLESS_SALVA_CORRISPONDENZE_FONDI_START"] = "FONDI_PAPERLESS_SALVA_CORRISPONDENZE_FONDI_START";
  EReduxActionTypes["FONDI_PAPERLESS_SALVA_CORRISPONDENZE_FONDI_SUCCESS"] = "FONDI_PAPERLESS_SALVA_CORRISPONDENZE_FONDI_SUCCESS";
  EReduxActionTypes["FONDI_PAPERLESS_EXPORT_CORRISPONDENZE_FONDI_PDF_START"] = "FONDI_PAPERLESS_EXPORT_CORRISPONDENZE_FONDI_PDF_START";
  EReduxActionTypes["FONDI_PAPERLESS_EXPORT_CORRISPONDENZE_FONDI_PDF_SUCCESS"] = "FONDI_PAPERLESS_EXPORT_CORRISPONDENZE_FONDI_PDF_SUCCESS";
  EReduxActionTypes["FONDI_PAPERLESS_RESET"] = "FONDI_PAPERLESS_RESET";
  // MESSAGGI
  EReduxActionTypes["MESSAGGI_ACCETTATO"] = "MESSAGGI_ACCETTATO";
  EReduxActionTypes["MESSAGGI_GET_ACCETTAZIONI_START"] = "MESSAGGI_GET_ACCETTAZIONI_START";
  EReduxActionTypes["MESSAGGI_GET_ACCETTAZIONI_SUCCESS"] = "MESSAGGI_GET_ACCETTAZIONI_SUCCESS";
  EReduxActionTypes["MESSAGGI_GET_ACCETTAZIONI_ERROR"] = "MESSAGGI_GET_ACCETTAZIONI_ERROR";
  EReduxActionTypes["MESSAGGI_GET_BLOCCANTI_START"] = "MESSAGGI_GET_BLOCCANTI_START";
  EReduxActionTypes["MESSAGGI_GET_BLOCCANTI_SUCCESS"] = "MESSAGGI_GET_BLOCCANTI_SUCCESS";
  EReduxActionTypes["MESSAGGI_GET_BLOCCANTI_ERROR"] = "MESSAGGI_GET_BLOCCANTI_ERROR";
  EReduxActionTypes["MESSAGGI_RESET_ACCETTAZIONI"] = "MESSAGGI_RESET_ACCETTAZIONI";
  EReduxActionTypes["MESSAGGI_GET_COMUNICAZIONI_START"] = "MESSAGGI_GET_COMUNICAZIONI_START";
  EReduxActionTypes["MESSAGGI_GET_COMUNICAZIONI_SUCCESS"] = "MESSAGGI_GET_COMUNICAZIONI_SUCCESS";
  EReduxActionTypes["MESSAGGI_GET_COMUNICAZIONI_ERROR"] = "MESSAGGI_GET_COMUNICAZIONI_ERROR";
  EReduxActionTypes["MESSAGGI_SET_MESSAGGIO_COMUNICAZIONE_SELEZIONATO"] = "MESSAGGI_SET_MESSAGGIO_COMUNICAZIONE_SELEZIONATO";
  EReduxActionTypes["MESSAGGI_GET_NUMERO_MESSAGGI_NON_LETTI_START"] = "MESSAGGI_GET_NUMERO_MESSAGGI_NON_LETTI_START";
  EReduxActionTypes["MESSAGGI_GET_NUMERO_MESSAGGI_NON_LETTI_SUCCESS"] = "MESSAGGI_GET_NUMERO_MESSAGGI_NON_LETTI_SUCCESS";
  EReduxActionTypes["MESSAGGI_GET_NUMERO_MESSAGGI_NON_LETTI_ERROR"] = "MESSAGGI_GET_NUMERO_MESSAGGI_NON_LETTI_ERROR";
  EReduxActionTypes["MESSAGGI_SEGNA_COME_LETTO_START"] = "MESSAGGI_SEGNA_COME_LETTO_START";
  EReduxActionTypes["MESSAGGI_SEGNA_COME_LETTO_SUCCESS"] = "MESSAGGI_SEGNA_COME_LETTO_SUCCESS";
  EReduxActionTypes["MESSAGGI_SEGNA_COME_LETTO_ERROR"] = "MESSAGGI_SEGNA_COME_LETTO_ERROR";
  EReduxActionTypes["MESSAGGI_UPDATE_BLOCCANTI"] = "MESSAGGI_UPDATE_BLOCCANTI";
  EReduxActionTypes["MESSAGGI_RESET_BLOCCANTI"] = "MESSAGGI_RESET_BLOCCANTI";
  EReduxActionTypes["MESSAGGI_GET_ACCETTAZIONI_ASSISTENTE_VOCALE_SUCCESS"] = "MESSAGGI_GET_ACCETTAZIONI_ASSISTENTE_VOCALE_SUCCESS";
  EReduxActionTypes["MESSAGGI_RESET_ACCETTAZIONI_ASSISTENTE_VOCALE"] = "MESSAGGI_RESET_ACCETTAZIONI_ASSISTENTE_VOCALE";
  // DOCUMENTI
  EReduxActionTypes["DOCUMENTI_SET_FONTE_DOCUMENTO"] = "DOCUMENTI_SET_FONTE_DOCUMENTO";
  EReduxActionTypes["DOCUMENTI_SET_TIPOLOGIA_DOCUMENTAZIONE"] = "DOCUMENTI_SET_TIPOLOGIA_DOCUMENTAZIONE";
  EReduxActionTypes["DOCUMENTI_GET_RAPPORTI_UTENTE_DOCUMENTALE_START"] = "DOCUMENTI_GET_RAPPORTI_UTENTE_DOCUMENTALE_START";
  EReduxActionTypes["DOCUMENTI_GET_RAPPORTI_UTENTE_DOCUMENTALE_SUCCESS"] = "DOCUMENTI_GET_RAPPORTI_UTENTE_DOCUMENTALE_SUCCESS";
  EReduxActionTypes["DOCUMENTI_GET_ELENCO_COMUNICAZIONI_START"] = "DOCUMENTI_GET_ELENCO_COMUNICAZIONI_START";
  EReduxActionTypes["DOCUMENTI_GET_ELENCO_COMUNICAZIONI_SUCCESS"] = "DOCUMENTI_GET_ELENCO_COMUNICAZIONI_SUCCESS";
  EReduxActionTypes["DOCUMENTI_GET_TIPOLOGIE_COMUNICAZIONI_START"] = "DOCUMENTI_GET_TIPOLOGIE_COMUNICAZIONI_START";
  EReduxActionTypes["DOCUMENTI_GET_TIPOLOGIE_COMUNICAZIONI_SUCCESS"] = "DOCUMENTI_GET_TIPOLOGIE_COMUNICAZIONI_SUCCESS";
  EReduxActionTypes["DOCUMENTI_GET_COMUNICAZIONE_START"] = "DOCUMENTI_GET_COMUNICAZIONE_START";
  EReduxActionTypes["DOCUMENTI_GET_COMUNICAZIONE_SUCCESS"] = "DOCUMENTI_GET_COMUNICAZIONE_SUCCESS";
  EReduxActionTypes["DOCUMENTI_GET_ELENCO_DOCUMENTI_START"] = "DOCUMENTI_GET_ELENCO_DOCUMENTI_START";
  EReduxActionTypes["DOCUMENTI_GET_ELENCO_DOCUMENTI_SUCCESS"] = "DOCUMENTI_GET_ELENCO_DOCUMENTI_SUCCESS";
  EReduxActionTypes["DOCUMENTI_GET_DOCUMENTO_START"] = "DOCUMENTI_GET_DOCUMENTO_START";
  EReduxActionTypes["DOCUMENTI_GET_DOCUMENTO_SUCCESS"] = "DOCUMENTI_GET_DOCUMENTO_SUCCESS";
  EReduxActionTypes["DOCUMENTI_GET_PARAMETRI_START"] = "DOCUMENTI_GET_PARAMETRI_START";
  EReduxActionTypes["DOCUMENTI_GET_PARAMETRI_SUCCESS"] = "DOCUMENTI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["DOCUMENTI_GET_NUMERO_NUOVE_COMUNICAZIONI_START"] = "DOCUMENTI_GET_NUMERO_NUOVE_COMUNICAZIONI_START";
  EReduxActionTypes["DOCUMENTI_GET_NUMERO_NUOVE_COMUNICAZIONI_SUCCESS"] = "DOCUMENTI_GET_NUMERO_NUOVE_COMUNICAZIONI_SUCCESS";
  EReduxActionTypes["DOCUMENTI_SEGNA_COME_LETTI_START"] = "DOCUMENTI_SEGNA_COME_LETTI_START";
  EReduxActionTypes["DOCUMENTI_SEGNA_COME_LETTI_SUCCESS"] = "DOCUMENTI_SEGNA_COME_LETTI_SUCCESS";
  EReduxActionTypes["DOCUMENTI_SET_TIPO_RAPPORTO"] = "DOCUMENTI_SET_TIPO_RAPPORTO";
  EReduxActionTypes["DOCUMENTI_SET_RAPPORTO"] = "DOCUMENTI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["DOCUMENTI_SET_DATA_DA"] = "DOCUMENTI_SET_DATA_DA";
  EReduxActionTypes["DOCUMENTI_SET_DATA_A"] = "DOCUMENTI_SET_DATA_A";
  EReduxActionTypes["DOCUMENTI_SET_SOLO_NON_LETTI"] = "DOCUMENTI_SET_SOLO_NON_LETTI";
  EReduxActionTypes["DOCUMENTI_SET_TUTTI_I_RAPPORTI"] = "DOCUMENTI_SET_TUTTI_I_RAPPORTI";
  EReduxActionTypes["DOCUMENTI_SET_INTERVALLO"] = "DOCUMENTI_SET_INTERVALLO";
  EReduxActionTypes["DOCUMENTI_COMUNICAZIONI_RESET"] = "DOCUMENTI_COMUNICAZIONI_RESET";
  EReduxActionTypes["DOCUMENTI_CONTRATTI_FORM_RESET"] = "DOCUMENTI_CONTRATTI_FORM_RESET";
  EReduxActionTypes["DOCUMENTI_FORM_RESET"] = "DOCUMENTI_FORM_RESET";
  // ELENCO RAPPORTI
  EReduxActionTypes["ELENCO_RAPPORTI_GET_PREDEFINITI_START"] = "ELENCO_RAPPORTI_GET_PREDEFINITI_START";
  EReduxActionTypes["ELENCO_RAPPORTI_GET_PREDEFINITI_SUCCESS"] = "ELENCO_RAPPORTI_GET_PREDEFINITI_SUCCESS";
  EReduxActionTypes["ELENCO_RAPPORTI_GET_RAPPORTI_UTENTE_START"] = "ELENCO_RAPPORTI_GET_RAPPORTI_UTENTE_START";
  EReduxActionTypes["ELENCO_RAPPORTI_GET_RAPPORTI_UTENTE_SUCCESS"] = "ELENCO_RAPPORTI_GET_RAPPORTI_UTENTE_SUCCESS";
  EReduxActionTypes["ELENCO_RAPPORTI_AGGIORNA_START"] = "ELENCO_RAPPORTI_AGGIORNA_START";
  EReduxActionTypes["ELENCO_RAPPORTI_AGGIORNA_SUCCESS"] = "ELENCO_RAPPORTI_AGGIORNA_SUCCESS";
  EReduxActionTypes["ELENCO_RAPPORTI_AGGIORNA_ALIAS_START"] = "ELENCO_RAPPORTI_AGGIORNA_ALIAS_START";
  EReduxActionTypes["ELENCO_RAPPORTI_AGGIORNA_ALIAS_SUCCESS"] = "ELENCO_RAPPORTI_AGGIORNA_ALIAS_SUCCESS";
  EReduxActionTypes["SET_ALIAS_RAPPORTO_SELEZIONATO"] = "SET_ALIAS_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_ITALIA"] = "ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_ITALIA";
  EReduxActionTypes["ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_DEPOSITO"] = "ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_DEPOSITO";
  EReduxActionTypes["ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_ESTERO"] = "ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_CONTO_ESTERO";
  EReduxActionTypes["ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_DEPOSITI_RISPARMIO"] = "ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_DEPOSITI_RISPARMIO";
  EReduxActionTypes["ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_BONIFICI"] = "ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_BONIFICI";
  EReduxActionTypes["ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_PAGAMENTI"] = "ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_PAGAMENTI";
  EReduxActionTypes["ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_TRADING"] = "ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_TRADING";
  EReduxActionTypes["ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_TRADING_INFO"] = "ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_TRADING_INFO";
  EReduxActionTypes["ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_FINANZIAMENTI_INFO"] = "ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_FINANZIAMENTI_INFO";
  EReduxActionTypes["ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_POLIZZE_BANCA_ASS"] = "ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO_POLIZZE_BANCA_ASS";
  EReduxActionTypes["SELEZIONA_RAPPORTI_RESET_FORM"] = "SELEZIONA_RAPPORTI_RESET_FORM";
  EReduxActionTypes["ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO"] = "ELENCO_RAPPORTI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["ELENCO_RAPPORTI_ESPORTA_DETTAGLIO_PDF_START"] = "ELENCO_RAPPORTI_ESPORTA_DETTAGLIO_PDF_START";
  EReduxActionTypes["ELENCO_RAPPORTI_ESPORTA_DETTAGLIO_PDF_SUCCESS"] = "ELENCO_RAPPORTI_ESPORTA_DETTAGLIO_PDF_SUCCESS";
  // PNS
  EReduxActionTypes["PNS_SUBSCRIPTION_START"] = "PNS_SUBSCRIPTION_START";
  EReduxActionTypes["PNS_SUBSCRIPTION_SUCCESS"] = "PNS_SUBSCRIPTION_SUCCESS";
  EReduxActionTypes["PNS_SUBSCRIPTION_ERROR"] = "PNS_SUBSCRIPTION_ERROR";
  // ELENCO CONSENSI
  EReduxActionTypes["ELENCO_CONSENSI_GET_CONSENSI_START"] = "ELENCO_CONSENSI_GET_CONSENSI_START";
  EReduxActionTypes["ELENCO_CONSENSI_GET_CONSENSI_SUCCESS"] = "ELENCO_CONSENSI_GET_CONSENSI_SUCCESS";
  EReduxActionTypes["AUTORIZZA_CONSENSI_START"] = "AUTORIZZA_CONSENSI_START";
  EReduxActionTypes["AUTORIZZA_CONSENSI_SUCCESS"] = "AUTORIZZA_CONSENSI_SUCCESS";
  EReduxActionTypes["AUTORIZZA_CONSENSI_ERROR"] = "AUTORIZZA_CONSENSI_ERROR";
  EReduxActionTypes["CONTROLLO_DISABILITA_CONSENSI_START"] = "CONTROLLO_DISABILITA_CONSENSI_START";
  EReduxActionTypes["CONTROLLO_DISABILITA_CONSENSI_SUCCESS"] = "CONTROLLO_DISABILITA_CONSENSI_SUCCESS";
  EReduxActionTypes["CONTROLLO_DISABILITA_CONSENSI_ERROR"] = "CONTROLLO_DISABILITA_CONSENSI_ERROR";
  EReduxActionTypes["GET_INFO_AUTENTICAZIONE_CONSENSI_START"] = "GET_INFO_AUTENTICAZIONE_CONSENSI_START";
  EReduxActionTypes["GET_INFO_AUTENTICAZIONE_CONSENSI_SUCCESS"] = "GET_INFO_AUTENTICAZIONE_CONSENSI_SUCCESS";
  EReduxActionTypes["GET_INFO_AUTENTICAZIONE_CONSENSI_ERROR"] = "GET_INFO_AUTENTICAZIONE_CONSENSI_ERROR";
  EReduxActionTypes["CONSENSI_SET_STEP"] = "CONSENSI_SET_STEP";
  EReduxActionTypes["CONSENSI_RESET_FORM"] = "CONSENSI_RESET_FORM";
  EReduxActionTypes["CONSENSI_RESET"] = "CONSENSI_RESET";
  // CERCA TITOLI
  EReduxActionTypes["CERCA_TITOLI_GET_FORM_PARAMETERS_START"] = "CERCA_TITOLI_GET_FORM_PARAMETERS_START";
  EReduxActionTypes["CERCA_TITOLI_GET_FORM_PARAMETERS_SUCCESS"] = "CERCA_TITOLI_GET_FORM_PARAMETERS_SUCCESS";
  EReduxActionTypes["CERCA_TITOLI_GET_FORM_PARAMETERS_ERROR"] = "CERCA_TITOLI_GET_FORM_PARAMETERS_ERROR";
  EReduxActionTypes["CERCA_TITOLI_GET_TITOLI_START"] = "CERCA_TITOLI_GET_TITOLI_START";
  EReduxActionTypes["CERCA_TITOLI_GET_TITOLI_SUCCESS"] = "CERCA_TITOLI_GET_TITOLI_SUCCESS";
  EReduxActionTypes["CERCA_TITOLI_GET_TITOLI_ERROR"] = "CERCA_TITOLI_GET_TITOLI_ERROR";
  EReduxActionTypes["SET_CERCA_TITOLI_ISIN"] = "SET_CERCA_TITOLI_ISIN";
  EReduxActionTypes["SET_CERCA_TITOLI_MERCATO"] = "SET_CERCA_TITOLI_MERCATO";
  EReduxActionTypes["SET_CERCA_TITOLI_DESCRIZIONE"] = "SET_CERCA_TITOLI_DESCRIZIONE";
  EReduxActionTypes["SET_CERCA_TITOLI_TIPO"] = "SET_CERCA_TITOLI_TIPO";
  EReduxActionTypes["CERCA_TITOLI_RESET_FORM"] = "CERCA_TITOLI_RESET_FORM";
  EReduxActionTypes["CERCA_TITOLI_SET_ACCORDION_FILTRI_OPENED"] = "TITOLI_SET_ACCORDION_FILTRI_OPENED";
  EReduxActionTypes["CERCA_TITOLI_AGGIUNGI_PREFERITO_START"] = "CERCA_TITOLI_AGGIUNGI_PREFERITO_START";
  EReduxActionTypes["CERCA_TITOLI_AGGIUNGI_PREFERITO_SUCCESS"] = "CERCA_TITOLI_AGGIUNGI_PREFERITO_SUCCESS";
  EReduxActionTypes["CERCA_TITOLI_RIMUOVI_PREFERITO_START"] = "CERCA_TITOLI_RIMUOVI_PREFERITO_START";
  EReduxActionTypes["CERCA_TITOLI_RIMUOVI_PREFERITO_SUCCESS"] = "CERCA_TITOLI_RIMUOVI_PREFERITO_SUCCESS";
  EReduxActionTypes["CERCA_TITOLI_SET_RAPPORTO_SELEZIONATO"] = "CERCA_TITOLI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["CERCA_TITOLI_SET_ONLY_PREFERRED"] = "CERCA_TITOLI_SET_ONLY_PREFERRED";
  EReduxActionTypes["CERCA_TITOLI_IS_PREFERRED_START"] = "CERCA_TITOLI_IS_PREFERRED_START";
  EReduxActionTypes["CERCA_TITOLI_IS_PREFERRED_SUCCESS"] = "CERCA_TITOLI_IS_PREFERRED_SUCCESS";
  EReduxActionTypes["CERCA_TITOLI_SET_SHOW_PREFERITO_MODAL"] = "CERCA_TITOLI_SET_SHOW_PREFERITO_MODAL";
  EReduxActionTypes["CERCA_TITOLI_SET_TITOLO_SELEZIONATO"] = "CERCA_TITOLI_SET_TITOLO_SELEZIONATO";
  EReduxActionTypes["DATI_FILIALE_GET_DATI_START"] = "DATI_FILIALE_GET_DATI_START";
  EReduxActionTypes["DATI_FILIALE_GET_DATI_SUCCESS"] = "DATI_FILIALE_GET_DATI_SUCCESS";
  EReduxActionTypes["DATI_FILIALE_GET_DATI_ERROR"] = "DATI_FILIALE_GET_DATI_ERROR";
  EReduxActionTypes["CERCA_TITOLI_SET_OFFSET"] = "CERCA_TITOLI_SET_OFFSET";
  EReduxActionTypes["DATI_PERSONALI_AUTORIZZA_DATI_AGGIUNTIVI_START"] = "DATI_PERSONALI_AUTORIZZA_DATI_AGGIUNTIVI_START";
  EReduxActionTypes["DATI_PERSONALI_AUTORIZZA_DATI_AGGIUNTIVI_SUCCESS"] = "DATI_PERSONALI_AUTORIZZA_DATI_AGGIUNTIVI_SUCCESS";
  EReduxActionTypes["DATI_PERSONALI_GET_DATI_AGGIUNTIVI_START"] = "DATI_PERSONALI_GET_DATI_AGGIUNTIVI_START";
  EReduxActionTypes["DATI_PERSONALI_GET_DATI_AGGIUNTIVI_SUCCESS"] = "DATI_PERSONALI_GET_DATI_AGGIUNTIVI_SUCCESS";
  EReduxActionTypes["DATI_PERSONALI_GET_DATI_ANAGRAFICI_START"] = "DATI_PERSONALI_GET_DATI_ANAGRAFICI_START";
  EReduxActionTypes["DATI_PERSONALI_GET_DATI_ANAGRAFICI_SUCCESS"] = "DATI_PERSONALI_GET_DATI_ANAGRAFICI_SUCCESS";
  EReduxActionTypes["DATI_PERSONALI_GET_INFORMAZIONI_DATI_AGGIUNTIVI_START"] = "DATI_PERSONALI_GET_INFORMAZIONI_DATI_AGGIUNTIVI_START";
  EReduxActionTypes["DATI_PERSONALI_GET_INFORMAZIONI_DATI_AGGIUNTIVI_SUCCESS"] = "DATI_PERSONALI_GET_INFORMAZIONI_DATI_AGGIUNTIVI_SUCCESS";
  EReduxActionTypes["DATI_PERSONALI_MODIFICA_DATI_AGGIUNTIVI_START"] = "DATI_PERSONALI_MODIFICA_DATI_AGGIUNTIVI_START";
  EReduxActionTypes["DATI_PERSONALI_MODIFICA_DATI_AGGIUNTIVI_SUCCESS"] = "DATI_PERSONALI_MODIFICA_DATI_AGGIUNTIVI_SUCCESS";
  EReduxActionTypes["DATI_PERSONALI_SET_STEP"] = "DATI_PERSONALI_SET_STEP";
  EReduxActionTypes["DATI_PERSONALI_RESET"] = "DATI_PERSONALI_RESET";
  EReduxActionTypes["DATI_PERSONALI_SET_RESIDENZA"] = "DATI_PERSONALI_SET_RESIDENZA";
  EReduxActionTypes["DATI_PERSONALI_SET_PROVINCIA"] = "DATI_PERSONALI_SET_PROVINCIA";
  EReduxActionTypes["DATI_PERSONALI_SET_COMUNE"] = "DATI_PERSONALI_SET_COMUNE";
  EReduxActionTypes["DATI_PERSONALI_SET_LOCALITA"] = "DATI_PERSONALI_SET_LOCALITA";
  EReduxActionTypes["DATI_PERSONALI_SET_CAP"] = "DATI_PERSONALI_SET_CAP";
  EReduxActionTypes["DATI_PERSONALI_SET_VIA_PLAZA"] = "DATI_PERSONALI_SET_VIA_PLAZA";
  EReduxActionTypes["DATI_PERSONALI_SET_INDIRIZIO"] = "DATI_PERSONALI_SET_INDIRIZIO";
  EReduxActionTypes["DATI_PERSONALI_SET_NUMERO_CIVICIO"] = "DATI_PERSONALI_SET_NUMERO_CIVICIO";
  EReduxActionTypes["DATI_PERSONALI_SET_SET_FORM_SUBMITTED"] = "DATI_PERSONALI_SET_SET_FORM_SUBMITTED";
  EReduxActionTypes["DATI_PERSONALI_IMPOSTA_CONTATTI_CONTROLLO_START"] = "DATI_PERSONALI_IMPOSTA_CONTATTI_CONTROLLO_START";
  EReduxActionTypes["DATI_PERSONALI_IMPOSTA_CONTATTI_CONTROLLO_SUCCESS"] = "DATI_PERSONALI_IMPOSTA_CONTATTI_CONTROLLO_SUCCESS";
  EReduxActionTypes["DATI_PERSONALI_RESET_CONTATTI_CONTROLLO"] = "DATI_PERSONALI_RESET_CONTATTI_CONTROLLO";
  EReduxActionTypes["DATI_PERSONALI_IMPOSTA_CONTATTI_GET_INFO_DISPOSITIVA_START"] = "DATI_PERSONALI_IMPOSTA_CONTATTI_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["DATI_PERSONALI_IMPOSTA_CONTATTI_GET_INFO_DISPOSITIVA_SUCCESS"] = "DATI_PERSONALI_IMPOSTA_CONTATTI_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["DATI_PERSONALI_RESET_CONTATTI_GET_INFO_DISPOSITIVA"] = "DATI_PERSONALI_RESET_CONTATTI_GET_INFO_DISPOSITIVA";
  EReduxActionTypes["DATI_PERSONALI_IMPOSTA_CONTATTI_AUTORIZZA_START"] = "DATI_PERSONALI_IMPOSTA_CONTATTI_AUTORIZZA_START";
  EReduxActionTypes["DATI_PERSONALI_IMPOSTA_CONTATTI_AUTORIZZA_SUCCESS"] = "DATI_PERSONALI_IMPOSTA_CONTATTI_AUTORIZZA_SUCCESS";
  EReduxActionTypes["DATI_PERSONALI_RESET_CONTATTI_AUTORIZZA"] = "DATI_PERSONALI_RESET_CONTATTI_AUTORIZZA";
  EReduxActionTypes["DATI_PERSONALI_SET_EMAIL"] = "DATI_PERSONALI_SET_EMAIL";
  EReduxActionTypes["DATI_PERSONALI_SET_CELLULARE"] = "DATI_PERSONALI_SET_CELLULARE";
  EReduxActionTypes["DATI_PERSONALI_SET_FORM_SUBMITTED_MODIFICA_CONTATTI"] = "DATI_PERSONALI_SET_FORM_SUBMITTED_MODIFICA_CONTATTI";
  // MY BANK
  EReduxActionTypes["MY_BANK_AUTORIZZA_START"] = "MY_BANK_AUTORIZZA_START";
  EReduxActionTypes["MY_BANK_AUTORIZZA_SUCCESS"] = "MY_BANK_AUTORIZZA_SUCCESS";
  EReduxActionTypes["MY_BANK_CONTROLLO_START"] = "MY_BANK_CONTROLLO_START";
  EReduxActionTypes["MY_BANK_CONTROLLO_SUCCESS"] = " MY_BANK_CONTROLLO_SUCCESS";
  EReduxActionTypes["MY_BANK_GET_INFO_AUTENTICAZIONE_START"] = "MY_BANK_GET_INFO_AUTENTICAZIONE_START";
  EReduxActionTypes["MY_BANK_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "MY_BANK_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxActionTypes["MY_BANK_SET_STEP"] = "MY_BANK_SET_STEP";
  EReduxActionTypes["MY_BANK_RESET"] = "MY_BANK_RESET";
  EReduxActionTypes["MY_BANK_ANNULLA_START"] = "MY_BANK_ANNULLA_START";
  EReduxActionTypes["MY_BANK_ANNULLA_SUCCESS"] = "MY_BANK_ANNULLA_SUCCESS";
  EReduxActionTypes["MY_BANK_GET_PARAMETRI_START"] = "MY_BANK_GET_PARAMETRI_START";
  EReduxActionTypes["MY_BANK_GET_PARAMETRI_SUCCESS"] = "MY_BANK_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["MY_BANK_RAPPORTO_SELEZIONATO"] = "MY_BANK_RAPPORTO_SELEZIONATO ";
  EReduxActionTypes["MY_BANK_SET_My_BANK_TOKEN"] = "MY_BANK_SET_My_BANK_TOKEN ";
  // BILANCIO
  EReduxActionTypes["BILANCIO_GET_BILANCIO_START"] = "BILANCIO_GET_BILANCIO_START";
  EReduxActionTypes["BILANCIO_GET_BILANCIO_SUCCESS"] = "BILANCIO_GET_BILANCIO_SUCCESS";
  EReduxActionTypes["BILANCIO_GET_BILANCIO_ERROR"] = "BILANCIO_GET_BILANCIO_ERROR";
  EReduxActionTypes["BILANCIO_RAPPORTO_SELEZIONATO"] = "BILANCIO_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["BILANCIO_DATA_INIZIALE"] = "BILANCIO_DATA_INIZIALE";
  EReduxActionTypes["BILANCIO_DATA_FINALE"] = "BILANCIO_DATA_FINALE";
  EReduxActionTypes["BILANCIO_TUTTI_RAPPORTI_SELEZIONATI"] = "BILANCIO_TUTTI_RAPPORTI_SELEZIONATI";
  EReduxActionTypes["BILANCIO_RESET_FILTRI"] = "BILANCIO_RESET_FILTRI";
  EReduxActionTypes["BILANCIO_RESET"] = "BILANCIO_RESET";
  EReduxActionTypes["BILANCIO_EXPORT_PDF_START_ACTION"] = "BILANCIO_EXPORT_PDF_START_ACTION";
  EReduxActionTypes["BILANCIO_EXPORT_PDF_SUCCESS_ACTION"] = "BILANCIO_EXPORT_PDF_SUCCESS_ACTION";
  // PATRIMONIO
  EReduxActionTypes["PATRIMONIO_GET_RIEPILOGO_START"] = "PATRIMONIO_GET_RIEPILOGO_START";
  EReduxActionTypes["PATRIMONIO_GET_RIEPILOGO_SUCCESS"] = "PATRIMONIO_GET_RIEPILOGO_SUCCESS";
  EReduxActionTypes["PATRIMONIO_RIEPILOGO_PDF_START"] = "PATRIMONIO_RIEPILOGO_PDF_START";
  EReduxActionTypes["PATRIMONIO_RIEPILOGO_PDF_SUCCESS"] = "PATRIMONIO_RIEPILOGO_PDF_SUCCESS";
  EReduxActionTypes["PATRIMONIO_RESET"] = "PATRIMONIO_RESET";
  // BANNER
  EReduxActionTypes["BANNER_GET_BANNER_START"] = "BANNER_GET_BANNER_START";
  EReduxActionTypes["BANNER_GET_BANNER_SUCCESS"] = "BANNER_GET_BANNER_SUCCESS";
  EReduxActionTypes["BANNER_SET_BANNER_SELEZIONATO"] = "BANNER_SET_BANNER_SELEZIONATO";
  EReduxActionTypes["BANNER_SET_BANNER_IDS"] = "BANNER_SET_BANNER_IDS";
  EReduxActionTypes["BANNER_RESET_BANNER_LIST"] = "BANNER_RESET_BANNER_LIST";
  EReduxActionTypes["BANNER_SET_BANNER_NASCOSTO"] = "BANNER_SET_BANNER_NASCOSTO";
  EReduxActionTypes["BANNER_SET_VISIBILITA"] = "BANNER_SET_VISIBILITA";
  EReduxActionTypes["BANNER_SET_BANNER_CON_IMMAGINE"] = "BANNER_SET_BANNER_CON_IMMAGINE";
  EReduxActionTypes["BANNER_SET_BANNER_SENZA_IMMAGINE"] = "BANNER_SET_BANNER_SENZA_IMMAGINE";
  EReduxActionTypes["BANNER_SET_BANNER_CON_IMMAGINE_SELEZIONATO"] = "BANNER_SET_BANNER_CON_IMMAGINE_SELEZIONATO";
  EReduxActionTypes["BANNER_SET_BANNER_SENZA_IMMAGINE_SELEZIONATO"] = "BANNER_SET_BANNER_SENZA_IMMAGINE_SELEZIONATO";
  // NOTIFICEPUSH
  EReduxActionTypes["NOTIFICE_PUSH_SEND_NET_INSURANCE_START"] = "NOTIFICE_PUSH_SEND_NET_INSURANCE_START";
  EReduxActionTypes["NOTIFICE_PUSH_SEND_NET_INSURANCE_SUCCESS"] = "NOTIFICE_PUSH_SEND_NET_INSURANCE_SUCCESS";
  EReduxActionTypes["NOTIFICHE_PUSH_SET_SHOW_MODAL"] = "NOTIFICHE_PUSH_SET_SHOW_MODAL";
  // FAQ
  EReduxActionTypes["FAQ_GET_START"] = "FAQ_GET_START";
  EReduxActionTypes["FAQ_GET_SUCCESS"] = "FAQ_GET_SUCCESS";
  EReduxActionTypes["FAQ_GET_ERROR"] = "FAQ_GET_ERROR";
  EReduxActionTypes["FAQ_SET_CATEGORIA_SEL"] = "FAQ_SET_CATEGORIA_SEL";
  EReduxActionTypes["FAQ_SCARICA_ALLEGATO_START"] = "FAQ_SCARICA_ALLEGATO_START";
  EReduxActionTypes["FAQ_SCARICA_ALLEGATO_SUCCESS"] = "FAQ_SCARICA_ALLEGATO_SUCCESS";
  EReduxActionTypes["FAQ_SCARICA_ALLEGATO_ERROR"] = "FAQ_SCARICA_ALLEGATO_ERROR";
  // STORICO ORDINI
  EReduxActionTypes["STORICO_ORDINI_GET_ORDINI_IN_SCADENZA_START"] = "STORICO_ORDINI_GET_ORDINI_IN_SCADENZA_START";
  EReduxActionTypes["STORICO_ORDINI_GET_ORDINI_IN_SCADENZA_SUCCESS"] = "STORICO_ORDINI_GET_ORDINI_IN_SCADENZA_SUCCESS";
  EReduxActionTypes["STORICO_ORDINI_SET_RAPPORTO_SELEZIONATO"] = "STORICO_ORDINI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["STORICO_ORDINI_SET_ACCORDION_FILTRI_OPENED"] = "STORICO_ORDINI_SET_ACCORDION_FILTRI_OPENED";
  EReduxActionTypes["STORICO_ORDINI_GET_PARAMETRI_TRADING_START"] = "STORICO_ORDINI_GET_PARAMETRI_TRADING_START";
  EReduxActionTypes["STORICO_ORDINI_GET_PARAMETRI_TRADING_SUCCESS"] = "STORICO_ORDINI_GET_PARAMETRI_TRADING_SUCCESS";
  EReduxActionTypes["STORICO_ORDINI_SET_DATA_DA"] = "STORICO_ORDINI_SET_DATA_DA";
  EReduxActionTypes["STORICO_ORDINI_SET_DATA_A"] = "STORICO_ORDINI_SET_DATA_A";
  EReduxActionTypes["STORICO_ORDINI_SET_STATI"] = "STORICO_ORDINI_SET_STATI";
  EReduxActionTypes["STORICO_ORDINI_SET_PERIODO"] = "STORICO_ORDINI_SET_PERIODO";
  EReduxActionTypes["STORICO_ORDINI_RESET_DETTAGLIO_ORDINE"] = "STORICO_ORDINI_RESET_DETTAGLIO_ORDINE";
  EReduxActionTypes["STORICO_ORDINI_RESET_FILTRO"] = "STORICO_ORDINI_RESET_FILTRO";
  EReduxActionTypes["STORICO_ORDINI_GET_ESEGUITI_ORDINE_START"] = "STORICO_ORDINI_GET_ESEGUITI_ORDINE_START";
  EReduxActionTypes["STORICO_ORDINI_GET_ESEGUITI_ORDINE_SUCCESS"] = "STORICO_ORDINI_GET_ESEGUITI_ORDINE_SUCCESS";
  EReduxActionTypes["STORICO_ORDINI_SET_DETTAGLIO_ORDINE"] = "STORICO_ORDINI_SET_DETTAGLIO_ORDINE";
  EReduxActionTypes["STORICO_ORDINI_ESPORTA_PDF_ELENCO_START"] = "STORICO_ORDINI_ESPORTA_PDF_ELENCO_START";
  EReduxActionTypes["STORICO_ORDINI_ESPORTA_PDF_ELENCO_SUCCESS"] = "STORICO_ORDINI_ESPORTA_PDF_ELENCO_SUCCESS";
  EReduxActionTypes["STORICO_ORDINI_ESPORTA_PDF_DETTAGLIO_START"] = "STORICO_ORDINI_ESPORTA_PDF_DETTAGLIO_START";
  EReduxActionTypes["STORICO_ORDINI_ESPORTA_PDF_DETTAGLIO_SUCCESS"] = "STORICO_ORDINI_ESPORTA_PDF_DETTAGLIO_SUCCESS";
  EReduxActionTypes["STORICO_ORDINI_RESET_ESEGUITI_ORDINE"] = "STORICO_ORDINI_RESET_ESEGUITI_ORDINE";
  EReduxActionTypes["STORICO_ORDINI_SET_OFFSET"] = "STORICO_ORDINI_SET_OFFSET";
  // REVOCA ORDINE
  EReduxActionTypes["REVOCA_ORDINE_CONTROLLO_REVOCA_START"] = "REVOCA_ORDINE_CONTROLLO_REVOCA_START";
  EReduxActionTypes["REVOCA_ORDINE_CONTROLLO_REVOCA_SUCCESS"] = "REVOCA_ORDINE_CONTROLLO_REVOCA_SUCCESS";
  EReduxActionTypes["REVOCA_ORDINE_GET_INFO_REVOCA_START"] = "REVOCA_ORDINE_GET_INFO_REVOCA_START";
  EReduxActionTypes["REVOCA_ORDINE_GET_INFO_REVOCA_SUCCESS"] = "REVOCA_ORDINE_GET_INFO_REVOCA_SUCCESS";
  EReduxActionTypes["REVOCA_ORDINE_AUTORIZZA_REVOCA_START"] = "REVOCA_ORDINE_AUTORIZZA_REVOCA_START";
  EReduxActionTypes["REVOCA_ORDINE_AUTORIZZA_REVOCA_SUCCESS"] = "REVOCA_ORDINE_AUTORIZZA_REVOCA_SUCCESS";
  EReduxActionTypes["REVOCA_ORDINE_SET_STEP_REVOCA"] = "REVOCA_ORDINE_SET_STEP_REVOCA";
  EReduxActionTypes["REVOCA_ORDINE_RESET_FORM_REVOCA"] = "REVOCA_ORDINE_RESET_FORM_REVOCA";
  // FILIALE VIRTUALE
  EReduxActionTypes["FILIALE_VIRTUALE_GET_PARAMETRI_START"] = "FILIALE_VIRTUALE_GET_PARAMETRI_START";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_PARAMETRI_SUCCESS"] = "FILIALE_VIRTUALE_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_PARAMETRI_ERROR"] = "FILIALE_VIRTUALE_GET_PARAMETRI_ERROR";
  EReduxActionTypes["FILIALE_VIRTUALE_RESET_FILTRI"] = "FILIALE_VIRTUALE_RESET_FILTRI";
  EReduxActionTypes["FILIALE_VIRTUALE_DESCRIZIONE"] = "FILIALE_VIRTUALE_DESCRIZIONE";
  EReduxActionTypes["FILIALE_VIRTUALE_DATA_RICHIESTA_DA"] = "FILIALE_VIRTUALE_DATA_RICHIESTA_DA";
  EReduxActionTypes["FILIALE_VIRTUALE_DATA_RICHIESTA_A"] = "FILIALE_VIRTUALE_DATA_RICHIESTA_A";
  EReduxActionTypes["FILIALE_VIRTUALE_TEMPLATE_SELEZIONATO"] = "FILIALE_VIRTUALE_TEMPLATE_SELEZIONATO";
  EReduxActionTypes["FILIALE_VIRTUALE_RESET_FORM"] = "FILIALE_VIRTUALE_RESET_FORM";
  EReduxActionTypes["FILIALE_VIRTUALE_STEP"] = "FILIALE_VIRTUALE_STEP";
  EReduxActionTypes["FILIALE_VIRTUALE_RESET_STEP"] = "FILIALE_VIRTUALE_RESET_STEP";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_ELENCO_TEMPLATE_START"] = "FILIALE_VIRTUALE_GET_ELENCO_TEMPLATE_START";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_ELENCO_TEMPLATE_SUCCESS"] = "FILIALE_VIRTUALE_GET_ELENCO_TEMPLATE_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_ELENCO_TEMPLATE_ERROR"] = "FILIALE_VIRTUALE_GET_ELENCO_TEMPLATE_ERROR";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_INFO_AUTENTICAZIONE_START"] = "FILIALE_VIRTUALE_GET_INFO_AUTENTICAZIONE_START";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_INFO_AUTENTICAZIONE_SUCCESS"] = "FILIALE_VIRTUALE_GET_INFO_AUTENTICAZIONE_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_RESET_INFO_AUTENTICAZIONE"] = "FILIALE_VIRTUALE_RESET_INFO_AUTENTICAZIONE";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_INFO_AUTENTICAZIONE_ERROR"] = "FILIALE_VIRTUALE_GET_INFO_AUTENTICAZIONE_ERROR";
  EReduxActionTypes["FILIALE_VIRTUALE_AUTORIZZA_START"] = "FILIALE_VIRTUALE_AUTORIZZA_START";
  EReduxActionTypes["FILIALE_VIRTUALE_AUTORIZZA_SUCCESS"] = "FILIALE_VIRTUALE_AUTORIZZA_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_AUTORIZZA_ERROR"] = "FILIALE_VIRTUALE_AUTORIZZA_ERROR";
  EReduxActionTypes["FILIALE_VIRTUALE_RESET_RIEPILOGO"] = "FILIALE_VIRTUALE_RESET_RIEPILOGO";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_ELENCO_RICHIESTE_START"] = "FILIALE_VIRTUALE_GET_ELENCO_RICHIESTE_START";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_ELENCO_RICHIESTE_SUCCESS"] = "FILIALE_VIRTUALE_GET_ELENCO_RICHIESTE_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_ELENCO_RICHIESTE_ERROR"] = "FILIALE_VIRTUALE_GET_ELENCO_RICHIESTE_ERROR";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_CONTATTI_START"] = "FILIALE_VIRTUALE_GET_CONTATTI_START";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_CONTATTI_SUCCESS"] = "FILIALE_VIRTUALE_GET_CONTATTI_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_CONTATTI_ERROR"] = "FILIALE_VIRTUALE_GET_CONTATTI_ERROR";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_RAPPORTI_START"] = "FILIALE_VIRTUALE_GET_RAPPORTI_START";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_RAPPORTI_SUCCESS"] = "FILIALE_VIRTUALE_GET_RAPPORTI_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_RAPPORTI_ERROR"] = "FILIALE_VIRTUALE_GET_RAPPORTI_ERROR";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_DETTAGLIO_START"] = "FILIALE_VIRTUALE_GET_DETTAGLIO_START";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_DETTAGLIO_SUCCESS"] = "FILIALE_VIRTUALE_GET_DETTAGLIO_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_DETTAGLIO_ERROR"] = "FILIALE_VIRTUALE_GET_DETTAGLIO_ERROR";
  EReduxActionTypes["FILIALE_VIRTUALE_RESET_DETTAGLIO"] = "FILIALE_VIRTUALE_RESET_DETTAGLIO";
  EReduxActionTypes["FILIALE_VIRTUALE_SALVA_RICHIESTA_START"] = "FILIALE_VIRTUALE_SALVA_RICHIESTA_START";
  EReduxActionTypes["FILIALE_VIRTUALE_SALVA_RICHIESTA_SUCCESS"] = "FILIALE_VIRTUALE_SALVA_RICHIESTA_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_SALVA_RICHIESTA_ERROR"] = "FILIALE_VIRTUALE_SALVA_RICHIESTA_ERROR";
  EReduxActionTypes["FILIALE_VIRTUALE_ELIMINA_RICHIESTA_START"] = "FILIALE_VIRTUALE_ELIMINA_RICHIESTA_START";
  EReduxActionTypes["FILIALE_VIRTUALE_ELIMINA_RICHIESTA_SUCCESS"] = "FILIALE_VIRTUALE_ELIMINA_RICHIESTA_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_ELIMINA_RICHIESTA_ERROR"] = "FILIALE_VIRTUALE_ELIMINA_RICHIESTA_ERROR";
  EReduxActionTypes["FILIALE_VIRTUALE_SET_VALUE_TEMPLATE"] = "FILIALE_VIRTUALE_SET_VALUE_TEMPLATE";
  EReduxActionTypes["FILIALE_VIRTUALE_SET_TIPO"] = "FILIALE_VIRTUALE_SET_TIPO";
  EReduxActionTypes["FILIALE_VIRTUALE_SET_FORMSUBMITTED"] = "FILIALE_VIRTUALE_SET_FORMSUBMITTED";
  EReduxActionTypes["FILIALE_VIRTUALE_CONDIVIDI_PDF_START"] = "FILIALE_VIRTUALE_CONDIVIDI_PDF_START";
  EReduxActionTypes["FILIALE_VIRTUALE_CONDIVIDI_PDF_SUCCESS"] = "FILIALE_VIRTUALE_CONDIVIDI_PDF_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_CONDIVIDI_PDF_ERROR"] = "FILIALE_VIRTUALE_CONDIVIDI_PDF_ERROR";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_CATALOGO_TEMPLATE_START"] = "FILIALE_VIRTUALE_GET_CATALOGO_TEMPLATE_START";
  EReduxActionTypes["FILIALE_VIRTUALE_GET_CATALOGO_TEMPLATE_SUCCESS"] = "FILIALE_VIRTUALE_GET_CATALOGO_TEMPLATE_SUCCESS";
  EReduxActionTypes["FILIALE_VIRTUALE_RESET_ESITO"] = "FILIALE_VIRTUALE_RESET_ESITO";
  // PRESTITO IDEA
  EReduxActionTypes["PRESTITO_IDEA_GET_DATI_UTENTE_START"] = "PRESTITO_IDEA_GET_DATI_UTENTE_START";
  EReduxActionTypes["PRESTITO_IDEA_GET_DATI_UTENTE_SUCCESS"] = "PRESTITO_IDEA_GET_DATI_UTENTE_SUCCESS";
  EReduxActionTypes["PRESTITO_IDEA_SET_RAPPORTO_DATI_UTENTE"] = "PRESTITO_IDEA_SET_RAPPORTO_DATI_UTENTE";
  EReduxActionTypes["PRESTITO_IDEA_GET_URL_START"] = "PRESTITO_IDEA_GET_URL_START";
  EReduxActionTypes["PRESTITO_IDEA_GET_URL_SUCCESS"] = "PRESTITO_IDEA_GET_URL_SUCCESS";
  EReduxActionTypes["PRESTITO_IDEA_RESET"] = "PRESTITO_IDEA_RESET";
  EReduxActionTypes["PRESTITO_IDEA_GET_PARAMETRI_START"] = "PRESTITO_IDEA_GET_PARAMETRI_START";
  EReduxActionTypes["PRESTITO_IDEA_GET_PARAMETRI_SUCCESS"] = "PRESTITO_IDEA_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["PRESTITO_IDEA_SET_RAPPORTO_SELEZIONATO_CAROUSEL"] = "PRESTITO_IDEA_SET_RAPPORTO_SELEZIONATO_CAROUSEL";
  EReduxActionTypes["PRESTITO_IDEA_GET_DETTAGLIO_INFORMATIVA_START"] = "PRESTITO_IDEA_GET_DETTAGLIO_INFORMATIVA_START";
  EReduxActionTypes["PRESTITO_IDEA_GET_DETTAGLIO_INFORMATIVA_SUCCESS"] = "PRESTITO_IDEA_GET_DETTAGLIO_INFORMATIVA_SUCCESS";
  // FIDO ISI DISPO
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_RAPPORTO_SELEZIONATO"] = "RICHIEDI_FIDO_ISI_DISPO_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_MASSIMALE_TRANSAZIONE"] = "RICHIEDI_FIDO_ISI_DISPO_SET_MASSIMALE_TRANSAZIONE";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_STATO_CIVILE"] = "RICHIEDI_FIDO_ISI_DISPO_SET_STATO_CIVILE";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_NUMERO_ADULTI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_NUMERO_ADULTI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_NUMERO_FIGLI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_NUMERO_FIGLI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_SITUAZIONE_ABITATIVA"] = "RICHIEDI_FIDO_ISI_DISPO_SET_SITUAZIONE_ABITATIVA";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_PROFESSIONALE"] = "RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_PROFESSIONALE";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_DETTAGLIO_PROFESSIONE"] = "RICHIEDI_FIDO_ISI_DISPO_SET_DETTAGLIO_PROFESSIONE";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_DATORE_DI_LAVORO"] = "RICHIEDI_FIDO_ISI_DISPO_SET_DATORE_DI_LAVORO";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_RAPPORTO_DI_LAVORO"] = "RICHIEDI_FIDO_ISI_DISPO_SET_RAPPORTO_DI_LAVORO";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ANNO_DI_INIZIO"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ANNO_DI_INIZIO";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ACCREDITO_DOMICILIATO_PRESSO_BANCA"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ACCREDITO_DOMICILIATO_PRESSO_BANCA";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_REDDITO_NETTO_MENSILE"] = "RICHIEDI_FIDO_ISI_DISPO_SET_REDDITO_NETTO_MENSILE";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ALTRI_REDDITI_MENSILI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ALTRI_REDDITI_MENSILI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ONERI_MENSILI_CREDITI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ONERI_MENSILI_CREDITI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_IMPEGNI_MENSILI_AFFITTI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_IMPEGNI_MENSILI_AFFITTI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ALTRI_IMPEGNI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ALTRI_IMPEGNI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_CARTE_DI_CREDITO_POSSEDUTE"] = "RICHIEDI_FIDO_ISI_DISPO_SET_CARTE_DI_CREDITO_POSSEDUTE";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_FORM_SUBMITTED"] = "RICHIEDI_FIDO_ISI_DISPO_SET_FORM_SUBMITTED";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_STEP"] = "RICHIEDI_FIDO_ISI_DISPO_SET_STEP";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_CONTROLLO_START"] = "RICHIEDI_FIDO_ISI_DISPO_CONTROLLO_START";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_CONTROLLO_SUCCESS"] = "RICHIEDI_FIDO_ISI_DISPO_CONTROLLO_SUCCESS";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_GET_PARAMETRI_START"] = "RICHIEDI_FIDO_ISI_DISPO_GET_PARAMETRI_START";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_GET_PARAMETRI_SUCCESS"] = "RICHIEDI_FIDO_ISI_DISPO_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_GET_INFO_DISPOSITIVA_START"] = "RICHIEDI_FIDO_ISI_DISPO_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_GET_INFO_DISPOSITIVA_SUCCESS"] = "RICHIEDI_FIDO_ISI_DISPO_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_RESET"] = "RICHIEDI_FIDO_ISI_DISPO_RESET";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_AUTORIZZA_START"] = "RICHIEDI_FIDO_ISI_DISPO_AUTORIZZA_START";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_AUTORIZZA_SUCCESS"] = "RICHIEDI_FIDO_ISI_DISPO_AUTORIZZA_SUCCESS";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_RESET_RIEPILOGO"] = "RICHIEDI_FIDO_ISI_DISPO_RESET_RIEPILOGO";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_RESET_ESITO"] = "RICHIEDI_FIDO_ISI_DISPO_RESET_ESITO";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_INATTIVI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_INATTIVI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_DIPENDENTI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_DIPENDENTI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_MILITARI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_MILITARI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_DOCENTI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_DOCENTI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_LIBERI_PROFESSIONISTI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_LIBERI_PROFESSIONISTI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_AUTONOMI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_CATEGORIA_AUTONOMI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_STATO_CIVILE"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_STATO_CIVILE";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_SITUAZIONE_ABITATIVA"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_SITUAZIONE_ABITATIVA";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_PROFESSIONE"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_PROFESSIONE";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_INATTIVI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_INATTIVI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_DIPENDENTI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_DIPENDENTI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_MILITARI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_MILITARI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_DOCENTI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_DOCENTI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_LIBERI_PROFESSIONISTI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_LIBERI_PROFESSIONISTI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_AUTONOMI"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CATEGORIA_AUTONOMI";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_DETTAGLIO_PROFESSIONE"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_DETTAGLIO_PROFESSIONE";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_RAPPORTO_DI_LAVORO"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_RAPPORTO_DI_LAVORO";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CARTE_POSSEDUTE"] = "RICHIEDI_FIDO_ISI_DISPO_SET_ARRAY_CARTE_POSSEDUTE";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_ESPORTA_PDF_RICHIESTA_FIDO_START"] = "RICHIEDI_FIDO_ISI_DISPO_ESPORTA_PDF_RICHIESTA_FIDO_START";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_ESPORTA_PDF_RICHIESTA_FIDO_SUCCESS"] = "RICHIEDI_FIDO_ISI_DISPO_ESPORTA_PDF_RICHIESTA_FIDO_SUCCESS";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_PRESA_VISIONE_INFORMATIVA_ENABLED"] = "RICHIEDI_FIDO_ISI_DISPO_SET_PRESA_VISIONE_INFORMATIVA_ENABLED";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_PRESA_VISIONE_INFORMATIVA"] = "RICHIEDI_FIDO_ISI_DISPO_SET_PRESA_VISIONE_INFORMATIVA";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_DICHIARAZIONE_SCOPO"] = "RICHIEDI_FIDO_ISI_DISPO_SET_DICHIARAZIONE_SCOPO";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_MASSIMALE_MAX"] = "RICHIEDI_FIDO_ISI_DISPO_SET_MASSIMALE_MAX";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_MASSIMALE_MIN"] = "RICHIEDI_FIDO_ISI_DISPO_SET_MASSIMALE_MIN";
  EReduxActionTypes["NEOSURANCE_GET_POLIZZE_START"] = "NEOSURANCE_GET_POLIZZE_START";
  EReduxActionTypes["NEOSURANCE_GET_POLIZZE_SUCCESS"] = "NEOSURANCE_GET_POLIZZE_SUCCESS";
  EReduxActionTypes["NEOSURANCE_GET_POLIZZE_ERROR"] = "NEOSURANCE_GET_POLIZZE_ERROR";
  EReduxActionTypes["NEOSURANCE_SET_RAPPORTO_SELEZIONATO"] = "NEOSURANCE_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["RICHIEDI_FIDO_ISI_DISPO_SET_STEP_MASSIMALE"] = "RICHIEDI_FIDO_ISI_DISPO_SET_STEP_MASSIMALE";
  // TRIBUTI LOCALI
  EReduxActionTypes["TRIBUTI_LOCALI_GET_PARAMETRI_START"] = "TRIBUTI_LOCALI_GET_PARAMETRI_START";
  EReduxActionTypes["TRIBUTI_LOCALI_GET_PARAMETRI_SUCCESS"] = "TRIBUTI_LOCALI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_SCADENZA_START"] = "TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_SCADENZA_START";
  EReduxActionTypes["TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_SCADENZA_SUCCESS"] = "TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_SCADENZA_SUCCESS";
  EReduxActionTypes["TRIBUTI_LOCALI_RESET_ELENCO_TRIBUTI_SCADENZA"] = "TRIBUTI_LOCALI_RESET_ELENCO_TRIBUTI_SCADENZA";
  EReduxActionTypes["TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_CONTO_TERZI_START"] = "TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_CONTO_TERZI_START";
  EReduxActionTypes["TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_CONTO_TERZI_SUCCESS"] = "TRIBUTI_LOCALI_GET_ELENCO_TRIBUTI_CONTO_TERZI_SUCCESS";
  EReduxActionTypes["TRIBUTI_LOCALI_RESET_ELENCO_TRIBUTI_CONTO_TERZI"] = "TRIBUTI_LOCALI_RESET_ELENCO_TRIBUTI_CONTO_TERZI";
  EReduxActionTypes["TRIBUTI_LOCALI_SET_DETTAGLIO"] = "TRIBUTI_LOCALI_SET_DETTAGLIO";
  EReduxActionTypes["TRIBUTI_LOCALI_RESET_DETTAGLIO"] = "TRIBUTI_LOCALI_RESET_DETTAGLIO";
  EReduxActionTypes["TRIBUTI_LOCALI_SET_CODICE_FISCALE_CONTRIBUENTE"] = "TRIBUTI_LOCALI_SET_CODICE_FISCALE_CONTRIBUENTE";
  EReduxActionTypes["TRIBUTI_LOCALI_SET_CODICE_PAGAMENTO"] = "TRIBUTI_LOCALI_SET_CODICE_PAGAMENTO";
  EReduxActionTypes["TRIBUTI_LOCALI_SET_FILTRI_SUBMITTED"] = "TRIBUTI_LOCALI_SET_FILTRI_SUBMITTED";
  EReduxActionTypes["TRIBUTI_LOCALI_RESET_FILTRI"] = "TRIBUTI_LOCALI_RESET_FILTRI";
  EReduxActionTypes["TRIBUTI_LOCALI_SET_FORM_STEP"] = "TRIBUTI_LOCALI_SET_FORM_STEP";
  EReduxActionTypes["TRIBUTI_LOCALI_RAPPORTO_SELEZIONATO"] = "TRIBUTI_LOCALI_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["TRIBUTI_LOCALI_PRESA_VISIONE"] = "TRIBUTI_LOCALI_PRESA_VISIONE";
  EReduxActionTypes["TRIBUTI_LOCALI_RESET_FORM"] = "TRIBUTI_LOCALI_RESET_FORM";
  EReduxActionTypes["TRIBUTI_LOCALI_GET_NOTIFICA_TRIBUTI_START"] = "TRIBUTI_LOCALI_GET_NOTIFICA_TRIBUTI_START";
  EReduxActionTypes["TRIBUTI_LOCALI_GET_NOTIFICA_TRIBUTI_SUCCESS"] = "TRIBUTI_LOCALI_GET_NOTIFICA_TRIBUTI_SUCCESS";
  EReduxActionTypes["TRIBUTI_LOCALI_CONTROLLO_PAGAMENTO_START"] = "TRIBUTI_LOCALI_CONTROLLO_PAGAMENTO_START";
  EReduxActionTypes["TRIBUTI_LOCALI_CONTROLLO_PAGAMENTO_SUCCESS"] = "TRIBUTI_LOCALI_CONTROLLO_PAGAMENTO_SUCCESS";
  EReduxActionTypes["TRIBUTI_LOCALI_RESET_RIEPILOGO"] = "TRIBUTI_LOCALI_RESET_RIEPILOGO";
  EReduxActionTypes["TRIBUTI_LOCALI_INFO_AUTENTICAZIONE_PAGAMENTO_START"] = "TRIBUTI_LOCALI_INFO_AUTENTICAZIONE_PAGAMENTO_START";
  EReduxActionTypes["TRIBUTI_LOCALI_INFO_AUTENTICAZIONE_PAGAMENTO_SUCCESS"] = "TRIBUTI_LOCALI_INFO_AUTENTICAZIONE_PAGAMENTO_SUCCESS";
  EReduxActionTypes["TRIBUTI_LOCALI_AUTORIZZA_PAGAMENTO_START"] = "TRIBUTI_LOCALI_AUTORIZZA_PAGAMENTO_START";
  EReduxActionTypes["TRIBUTI_LOCALI_AUTORIZZA_PAGAMENTO_SUCCESS"] = "TRIBUTI_LOCALI_AUTORIZZA_PAGAMENTO_SUCCESS";
  EReduxActionTypes["TRIBUTI_LOCALI_RESET_ESITO"] = "TRIBUTI_LOCALI_RESET_ESITO";
  EReduxActionTypes["TRIBUTI_LOCALI_RESET_NOTIFICA_TRIBUTI"] = "TRIBUTI_LOCALI_RESET_NOTIFICA_TRIBUTI";
  // RENDIMENTI
  EReduxActionTypes["RENDIMENTI_RESET_FILTRI"] = "RENDIMENTI_RESET_FILTRI";
  EReduxActionTypes["RENDIMENTI_DATA_DA"] = "RENDIMENTI_DATA_DA";
  EReduxActionTypes["RENDIMENTI_DATA_A"] = "RENDIMENTI_DATA_A";
  EReduxActionTypes["RENDIMENTI_PERIODO"] = "RENDIMENTI_PERIODO";
  EReduxActionTypes["RENDIMENTI_INCLUDI_POSIZIONI_CHIUSE"] = "RENDIMENTI_INCLUDI_POSIZIONI_CHIUSE";
  EReduxActionTypes["RENDIMENTI_GET_RAPPORTI_START"] = "RENDIMENTI_GET_RAPPORTI_START";
  EReduxActionTypes["RENDIMENTI_GET_RAPPORTI_SUCCESS"] = "RENDIMENTI_GET_RAPPORTI_SUCCESS";
  EReduxActionTypes["RENDIMENTI_GET_ACCOUNT_START"] = "RENDIMENTI_GET_ACCOUNT_START";
  EReduxActionTypes["RENDIMENTI_GET_ACCOUNT_SUCCESS"] = "RENDIMENTI_GET_ACCOUNT_SUCCESS";
  EReduxActionTypes["RENDIMENTI_SET_RAPPORTO_SELEZIONATO"] = "RENDIMENTI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["RENDIMENTI_SET_TUTTI_RAPPORTI"] = "RENDIMENTI_SET_TUTTI_RAPPORTI";
  EReduxActionTypes["RENDIMENTI_SET_SELECTED_DEPOSITO"] = "RENDIMENTI_SET_SELECTED_DEPOSITO";
  EReduxActionTypes["RENDIMENTI_SET_ACCORDION_FILTRI_OPENED"] = "RENDIMENTI_SET_ACCORDION_FILTRI_OPENED";
  EReduxActionTypes["RENDIMENTI_STAMPA_ELENCO_RENDIMENTI_START"] = "RENDIMENTI_STAMPA_ELENCO_RENDIMENTI_START";
  EReduxActionTypes["RENDIMENTI_STAMPA_ELENCO_RENDIMENTI_SUCCESS"] = "RENDIMENTI_STAMPA_ELENCO_RENDIMENTI_SUCCESS";
  EReduxActionTypes["RENDIMENTI_RESET"] = "RENDIMENTI_RESET";
  // RICERCA FILIALE ATM
  EReduxActionTypes["RICERCA_FILIALE_ATM_GET_LISTA_START"] = "RICERCA_FILIALE_ATM_GET_LISTA_START";
  EReduxActionTypes["RICERCA_FILIALE_ATM_GET_LISTA_SUCCESS"] = "RICERCA_FILIALE_ATM_GET_LISTA_SUCCESS";
  EReduxActionTypes["RICERCA_FILIALE_ATM_GET_LISTA_BY_RADIUS_START"] = "RICERCA_FILIALE_ATM_GET_LISTA_BY_RADIUS_START";
  EReduxActionTypes["RICERCA_FILIALE_ATM_GET_LISTA_BY_RADIUS_SUCCESS"] = "RICERCA_FILIALE_ATM_GET_LISTA_BY_RADIUS_SUCCESS";
  EReduxActionTypes["RICERCA_FILIALE_GET_TEXT_SEARCH_START"] = "RICERCA_FILIALE_GET_TEXT_SEARCH_START";
  EReduxActionTypes["RICERCA_FILIALE_GET_TEXT_SEARCH_SUCCESS"] = "RICERCA_FILIALE_GET_TEXT_SEARCH_SUCCESS";
  // PORTAFOGLIO TITOLI
  EReduxActionTypes["DOSSIER_TITOLI_GET_RAPPORTI_START"] = "DOSSIER_TITOLI_GET_RAPPORTI_START";
  EReduxActionTypes["DOSSIER_TITOLI_GET_RAPPORTI_SUCCESS"] = "DOSSIER_TITOLI_GET_RAPPORTI_SUCCESS";
  EReduxActionTypes["DOSSIER_TITOLI_GET_CUSTOMER_DOSSIER_START"] = "DOSSIER_TITOLI_GET_CUSTOMER_DOSSIER_START";
  EReduxActionTypes["DOSSIER_TITOLI_GET_CUSTOMER_DOSSIER_SUCCESS"] = "DOSSIER_TITOLI_GET_CUSTOMER_DOSSIER_SUCCESS";
  EReduxActionTypes["DOSSIER_TITOLI_SET_RAPPORTO_SELEZIONATO"] = "DOSSIER_TITOLI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["DOSSIER_TITOLI_SET_TUTTI_RAPPORTI"] = "DOSSIER_TITOLI_SET_TUTTI_RAPPORTI";
  EReduxActionTypes["DOSSIER_TITOLI_SET_DEPOSITI_SELEZIONATI"] = "DOSSIER_TITOLI_SET_DEPOSITI_SELEZIONATI";
  EReduxActionTypes["DOSSIER_TITOLI_GET_GRAPHICS_START"] = "DOSSIER_TITOLI_GET_GRAPHICS_START";
  EReduxActionTypes["DOSSIER_TITOLI_GET_GRAPHICS_SUCCESS"] = "DOSSIER_TITOLI_GET_GRAPHICS_SUCCESS";
  EReduxActionTypes["DOSSIER_TITOLI_RESET"] = "DOSSIER_TITOLI_RESET";
  EReduxActionTypes["DOSSIER_TITOLI_RESET_DOSSIER"] = "DOSSIER_TITOLI_RESET_DOSSIER";
  EReduxActionTypes["DOSSIER_TITOLI_RESET_GRAPHICS"] = "DOSSIER_TITOLI_RESET_GRAPHICS";
  EReduxActionTypes["DOSSIER_TITOLI_ESPORTA_PDF_START"] = "DOSSIER_TITOLI_ESPORTA_PDF_START";
  EReduxActionTypes["DOSSIER_TITOLI_ESPORTA_PDF_SUCCESS"] = "DOSSIER_TITOLI_ESPORTA_PDF_SUCCESS";
  EReduxActionTypes["DOSSIER_TITOLI_ESPORTA_EXCEL_START"] = "DOSSIER_TITOLI_ESPORTA_EXCEL_START";
  EReduxActionTypes["DOSSIER_TITOLI_ESPORTA_EXCEL_SUCCESS"] = "DOSSIER_TITOLI_ESPORTA_EXCEL_SUCCESS";
  EReduxActionTypes["DOSSIER_TITOLI_SET_TITOLO_DA_ACQUISTARE"] = "DOSSIER_TITOLI_SET_TITOLO_DA_ACQUISTARE";
  EReduxActionTypes["DOSSIER_TITOLI_RESET_TITOLO_DA_ACQUISTARE"] = "DOSSIER_TITOLI_RESET_TITOLO_DA_ACQUISTARE";
  //CARTE RICARICABILI
  EReduxActionTypes["CARTE_RICARICABILI_GET_PARAMETRI_START"] = "CARTE_RICARICABILI_GET_PARAMETRI_START";
  EReduxActionTypes["CARTE_RICARICABILI_GET_PARAMETRI_SUCCESS"] = "CARTE_RICARICABILI_GET_PARAMETRI_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "CARTE_RICARICABILI_GET_PARAMETRI_PER_RIPETI_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_GET_PARAMETRI_ERROR"] = "CARTE_RICARICABILI_GET_PARAMETRI_ERROR";
  EReduxActionTypes["CARTE_RICARICABILI_SET_RAPPORTO_SELEZIONATO"] = "CARTE_RICARICABILI_SET_RAPPORTO_SELEZIONATO";
  EReduxActionTypes["CARTE_RICARICABILI_SET_IMPORTO"] = "CARTE_RICARICABILI_SET_IMPORTO";
  EReduxActionTypes["CARTE_RICARICABILI_PERIODO"] = "CARTE_RICARICABILI_PERIODO";
  EReduxActionTypes["CARTE_RICARICABILI_SET_DATA_ESECUZIONE"] = "CARTE_RICARICABILI_SET_ESECUZIONE";
  EReduxActionTypes["CARTE_RICARICABILI_SET_SAVE_ONE_CLICK"] = "CARTE_RICARICABILI_SET_SAVE_ONE_CLICK";
  EReduxActionTypes["CARTE_RICARICABILI_SET_ALIAS_ONE_CLICK"] = "CARTE_RICARICABILI_SET_ALIAS_ONE_CLICK";
  EReduxActionTypes["CARTE_RICARICABILI_SET_FORM_SUBMITTED"] = "CARTE_RICARICABILI_SET_FORM_SUBMITTED";
  EReduxActionTypes["CARTE_RICARICABILI_SET_STEP"] = "CARTE_RICARICABILI_SET_STEP";
  EReduxActionTypes["CARTE_RICARICABILI_CONTROLLO_START"] = "CARTE_RICARICABILI_CONTROLLO_START";
  EReduxActionTypes["CARTE_RICARICABILI_CONTROLLO_SUCCESS"] = "CARTE_RICARICABILI_CONTROLLO_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_SET_PAN"] = "CARTE_RICARICABILI_SET_PAN";
  EReduxActionTypes["CARTE_RICARICABILI_SET_TIPO_CARTA"] = "CARTE_RICARICABILI_SET_TIPO_CARTA";
  EReduxActionTypes["CARTE_RICARICABILI_GET_BOZZE_START"] = "CARTE_RICARICABILI_GET_BOZZE_START";
  EReduxActionTypes["CARTE_RICARICABILI_GET_BOZZE_SUCCESS"] = "CARTE_RICARICABILI_GET_BOZZE_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_GET_BOZZE_ERROR"] = "CARTE_RICARICABILI_GET_BOZZE_ERROR";
  EReduxActionTypes["CARTE_RICARICABILI_GET_BOZZA_START"] = "CARTE_RICARICABILI_GET_BOZZA_START";
  EReduxActionTypes["CARTE_RICARICABILI_GET_BOZZA_SUCCESS"] = "CARTE_RICARICABILI_GET_BOZZA_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_GET_BOZZA_ERROR"] = "CARTE_RICARICABILI_GET_BOZZA_ERROR";
  EReduxActionTypes["CARTE_RICARICABILI_GET_RICARICA_FROM_BOZZA"] = "CARTE_RICARICABILI_GET_RICARICA_FROM_BOZZA";
  EReduxActionTypes["CARTE_RICARICABILI_ADD_BOZZA_START"] = "CARTE_RICARICABILI_ADD_BOZZA_START";
  EReduxActionTypes["CARTE_RICARICABILI_ADD_BOZZA_SUCCESS"] = "CARTE_RICARICABILI_ADD_BOZZA_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_ADD_BOZZA_ERROR"] = "CARTE_RICARICABILI_ADD_BOZZA_ERROR";
  EReduxActionTypes["CARTE_RICARICABILI_DEL_BOZZA_START"] = "CARTE_RICARICABILI_DEL_BOZZA_START";
  EReduxActionTypes["CARTE_RICARICABILI_DEL_BOZZA_SUCCESS"] = "CARTE_RICARICABILI_DEL_BOZZA_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_DEL_BOZZA_ERROR"] = "CARTE_RICARICABILI_DEL_BOZZA_ERROR";
  EReduxActionTypes["CARTE_RICARICABILI_UPDATE_BOZZA_START"] = "CARTE_RICARICABILI_UPDATE_BOZZA_START";
  EReduxActionTypes["CARTE_RICARICABILI_UPDATE_BOZZA_SUCCESS"] = "CARTE_RICARICABILI_UPDATE_BOZZA_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_UPDATE_BOZZA_ERROR"] = "CARTE_RICARICABILI_UPDATE_BOZZA_ERROR";
  EReduxActionTypes["CARTE_RICARICABILI_GET_ONECLICK_START"] = "CARTE_RICARICABILI_GET_ONECLICK_START";
  EReduxActionTypes["CARTE_RICARICABILI_GET_ONECLICK_SUCCESS"] = "CARTE_RICARICABILI_GET_ONECLICK_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_GET_ONECLICK_ERROR"] = "CARTE_RICARICABILI_GET_ONECLICK_ERROR";
  EReduxActionTypes["CARTE_RICARICABILI_GET_INFO_DISPOSITIVA_START"] = "CARTE_RICARICABILI_GET_INFO_DISPOSITIVA_START";
  EReduxActionTypes["CARTE_RICARICABILI_GET_INFO_DISPOSITIVA_SUCCESS"] = "CARTE_RICARICABILI_GET_INFO_DISPOSITIVA_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_RESET"] = "CARTE_RICARICABILI_RESET";
  EReduxActionTypes["CARTE_RICARICABILI_RESET_BOZZE"] = "CARTE_RICARICABILI_RESET_BOZZE";
  EReduxActionTypes["CARTE_RICARICABILI_RESET_INFO_DISPOSITIVA"] = "CARTE_RICARICABILI_RESET_INFO_DISPOSITIVA";
  EReduxActionTypes["CARTE_RICARICABILI_RESET_ESITO"] = "CARTE_RICARICABILI_RESET_ESITO";
  EReduxActionTypes["CARTE_RICARICABILI_RESET_RIEPILOGO"] = "CARTE_RICARICABILI_RESET_RIEPILOGO";
  EReduxActionTypes["CARTE_RICARICABILI_AUTORIZZA_START"] = "CARTE_RICARICABILI_AUTORIZZA_START";
  EReduxActionTypes["CARTE_RICARICABILI_AUTORIZZA_SUCCESS"] = "CARTE_RICARICABILI_AUTORIZZA_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_AUTORIZZA_ERROR"] = "CARTE_RICARICABILI_AUTORIZZA_ERROR";
  EReduxActionTypes["CARTE_RICARICABILI_GET_MOVIMENTI_PARAMETRI_START"] = "CARTE_RICARICABILI_GET_MOVIMENTI_PARAMETRI_START";
  EReduxActionTypes["CARTE_RICARICABILI_GET_MOVIMENTI_PARAMETRI_SUCCESS"] = "CARTE_RICARICABILI_GET_MOVIMENTI_PARAMETRI_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_GET_LISTA_MOVIMENTI_START"] = "CARTE_RICARICABILI_GET_LISTA_MOVIMENTI_START";
  EReduxActionTypes["CARTE_RICARICABILI_GET_LISTA_MOVIMENTI_SUCCESS"] = "CARTE_RICARICABILI_GET_LISTA_MOVIMENTI_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_ESPORTA_MOVIMENTI_START"] = "CARTE_RICARICABILI_ESPORTA_MOVIMENTI_START";
  EReduxActionTypes["CARTE_RICARICABILI_ESPORTA_MOVIMENTI_SUCCESS"] = "CARTE_RICARICABILI_ESPORTA_MOVIMENTI_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_ESPORTA_MOVIMENTI_ERROR"] = "CARTE_RICARICABILI_ESPORTA_MOVIMENTI_ERROR";
  EReduxActionTypes["CARTE_RICARICABILI_ESPORTA_DETTAGLIO_MOVIMENTI_START"] = "CARTE_RICARICABILI_ESPORTA_DETTAGLIO_MOVIMENTI_START";
  EReduxActionTypes["CARTE_RICARICABILI_ESPORTA_DETTAGLIO_MOVIMENTI_SUCCESS"] = "CARTE_RICARICABILI_ESPORTA_DETTAGLIO_MOVIMENTI_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_ESPORTA_DETTAGLIO_MOVIMENTI_ERROR"] = "CARTE_RICARICABILI_ESPORTA_DETTAGLIO_MOVIMENTI_ERROR";
  EReduxActionTypes["CARTE_RICARICABILI_ESPORTA_EXCEL_MOVIMENTI_START"] = "CARTE_RICARICABILI_ESPORTA_EXCEL_MOVIMENTI_START";
  EReduxActionTypes["CARTE_RICARICABILI_ESPORTA_EXCEL_MOVIMENTI_SUCCESS"] = "CARTE_RICARICABILI_ESPORTA_EXCEL_MOVIMENTI_SUCCESS";
  EReduxActionTypes["CARTE_RICARICABILI_ESPORTA_EXCEL_MOVIMENTI_ERROR"] = "CARTE_RICARICABILI_ESPORTA_EXCEL_MOVIMENTI_ERROR";
  EReduxActionTypes["CARTE_RICARICABILI_SET_DATA_DA"] = "CARTE_RICARICABILI_SET_DATA_DA";
  EReduxActionTypes["CARTE_RICARICABILI_SET_DATA_A"] = "CARTE_RICARICABILI_SET_DATA_A";
  EReduxActionTypes["CARTE_RICARICABILI_SET_PAN_FILTRI"] = "CARTE_RICARICABILI_SET_PAN_FILTRI";
  EReduxActionTypes["CARTE_RICARICABILI_SET_RAPPORTO_FILTRI"] = "CARTE_RICARICABILI_SET_RAPPORTO_FILTRI";
  EReduxActionTypes["CARTE_RICARICABILI_SET_RAPPORTO_SELEZIONATO_FILTRI"] = "CARTE_RICARICABILI_SET_RAPPORTO_SELEZIONATO_FILTRI";
  EReduxActionTypes["CARTE_RICARICABILI_RIMUOVI_FILTRI"] = "CARTE_RICARICABILI_RIMUOVI_FILTRI";
  EReduxActionTypes["CARTE_RICARICABILI_MOVIMENTI_SET_ORDINAMENTO"] = "CARTE_RICARICABILI_MOVIMENTI_SET_ORDINAMENTO";
  EReduxActionTypes["CARTA_CONTO_START_PIN_VIEW__SCA_START"] = "CARTA_CONTO_START_PIN_VIEW__SCA_START";
  EReduxActionTypes["CARTA_CONTO_START_PIN_VIEW__SCA_SUCCESS"] = "CARTA_CONTO_START_PIN_VIEW__SCA_SUCCESS";
  EReduxActionTypes["CARTA_CONTO_GET_PIN_VIEW_START"] = "CARTA_CONTO_GET_PIN_VIEW_START";
  EReduxActionTypes["CARTA_CONTO_GET_PIN_VIEW_SUCCESS"] = "CARTA_CONTO_GET_PIN_VIEW_SUCCESS";
  EReduxActionTypes["CARTA_CONTO_RESET_PIN_VIEW"] = "CARTA_CONTO_RESET_PIN_VIEW";
  EReduxActionTypes["CARTA_CONTO_START_PAN_VIEW__SCA_START"] = "CARTA_CONTO_START_PAN_VIEW__SCA_START";
  EReduxActionTypes["CARTA_CONTO_START_PAN_VIEW__SCA_SUCCESS"] = "CARTA_CONTO_START_PAN_VIEW__SCA_SUCCESS";
  EReduxActionTypes["CARTA_CONTO_GET_PAN_VIEW_START"] = "CARTA_CONTO_GET_PAN_VIEW_START";
  EReduxActionTypes["CARTA_CONTO_GET_PAN_VIEW_SUCCESS"] = "CARTA_CONTO_GET_PAN_VIEW_SUCCESS";
  EReduxActionTypes["CARTA_CONTO_COPY_PAN_VIEW_SUCCESS"] = "CARTA_CONTO_COPY_PAN_VIEW_SUCCESS";
  EReduxActionTypes["CARTA_CONTO_RESET_PAN_VIEW"] = "CARTA_CONTO_RESET_PAN_VIEW";
})(EReduxActionTypes = exports.EReduxActionTypes || (exports.EReduxActionTypes = {}));
// ES6 spec
__exportStar(require("./adeguataVerifica"), exports);
__exportStar(require("./altoAdigePass"), exports);
__exportStar(require("./anatocismo"), exports);
__exportStar(require("./archivioBozze"), exports);
__exportStar(require("./assegni"), exports);
__exportStar(require("./bancomatPay"), exports);
__exportStar(require("./banner"), exports);
__exportStar(require("./bilancio"), exports);
__exportStar(require("./bloccoCarte"), exports);
__exportStar(require("./bollettinoFreccia"), exports);
__exportStar(require("./bollettinoPostale"), exports);
__exportStar(require("./bolloAci"), exports);
__exportStar(require("./bonifico-deposito"), exports);
__exportStar(require("./bonifico-versamento-deposito"), exports);
__exportStar(require("./bonificoEstero"), exports);
__exportStar(require("./bonificoMyBank"), exports);
__exportStar(require("./bonificoRipetitivo"), exports);
__exportStar(require("./bonificoSepa"), exports);
__exportStar(require("./cambi"), exports);
__exportStar(require("./cartaConto"), exports);
__exportStar(require("./carte-ricaricabili"), exports);
__exportStar(require("./catalogoProdotti"), exports);
__exportStar(require("./cBill"), exports);
__exportStar(require("./cercaTitoli"), exports);
__exportStar(require("./collaboration"), exports);
__exportStar(require("./commons.types"), exports);
__exportStar(require("./compravendita"), exports);
__exportStar(require("./consensi"), exports);
__exportStar(require("./datiFiliale"), exports);
__exportStar(require("./datiPersonali"), exports);
__exportStar(require("./depositoRisparmi"), exports);
__exportStar(require("./documenti"), exports);
__exportStar(require("./dossierTitoli"), exports);
__exportStar(require("./f24"), exports);
__exportStar(require("./faq"), exports);
__exportStar(require("./filialeVirtuale"), exports);
__exportStar(require("./finanza"), exports);
__exportStar(require("./firma-digitale"), exports);
__exportStar(require("./fondiPaperless"), exports);
__exportStar(require("./general"), exports);
__exportStar(require("./gestioneRapporti"), exports);
__exportStar(require("./helpers"), exports);
__exportStar(require("./i18n"), exports);
__exportStar(require("./indici"), exports);
__exportStar(require("./limitiOperativi"), exports);
__exportStar(require("./login"), exports);
__exportStar(require("./mandatiSdd"), exports);
__exportStar(require("./mav"), exports);
__exportStar(require("./menu"), exports);
__exportStar(require("./messaggi"), exports);
__exportStar(require("./mifid"), exports);
__exportStar(require("./movimenti"), exports);
__exportStar(require("./mutui"), exports);
__exportStar(require("./neosurance"), exports);
__exportStar(require("./news"), exports);
__exportStar(require("./nexi"), exports);
__exportStar(require("./nexiDebit"), exports);
__exportStar(require("./notificaPush"), exports);
__exportStar(require("./onboarding"), exports);
__exportStar(require("./oneclick"), exports);
__exportStar(require("./operazioni"), exports);
__exportStar(require("./pagamenti"), exports);
__exportStar(require("./patrimonio"), exports);
__exportStar(require("./pct"), exports);
__exportStar(require("./pfm"), exports);
__exportStar(require("./pns"), exports);
__exportStar(require("./polizze"), exports);
__exportStar(require("./postlogin"), exports);
__exportStar(require("./prelogin"), exports);
__exportStar(require("./prestiti"), exports);
__exportStar(require("./prestitoIdea"), exports);
__exportStar(require("./profilo"), exports);
__exportStar(require("./psd2"), exports);
__exportStar(require("./rav"), exports);
__exportStar(require("./rendimenti"), exports);
__exportStar(require("./riba"), exports);
__exportStar(require("./ricaricaCellulare"), exports);
__exportStar(require("./ricercaFilialeAtm"), exports);
__exportStar(require("./richiediFidoIsiDispo"), exports);
__exportStar(require("./rubrica"), exports);
__exportStar(require("./storicoOrdini"), exports);
__exportStar(require("./timeDeposit"), exports);
__exportStar(require("./titoliQuotati"), exports);
__exportStar(require("./tributiLocali"), exports);
__exportStar(require("./ultimiAccessi"), exports);
__exportStar(require("./variazioneCanali"), exports);
__exportStar(require("./watchlist"), exports);
__exportStar(require("./widgets"), exports);
__exportStar(require("./zainettoFiscale"), exports);