"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAppuntamentoDaModificare = exports.resetFormCreaAppuntamento = exports.setArgomentoSelezionatoCreaAppuntamentoAction = exports.setModalitaIncontroCreaAppuntamentoAction = exports.setNoteCreaAppuntamento = exports.getArgomentiEvento = exports.resetTokenEvento = exports.getTokenEvento = exports.getElencoAppuntamenti = exports.resetAppuntamentoCreato = exports.resetAppuntamentoModificato = exports.resetAppuntamentoEliminato = exports.eliminaAppuntamento = exports.modificaAppuntamento = exports.creaAppuntamento = exports.getDisponibilitaConsulentePerModifica = exports.getDisponibilitaConsulente = exports.getConsulente = exports.getConfigCollaboration = void 0;
const api_collaboration_1 = require("@sparkasse/api-collaboration");
const general_1 = require("../general");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const collaboration_types_1 = require("./collaboration.types");
// const idUserConsulente = 'f7a406c4-67ef-46e5-812e-59f09be5792b'; // Christian Ruggieri / da usare se si sta utilizzando l'organizzazione Linkstest
// const idUserConsulente = '0dc159a0-3591-4b8b-a73e-efdbbfe97a08'; // Francesca Faietti / da usare se si sta utilizzando l'organizzazione Sparkasse
const tenant = general_1.selectedAbi === '06045' ? 'SPARKASSE' : 'CIVIBANK';
let codiceFiscaleDemo = ""; //"ZNLDVD71P22A952L";
let ndgDemo = ""; // "DAVIDE.ZANELOTTO@TIN.IT";
let emailDemo = ""; // "DAVIDE.ZANELOTTO@TIN.IT";
let nomeDemo = ""; // "Davide Zanelotto";
// da portare a true se si vuole che l'utenza con cui si fa login sia Alessandro Marra (alemarra89@gmail.com)
// in modo da sovrascrivere la vera intestazione dell'utenza bancaria che risulta essere Evelyn Pichler
const demoSparkasse = false;
if (demoSparkasse) {
  codiceFiscaleDemo = "MRRLSN89C14D883D";
  ndgDemo = "000100065874";
  emailDemo = "alemarra89@gmail.com";
  nomeDemo = "Alessandro Marra";
}
function generateCorrelationId() {
  return `${tenant}_${new Date().getTime()}`;
}
function generalError(value) {
  return async dispatch => {
    dispatch((0, general_actions_1.setError)(value));
  };
}
function getConfigCollaborationStart() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_CONFIG_COLLABORATION_START
  };
}
function getConfigCollaborationSuccess(payload) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_CONFIG_COLLABORATION_SUCCESS,
    payload
  };
}
const getConfigCollaboration = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getConfigCollaborationStart());
  extraArguments.getItem('jwt').then(jwt => {
    const request = {
      correlationId: generateCorrelationId(),
      tenant,
      authorization: '_',
      channel: extraArguments.platform
    };
    new api_collaboration_1.CustomerWebCollaborationControllerApi((0, general_configuration_1.default)(api_collaboration_1.Configuration, extraArguments, '', jwt, undefined, "collaboration")).getConfigFromCustomer(request).then(response => {
      dispatch(getConfigCollaborationSuccess(response));
    }).catch(async error => {
      dispatch(generalError(await (0, helpers_1.formatError)(error)));
    }).finally(() => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  });
};
exports.getConfigCollaboration = getConfigCollaboration;
function getConsulenteStart() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_CONSULENTE_START
  };
}
function getConsulenteSuccess(payload) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_CONSULENTE_SUCCESS,
    payload
  };
}
const getConsulente = () => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getConsulenteStart());
  extraArguments.getItem('jwt').then(jwt => {
    var _a, _b;
    const request = {
      correlationId: generateCorrelationId(),
      ndg: ((_b = (_a = getState().profilo.profilo) === null || _a === void 0 ? void 0 : _a.datiUtente) === null || _b === void 0 ? void 0 : _b.ndg) || '',
      tenant,
      authorization: '_',
      channel: extraArguments.platform
    };
    new api_collaboration_1.CustomerWebCollaborationControllerApi((0, general_configuration_1.default)(api_collaboration_1.Configuration, extraArguments, '', jwt, undefined, "collaboration")).findCounselor(request).then(response => {
      dispatch(getConsulenteSuccess(response));
    }).catch(async error => {
      console.log(error);
      if (error.status != 404) {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }
    }).finally(() => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  });
};
exports.getConsulente = getConsulente;
function getDisponibilitaConsulenteStart() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_DISPONIBILITA_CONSULENTE_START
  };
}
function getDisponibilitaConsulenteSuccess(payload) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_DISPONIBILITA_CONSULENTE_SUCCESS,
    payload
  };
}
const getDisponibilitaConsulente = isModifica => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDisponibilitaConsulenteStart());
  const stateCollaboration = getState().collaboration;
  const request = {
    correlationId: generateCorrelationId(),
    tenant,
    authorization: '_',
    channel: extraArguments.platform,
    requestBody: {
      eventType: isModifica ? stateCollaboration.appuntamentoDaModificare.kind : stateCollaboration.form.modalitaIncontro,
      branch: (((_a = stateCollaboration.consulente) === null || _a === void 0 ? void 0 : _a.type) == 'VIRTUAL' || !((_b = stateCollaboration.consulente) === null || _b === void 0 ? void 0 : _b.id)) && stateCollaboration.form.modalitaIncontro == 'BRANCH' ? (_d = (_c = getState().profilo.profilo) === null || _c === void 0 ? void 0 : _c.datiUtente) === null || _d === void 0 ? void 0 : _d.filiale : '',
      counselorGraphId: ((_e = stateCollaboration.consulente) === null || _e === void 0 ? void 0 : _e.type) == 'VIRTUAL' && stateCollaboration.form.modalitaIncontro == 'BRANCH' ? '' : (_f = stateCollaboration.consulente) === null || _f === void 0 ? void 0 : _f.idGraphUser,
      customerType: ((_g = stateCollaboration.consulente) === null || _g === void 0 ? void 0 : _g.type) == 'VIRTUAL' || !((_h = stateCollaboration.consulente) === null || _h === void 0 ? void 0 : _h.id) ? 'UNMANAGED_CUSTOMER' : 'MANAGED_CUSTOMER',
      serviceProductId: stateCollaboration.form.argomentoSelezionato
    }
  };
  extraArguments.getItem('jwt').then(jwt => {
    new api_collaboration_1.CustomerWebCollaborationControllerApi((0, general_configuration_1.default)(api_collaboration_1.Configuration, extraArguments, '', jwt, undefined, "collaboration")).getCalendarFromCustomer(request).then(response => {
      dispatch(getDisponibilitaConsulenteSuccess(response));
    }).catch(async error => {
      dispatch(generalError(await (0, helpers_1.formatError)(error)));
    }).finally(() => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  });
  // }
};
exports.getDisponibilitaConsulente = getDisponibilitaConsulente;
const getDisponibilitaConsulentePerModifica = () => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getDisponibilitaConsulenteStart());
  const stateCollaboration = getState().collaboration;
  const request = {
    correlationId: generateCorrelationId(),
    tenant,
    authorization: '_',
    channel: extraArguments.platform,
    requestBody: {
      eventType: stateCollaboration.appuntamentoDaModificare.kind,
      branch: (_b = (_a = getState().profilo.profilo) === null || _a === void 0 ? void 0 : _a.datiUtente) === null || _b === void 0 ? void 0 : _b.filiale,
      counselorGraphId: stateCollaboration.appuntamentoDaModificare.organizerId,
      customerType: ((_c = stateCollaboration.consulente) === null || _c === void 0 ? void 0 : _c.type) == 'VIRTUAL' ? 'UNMANAGED_CUSTOMER' : 'MANAGED_CUSTOMER'
    }
  };
  extraArguments.getItem('jwt').then(jwt => {
    new api_collaboration_1.CustomerWebCollaborationControllerApi((0, general_configuration_1.default)(api_collaboration_1.Configuration, extraArguments, '', jwt, undefined, "collaboration")).getCalendarFromCustomer(request).then(response => {
      dispatch(getDisponibilitaConsulenteSuccess(response));
    }).catch(async error => {
      dispatch(generalError(await (0, helpers_1.formatError)(error)));
    }).finally(() => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  });
  // }
};
exports.getDisponibilitaConsulentePerModifica = getDisponibilitaConsulentePerModifica;
function creaAppuntamentoStart() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.CREA_APPUNTAMENTO_START
  };
}
function creaAppuntamentoSuccess(payload) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.CREA_APPUNTAMENTO_SUCCESS,
    payload
  };
}
const creaAppuntamento = (slot, nonPortafogliatoInFiliale) => async (dispatch, getState, extraArguments) => {
  var _a, _b, _c;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(creaAppuntamentoStart());
  const datiUtente = (_a = getState().profilo.profilo) === null || _a === void 0 ? void 0 : _a.datiUtente;
  const datiPersonali = datiUtente === null || datiUtente === void 0 ? void 0 : datiUtente.datiPersonali;
  const form = getState().collaboration.form;
  if (datiPersonali && slot.from && slot.to) {
    const {
      email,
      codiceFiscale,
      anagrafe
    } = datiPersonali;
    const request = {
      correlationId: generateCorrelationId(),
      iduser: nonPortafogliatoInFiliale ? (_b = slot.lstCounselor) === null || _b === void 0 ? void 0 : _b[0].idGraphUser : ((_c = getState().collaboration.consulente) === null || _c === void 0 ? void 0 : _c.idGraphUser) || '',
      tenant,
      authorization: '_',
      channel: extraArguments.platform,
      requestBody: {
        start: slot.from,
        end: slot.to,
        email: emailDemo || email || '',
        // fiscalCode: codiceFiscaleDemo || codiceFiscale || '',
        ndg: (datiUtente === null || datiUtente === void 0 ? void 0 : datiUtente.ndg) || '',
        name: nomeDemo || (anagrafe === null || anagrafe === void 0 ? void 0 : anagrafe.nomeIntestazione) + ' ' + (anagrafe === null || anagrafe === void 0 ? void 0 : anagrafe.cognomeIntestazione),
        idServiceProduct: form.argomentoSelezionato || 1,
        note: form.note,
        kind: form.modalitaIncontro,
        eventCreatorType: 'CUSTOMER'
      }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_collaboration_1.CustomerWebCollaborationControllerApi((0, general_configuration_1.default)(api_collaboration_1.Configuration, extraArguments, '', jwt, undefined, "collaboration")).createEventFromCustomer(request).then(response => {
        dispatch(creaAppuntamentoSuccess(response));
      }).catch(async error => {
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
  }
  // }
};
exports.creaAppuntamento = creaAppuntamento;
function modificaAppuntamentoStart() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.MODIFICA_APPUNTAMENTO_START
  };
}
function modificaAppuntamentoSuccess(payload) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.MODIFICA_APPUNTAMENTO_SUCCESS,
    payload
  };
}
const modificaAppuntamento = (idEvent, giorno, slot) => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(modificaAppuntamentoStart());
  const request = {
    correlationId: generateCorrelationId(),
    tenant,
    authorization: '_',
    channel: extraArguments.platform,
    idEvent,
    iduser: ((_a = getState().collaboration.appuntamentoDaModificare) === null || _a === void 0 ? void 0 : _a.counselorId) || '',
    requestBody: {
      start: giorno.split(' ')[0] + ' ' + (slot === null || slot === void 0 ? void 0 : slot.split(' ')[0]),
      end: giorno.split(' ')[0] + ' ' + (slot === null || slot === void 0 ? void 0 : slot.split(' ')[2])
    }
  };
  extraArguments.getItem('jwt').then(jwt => {
    new api_collaboration_1.CustomerWebCollaborationControllerApi((0, general_configuration_1.default)(api_collaboration_1.Configuration, extraArguments, '', jwt, undefined, "collaboration")).updateEventFromCustomer(request).then(response => {
      dispatch(modificaAppuntamentoSuccess(response));
    }).catch(async error => {
      dispatch(generalError(await (0, helpers_1.formatError)(error)));
    }).finally(() => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  });
};
exports.modificaAppuntamento = modificaAppuntamento;
function eliminaAppuntamentoStart() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.ELIMINA_APPUNTAMENTO_START
  };
}
function eliminaAppuntamentoSuccess(payload) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.ELIMINA_APPUNTAMENTO_SUCCESS,
    payload
  };
}
const eliminaAppuntamento = idEvent => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(eliminaAppuntamentoStart());
  const request = {
    correlationId: generateCorrelationId(),
    tenant,
    authorization: '_',
    channel: extraArguments.platform,
    idEvent,
    iduser: ((_a = getState().collaboration.appuntamentoDaModificare) === null || _a === void 0 ? void 0 : _a.counselorId) || ''
  };
  extraArguments.getItem('jwt').then(jwt => {
    new api_collaboration_1.CustomerWebCollaborationControllerApi((0, general_configuration_1.default)(api_collaboration_1.Configuration, extraArguments, '', jwt, undefined, "collaboration")).deleteEventFromCustomer(request).then(response => {
      dispatch(eliminaAppuntamentoSuccess(response));
    }).catch(async error => {
      dispatch(generalError(await (0, helpers_1.formatError)(error)));
    }).finally(() => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  });
};
exports.eliminaAppuntamento = eliminaAppuntamento;
function resetAppuntamentoEliminato() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.RESET_APPUNTAMENTO_ELIMINATO
  };
}
exports.resetAppuntamentoEliminato = resetAppuntamentoEliminato;
function resetAppuntamentoModificato() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.RESET_APPUNTAMENTO_MODIFICATO
  };
}
exports.resetAppuntamentoModificato = resetAppuntamentoModificato;
function resetAppuntamentoCreato() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.RESET_APPUNTAMENTO_CREATO
  };
}
exports.resetAppuntamentoCreato = resetAppuntamentoCreato;
function getElencoAppuntamentiStart() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_ELENCO_APPUNTAMENTI_START
  };
}
function getElencoAppuntamentiSuccess(payload) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_ELENCO_APPUNTAMENTI_SUCCESS,
    payload
  };
}
const getElencoAppuntamenti = () => async (dispatch, getState, extraArguments) => {
  var _a;
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getElencoAppuntamentiStart());
  const datiUtente = (_a = getState().profilo.profilo) === null || _a === void 0 ? void 0 : _a.datiUtente;
  if (datiUtente) {
    const {
      ndg
    } = datiUtente;
    const request = {
      correlationId: generateCorrelationId(),
      // fiscalCode: codiceFiscaleDemo || codiceFiscale || '',
      ndg: ndg || '',
      tenant,
      authorization: '_',
      channel: extraArguments.platform
      // requestBody: {
      //   from: undefined,
      //   to: undefined
      // }
    };
    extraArguments.getItem('jwt').then(jwt => {
      new api_collaboration_1.CustomerWebCollaborationControllerApi((0, general_configuration_1.default)(api_collaboration_1.Configuration, extraArguments, '', jwt, undefined, "collaboration")).getCustomerEvents(request).then(response => {
        dispatch(getElencoAppuntamentiSuccess(response));
      }).catch(async error => {
        dispatch(getElencoAppuntamentiSuccess([]));
        dispatch(generalError(await (0, helpers_1.formatError)(error)));
      }).finally(() => {
        dispatch((0, general_actions_1.setLoader)(false));
      });
    });
    // }
  }
  ;
};
exports.getElencoAppuntamenti = getElencoAppuntamenti;
function getTokenEventoStart() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_TOKEN_EVENTO_START
  };
}
function getTokenEventoSuccess(payload) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_TOKEN_EVENTO_SUCCESS,
    payload
  };
}
const getTokenEvento = idEvent => async (dispatch, getState, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getTokenEventoStart());
  extraArguments.getItem('jwt').then(jwt => {
    const request = {
      correlationId: generateCorrelationId(),
      tenant,
      authorization: '_',
      channel: extraArguments.platform,
      idEvent
    };
    new api_collaboration_1.CustomerWebCollaborationControllerApi((0, general_configuration_1.default)(api_collaboration_1.Configuration, extraArguments, '', jwt, undefined, "collaboration")).getEventToken(request).then(response => {
      dispatch(getTokenEventoSuccess(response));
    }).catch(async error => {
      dispatch(generalError(await (0, helpers_1.formatError)(error)));
    }).finally(() => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  });
};
exports.getTokenEvento = getTokenEvento;
function resetTokenEvento() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.RESET_TOKEN_EVENTO
  };
}
exports.resetTokenEvento = resetTokenEvento;
// get argomenti - start
function getArgomentiEventoStart() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_ARGOMENTI_EVENTO_START
  };
}
function getArgomentiEventoSuccess(payload) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.GET_ARGOMENTI_EVENTO_SUCCESS,
    payload
  };
}
const getArgomentiEvento = () => async (dispatch, _, extraArguments) => {
  dispatch((0, general_actions_1.setLoader)(true));
  dispatch(getArgomentiEventoStart());
  extraArguments.getItem('jwt').then(jwt => {
    const request = {
      correlationId: generateCorrelationId(),
      tenant,
      authorization: '_',
      channel: extraArguments.platform
    };
    new api_collaboration_1.CustomerWebCollaborationControllerApi((0, general_configuration_1.default)(api_collaboration_1.Configuration, extraArguments, '', jwt, undefined, "collaboration")).getAllServiceProductCustomer(request).then(response => {
      dispatch(getArgomentiEventoSuccess(response));
    }).catch(async error => {
      dispatch(generalError(await (0, helpers_1.formatError)(error)));
    }).finally(() => {
      dispatch((0, general_actions_1.setLoader)(false));
    });
  });
};
exports.getArgomentiEvento = getArgomentiEvento;
function setNoteCreaAppuntamento(nota) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.SET_CREA_APPUNTAMENTO_NOTE,
    payload: nota
  };
}
exports.setNoteCreaAppuntamento = setNoteCreaAppuntamento;
;
function setModalitaIncontroCreaAppuntamentoAction(modalita) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.SET_CREA_APPUNTAMENTO_MODALITA_INCONTRO,
    payload: modalita
  };
}
exports.setModalitaIncontroCreaAppuntamentoAction = setModalitaIncontroCreaAppuntamentoAction;
;
function setArgomentoSelezionatoCreaAppuntamentoAction(argomento) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.SET_CREA_APPUNTAMENTO_ARGOMENTO_SELEZIONATO,
    payload: argomento
  };
}
exports.setArgomentoSelezionatoCreaAppuntamentoAction = setArgomentoSelezionatoCreaAppuntamentoAction;
;
function resetFormCreaAppuntamento() {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.RESET_FORM_CREA_APPUNTAMENTO
  };
}
exports.resetFormCreaAppuntamento = resetFormCreaAppuntamento;
;
function setAppuntamentoDaModificare(appuntamento) {
  return {
    type: collaboration_types_1.EReduxCollaborationActionTypes.SET_APPUNTAMENTO_DA_MODIFICARE,
    payload: appuntamento
  };
}
exports.setAppuntamentoDaModificare = setAppuntamentoDaModificare;