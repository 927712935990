"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CartaContoControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class CartaContoControllerApi extends runtime.BaseAPI {
  /**
   * Export in pdf del dettaglio del carta conto
   */
  dettaglioCartaContoPdfRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.bonificoSepaDto === null || requestParameters.bonificoSepaDto === undefined) {
        throw new runtime.RequiredError('bonificoSepaDto', 'Required parameter requestParameters.bonificoSepaDto was null or undefined when calling dettaglioCartaContoPdf.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/cartaconto/dettaglio/export/pdf`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BonificoSepaDtoToJSON(requestParameters.bonificoSepaDto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Export in pdf del dettaglio del carta conto
   */
  dettaglioCartaContoPdf(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.dettaglioCartaContoPdfRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Recupera il PAN della carta
   */
  getEncryptedSmartCardPanViewRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.body === null || requestParameters.body === undefined) {
        throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling getEncryptedSmartCardPanView.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/cartaconto/pan`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body
      });
      return new runtime.TextApiResponse(response);
    });
  }
  /**
   * Recupera il PAN della carta
   */
  getEncryptedSmartCardPanView(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getEncryptedSmartCardPanViewRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Recupera il PAN della carta
   */
  getPanViewSmartCardRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.txId === null || requestParameters.txId === undefined) {
        throw new runtime.RequiredError('txId', 'Required parameter requestParameters.txId was null or undefined when calling getPanViewSmartCard.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/cartaconto/getPanView/{txId}`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters.txId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.PanViewResponseDtoFromJSON(jsonValue));
    });
  }
  /**
   * Recupera il PAN della carta
   */
  getPanViewSmartCard(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getPanViewSmartCardRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Recupera il PIN della carta
   */
  getPinViewSmartCardRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.txId === null || requestParameters.txId === undefined) {
        throw new runtime.RequiredError('txId', 'Required parameter requestParameters.txId was null or undefined when calling getPinViewSmartCard.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/cartaconto/getPinView/{txId}`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters.txId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.PinViewResponseDtoFromJSON(jsonValue));
    });
  }
  /**
   * Recupera il PIN della carta
   */
  getPinViewSmartCard(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getPinViewSmartCardRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura parametri per la dispo carta conto
   */
  parametriDispoCartaContoRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/cartaconto/parametri`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriCartaContoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri per la dispo carta conto
   */
  parametriDispoCartaConto() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriDispoCartaContoRaw();
      return yield response.value();
    });
  }
  /**
   * Inizializza l\'operazione di SCA per la visualizzazione del PAN della carta
   */
  startPanViewSCASmartCardRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.startPanViewSCARequestDto === null || requestParameters.startPanViewSCARequestDto === undefined) {
        throw new runtime.RequiredError('startPanViewSCARequestDto', 'Required parameter requestParameters.startPanViewSCARequestDto was null or undefined when calling startPanViewSCASmartCard.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/cartaconto/startPanViewSCA`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.StartPanViewSCARequestDtoToJSON(requestParameters.startPanViewSCARequestDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.StartPanViewSCAResponseDtoFromJSON(jsonValue));
    });
  }
  /**
   * Inizializza l\'operazione di SCA per la visualizzazione del PAN della carta
   */
  startPanViewSCASmartCard(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.startPanViewSCASmartCardRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Inizializza l\'operazione di SCA per la visualizzazione del PIN della carta
   */
  startPinViewSCASmartCardRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.startPinViewSCARequestDto === null || requestParameters.startPinViewSCARequestDto === undefined) {
        throw new runtime.RequiredError('startPinViewSCARequestDto', 'Required parameter requestParameters.startPinViewSCARequestDto was null or undefined when calling startPinViewSCASmartCard.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/cartaconto/startPinViewSCA`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.StartPinViewSCARequestDtoToJSON(requestParameters.startPinViewSCARequestDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.StartPinViewSCAResponseDtoFromJSON(jsonValue));
    });
  }
  /**
   * Inizializza l\'operazione di SCA per la visualizzazione del PIN della carta
   */
  startPinViewSCASmartCard(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.startPinViewSCASmartCardRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.CartaContoControllerApi = CartaContoControllerApi;