"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CarteControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class CarteControllerApi extends runtime.BaseAPI {
  /**
   * Lettura dei dati relativi alle carte dell\'utente
   */
  recuperaInfoCarteRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      if (requestParameters.codiceRapporto !== undefined) {
        queryParameters['codiceRapporto'] = requestParameters.codiceRapporto;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/daticarte`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DatiCarteDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura dei dati relativi alle carte dell\'utente
   */
  recuperaInfoCarte(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.recuperaInfoCarteRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.CarteControllerApi = CarteControllerApi;