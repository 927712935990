"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StampaElencoTitoliTypeEnum = exports.StampaElencoRendimentiTypeEnum = exports.PrometeiaControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class PrometeiaControllerApi extends runtime.BaseAPI {
  /**
   * Export in pdf del dettaglio del pagamento in base ai criteri inviati per il rapporto indicato
   */
  exportSchedaTitoloRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.codiceIsin === null || requestParameters.codiceIsin === undefined) {
        throw new runtime.RequiredError('codiceIsin', 'Required parameter requestParameters.codiceIsin was null or undefined when calling exportSchedaTitolo.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/prometeia/schedatitolo/{codiceIsin}/pdf`.replace(`{${"codiceIsin"}}`, encodeURIComponent(String(requestParameters.codiceIsin))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Export in pdf del dettaglio del pagamento in base ai criteri inviati per il rapporto indicato
   */
  exportSchedaTitolo(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.exportSchedaTitoloRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce il dettaglio di un breakdown
   */
  getBreakdownDetailRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.getBreakdownDetailInDto === null || requestParameters.getBreakdownDetailInDto === undefined) {
        throw new runtime.RequiredError('getBreakdownDetailInDto', 'Required parameter requestParameters.getBreakdownDetailInDto was null or undefined when calling getBreakdownDetail.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/prometeia/breakdowns/detail`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.GetBreakdownDetailInDtoToJSON(requestParameters.getBreakdownDetailInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BreakdownDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce il dettaglio di un breakdown
   */
  getBreakdownDetail(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getBreakdownDetailRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce i breakdowns
   */
  getBreakdownsRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.getBreakdownsInDto === null || requestParameters.getBreakdownsInDto === undefined) {
        throw new runtime.RequiredError('getBreakdownsInDto', 'Required parameter requestParameters.getBreakdownsInDto was null or undefined when calling getBreakdowns.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/prometeia/breakdowns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.GetBreakdownsInDtoToJSON(requestParameters.getBreakdownsInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.BreakdownDtoFromJSON));
    });
  }
  /**
   * Restituisce i breakdowns
   */
  getBreakdowns(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getBreakdownsRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * customer dossier
   */
  getCustomerDossierRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.dossierRequestInDto === null || requestParameters.dossierRequestInDto === undefined) {
        throw new runtime.RequiredError('dossierRequestInDto', 'Required parameter requestParameters.dossierRequestInDto was null or undefined when calling getCustomerDossier.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/prometeia/dossier`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.DossierRequestInDtoToJSON(requestParameters.dossierRequestInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.DossierBODtoFromJSON(jsonValue));
    });
  }
  /**
   * customer dossier
   */
  getCustomerDossier(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getCustomerDossierRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * calculate performance
   */
  getPerformanceRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.performanceRequestInDto === null || requestParameters.performanceRequestInDto === undefined) {
        throw new runtime.RequiredError('performanceRequestInDto', 'Required parameter requestParameters.performanceRequestInDto was null or undefined when calling getPerformance.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/prometeia/performance`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.PerformanceRequestInDtoToJSON(requestParameters.performanceRequestInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.AccountBODtoFromJSON(jsonValue));
    });
  }
  /**
   * calculate performance
   */
  getPerformance(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getPerformanceRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce le risposte del questionario
   */
  getQuestionnaireAnswersRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.getQuestionnaireAnswersInDto === null || requestParameters.getQuestionnaireAnswersInDto === undefined) {
        throw new runtime.RequiredError('getQuestionnaireAnswersInDto', 'Required parameter requestParameters.getQuestionnaireAnswersInDto was null or undefined when calling getQuestionnaireAnswers.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/prometeia/mifid/risposte`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.GetQuestionnaireAnswersInDtoToJSON(requestParameters.getQuestionnaireAnswersInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.QuestionnaireAnswersDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce le risposte del questionario
   */
  getQuestionnaireAnswers(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getQuestionnaireAnswersRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Stampa dettaglio MiFID pdf
   */
  printReportRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.questionnaireReportInDto === null || requestParameters.questionnaireReportInDto === undefined) {
        throw new runtime.RequiredError('questionnaireReportInDto', 'Required parameter requestParameters.questionnaireReportInDto was null or undefined when calling printReport.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/prometeia/mifid/report`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.QuestionnaireReportInDtoToJSON(requestParameters.questionnaireReportInDto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Stampa dettaglio MiFID pdf
   */
  printReport(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.printReportRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Salva le risposte del questionario
   */
  saveQuestionnaireAnswersRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.saveQuestionnaireAnswersInDto === null || requestParameters.saveQuestionnaireAnswersInDto === undefined) {
        throw new runtime.RequiredError('saveQuestionnaireAnswersInDto', 'Required parameter requestParameters.saveQuestionnaireAnswersInDto was null or undefined when calling saveQuestionnaireAnswers.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/prometeia/mifid/save`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.SaveQuestionnaireAnswersInDtoToJSON(requestParameters.saveQuestionnaireAnswersInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.QuestionnaireAnswersDtoFromJSON(jsonValue));
    });
  }
  /**
   * Salva le risposte del questionario
   */
  saveQuestionnaireAnswers(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.saveQuestionnaireAnswersRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * calculate performance
   */
  soggettiOperantiPerFinanzaRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/prometeia/soggettioperanti`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.DatiIntestatarioDtoFromJSON));
    });
  }
  /**
   * calculate performance
   */
  soggettiOperantiPerFinanza() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.soggettiOperantiPerFinanzaRaw();
      return yield response.value();
    });
  }
  /**
   * Genera file PDF o XLS rendimenti
   */
  stampaElencoRendimentiRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.type === null || requestParameters.type === undefined) {
        throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling stampaElencoRendimenti.');
      }
      if (requestParameters.accountBODto === null || requestParameters.accountBODto === undefined) {
        throw new runtime.RequiredError('accountBODto', 'Required parameter requestParameters.accountBODto was null or undefined when calling stampaElencoRendimenti.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/prometeia/export/rendimenti/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.AccountBODtoToJSON(requestParameters.accountBODto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Genera file PDF o XLS rendimenti
   */
  stampaElencoRendimenti(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.stampaElencoRendimentiRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Genera file PDF o XLS titoli
   */
  stampaElencoTitoliRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.type === null || requestParameters.type === undefined) {
        throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling stampaElencoTitoli.');
      }
      if (requestParameters.dossierBODto === null || requestParameters.dossierBODto === undefined) {
        throw new runtime.RequiredError('dossierBODto', 'Required parameter requestParameters.dossierBODto was null or undefined when calling stampaElencoTitoli.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/prometeia/export/elencotitoli/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.DossierBODtoToJSON(requestParameters.dossierBODto)
      });
      return new runtime.BlobApiResponse(response);
    });
  }
  /**
   * Genera file PDF o XLS titoli
   */
  stampaElencoTitoli(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.stampaElencoTitoliRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.PrometeiaControllerApi = PrometeiaControllerApi;
/**
    * @export
    * @enum {string}
    */
var StampaElencoRendimentiTypeEnum;
(function (StampaElencoRendimentiTypeEnum) {
  StampaElencoRendimentiTypeEnum["pdf"] = "pdf";
  StampaElencoRendimentiTypeEnum["excel"] = "excel";
})(StampaElencoRendimentiTypeEnum = exports.StampaElencoRendimentiTypeEnum || (exports.StampaElencoRendimentiTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var StampaElencoTitoliTypeEnum;
(function (StampaElencoTitoliTypeEnum) {
  StampaElencoTitoliTypeEnum["pdf"] = "pdf";
  StampaElencoTitoliTypeEnum["excel"] = "excel";
})(StampaElencoTitoliTypeEnum = exports.StampaElencoTitoliTypeEnum || (exports.StampaElencoTitoliTypeEnum = {}));