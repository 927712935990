"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BozzeControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class BozzeControllerApi extends runtime.BaseAPI {
  /**
   * Restituisce le bozze dato un codice funzione
   */
  bozzeRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.archivioBozzeInDto === null || requestParameters.archivioBozzeInDto === undefined) {
        throw new runtime.RequiredError('archivioBozzeInDto', 'Required parameter requestParameters.archivioBozzeInDto was null or undefined when calling bozze.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/archiviobozze/bozze`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.ArchivioBozzeInDtoToJSON(requestParameters.archivioBozzeInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BozzeWrapperDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce le bozze dato un codice funzione
   */
  bozze(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.bozzeRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Recupero di una bozza dato l\'id
   */
  dettaglioBozzaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling dettaglioBozza.');
      }
      if (requestParameters.codiceFunzione === null || requestParameters.codiceFunzione === undefined) {
        throw new runtime.RequiredError('codiceFunzione', 'Required parameter requestParameters.codiceFunzione was null or undefined when calling dettaglioBozza.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/archiviobozze/bozze/{codiceFunzione}/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"codiceFunzione"}}`, encodeURIComponent(String(requestParameters.codiceFunzione))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BonificoSepaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Recupero di una bozza dato l\'id
   */
  dettaglioBozza(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.dettaglioBozzaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Eliminazione di una bozza dato l\'id
   */
  eliminaBozzaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling eliminaBozza.');
      }
      if (requestParameters.codiceFunzione === null || requestParameters.codiceFunzione === undefined) {
        throw new runtime.RequiredError('codiceFunzione', 'Required parameter requestParameters.codiceFunzione was null or undefined when calling eliminaBozza.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/archiviobozze/bozze/{codiceFunzione}/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"codiceFunzione"}}`, encodeURIComponent(String(requestParameters.codiceFunzione))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Eliminazione di una bozza dato l\'id
   */
  eliminaBozza(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.eliminaBozzaRaw(requestParameters);
    });
  }
  /**
   * Lettura parametri per le bozze
   */
  parametriBozzeRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/archiviobozze/parametri`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriBozzeDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri per le bozze
   */
  parametriBozze() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriBozzeRaw();
      return yield response.value();
    });
  }
}
exports.BozzeControllerApi = BozzeControllerApi;