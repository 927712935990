"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PfmControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class PfmControllerApi extends runtime.BaseAPI {
  /**
   * Restituisce l\'elenco delle categorie pfm
   */
  elencoCategoriePfmRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/pfm/categorie`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.CategoryDtoFromJSON));
    });
  }
  /**
   * Restituisce l\'elenco delle categorie pfm
   */
  elencoCategoriePfm() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.elencoCategoriePfmRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce i valori del bilancio sintentico per la widget specifica
   */
  recuperaWidgetBilancioSinteticoRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/pfm/widget/bilanciosintetico`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BilancioSinteticoDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce i valori del bilancio sintentico per la widget specifica
   */
  recuperaWidgetBilancioSintetico() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.recuperaWidgetBilancioSinteticoRaw();
      return yield response.value();
    });
  }
  /**
   * Restituisce i valori del patrimonio per la widget specifica
   */
  recuperaWidgetPatrimonioRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/pfm/widget/patrimonio`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.WidgetBeniDebitiDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce i valori del patrimonio per la widget specifica
   */
  recuperaWidgetPatrimonio() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.recuperaWidgetPatrimonioRaw();
      return yield response.value();
    });
  }
}
exports.PfmControllerApi = PfmControllerApi;