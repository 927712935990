"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BonificoEsteroControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class BonificoEsteroControllerApi extends runtime.BaseAPI {
  /**
   * Autorizzazione di una disposizione di bonifico
   */
  autorizzaBonificoEsteroRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaBonificoEstero.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaBonificoEstero.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bonificoestero/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneBonificoEsteroDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di bonifico
   */
  autorizzaBonificoEstero(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.autorizzaBonificoEsteroRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce una bozza dato l\'id per bonifico estero
   */
  bozzaBonificoEsteroRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling bozzaBonificoEstero.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bonificoestero/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BonificoEsteroDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce una bozza dato l\'id per bonifico estero
   */
  bozzaBonificoEstero(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.bozzaBonificoEsteroRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce le bozze per bonifico estero
   */
  bozzeBonificoEsteroRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
        throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling bozzeBonificoEstero.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bonificoestero/bozze`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BonificiEsteroDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce le bozze per bonifico estero
   */
  bozzeBonificoEstero(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.bozzeBonificoEsteroRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Recupera il cambio per la divisa
   */
  cambioBonificoEsteroRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.divisa === null || requestParameters.divisa === undefined) {
        throw new runtime.RequiredError('divisa', 'Required parameter requestParameters.divisa was null or undefined when calling cambioBonificoEstero.');
      }
      const queryParameters = {};
      if (requestParameters.divisa !== undefined) {
        queryParameters['divisa'] = requestParameters.divisa;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bonificoestero/cambioBonificoEstero`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.CambioDivisaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Recupera il cambio per la divisa
   */
  cambioBonificoEstero(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.cambioBonificoEsteroRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Esegue il controllo di validità di un nuovo bonifico Estero in base ai valori specificati nell\'oggetto di richiesta
   */
  controlloBonificoEsteroRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.bonificoEsteroDto === null || requestParameters.bonificoEsteroDto === undefined) {
        throw new runtime.RequiredError('bonificoEsteroDto', 'Required parameter requestParameters.bonificoEsteroDto was null or undefined when calling controlloBonificoEstero.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bonificoestero/controllo`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BonificoEsteroDtoToJSON(requestParameters.bonificoEsteroDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BonificoEsteroDtoFromJSON(jsonValue));
    });
  }
  /**
   * Esegue il controllo di validità di un nuovo bonifico Estero in base ai valori specificati nell\'oggetto di richiesta
   */
  controlloBonificoEstero(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.controlloBonificoEsteroRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Recupera il cambio per la divisa
   */
  diviseBonificoEsteroRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bonificoestero/diviseBonificoEstero`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.DivisaFromJSON));
    });
  }
  /**
   * Recupera il cambio per la divisa
   */
  diviseBonificoEstero() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.diviseBonificoEsteroRaw();
      return yield response.value();
    });
  }
  /**
   * Eliminazione di una bozza dato l\'id
   */
  eliminaBozzaBonificoEsteroRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling eliminaBozzaBonificoEstero.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bonificoestero/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Eliminazione di una bozza dato l\'id
   */
  eliminaBozzaBonificoEstero(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.eliminaBozzaBonificoEsteroRaw(requestParameters);
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneBonificoEsteroRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneBonificoEstero.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bonificoestero/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneBonificoEstero(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.informazioniAutenticazioneBonificoEsteroRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce le operazioni per bonifico Estero
   */
  operazioniOneClickBonificoEsteroRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.operazioniOneClickInputDto === null || requestParameters.operazioniOneClickInputDto === undefined) {
        throw new runtime.RequiredError('operazioniOneClickInputDto', 'Required parameter requestParameters.operazioniOneClickInputDto was null or undefined when calling operazioniOneClickBonificoEstero.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bonificoestero/oneclick`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.OperazioniOneClickInputDtoToJSON(requestParameters.operazioniOneClickInputDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OperazioniOneClickDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce le operazioni per bonifico Estero
   */
  operazioniOneClickBonificoEstero(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.operazioniOneClickBonificoEsteroRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura paesi per ricerca bic
   */
  paesiBicRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bonificoestero/paesibic`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.PaeseDtoFromJSON));
    });
  }
  /**
   * Lettura paesi per ricerca bic
   */
  paesiBic() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.paesiBicRaw();
      return yield response.value();
    });
  }
  /**
   * Lettura parametri bonifici estero
   */
  parametriBonificoEsteroRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bonificoestero/parametri`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriBonificoEsteroDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri bonifici estero
   */
  parametriBonificoEstero() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriBonificoEsteroRaw();
      return yield response.value();
    });
  }
  /**
   * Salva una bozza per bonifico estero
   */
  salvaBozzaBonificoEsteroRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.bonificoEsteroDto === null || requestParameters.bonificoEsteroDto === undefined) {
        throw new runtime.RequiredError('bonificoEsteroDto', 'Required parameter requestParameters.bonificoEsteroDto was null or undefined when calling salvaBozzaBonificoEstero.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bonificoestero/bozze/salvabozza`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BonificoEsteroDtoToJSON(requestParameters.bonificoEsteroDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BonificoEsteroDtoFromJSON(jsonValue));
    });
  }
  /**
   * Salva una bozza per bonifico estero
   */
  salvaBozzaBonificoEstero(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.salvaBozzaBonificoEsteroRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Ricerca bic per paese e nome istituto
   */
  searchBicRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.countryCode === null || requestParameters.countryCode === undefined) {
        throw new runtime.RequiredError('countryCode', 'Required parameter requestParameters.countryCode was null or undefined when calling searchBic.');
      }
      if (requestParameters.bankName === null || requestParameters.bankName === undefined) {
        throw new runtime.RequiredError('bankName', 'Required parameter requestParameters.bankName was null or undefined when calling searchBic.');
      }
      const queryParameters = {};
      if (requestParameters.countryCode !== undefined) {
        queryParameters['countryCode'] = requestParameters.countryCode;
      }
      if (requestParameters.bankName !== undefined) {
        queryParameters['bankName'] = requestParameters.bankName;
      }
      if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bonificoestero/searchbic`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.SearchBicResponseFromJSON(jsonValue));
    });
  }
  /**
   * Ricerca bic per paese e nome istituto
   */
  searchBic(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.searchBicRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Modifica di una bozza dato l\'id per bonifico Estero
   */
  updateBozzaBonificoEsteroRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateBozzaBonificoEstero.');
      }
      if (requestParameters.bonificoEsteroDto === null || requestParameters.bonificoEsteroDto === undefined) {
        throw new runtime.RequiredError('bonificoEsteroDto', 'Required parameter requestParameters.bonificoEsteroDto was null or undefined when calling updateBozzaBonificoEstero.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bonificoestero/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BonificoEsteroDtoToJSON(requestParameters.bonificoEsteroDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BonificoEsteroDtoFromJSON(jsonValue));
    });
  }
  /**
   * Modifica di una bozza dato l\'id per bonifico Estero
   */
  updateBozzaBonificoEstero(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.updateBozzaBonificoEsteroRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.BonificoEsteroControllerApi = BonificoEsteroControllerApi;