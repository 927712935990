"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bonificoRipetitivoReducer = exports.FEBBRAIO_29_ABILITATO = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const moment = require("moment");
const __1 = require("..");
const helpers_1 = require("../helpers");
const bonificoripetitivo_types_1 = require("./bonificoripetitivo.types");
exports.FEBBRAIO_29_ABILITATO = true;
const initialState = {
  parametri: (0, api_rest_1.ParametriBonificoRipetitivoDtoFromJSON)({}),
  parametriElenco: (0, api_rest_1.ParametriBonificoRipetitivoDtoFromJSON)({}),
  riepilogo: (0, api_rest_1.RipetitivoDtoFromJSON)({}),
  infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON)({}),
  form: (0, bonificoripetitivo_types_1.FormBonificoRipetitivoDtoFromJSONTyped)({}),
  riepilogoRevoca: (0, api_rest_1.RipetitivoDtoFromJSON)({}),
  formRevoca: (0, bonificoripetitivo_types_1.FormRevocaBonificoDtoFromJSON)({}),
  infoRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON)({}),
  bozzeBonifico: (0, api_rest_1.RipetitiviDtoFromJSON)({}),
  currentBozza: (0, api_rest_1.RipetitivoDtoFromJSON)({}),
  formElenco: (0, bonificoripetitivo_types_1.FormElencoBonificiRipetitiviDtoFromJSON)({}),
  elenco: (0, api_rest_1.RipetitiviDtoFromJSON)({}),
  dettaglioBonificoRipetitivo: undefined,
  pagamentoRipetitivoSelezionato: undefined,
  riepilogoSospendi: (0, api_rest_1.RipetitivoDtoFromJSON)({}),
  formSospendi: (0, bonificoripetitivo_types_1.FormSospendiBonificoDtoFromJSON)({}),
  infoSospendi: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  esitoSospendi: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON)({}),
  riepilogoRiattiva: (0, api_rest_1.RipetitivoDtoFromJSON)({}),
  formRiattiva: (0, bonificoripetitivo_types_1.FormRiattivaBonificoDtoFromJSON)({}),
  infoRiattiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
  esitoRiattiva: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON)({})
};
let rapportoSelezionato;
const buildTabellaRate = (tr, giornoSelezionato, meseSelezionato, fineMese) => {
  let ntr = [];
  const today = moment();
  const mese = meseSelezionato || today.month() + 1;
  const giorno = giornoSelezionato || today.date();
  let index = 0;
  if (tr) {
    for (let i = tr.length - 1; i >= 0; i--) {
      let rata = tr[i];
      if (rata.mese && rata.giorno && (rata.mese > mese || rata.mese === mese && (rata.giorno >= giorno || fineMese))) {
        index = i;
      }
    }
    ntr = ntr.concat(...tr.slice(index), ...tr.slice(0, index));
  }
  return ntr;
};
const buildRate = (form, periodo, dateStart) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
  let rate = form.rate;
  if (periodo === api_rest_1.DisposizioneDtoTipoPeriodoEnum.V && dateStart) {
    const date = moment(dateStart);
    for (let i = 0; i < form.rate.length; i++) {
      const giorniInMese = date.month() == 1 ? date.isLeapYear() && exports.FEBBRAIO_29_ABILITATO ? 29 : 28 : date.daysInMonth();
      rate[i] = {
        index: i,
        giorniInMese,
        giorno: ((_b = (_a = form.rate) === null || _a === void 0 ? void 0 : _a[i]) === null || _b === void 0 ? void 0 : _b.giorno) ? (_d = (_c = form.rate) === null || _c === void 0 ? void 0 : _c[i]) === null || _d === void 0 ? void 0 : _d.giorno : form.fineMese ? giorniInMese : undefined,
        mese: date.month() + 1,
        anno: exports.FEBBRAIO_29_ABILITATO ? 2024 : 2023,
        annoRiferimento: date.year(),
        selected: ((_f = (_e = form.rate) === null || _e === void 0 ? void 0 : _e[i]) === null || _f === void 0 ? void 0 : _f.selected) ? true : false,
        importo: ((_h = (_g = form.rate) === null || _g === void 0 ? void 0 : _g[i]) === null || _h === void 0 ? void 0 : _h.importo) ? (_k = (_j = form.rate) === null || _j === void 0 ? void 0 : _j[i]) === null || _k === void 0 ? void 0 : _k.importo : undefined,
        importoDirty: ((_m = (_l = form.rate) === null || _l === void 0 ? void 0 : _l[i]) === null || _m === void 0 ? void 0 : _m.importoDirty) || false,
        importoError: (((_p = (_o = form.rate) === null || _o === void 0 ? void 0 : _o[i]) === null || _p === void 0 ? void 0 : _p.importo) ? (_r = (_q = form.rate) === null || _q === void 0 ? void 0 : _q[i]) === null || _r === void 0 ? void 0 : _r.importo : undefined) ? '' : 'invalid.required',
        giornoDirty: ((_t = (_s = form.rate) === null || _s === void 0 ? void 0 : _s[i]) === null || _t === void 0 ? void 0 : _t.giornoDirty) || false,
        giornoError: (((_v = (_u = form.rate) === null || _u === void 0 ? void 0 : _u[i]) === null || _v === void 0 ? void 0 : _v.giorno) ? (_x = (_w = form.rate) === null || _w === void 0 ? void 0 : _w[i]) === null || _x === void 0 ? void 0 : _x.giorno : form.fineMese ? giorniInMese : undefined) ? '' : 'invalid.required',
        disabled: false
      };
      date.add(1, 'months');
    }
  }
  return rate.sort((a, b) => a.mese - b.mese);
};
const buildTabellaRateForBozze = (rateSelezionate, form, periodo, giornoSelezionato, importo, dataStart) => {
  let rateComplete = buildRate(form, periodo, dataStart);
  const rateSelezionateArray = rateSelezionate || [];
  if (rateComplete) {
    rateComplete = rateComplete === null || rateComplete === void 0 ? void 0 : rateComplete.map(r => {
      const rataPresente = rateSelezionateArray.find(rs => rs.mese == r.mese);
      if (rataPresente) {
        return {
          ...r,
          importo: rataPresente.importo,
          importoDirty: true,
          importoError: (0, helpers_1.validationField)(rataPresente.importo, 0),
          giorno: rataPresente.giorno,
          giornoDirty: true,
          giornoError: !rataPresente.giorno ? 'invalid.required' : '',
          selected: true
        };
      } else {
        return {
          ...r,
          importo: importo,
          importoDirty: true,
          importoError: (0, helpers_1.validationField)(importo, 0),
          giorno: giornoSelezionato,
          giornoDirty: true,
          selected: false
        };
      }
      //return { ...r, selected: rateSelezionateArray.find(rs => rs.mese == r.mese) ? true : false, importo: importo, giorno: giornoSelezionato, importoDirty: true, giornoDirty: true }
    });
  }
  return rateComplete;
};
const bonificoRipetitivoReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36;
  switch (action.type) {
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_PARAMETRI_SUCCESS:
      {
        const periodi = {
          ...action.payload.periodo
        };
        delete periodi['Q_1'];
        delete periodi['S_1'];
        return {
          ...initialState,
          formRiattiva: {
            ...state.formRiattiva
          },
          esitoRiattiva: {
            ...state.esitoRiattiva
          },
          formSospendi: {
            ...state.formSospendi
          },
          esitoSospendi: {
            ...state.esitoSospendi
          },
          parametri: {
            ...action.payload,
            periodo: periodi
          },
          formElenco: {
            ...state.formElenco
          },
          elenco: {
            ...state.elenco
          },
          parametriElenco: {
            ...state.parametriElenco
          },
          form: {
            ...state.form,
            dataProssimoPagamento: {
              value: state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETIVO.PRIMADATAUTILE'].toString() : '',
              error: '',
              dirty: true
            }
          }
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_PARAMETRI_ELENCO_SUCCESS:
      {
        const periodi = {
          ...action.payload.periodo
        };
        delete periodi['Q_1'];
        delete periodi['S_1'];
        return {
          ...initialState,
          formRiattiva: {
            ...state.formRiattiva
          },
          esitoRiattiva: {
            ...state.esitoRiattiva
          },
          formSospendi: {
            ...state.formSospendi
          },
          esitoSospendi: {
            ...state.esitoSospendi
          },
          parametriElenco: {
            ...action.payload,
            periodo: periodi
          },
          formElenco: {
            ...state.formElenco,
            dataDa: {
              value: String((_a = action.payload.parametri) === null || _a === void 0 ? void 0 : _a["DEFAULT.BONIFICORIPETITIVO.DATA_INIZIALE"]) || '',
              dirty: true,
              error: ''
            },
            dataA: {
              value: String((_b = action.payload.parametri) === null || _b === void 0 ? void 0 : _b["DEFAULT.BONIFICORIPETITIVO.DATA_FINALE"]) || '',
              dirty: true,
              error: ''
            }
          },
          elenco: {
            ...state.elenco
          }
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_PARAMETRI_PER_RIPETI_SUCCESS:
      {
        const periodi = {
          ...action.payload.periodo
        };
        delete periodi['Q_1'];
        delete periodi['S_1'];
        return {
          ...initialState,
          parametri: {
            ...action.payload,
            periodo: periodi
          },
          form: {
            ...state.form,
            addRubrica: false,
            addWhiteList: false,
            ripeti: true
          },
          parametriElenco: action.payload,
          formElenco: {
            ...state.formElenco,
            dataDa: {
              value: String((_c = action.payload.parametri) === null || _c === void 0 ? void 0 : _c["DEFAULT.BONIFICORIPETITIVO.DATA_INIZIALE"]) || '',
              dirty: true,
              error: ''
            },
            dataA: {
              value: String((_d = action.payload.parametri) === null || _d === void 0 ? void 0 : _d["DEFAULT.BONIFICORIPETITIVO.DATA_FINALE"]) || '',
              dirty: true,
              error: ''
            }
          },
          elenco: {
            ...state.elenco
          }
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_PARAMETRI_PER_MODIFICA_SUCCESS:
      {
        const periodi = {
          ...action.payload.periodo
        };
        delete periodi['Q_1'];
        delete periodi['S_1'];
        return {
          ...initialState,
          parametri: {
            ...action.payload,
            periodo: periodi
          },
          formRiattiva: {
            ...state.formRiattiva
          },
          esitoRiattiva: {
            ...state.esitoRiattiva
          },
          formSospendi: {
            ...state.formSospendi
          },
          esitoSospendi: {
            ...state.esitoSospendi
          },
          form: {
            ...state.form,
            importo: ((_g = (_f = (_e = state.esito) === null || _e === void 0 ? void 0 : _e.disposizione) === null || _f === void 0 ? void 0 : _f.disposizione) === null || _g === void 0 ? void 0 : _g.importoBase) || 0,
            descrizione: ((_j = (_h = state.esito) === null || _h === void 0 ? void 0 : _h.disposizione) === null || _j === void 0 ? void 0 : _j.descrizioneMotivazioneOrdine) || '',
            codiceDisposizione: (_m = (_l = (_k = state.esito) === null || _k === void 0 ? void 0 : _k.disposizione) === null || _l === void 0 ? void 0 : _l.disposizione) === null || _m === void 0 ? void 0 : _m.codiceDisposizione,
            isModifica: true
          },
          parametriElenco: action.payload,
          formElenco: {
            ...state.formElenco,
            dataDa: {
              value: String((_o = action.payload.parametri) === null || _o === void 0 ? void 0 : _o["DEFAULT.BONIFICORIPETITIVO.DATA_INIZIALE"]) || '',
              dirty: true,
              error: ''
            },
            dataA: {
              value: String((_p = action.payload.parametri) === null || _p === void 0 ? void 0 : _p["DEFAULT.BONIFICORIPETITIVO.DATA_FINALE"]) || '',
              dirty: true,
              error: ''
            }
          },
          elenco: {
            ...state.elenco
          }
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_CONTROLLO_SUCCESS:
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_CONTROLLO_MODIFICA_SUCCESS:
      return {
        ...state,
        riepilogo: {
          ...action.payload,
          disposizione: {
            ...action.payload.disposizione,
            tabellaRate: buildTabellaRate((_q = action.payload.disposizione) === null || _q === void 0 ? void 0 : _q.tabellaRate, (_r = action.payload.disposizione) === null || _r === void 0 ? void 0 : _r.giornoSelezionato, (_s = action.payload.disposizione) === null || _s === void 0 ? void 0 : _s.meseSelezionato, state.form.fineMese)
          }
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_INFO_DISPOSITIVA_SUCCESS:
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_INFO_MODIFICA_DISPOSITIVA_SUCCESS:
      return {
        ...state,
        infoDispositiva: action.payload,
        esito: initialState.esito
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_AUTORIZZA_SUCCESS:
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_AUTORIZZA_MODIFICA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          //riepilogo: initialState.riepilogo,
          esito: {
            ...action.payload,
            disposizione: {
              ...action.payload.disposizione,
              disposizione: {
                ...((_t = action.payload.disposizione) === null || _t === void 0 ? void 0 : _t.disposizione),
                tabellaRate: buildTabellaRate((_v = (_u = action.payload.disposizione) === null || _u === void 0 ? void 0 : _u.disposizione) === null || _v === void 0 ? void 0 : _v.tabellaRate, (_x = (_w = action.payload.disposizione) === null || _w === void 0 ? void 0 : _w.disposizione) === null || _x === void 0 ? void 0 : _x.giornoSelezionato, (_z = (_y = action.payload.disposizione) === null || _y === void 0 ? void 0 : _y.disposizione) === null || _z === void 0 ? void 0 : _z.meseSelezionato, (_1 = (_0 = action.payload.disposizione) === null || _0 === void 0 ? void 0 : _0.disposizione) === null || _1 === void 0 ? void 0 : _1.fineMese)
              }
            }
          }
        };
      } else {
        return {
          ...state,
          infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
          esito: {
            ...action.payload,
            disposizione: {
              ...action.payload.disposizione,
              disposizione: {
                ...((_2 = action.payload.disposizione) === null || _2 === void 0 ? void 0 : _2.disposizione),
                tabellaRate: buildTabellaRate((_4 = (_3 = action.payload.disposizione) === null || _3 === void 0 ? void 0 : _3.disposizione) === null || _4 === void 0 ? void 0 : _4.tabellaRate, (_6 = (_5 = action.payload.disposizione) === null || _5 === void 0 ? void 0 : _5.disposizione) === null || _6 === void 0 ? void 0 : _6.giornoSelezionato, (_8 = (_7 = action.payload.disposizione) === null || _7 === void 0 ? void 0 : _7.disposizione) === null || _8 === void 0 ? void 0 : _8.meseSelezionato, (_10 = (_9 = action.payload.disposizione) === null || _9 === void 0 ? void 0 : _9.disposizione) === null || _10 === void 0 ? void 0 : _10.fineMese)
              }
            }
          }
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_BENEFICIARIO:
      return {
        ...state,
        form: {
          ...state.form,
          beneficiario: action.payload,
          beneficiarioDirty: true,
          beneficiarioError: (0, helpers_1.validationField)(action.payload, '', false, 3),
          addRubrica: false,
          addWhiteList: false,
          bozzaSalvata: false,
          idContattoBeneficiario: undefined
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_IBAN:
      return {
        ...state,
        form: {
          ...state.form,
          iban: action.payload,
          ibanDirty: true,
          ibanError: (0, helpers_1.validationField)(action.payload, '', true),
          addRubrica: false,
          addWhiteList: false,
          bozzaSalvata: false,
          idContattoBeneficiario: undefined
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_ID_CONTATTO_BENEFICIARIO:
      return {
        ...state,
        form: {
          ...state.form,
          idContattoBeneficiario: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_ADD_RUBRICA:
      return {
        ...state,
        form: {
          ...state.form,
          addRubrica: action.payload,
          addWhiteList: !action.payload && false
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_ADD_WHITELIST:
      return {
        ...state,
        form: {
          ...state.form,
          addWhiteList: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_IMPORTO:
      return {
        ...state,
        form: {
          ...state.form,
          importo: action.payload,
          importoDirty: true,
          importoError: (0, helpers_1.validationField)(action.payload, 0),
          bozzaSalvata: false,
          rate: state.form.rate.map(r => ({
            ...r,
            importo: action.payload,
            importoError: (0, helpers_1.validationField)(action.payload, 0)
          }))
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_DATA_ESEC:
      return {
        ...state,
        form: {
          ...state.form,
          dataEsecuzione: action.payload,
          dataEsecuzioneDirty: true,
          dataEsecuzioneError: (0, helpers_1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETITIVO.FESTIVI'] : [], true, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETIVO.PRIMADATAUTILE'].toString() : '')
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_DESCRIZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          descrizione: action.payload,
          descrizioneDirty: true,
          descrizioneError: (0, helpers_1.validationField)(action.payload, '', undefined, 3),
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_TIPO_IMPORTO:
      return {
        ...state,
        form: {
          ...state.form,
          importo: action.payload === api_rest_1.DisposizioneDtoTipoImportoEnum.V ? initialState.form.importo : state.form.importo,
          importoError: action.payload === api_rest_1.DisposizioneDtoTipoImportoEnum.V ? '' : (0, helpers_1.validationField)(state.form.importo, ''),
          giorno: initialState.form.giorno,
          fineMese: false,
          tipoDisposizione: initialState.form.tipoDisposizione,
          dataScadenza: initialState.form.dataScadenza,
          dataProssimoPagamento: initialState.form.dataProssimoPagamento,
          rate: action.payload === state.form.tipoImporto.value ? state.form.rate : state.form.rate.map(r => ({
            ...r,
            selected: false,
            giorno: undefined,
            giornoDirty: false,
            giornoError: '',
            importo: undefined,
            importoDirty: false,
            importoError: ''
          })),
          tipoImporto: {
            value: action.payload,
            error: (0, helpers_1.validationField)(action.payload, ''),
            dirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_TIPO_DATA_PAGAMENTO:
      return {
        ...state,
        form: {
          ...state.form,
          tipoDataPagamento: {
            value: action.payload,
            error: (0, helpers_1.validationField)(action.payload, ''),
            dirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_PERIODO:
      let rate = buildRate(state.form, action.payload, action.dateStart);
      return {
        ...state,
        form: {
          ...state.form,
          giorno: action.payload === api_rest_1.DisposizioneDtoTipoPeriodoEnum.V && state.form.tipoImporto.value === api_rest_1.DisposizioneDtoTipoImportoEnum.F ? state.form.giorno : initialState.form.giorno,
          periodo: {
            value: action.payload,
            error: (0, helpers_1.validationField)(action.payload, ''),
            dirty: true
          },
          rate: rate,
          rateDirty: false,
          rateError: state.form.periodo.value === 'V' && !rate.some(r => r.selected) ? 'invalid.selezionareAlmenoUnMese' : '',
          dateStart: action.dateStart,
          bozzaSalvata: false,
          tipoDisposizione: action.payload === 'V' ? initialState.form.tipoDisposizione : state.form.tipoDisposizione,
          dataScadenza: action.payload === 'V' ? initialState.form.dataScadenza : state.form.dataScadenza,
          dataProssimoPagamento: {
            value: state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETIVO.PRIMADATAUTILE'].toString() : '',
            error: '',
            dirty: true
          }
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_TIPO_DISPOSIZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          tipoDisposizione: {
            value: action.payload,
            error: (0, helpers_1.validationField)(action.payload, ''),
            dirty: true
          },
          dataScadenza: initialState.form.dataScadenza,
          numeroRate: initialState.form.numeroRate,
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_DATA_SCADENZA:
      return {
        ...state,
        form: {
          ...state.form,
          dataScadenza: {
            value: action.payload,
            error: (0, helpers_1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETITIVO.FESTIVI'] : [], true, state.form.dataProssimoPagamento.value),
            dirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_NUMERO_RATE:
      return {
        ...state,
        form: {
          ...state.form,
          numeroRate: {
            value: action.payload,
            error: (0, helpers_1.validationField)(action.payload, ''),
            dirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GIORNO:
      return {
        ...state,
        form: {
          ...state.form,
          giorno: {
            value: action.payload,
            error: (0, helpers_1.validationField)(action.payload, 0, false, undefined, undefined, false, true),
            dirty: true
          },
          fineMese: false,
          rate: state.form.rate ? state.form.rate.map((r, i) => ({
            ...r,
            giorno: Math.min(r.giorniInMese, Number(action.payload)) || r.giorno
          })) : state.form.rate,
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_FINE_MESE:
      const date = moment(state.form.dataProssimoPagamento.value);
      const giorniInMese = date.month() == 1 ? date.isLeapYear() ? 28 : date.daysInMonth() : date.daysInMonth();
      return {
        ...state,
        form: {
          ...state.form,
          fineMese: action.payload,
          giorno: action.payload && state.form.dataProssimoPagamento.value && state.form.tipoImporto.value !== api_rest_1.DisposizioneDtoTipoImportoEnum.V ? {
            value: giorniInMese.toString(),
            dirty: true,
            error: ''
          } : {
            value: '',
            dirty: false,
            error: 'invalid.required'
          },
          dataProssimoPagamento: action.payload && state.form.dataProssimoPagamento.value ? {
            // value: (moment(state.form.dataProssimoPagamento.value).endOf('month').day() === 6 || moment(state.form.dataProssimoPagamento.value).endOf('month').day() === 0) ? moment(state.form.dataProssimoPagamento.value).endOf('month').day(5).toString() : moment(state.form.dataProssimoPagamento.value).endOf('month').toString(),
            value: moment(state.form.dataProssimoPagamento.value).endOf('month').day() === 6 || moment(state.form.dataProssimoPagamento.value).endOf('month').day() === 0 ? moment(state.form.dataProssimoPagamento.value).endOf('month').day(5) : moment(state.form.dataProssimoPagamento.value).endOf('month'),
            dirty: true,
            error: ''
          } : state.form.dataProssimoPagamento,
          rate: action.payload && state.form.rate ? state.form.rate.map(r => {
            const giornoValido = r.giorniInMese;
            const dayValid = !(0, helpers_1.validationFieldDate)(moment(new Date(r.anno, r.mese - 1, giornoValido)).format('YYYY-MM-DD'), [], false);
            return {
              ...r,
              giorno: dayValid ? giornoValido : 1,
              giornoDirty: true,
              giornoError: '',
              disabled: false
            };
          }) : state.form.rate.map(r => {
            return {
              ...r,
              giorno: undefined,
              giornoDirty: false,
              giornoError: 'invalid.required',
              selected: r.selected,
              importo: r.importo
            };
          }),
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_RATA_SELECTED:
      {
        const _rate = state.form.rate.map((r, i) => {
          if (i === action.position) {
            const selected = !!action.payload;
            const giorno = selected ? state.form.fineMese ? r.giorniInMese : !!state.form.giorno.error || !state.form.giorno.value ? undefined : Math.min(r.giorniInMese, Number(state.form.giorno.value)) : r.giorno;
            const importo = selected ? undefined : r.importo;
            return {
              ...r,
              selected,
              giorno,
              giornoDirty: false,
              giornoError: (0, helpers_1.validationFieldDate)(moment(new Date(r.anno, r.mese - 1, giorno)).format('YYYY-MM-DD'), [], false),
              importo,
              importoDirty: false,
              importoError: (0, helpers_1.validationField)(importo, 0)
            };
          }
          return r;
        });
        return {
          ...state,
          form: {
            ...state.form,
            rate: _rate,
            rateError: state.form.periodo.value === 'V' && !_rate.some(r => r.selected) ? 'invalid.selezionareAlmenoUnMese' : '',
            rateDirty: true
          }
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_RATA_GIORNO:
      {
        const _rate = state.form.rate.map((r, i) => i === action.position ? {
          ...r,
          giorno: action.payload,
          giornoDirty: true,
          giornoError: (0, helpers_1.validationFieldDate)(moment(new Date(r.anno, r.mese - 1, r.giorniInMese)).format('YYYY-MM-DD'), [], false)
        } : r);
        return {
          ...state,
          form: {
            ...state.form,
            rate: _rate
          }
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_RATA_IMPORTO:
      {
        // const primoGiornoUtile = moment(state.parametri!.parametri!['BONIFICORIPETIVO.PRIMADATAUTILE'], 'YYYY-MM-DD').date() || moment().date();
        const _rate = state.form.rate.map((r, i) => i === action.position ? {
          ...r,
          importo: action.payload,
          importoDirty: true,
          importoError: (0, helpers_1.validationField)(action.payload, 0)
        } : r);
        return {
          ...state,
          form: {
            ...state.form,
            rate: _rate
          }
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_DATA_PROSSIMO_PAGAMENTO:
      return {
        ...state,
        form: {
          ...state.form,
          dataProssimoPagamento: {
            value: action.payload,
            error: (0, helpers_1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETITIVO.FESTIVI'] : [], true, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETIVO.PRIMADATAUTILE'].toString() : '', '', false),
            dirty: true
          },
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_INTESTATARIO:
      return {
        ...state,
        form: {
          ...state.form,
          intestatario: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_IBAN_INT:
      return {
        ...state,
        form: {
          ...state.form,
          IBANInt: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload,
          bozzaSalvata: false
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_FORM_SUBMITTED:
      return {
        ...state,
        form: {
          ...state.form,
          formSubmitted: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_RESET:
      return {
        ...initialState,
        // mantengo i seguenti stati per consentire il back su esito riepilogo o esito sospendi
        formRiattiva: {
          ...state.formRiattiva
        },
        esitoRiattiva: {
          ...state.esitoRiattiva
        },
        formSospendi: {
          ...state.formSospendi
        },
        esitoSospendi: {
          ...state.esitoSospendi
        },
        parametriElenco: {
          ...state.parametriElenco
        },
        formElenco: {
          ...state.formElenco
        },
        elenco: {
          ...state.elenco
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_RIEPILOGO_REVOCA:
      return {
        ...state,
        formRevoca: initialState.formRevoca,
        riepilogoRevoca: action.payload
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_PROVENIENZA:
      return {
        ...state,
        formRevoca: {
          ...state.formRevoca,
          provenienza: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_STEP_REVOCA:
      return {
        ...state,
        formRevoca: {
          ...state.formRevoca,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_INFO_REVOCA_SUCCESS:
      return {
        ...state,
        infoRevoca: action.payload,
        esitoRevoca: initialState.esitoRevoca
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_BOZZASALVATA:
      return {
        ...state,
        form: {
          ...state.form,
          bozzaSalvata: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_AUTORIZZA_REVOCA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          esitoRevoca: action.payload
        };
      } else {
        return {
          ...state,
          parametri: initialState.parametri,
          riepilogo: initialState.riepilogo,
          esito: initialState.esito,
          form: initialState.form,
          riepilogoRevoca: initialState.riepilogoRevoca,
          infoRevoca: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
          esitoRevoca: action.payload
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_BOZZE_SUCCESS:
      return {
        ...state,
        bozzeBonifico: action.payload
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_BOZZA_SUCCESS:
      rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, (_11 = action.payload.ordinante) === null || _11 === void 0 ? void 0 : _11.contoAddebito);
      return {
        ...state,
        form: {
          ...state.form,
          beneficiario: ((_12 = action.payload.beneficiario) === null || _12 === void 0 ? void 0 : _12.anagrafica) || '',
          beneficiarioDirty: false,
          beneficiarioError: (0, helpers_1.validationField)((_13 = action.payload.beneficiario) === null || _13 === void 0 ? void 0 : _13.anagrafica, '', false, 3),
          iban: ((_14 = action.payload.beneficiario) === null || _14 === void 0 ? void 0 : _14.codiceIban) || '',
          ibanDirty: false,
          ibanError: (0, helpers_1.validationField)((_15 = action.payload.beneficiario) === null || _15 === void 0 ? void 0 : _15.codiceIban, '', true),
          importo: ((_16 = action.payload.disposizione) === null || _16 === void 0 ? void 0 : _16.importoBase) || 0,
          importoDirty: false,
          importoError: (0, helpers_1.validationField)((_17 = action.payload.disposizione) === null || _17 === void 0 ? void 0 : _17.importoBase, 0),
          descrizione: action.payload.descrizioneMotivazioneOrdine || action.payload.descrizioneCausale || '',
          descrizioneDirty: false,
          descrizioneError: (0, helpers_1.validationField)(action.payload.descrizioneMotivazioneOrdine || action.payload.descrizioneCausale || '', ''),
          rapportoSelezionato: rapportoSelezionato,
          periodo: {
            value: ((_18 = action.payload.disposizione) === null || _18 === void 0 ? void 0 : _18.periodo) ? action.payload.disposizione.periodo : '',
            dirty: false,
            error: ''
          },
          fineMese: !!((_19 = action.payload.disposizione) === null || _19 === void 0 ? void 0 : _19.fineMese),
          bozzaSalvata: true
        },
        currentBozza: action.payload
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_BONIFICO_FROM_BOZZA:
      {
        rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, (_20 = action.payload.ordinante) === null || _20 === void 0 ? void 0 : _20.contoAddebito);
        const {
          descrizioneCausale,
          descrizioneMotivazioneOrdine,
          disposizione: {
            importoBase,
            periodo,
            tipoPeriodo,
            tipoData,
            tipoDisposizione,
            tipoImporto,
            numeroRateTotali,
            dataPrimaRata,
            fineMese,
            giornoSelezionato,
            meseSelezionato,
            annoSelezionato,
            dataFineDisposizione
          } = {
            importoBase: 0,
            periodo: '',
            tipoPeriodo: '',
            tipoData: '',
            tipoDisposizione: '',
            tipoImporto: '',
            numeroRateTotali: undefined,
            dataPrimaRata: new Date(),
            fineMese: false,
            giornoSelezionato: 0,
            meseSelezionato: 0,
            annoSelezionato: 0,
            dataFineDisposizione: ''
          },
          beneficiario: {
            anagrafica,
            codiceIban
          } = {
            anagrafica: '',
            codiceIban: ''
          }
        } = action.payload;
        const formattedDataProssimoPagamento = `${annoSelezionato}-${meseSelezionato === null || meseSelezionato === void 0 ? void 0 : meseSelezionato.toString().padStart(2, '0')}-${giornoSelezionato === null || giornoSelezionato === void 0 ? void 0 : giornoSelezionato.toString().padStart(2, '0')}`;
        return {
          ...state,
          form: {
            ...state.form,
            beneficiario: anagrafica || '',
            beneficiarioDirty: false,
            beneficiarioError: '',
            iban: codiceIban || '',
            ibanDirty: false,
            ibanError: '',
            importo: importoBase || 0,
            importoDirty: false,
            importoError: '',
            descrizione: descrizioneMotivazioneOrdine || descrizioneCausale || '',
            descrizioneDirty: false,
            descrizioneError: '',
            rapportoSelezionato: rapportoSelezionato,
            dataScadenza: {
              dirty: false,
              value: moment(dataFineDisposizione).format('YYYY-MM-DD'),
              error: (0, helpers_1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETITIVO.FESTIVI'] : [], true, state.form.dataProssimoPagamento.value)
            },
            periodo: {
              dirty: false,
              value: tipoPeriodo === 'V' ? 'V' : periodo || '',
              error: ''
            },
            tipoDataPagamento: {
              value: tipoData || state.form.tipoDataPagamento.value,
              error: (0, helpers_1.validationField)(tipoData || state.form.tipoDataPagamento.value, ''),
              dirty: true
            },
            tipoDisposizione: {
              value: tipoDisposizione || state.form.tipoDisposizione.value,
              error: (0, helpers_1.validationField)(tipoDisposizione || state.form.tipoDisposizione.value, ''),
              dirty: true
            },
            tipoImporto: {
              value: tipoImporto || state.form.tipoImporto.value,
              error: (0, helpers_1.validationField)(tipoImporto || state.form.tipoImporto.value, ''),
              dirty: true
            },
            numeroRate: {
              value: String(numeroRateTotali) || state.form.numeroRate.value,
              error: (0, helpers_1.validationField)(numeroRateTotali || state.form.tipoImporto.value, ''),
              dirty: true
            },
            dataProssimoPagamento: {
              value: moment(dataPrimaRata || formattedDataProssimoPagamento),
              error: '',
              dirty: true
            },
            fineMese: !!fineMese,
            giorno: ((_21 = action.payload.disposizione) === null || _21 === void 0 ? void 0 : _21.tipoPeriodo) === api_rest_1.DisposizioneDtoTipoPeriodoEnum.V && action.payload.disposizione.tipoImporto === api_rest_1.DisposizioneDtoTipoImportoEnum.F ? {
              value: String(action.payload.disposizione.giornoSelezionato),
              error: '',
              dirty: false
            } : initialState.form.giorno,
            dateStart: moment().format('YYYY-MM-DD'),
            bozzaSalvata: false,
            rate: buildTabellaRateForBozze((_22 = action.payload.disposizione) === null || _22 === void 0 ? void 0 : _22.tabellaRate, state.form, (_23 = action.payload.disposizione) === null || _23 === void 0 ? void 0 : _23.tipoPeriodo, giornoSelezionato, importoBase, moment().toString()) || []
          },
          currentBozza: action.noBozza ? initialState.currentBozza : action.payload
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_BONIFICO_FOR_MODIFICA:
      {
        rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, (_24 = action.payload.ordinante) === null || _24 === void 0 ? void 0 : _24.contoAddebito);
        const {
          descrizioneMotivazioneOrdine,
          disposizione: {
            importoBase,
            periodo,
            tipoData,
            tipoDisposizione,
            tipoImporto,
            numeroRateTotali,
            dataPrimaRata,
            giornoSelezionato,
            tabellaRate,
            tipoPeriodo
          } = {
            importoBase: 0,
            periodo: '',
            tipoData: '',
            tipoDisposizione: '',
            tipoImporto: '',
            numeroRateTotali: undefined,
            dataPrimaRata: new Date(),
            giornoSelezionato: 0,
            tabellaRate: [],
            tipoPeriodo: ''
          },
          beneficiario: {
            anagrafica,
            codiceIban
          } = {
            anagrafica: '',
            codiceIban: ''
          }
        } = action.payload;
        return {
          ...state,
          form: {
            ...state.form,
            beneficiario: anagrafica || '',
            beneficiarioDirty: false,
            beneficiarioError: '',
            iban: codiceIban || '',
            ibanDirty: false,
            ibanError: '',
            importo: importoBase || 0,
            importoDirty: false,
            importoError: '',
            descrizione: descrizioneMotivazioneOrdine || '',
            descrizioneDirty: false,
            descrizioneError: '',
            rapportoSelezionato: rapportoSelezionato,
            periodo: {
              dirty: false,
              value: tipoPeriodo === 'V' ? 'V' : periodo || '',
              error: ''
            },
            tipoDataPagamento: {
              value: tipoData || state.form.tipoDataPagamento.value,
              error: (0, helpers_1.validationField)(tipoData || state.form.tipoDataPagamento.value, ''),
              dirty: true
            },
            tipoDisposizione: {
              value: tipoDisposizione || state.form.tipoDisposizione.value,
              error: (0, helpers_1.validationField)(tipoDisposizione || state.form.tipoDisposizione.value, ''),
              dirty: true
            },
            tipoImporto: {
              value: tipoImporto || state.form.tipoImporto.value,
              error: (0, helpers_1.validationField)(tipoImporto || state.form.tipoImporto.value, ''),
              dirty: true
            },
            numeroRate: {
              value: String(numeroRateTotali) || state.form.numeroRate.value,
              error: (0, helpers_1.validationField)(numeroRateTotali || state.form.tipoImporto.value, ''),
              dirty: true
            },
            dataProssimoPagamento: {
              value: moment(dataPrimaRata),
              error: '',
              dirty: true
            },
            isModifica: true,
            id: action.payload.id,
            codiceDisposizione: (_25 = action.payload.disposizione) === null || _25 === void 0 ? void 0 : _25.codiceDisposizione,
            giorno: tipoPeriodo === api_rest_1.DisposizioneDtoTipoPeriodoEnum.V && tipoImporto === api_rest_1.DisposizioneDtoTipoImportoEnum.F ? {
              value: String(giornoSelezionato),
              error: '',
              dirty: false
            } : initialState.form.giorno,
            rate: buildTabellaRateForBozze((_26 = action.payload.disposizione) === null || _26 === void 0 ? void 0 : _26.tabellaRate, state.form, (_27 = action.payload.disposizione) === null || _27 === void 0 ? void 0 : _27.tipoPeriodo, giornoSelezionato, importoBase, moment().toString()) || []
          },
          currentBozza: initialState.currentBozza
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_ADD_BOZZA_SUCCESS:
      return {
        ...state,
        bozzeBonifico: {
          ripetitivi: state.bozzeBonifico && state.bozzeBonifico.ripetitivi ? [...((_28 = state.bozzeBonifico) === null || _28 === void 0 ? void 0 : _28.ripetitivi), action.payload] : []
        },
        form: {
          ...state.form,
          bozzaSalvata: true
        },
        currentBozza: action.payload
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_DEL_BOZZA_SUCCESS:
      return {
        ...state,
        bozzeBonifico: {
          ripetitivi: state.bozzeBonifico && state.bozzeBonifico.ripetitivi ? state.bozzeBonifico.ripetitivi.filter(bonificoRipetitivo => bonificoRipetitivo.id !== action.payload) : []
        },
        form: {
          ...state.form,
          bozzaSalvata: state.currentBozza.id === action.payload ? false : state.form.bozzaSalvata
        },
        currentBozza: state.currentBozza.id === action.payload ? initialState.currentBozza : state.currentBozza
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_UPDATE_BOZZA_SUCCESS:
      return {
        ...state,
        bozzeBonifico: {
          ripetitivi: state.bozzeBonifico && state.bozzeBonifico.ripetitivi ? state.bozzeBonifico.ripetitivi.map(bonificoRipetitivo => bonificoRipetitivo.id === state.currentBozza.id ? action.payload : bonificoRipetitivo) : []
        },
        form: {
          ...state.form,
          bozzaSalvata: true
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_RESET_RIEPILOGO:
      return {
        ...state,
        riepilogo: initialState.riepilogo
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_SET_PAGAMENTO_RIPETIVIVO_SELEZIONATO:
      return {
        ...state,
        pagamentoRipetitivoSelezionato: action.payload
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          rapportoSelezionato: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_SET_BENEFICIARIO:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          beneficiario: {
            value: action.payload,
            error: (0, helpers_1.validationField)(action.payload, ''),
            dirty: true
          }
        }
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_SET_STATI:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          stati: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_DATA_DA:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          dataDa: {
            value: action.payload,
            error: (0, helpers_1.validationField)(action.payload, ''),
            dirty: true
          }
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_DATA_A:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          dataA: {
            value: action.payload,
            error: (0, helpers_1.validationField)(action.payload, ''),
            dirty: true
          }
        }
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_SET_CAUSALE:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          causale: {
            value: action.payload,
            error: (0, helpers_1.validationField)(action.payload, ''),
            dirty: true
          }
        }
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_SET_IMPORTO:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          importo: {
            value: action.payload,
            error: (0, helpers_1.validationField)(action.payload, ''),
            dirty: true
          }
        }
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_SET_ACCORDION_FILTRI_OPENED:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          accordionFiltriOpened: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_SET_FORM_SUBMITTED:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          formSubmitted: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_RESET_FORM_ELENCO:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          beneficiario: {
            value: '',
            dirty: false,
            error: ''
          },
          stati: [],
          causale: {
            value: '',
            dirty: false,
            error: ''
          },
          importo: {
            value: undefined,
            dirty: false,
            error: ''
          },
          dataDa: {
            value: String((_30 = (_29 = state.parametriElenco) === null || _29 === void 0 ? void 0 : _29.parametri) === null || _30 === void 0 ? void 0 : _30["DEFAULT.BONIFICORIPETITIVO.DATA_INIZIALE"]) || '',
            dirty: true,
            error: ''
          },
          dataA: {
            value: String((_32 = (_31 = state.parametriElenco) === null || _31 === void 0 ? void 0 : _31.parametri) === null || _32 === void 0 ? void 0 : _32["DEFAULT.BONIFICORIPETITIVO.DATA_FINALE"]) || '',
            dirty: true,
            error: ''
          },
          offset: initialState.formElenco.offset,
          formSubmitted: false,
          formElencoDirty: initialState.formElenco.formElencoDirty
        }
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_GET_ELENCO_SUCCESS:
      return {
        ...state,
        elenco: !action.offset ? action.payload : {
          ...state.elenco,
          ripetitivi: (state.elenco.ripetitivi || []).concat(action.payload.ripetitivi || [])
        }
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_GET_DETTAGLIO_BONIFICO_RIPETITIVO_SUCCESS:
      return {
        ...state,
        dettaglioBonificoRipetitivo: {
          ...action.payload,
          disposizione: {
            ...action.payload.disposizione,
            tabellaRate: buildTabellaRate((_33 = action.payload.disposizione) === null || _33 === void 0 ? void 0 : _33.tabellaRate, (_34 = action.payload.disposizione) === null || _34 === void 0 ? void 0 : _34.giornoSelezionato, (_35 = action.payload.disposizione) === null || _35 === void 0 ? void 0 : _35.meseSelezionato, (_36 = action.payload.disposizione) === null || _36 === void 0 ? void 0 : _36.fineMese)
          }
        }
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_RESET_DETTAGLIO_BONIFICO_RIPETITIVO:
      return {
        ...state,
        dettaglioBonificoRipetitivo: initialState.dettaglioBonificoRipetitivo
      };
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_SET_FORM_DIRTY:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          formElencoDirty: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_RIEPILOGO_SOSPENDI:
      return {
        ...state,
        formSospendi: initialState.formSospendi,
        infoSospendi: initialState.infoSospendi,
        esitoSospendi: initialState.esitoSospendi,
        riepilogoSospendi: action.payload
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_PROVENIENZA_SOSPENDI:
      return {
        ...state,
        formSospendi: {
          ...state.formSospendi,
          provenienza: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_DATA_INIZIO_SOSPENSIONE_SOSPENDI:
      return {
        ...state,
        formSospendi: {
          ...state.formSospendi,
          dataInizioSospensione: {
            value: action.payload,
            error: (0, helpers_1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETITIVO.FESTIVI'] : [], true, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETIVO.PRIMADATAUTILE'].toString() : ''),
            dirty: true
          }
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_DATA_FINE_SOSPENSIONE_SOSPENDI:
      return {
        ...state,
        formSospendi: {
          ...state.formSospendi,
          dataFineSospensione: {
            value: action.payload,
            error: (0, helpers_1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETITIVO.FESTIVI'] : [], true, state.formSospendi.dataInizioSospensione ? state.formSospendi.dataInizioSospensione.value : state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETIVO.PRIMADATAUTILE'].toString() : ''),
            dirty: true
          }
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_STEP_SOSPENDI:
      return {
        ...state,
        formSospendi: {
          ...state.formSospendi,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_INFO_SOSPENDI_SUCCESS:
      return {
        ...state,
        infoSospendi: action.payload,
        esitoSospendi: initialState.esitoSospendi
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_AUTORIZZA_SOSPENDI_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          esitoSospendi: action.payload
        };
      } else {
        return {
          ...state,
          parametri: initialState.parametri,
          riepilogo: initialState.riepilogo,
          esito: initialState.esito,
          form: initialState.form,
          riepilogoSospendi: initialState.riepilogoSospendi,
          infoSospendi: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
          esitoSospendi: action.payload
        };
      }
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_RIEPILOGO_RIATTIVA:
      return {
        ...state,
        formRiattiva: initialState.formRiattiva,
        infoRiattiva: initialState.infoRiattiva,
        esitoRiattiva: initialState.esitoRiattiva,
        riepilogoRiattiva: action.payload
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_PROVENIENZA_RIATTIVA:
      return {
        ...state,
        formRiattiva: {
          ...state.formRiattiva,
          provenienza: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_DATA_RIATTIVAZIONE_RIATTIVA:
      return {
        ...state,
        formRiattiva: {
          ...state.formRiattiva,
          dataRiattivazione: {
            value: action.payload,
            error: (0, helpers_1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETITIVO.FESTIVI'] : [], true, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICORIPETIVO.PRIMADATAUTILE'].toString() : ''),
            dirty: true
          }
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_SET_STEP_RIATTIVA:
      return {
        ...state,
        formRiattiva: {
          ...state.formRiattiva,
          step: action.payload
        }
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_GET_INFO_RIATTIVA_SUCCESS:
      return {
        ...state,
        infoRiattiva: action.payload,
        esitoRiattiva: initialState.esitoRiattiva
      };
    case __1.EReduxActionTypes.BONIFICO_RIPETITIVO_AUTORIZZA_RIATTIVA_SUCCESS:
      if (action.payload.stato === 'OK') {
        return {
          ...state,
          esitoRiattiva: action.payload
        };
      } else {
        return {
          ...state,
          parametri: initialState.parametri,
          riepilogo: initialState.riepilogo,
          esito: initialState.esito,
          form: initialState.form,
          riepilogoRiattiva: initialState.riepilogoRiattiva,
          infoRiattiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
          esitoRiattiva: action.payload
        };
      }
    case __1.EReduxActionTypes.BONIFICI_RIPETITIVI_SET_OFFSET:
      return {
        ...state,
        formElenco: {
          ...state.formElenco,
          offset: action.payload
        }
      };
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.bonificoRipetitivoReducer = bonificoRipetitivoReducer;
exports.default = exports.bonificoRipetitivoReducer;