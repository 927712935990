"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.movimentiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const moment = require("moment");
const __1 = require("..");
const helpers_1 = require("../helpers");
const initialState = {
  tempParametri: (0, api_rest_1.ParametriMovimentiDtoFromJSON)({}),
  parametri: (0, api_rest_1.ParametriMovimentiDtoFromJSON)({}),
  parametriWidget: (0, api_rest_1.ParametriMovimentiDtoFromJSON)({}),
  parametriContoDeposito: (0, api_rest_1.ParametriMovimentiDtoFromJSON)({}),
  movimentiHomepage: (0, api_rest_1.ListaMovimentiDtoFromJSON)({}),
  movimenti: (0, api_rest_1.ListaMovimentiDtoFromJSON)({}),
  form: {
    periodo: "",
    entrate: true,
    uscite: true,
    sorting: api_rest_1.CriteriRicercaMovimentiRequestInOrdinamentoEnum.D,
    movimentiNonContabilizzati: false
  },
  pdfMovimenti: undefined,
  excelMovimenti: undefined,
  pdfDettaglioMovimento: undefined,
  parametriCartaConto: (0, api_rest_1.ParametriMovimentiDtoFromJSON)({}),
  saldoComplessivo: undefined,
  startPinViewResponse: undefined,
  getPinViewResponse: undefined,
  startPanViewResponse: undefined,
  getPanViewResponse: undefined,
  copyPanViewResponse: undefined
};
const fixIBAN = rapporti => {
  return rapporti === null || rapporti === void 0 ? void 0 : rapporti.map(rapporto => {
    var _a, _b, _c;
    if (!!((_a = rapporto.rapporto) === null || _a === void 0 ? void 0 : _a.psd2)) {
      return {
        ...rapporto,
        rapporto: {
          ...rapporto.rapporto,
          iban: ((_b = rapporto.rapporto) === null || _b === void 0 ? void 0 : _b.iban) + "_" + ((_c = rapporto.rapporto) === null || _c === void 0 ? void 0 : _c.divisa)
        }
      };
    }
    return rapporto;
  });
};
const movimentiReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  var _a, _b, _c, _d, _e, _f, _g;
  switch (action.type) {
    case __1.EReduxActionTypes.MOVIMENTI_GET_PARAMETRI_SUCCESS:
      {
        const oldRapportiPsd2 = (_a = state.parametri.rapporti) === null || _a === void 0 ? void 0 : _a.filter(r => {
          var _a;
          return !!((_a = r.rapporto) === null || _a === void 0 ? void 0 : _a.psd2);
        });
        const oldHasPsd2Rapporti = oldRapportiPsd2 && (oldRapportiPsd2 === null || oldRapportiPsd2 === void 0 ? void 0 : oldRapportiPsd2.length) > 0;
        const newRapportiPsd2 = (_b = action.payload.rapporti) === null || _b === void 0 ? void 0 : _b.filter(r => {
          var _a;
          return !!((_a = r.rapporto) === null || _a === void 0 ? void 0 : _a.psd2);
        });
        const newHasPsd2Rapporti = newRapportiPsd2 && (newRapportiPsd2 === null || newRapportiPsd2 === void 0 ? void 0 : newRapportiPsd2.length) > 0;
        return {
          ...state,
          tempParametri: newHasPsd2Rapporti ? oldHasPsd2Rapporti ? state.tempParametri : state.parametri : action.payload,
          parametri: {
            ...action.payload,
            rapporti: fixIBAN(action.payload.rapporti)
          },
          parametriWidget: newHasPsd2Rapporti ? state.parametriWidget : action.payload,
          form: {
            ...state.form,
            isDeposito: initialState.form.isDeposito,
            isCarta: initialState.form.isCarta
          }
        };
      }
    case __1.EReduxActionTypes.MOVIMENTI_SWITCH_TO_TEMP_PARAMETRI:
      {
        const rapporti = (_c = state.parametri.rapporti) === null || _c === void 0 ? void 0 : _c.filter(r => {
          var _a;
          return !!((_a = r.rapporto) === null || _a === void 0 ? void 0 : _a.psd2);
        });
        const hasPsd2Rapporti = rapporti && (rapporti === null || rapporti === void 0 ? void 0 : rapporti.length) > 0;
        if (hasPsd2Rapporti) {
          console.log("ROLLBACK");
          return {
            ...state,
            parametri: state.tempParametri
          };
        }
        return {
          ...state
        };
      }
    case __1.EReduxActionTypes.MOVIMENTI_GET_PARAMETRI_CONTO_DEPOSITO_SUCCESS:
      return {
        ...state,
        parametriContoDeposito: action.payload,
        form: {
          ...state.form,
          isDeposito: true,
          isCarta: initialState.form.isCarta
        }
      };
    case __1.EReduxActionTypes.MOVIMENTI_GET_MOVIMENTI_HOMEPAGE_SUCCESS:
      return {
        ...state,
        movimentiHomepage: action.payload
      };
    case __1.EReduxActionTypes.MOVIMENTI_GET_MOVIMENTI_SUCCESS:
      return {
        ...state,
        movimenti: !action.offset ? action.payload : {
          ...state.movimenti,
          saldoFinale: action.payload.saldoFinale,
          movimenti: (state.movimenti.movimenti || []).concat(action.payload.movimenti || [])
        }
      };
    case __1.EReduxActionTypes.MOVIMENTI_ESPORTA_PDF_SUCCESS:
      return {
        ...state,
        pdfMovimenti: action.payload
      };
    case __1.EReduxActionTypes.MOVIMENTI_ESPORTA_EXCEL_SUCCESS:
      return {
        ...state,
        excelMovimenti: action.payload
      };
    case __1.EReduxActionTypes.MOVIMENTI_ESPORTA_DETTAGLIO_PDF_SUCCESS:
      return {
        ...state,
        pdfDettaglioMovimento: action.payload
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_SET_RAPPORTO_SELEZIONATO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionato: action.payload
        },
        startPinViewResponse: initialState.startPinViewResponse,
        getPinViewResponse: initialState.getPinViewResponse
      };
    case __1.EReduxActionTypes.CARTA_CONTO_START_PAN_VIEW__SCA_SUCCESS:
      {
        return {
          ...state,
          startPanViewResponse: action.payload
        };
      }
      ;
    case __1.EReduxActionTypes.CARTA_CONTO_GET_PAN_VIEW_SUCCESS:
      {
        return {
          ...state,
          getPanViewResponse: action.payload
        };
      }
      ;
    case __1.EReduxActionTypes.CARTA_CONTO_COPY_PAN_VIEW_SUCCESS:
      {
        return {
          ...state,
          copyPanViewResponse: action.payload
        };
      }
    case __1.EReduxActionTypes.CARTA_CONTO_RESET_PAN_VIEW:
      {
        return {
          ...state,
          startPanViewResponse: initialState.startPanViewResponse,
          getPanViewResponse: initialState.getPanViewResponse
        };
      }
      ;
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_SET_RAPPORTO_SELEZIONATO_CONTO_DEPOSITO:
      return {
        ...state,
        form: {
          ...state.form,
          rapportoSelezionatoContoDeposito: action.payload
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_CONTO:
      return {
        ...state,
        form: {
          ...state.form,
          conto: action.payload
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_RESET_FILTRO:
      let periodo;
      if (state.parametri.parametri) {
        periodo = state.parametri.parametri["MOVIMENTI.PERIODI.RICERCA"].find(p => !!p.defaultValue);
      } else if (state.parametriCartaConto.parametri) {
        periodo = state.parametriCartaConto.parametri["MOVIMENTI.PERIODI.RICERCA"].find(p => !!p.defaultValue);
      }
      let rapportoSelezionato;
      if (state.parametri.rapporti && state.parametri.rapporti.length > 0) {
        rapportoSelezionato = state.parametri.rapporti[0];
      } else if (state.parametriCartaConto.rapporti && state.parametriCartaConto.rapporti.length > 0) {
        rapportoSelezionato = state.parametriCartaConto.rapporti[0];
      }
      return {
        ...state,
        form: {
          ...state.form,
          periodo: periodo === null || periodo === void 0 ? void 0 : periodo.idPeriodo,
          dataDa: moment().subtract((periodo === null || periodo === void 0 ? void 0 : periodo.giorni) || 0, "days").format("YYYY-MM-DD"),
          dataDaError: "",
          dataA: moment().format("YYYY-MM-DD"),
          dataAError: "",
          descrizione: "",
          categorie: [],
          importo: undefined,
          entrataUscita: "-",
          entrate: true,
          uscite: true,
          movimentiNonContabilizzati: false,
          sorting: api_rest_1.CriteriRicercaMovimentiRequestInOrdinamentoEnum.D,
          rapportoSelezionato: rapportoSelezionato || initialState.form.rapportoSelezionato
        }
      };
    case __1.EReduxActionTypes.SALDO_MOVIMENTI_RIMUOVI_FILTRI:
      const periodi_saldo_movimenti = (_e = (_d = state.parametri) === null || _d === void 0 ? void 0 : _d.parametri) === null || _e === void 0 ? void 0 : _e["MOVIMENTI.PERIODI.RICERCA"];
      const _periodo_saldo_movimenti = periodi_saldo_movimenti === null || periodi_saldo_movimenti === void 0 ? void 0 : periodi_saldo_movimenti.find(p => !!p.defaultValue);
      const periodo_saldo_movimenti = _periodo_saldo_movimenti ? String(_periodo_saldo_movimenti.giorni) : "90";
      return {
        ...state,
        form: {
          ...initialState.form,
          periodo: periodo_saldo_movimenti,
          dataDa: moment().subtract((_periodo_saldo_movimenti === null || _periodo_saldo_movimenti === void 0 ? void 0 : _periodo_saldo_movimenti.giorni) || 0, "days").format("YYYY-MM-DD"),
          dataA: moment().format("YYYY-MM-DD"),
          rapportoSelezionato: state.form.rapportoSelezionato,
          accordionFiltriOpened: state.form.accordionFiltriOpened
        }
      };
    case __1.EReduxActionTypes.CARTA_CONTO_RIMUOVI_FILTRI:
      const periodi_carta_conto = (_g = (_f = state.parametriCartaConto) === null || _f === void 0 ? void 0 : _f.parametri) === null || _g === void 0 ? void 0 : _g["MOVIMENTI.PERIODI.RICERCA"];
      const _periodo_carta_conto = periodi_carta_conto === null || periodi_carta_conto === void 0 ? void 0 : periodi_carta_conto.find(p => !!p.defaultValue);
      const periodo_carta_conto = _periodo_carta_conto ? String(_periodo_carta_conto.giorni) : "90";
      return {
        ...state,
        form: {
          ...initialState.form,
          periodo: periodo_carta_conto,
          dataDa: moment().subtract((_periodo_carta_conto === null || _periodo_carta_conto === void 0 ? void 0 : _periodo_carta_conto.giorni) || 0, "days").format("YYYY-MM-DD"),
          dataA: moment().format("YYYY-MM-DD"),
          rapportoSelezionato: state.form.rapportoSelezionato,
          accordionFiltriOpened: state.form.accordionFiltriOpened,
          isDeposito: state.form.isDeposito,
          isCarta: state.form.isCarta
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_RESET_FILTRI_MOVIMENTI:
      return {
        ...state,
        form: initialState.form,
        movimenti: initialState.movimenti
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_DESCRIZIONE:
      return {
        ...state,
        form: {
          ...state.form,
          descrizione: action.payload,
          descrizioneError: ""
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_MOVIMENTI_NON_CONTABILIZZATI:
      return {
        ...state,
        form: {
          ...state.form,
          movimentiNonContabilizzati: action.payload
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_OFFSET:
      return {
        ...state,
        form: {
          ...state.form,
          offset: action.payload
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_LIST_LOADER:
      return {
        ...state,
        form: {
          ...state.form,
          listLoader: action.payload
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_PERIODO:
      return {
        ...state,
        form: {
          ...state.form,
          periodo: action.payload
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_DATA_DA:
      return {
        ...state,
        form: {
          ...state.form,
          dataDa: action.payload,
          dataDaError: (0, helpers_1.validationFieldDate)(action.payload, "", false, "", state.form.dataA, true),
          dataAError: (0, helpers_1.validationFieldDate)(state.form.dataA, "", false, action.payload),
          periodo: initialState.form.periodo
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_DATA_A:
      return {
        ...state,
        form: {
          ...state.form,
          dataA: action.payload,
          dataAError: (0, helpers_1.validationFieldDate)(action.payload, "", false, state.form.dataDa),
          dataDaError: (0, helpers_1.validationFieldDate)(state.form.dataDa, "", false, "", action.payload, true),
          periodo: initialState.form.periodo
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_IMPORTO:
      return {
        ...state,
        form: {
          ...state.form,
          importo: action.payload,
          importoError: ""
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_CATEGORIE:
      return {
        ...state,
        form: {
          ...state.form,
          categorie: action.payload
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_ENTRATE:
      return {
        ...state,
        form: {
          ...state.form,
          entrate: action.payload
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_USCITE:
      return {
        ...state,
        form: {
          ...state.form,
          uscite: action.payload
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_ENTRATE_USCITE:
      return {
        ...state,
        form: {
          ...state.form,
          entrate: action.payload === "1" || action.payload === "2",
          uscite: action.payload === "1" || action.payload === "3"
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_ENTRATA_USCITA:
      return {
        ...state,
        form: {
          ...state.form,
          entrataUscita: action.payload
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_ORDINAMENTO:
      return {
        ...state,
        form: {
          ...state.form,
          sorting: action.payload,
          offset: 0
        }
      };
    case __1.EReduxActionTypes.SALDO_E_MOVIMENTI_SET_ACCORDION_FILTRI_OPENED:
      return {
        ...state,
        form: {
          ...state.form,
          accordionFiltriOpened: action.payload
        }
      };
    case __1.EReduxActionTypes.MOVIMENTI_GET_PARAMETRI_CARTA_CONTO_SUCCESS:
      return {
        ...state,
        parametriCartaConto: action.payload,
        form: {
          ...state.form,
          isDeposito: initialState.form.isDeposito,
          isCarta: true
        }
      };
    case __1.EReduxActionTypes.MOVIMENTI_RESET_MOVIMENTI:
      return {
        ...state,
        movimenti: initialState.movimenti
      };
    case __1.EReduxActionTypes.MOVIMENTI_RESET_MOVIMENTI_HOMEPAGE:
      return {
        ...state,
        movimentiHomepage: initialState.movimentiHomepage
      };
    case __1.EReduxActionTypes.RAPPORTI_GET_SALDO_COMPLESSIVO_SUCCESS:
      return {
        ...state,
        saldoComplessivo: action.payload
      };
    case __1.EReduxActionTypes.ENROLLMENT_PSD2_ATTIVA_SUCCESS:
      // action invocata dopo enrollment PSD2
      return {
        ...state,
        parametri: initialState.parametri,
        parametriWidget: initialState.parametri,
        saldoComplessivo: initialState.saldoComplessivo,
        movimentiHomepage: initialState.movimentiHomepage // per homepage
        // movimenti: initialState.movimenti // per saldo e movimenti
      };
    case __1.EReduxActionTypes.GENERAL_RESET_CACHE_POST_DISPO:
      // action invocata dopo una dispositiva di tipo resetWidgetsDispoFunctions
      return {
        ...state,
        parametri: initialState.parametri,
        parametriWidget: initialState.parametri,
        parametriCartaConto: initialState.parametriCartaConto,
        parametriContoDeposito: initialState.parametriContoDeposito,
        saldoComplessivo: initialState.saldoComplessivo,
        movimentiHomepage: initialState.movimentiHomepage
      };
    case __1.EReduxActionTypes.GENERAL_REFRESH_WIDGETS:
      // action invocata tramite pull to refresh
      return {
        ...state,
        parametri: initialState.parametri,
        parametriWidget: initialState.parametri,
        saldoComplessivo: initialState.saldoComplessivo,
        movimentiHomepage: initialState.movimentiHomepage
      };
    case __1.EReduxActionTypes.MOVIMENTI_SET_TIPO_ELENCO_CARTA_CONTO:
      return {
        ...state,
        form: {
          ...state.form,
          isCarta: true
        }
      };
    case __1.EReduxActionTypes.MOVIMENTI_SET_TIPO_DIVISA_SELEZIONATA:
      return {
        ...state,
        form: {
          ...state.form,
          divisaSelezionata: action.payload
        }
      };
    case __1.EReduxActionTypes.CARTA_CONTO_START_PIN_VIEW__SCA_SUCCESS:
      return {
        ...state,
        startPinViewResponse: action.payload
      };
    case __1.EReduxActionTypes.CARTA_CONTO_GET_PIN_VIEW_SUCCESS:
      return {
        ...state,
        getPinViewResponse: action.payload
      };
    case __1.EReduxActionTypes.CARTA_CONTO_RESET_PIN_VIEW:
      {
        return {
          ...state,
          startPinViewResponse: initialState.startPinViewResponse,
          getPinViewResponse: initialState.getPinViewResponse
        };
      }
    case __1.EReduxActionTypes.RESET_ALL:
      // action invocata dalla logout
      return {
        ...initialState
      };
    case __1.EReduxActionTypes.MOVIMENTI_RESET_SALDO_E_MOVIMENTI:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
exports.movimentiReducer = movimentiReducer;
exports.default = exports.movimentiReducer;