"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RedirectControllerApi = void 0;
const runtime = require("../runtime");
/**
 *
 */
class RedirectControllerApi extends runtime.BaseAPI {
  /**
   * Servizio di redirect
   */
  redirectRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.jwt === null || requestParameters.jwt === undefined) {
        throw new runtime.RequiredError('jwt', 'Required parameter requestParameters.jwt was null or undefined when calling redirect.');
      }
      if (requestParameters.url === null || requestParameters.url === undefined) {
        throw new runtime.RequiredError('url', 'Required parameter requestParameters.url was null or undefined when calling redirect.');
      }
      const queryParameters = {};
      if (requestParameters.jwt !== undefined) {
        queryParameters['jwt'] = requestParameters.jwt;
      }
      if (requestParameters.url !== undefined) {
        queryParameters['url'] = requestParameters.url;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/redirect`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.TextApiResponse(response);
    });
  }
  /**
   * Servizio di redirect
   */
  redirect(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.redirectRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Redirect da stanza di firma digitale AliasLab
   */
  redirect1Raw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.jwt === null || requestParameters.jwt === undefined) {
        throw new runtime.RequiredError('jwt', 'Required parameter requestParameters.jwt was null or undefined when calling redirect1.');
      }
      if (requestParameters.signres === null || requestParameters.signres === undefined) {
        throw new runtime.RequiredError('signres', 'Required parameter requestParameters.signres was null or undefined when calling redirect1.');
      }
      if (requestParameters.sID === null || requestParameters.sID === undefined) {
        throw new runtime.RequiredError('sID', 'Required parameter requestParameters.sID was null or undefined when calling redirect1.');
      }
      const queryParameters = {};
      if (requestParameters.jwt !== undefined) {
        queryParameters['jwt'] = requestParameters.jwt;
      }
      if (requestParameters.signres !== undefined) {
        queryParameters['signres'] = requestParameters.signres;
      }
      if (requestParameters.sID !== undefined) {
        queryParameters['SID'] = requestParameters.sID;
      }
      if (requestParameters.test !== undefined) {
        queryParameters['test'] = requestParameters.test;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/redirect/firma/esito`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.TextApiResponse(response);
    });
  }
  /**
   * Redirect da stanza di firma digitale AliasLab
   */
  redirect1(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.redirect1Raw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Gestisce il redirect del pagamento psd2
   */
  redirectPsd2PaymentRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.jwt === null || requestParameters.jwt === undefined) {
        throw new runtime.RequiredError('jwt', 'Required parameter requestParameters.jwt was null or undefined when calling redirectPsd2Payment.');
      }
      if (requestParameters.idPayment === null || requestParameters.idPayment === undefined) {
        throw new runtime.RequiredError('idPayment', 'Required parameter requestParameters.idPayment was null or undefined when calling redirectPsd2Payment.');
      }
      const queryParameters = {};
      if (requestParameters.jwt !== undefined) {
        queryParameters['jwt'] = requestParameters.jwt;
      }
      if (requestParameters.idPayment !== undefined) {
        queryParameters['idPayment'] = requestParameters.idPayment;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/redirect/psd2/payment`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.TextApiResponse(response);
    });
  }
  /**
   * Gestisce il redirect del pagamento psd2
   */
  redirectPsd2Payment(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.redirectPsd2PaymentRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.RedirectControllerApi = RedirectControllerApi;