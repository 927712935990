"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCustomEnv = exports.setCustomEnv = exports.customEnv = exports.Environments = void 0;
exports.Environments = {
  LOCAL: {
    corebanking: 'https://on-banking-svi.sparkasse.it',
    oauth2: 'https://oauth2-uat.sparkasse.it',
    aliaslabUrl: 'https://smartotp-coll.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://sandbox.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'pass',
    neosuranceCode: 'spks',
    clientId: "da509bf5-2891-40b7-896c-dd8a4188d60c",
    googleAnalyticsId: 'UA-166011071-1',
    sessionLifetime: 300,
    scope: "ihbnext openid private_profile email phone prometeia psd2-tpp",
    publicSite: "https://sparkasse.it",
    hub: "https://gw.ads.ocp.cedacri.it/hubautenticazione",
    collaboration: "http://20.101.196.49",
    deploymentKeyGenesys: "37659b94-7a10-4f81-a605-84ca45ba4c9d",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking-svi.sparkasse.it',
    selfreset: 'https://on-banking-svi.sparkasse.it',
    pushNotification: "https://on-banking-svi.sparkasse.it",
    acsFe: 'https://on-banking-svi.sparkasse.it',
    panKey: '3zU#XfgbCUND7qMV'
  },
  DEV: {
    corebanking: 'https://on-banking-svi.sparkasse.it',
    oauth2: 'https://oauth2-uat.sparkasse.it',
    aliaslabUrl: 'https://smartotp-coll.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://sandbox.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'pass',
    neosuranceCode: 'spks',
    clientId: "da509bf5-2891-40b7-896c-dd8a4188d60c",
    googleAnalyticsId: 'UA-166011071-1',
    sessionLifetime: 300,
    scope: "ihbnext openid private_profile email phone prometeia psd2-tpp",
    publicSite: "https://sparkasse.it",
    hub: "https://gw.ads.ocp.cedacri.it/hubautenticazione",
    collaboration: "https://on-banking-svi.sparkasse.it",
    deploymentKeyGenesys: "37659b94-7a10-4f81-a605-84ca45ba4c9d",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking-svi.sparkasse.it',
    selfreset: 'https://on-banking-svi.sparkasse.it',
    pushNotification: "https://on-banking-svi.sparkasse.it",
    corporate: "https://cbcoll.cedacri.it/CORE/main/LogonAttempt",
    acsFe: 'https://on-banking-svi.sparkasse.it',
    panKey: '3zU#XfgbCUND7qMV'
  },
  QA: {
    corebanking: 'https://on-banking-qa.sparkasse.it',
    oauth2: 'https://oauth2-uat.sparkasse.it',
    aliaslabUrl: 'https://smartotp-coll.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://sandbox.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'pass',
    neosuranceCode: 'spks',
    clientId: "da509bf5-2891-40b7-896c-dd8a4188d60c",
    googleAnalyticsId: 'UA-166011071-1',
    sessionLifetime: 300,
    scope: "ihbnext openid private_profile email phone prometeia psd2-tpp",
    publicSite: "https://sparkasse.it",
    hub: "https://gw.ads.ocp.cedacri.it/hubautenticazione",
    collaboration: "https://on-banking-qa.sparkasse.it",
    deploymentKeyGenesys: "37659b94-7a10-4f81-a605-84ca45ba4c9d",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking-qa.sparkasse.it',
    selfreset: 'https://on-banking-qa.sparkasse.it',
    pushNotification: "https://on-banking-qa.sparkasse.it",
    corporate: "https://cbcoll.cedacri.it/CORE/main/LogonAttempt",
    acsFe: 'https://on-banking-qa.sparkasse.it',
    panKey: 'tDnEsmeg6$KU2cg3'
  },
  ADS: {
    corebanking: 'https://on-banking-col.sparkasse.it',
    oauth2: 'https://oauth2-uat.sparkasse.it',
    aliaslabUrl: 'https://smartotp-coll.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://sandbox.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'pass',
    neosuranceCode: 'spks',
    clientId: "da509bf5-2891-40b7-896c-dd8a4188d60c",
    googleAnalyticsId: 'UA-166011071-1',
    sessionLifetime: 300,
    scope: "ihbnext openid private_profile email phone prometeia psd2-tpp",
    publicSite: "https://sparkasse.it",
    hub: "https://gw.ads.ocp.cedacri.it/hubautenticazione",
    collaboration: "https://on-banking-col.sparkasse.it",
    deploymentKeyGenesys: "37659b94-7a10-4f81-a605-84ca45ba4c9d",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking-col.sparkasse.it',
    selfreset: 'https://on-banking-col.sparkasse.it',
    pushNotification: "https://on-banking-col.sparkasse.it",
    corporate: "https://cbcoll.cedacri.it/CORE/main/LogonAttempt",
    acsFe: 'https://on-banking-col.sparkasse.it',
    panKey: 'qWN28KQhb5v#QaFE'
  },
  PROD: {
    corebanking: 'https://on-banking.sparkasse.it',
    oauth2: 'https://oauth2.sparkasse.it',
    aliaslabUrl: 'https://smartotp.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://spks.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'gvaSNPSsysM1076Bup',
    neosuranceCode: 'spks',
    clientId: "7c33b68a-5f4f-4db0-bc94-ea6adc707e84",
    googleAnalyticsId: 'UA-183958198-1',
    sessionLifetime: 300,
    scope: "ihbnext openid private_profile email phone prometeia psd2-tpp",
    publicSite: "https://sparkasse.it",
    hub: "https://gw.prd.ocp.cedacri.it/hubautenticazione",
    collaboration: "https://on-banking.sparkasse.it",
    deploymentKeyGenesys: "627d1d36-7ebd-4eff-9628-4e601a5fda12",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking.sparkasse.it',
    selfreset: 'https://on-banking.sparkasse.it',
    pushNotification: "https://on-banking.sparkasse.it",
    corporate: "https://isi-business.sparkasse.it/CORE/main/LogonAttempt",
    acsFe: 'https://on-banking.sparkasse.it',
    panKey: 'F6VPej^QbEBHHd%S'
  },
  MOCK: {
    corebanking: 'https://on-banking-qa.sparkasse.it',
    oauth2: 'https://oauth2-uat.sparkasse.it',
    aliaslabUrl: 'https://smartotp-coll.trusttechnologies.it/BlackBox/jaxrs/',
    aliaslabKey: '6ab392fd02',
    neosuranceUrl: 'https://sandbox.neosurancecloud.net/sdk/api/v1.0/',
    neosuranceKey: 'pass',
    neosuranceCode: 'spks',
    clientId: "da509bf5-2891-40b7-896c-dd8a4188d60c",
    googleAnalyticsId: 'UA-166011071-1',
    sessionLifetime: 300,
    scope: "ihbnext openid private_profile email phone prometeia psd2-tpp",
    publicSite: "https://sparkasse.it",
    hub: "https://gw.ads.ocp.cedacri.it/hubautenticazione",
    collaboration: "https://on-banking-qa.sparkasse.it",
    deploymentKeyGenesys: "37659b94-7a10-4f81-a605-84ca45ba4c9d",
    documentScanner: "https://document-scanner-api.azurewebsites.net",
    identification: 'https://on-banking-svi.sparkasse.it',
    selfreset: 'https://on-banking-svi.sparkasse.it',
    pushNotification: "https://on-banking.sparkasse.it",
    corporate: "https://cbcoll.cedacri.it/CORE/main/LogonAttempt",
    acsFe: 'https://on-banking-qa.sparkasse.it',
    panKey: 'tDnEsmeg6$KU2cg3'
  }
};
function setCustomEnv(c) {
  exports.customEnv = c;
}
exports.setCustomEnv = setCustomEnv;
function getCustomEnv() {
  return exports.customEnv;
}
exports.getCustomEnv = getCustomEnv;