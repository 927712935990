"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SudtirolPassControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class SudtirolPassControllerApi extends runtime.BaseAPI {
  /**
   * Autorizzazione di una disposizione di SUDTIROL PASS
   */
  autorizzaBonificoSudtirolPassRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaBonificoSudtirolPass.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaBonificoSudtirolPass.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/sudtirolpass/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneSudtirolPassDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di SUDTIROL PASS
   */
  autorizzaBonificoSudtirolPass(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.autorizzaBonificoSudtirolPassRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Come oggetto di richiesta viene inviato l\'oggetto restituito dal servizio di verifica (prima dell\' invio va settato il taglio della ricarica da effettuare) contenete il transactionId necessario ad eseguire l\'operazione di ricarica.
   */
  controlloRicaricaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.ricaricaSudtirolPassDto === null || requestParameters.ricaricaSudtirolPassDto === undefined) {
        throw new runtime.RequiredError('ricaricaSudtirolPassDto', 'Required parameter requestParameters.ricaricaSudtirolPassDto was null or undefined when calling controlloRicarica.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/sudtirolpass/controlloricarica`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.RicaricaSudtirolPassDtoToJSON(requestParameters.ricaricaSudtirolPassDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.RicaricaSudtirolPassDtoFromJSON(jsonValue));
    });
  }
  /**
   * Come oggetto di richiesta viene inviato l\'oggetto restituito dal servizio di verifica (prima dell\' invio va settato il taglio della ricarica da effettuare) contenete il transactionId necessario ad eseguire l\'operazione di ricarica.
   */
  controlloRicarica(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.controlloRicaricaRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneSudtirolPassRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneSudtirolPass.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/sudtirolpass/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneSudtirolPass(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.informazioniAutenticazioneSudtirolPassRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce le operazioni per Ricarica SudtirolPass
   */
  operazioniOneClickRicaricaSudtirolPassRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.operazioniOneClickInputDto === null || requestParameters.operazioniOneClickInputDto === undefined) {
        throw new runtime.RequiredError('operazioniOneClickInputDto', 'Required parameter requestParameters.operazioniOneClickInputDto was null or undefined when calling operazioniOneClickRicaricaSudtirolPass.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/sudtirolpass/oneclick`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.OperazioniOneClickInputDtoToJSON(requestParameters.operazioniOneClickInputDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OperazioniOneClickDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce le operazioni per Ricarica SudtirolPass
   */
  operazioniOneClickRicaricaSudtirolPass(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.operazioniOneClickRicaricaSudtirolPassRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Recupera i parametri e la lista delle carte Sudtirol Pass associate all\'utente
   */
  parametriSudtirolRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/sudtirolpass/parametri`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriSudtirolPassDtoFromJSON(jsonValue));
    });
  }
  /**
   * Recupera i parametri e la lista delle carte Sudtirol Pass associate all\'utente
   */
  parametriSudtirol() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriSudtirolRaw();
      return yield response.value();
    });
  }
  /**
   * Recupera lo storico delle ricariche Sudtiroll Pass effettuate dall\'utente.
   */
  recuperaStoricoRicaricheRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.sudtirolPassRicaricheFiltriInDto === null || requestParameters.sudtirolPassRicaricheFiltriInDto === undefined) {
        throw new runtime.RequiredError('sudtirolPassRicaricheFiltriInDto', 'Required parameter requestParameters.sudtirolPassRicaricheFiltriInDto was null or undefined when calling recuperaStoricoRicariche.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/sudtirolpass/storicoricariche`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.SudtirolPassRicaricheFiltriInDtoToJSON(requestParameters.sudtirolPassRicaricheFiltriInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.DatiRicaricaDtoFromJSON));
    });
  }
  /**
   * Recupera lo storico delle ricariche Sudtiroll Pass effettuate dall\'utente.
   */
  recuperaStoricoRicariche(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.recuperaStoricoRicaricheRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce le informazioni relative allo stato della carta, tra cui il transactionId (Identificativo della transazione sul sistema Sudtirol Pass) da utilizzare per il servizio di controllo ricarica Sudtirol.
   */
  verificaRicaricaRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.verificaRicaricaRequestDto === null || requestParameters.verificaRicaricaRequestDto === undefined) {
        throw new runtime.RequiredError('verificaRicaricaRequestDto', 'Required parameter requestParameters.verificaRicaricaRequestDto was null or undefined when calling verificaRicarica.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/sudtirolpass/verificaricarica`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.VerificaRicaricaRequestDtoToJSON(requestParameters.verificaRicaricaRequestDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.RicaricaSudtirolPassDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce le informazioni relative allo stato della carta, tra cui il transactionId (Identificativo della transazione sul sistema Sudtirol Pass) da utilizzare per il servizio di controllo ricarica Sudtirol.
   */
  verificaRicarica(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.verificaRicaricaRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.SudtirolPassControllerApi = SudtirolPassControllerApi;