"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapRigheSezioneAltriEntiMN = exports.validateRigheSezioneAltriEntiIL = exports.mapRigheSezioneAltriEntiIL = exports.validateRigheSezioneIMU = exports.mapRigheSezioneIMU = exports.validateRigheSezioneRegioni = exports.mapRigheSezioneRegioni = exports.validateRigheSezioneINPS = exports.mapRigheSezioneINPS = exports.validateRigheSezioneErario = exports.mapRigheSezioneErario = exports.validationPeriodoAnnoEAnnoMese = exports.validationPeriodo = exports.validationRangeAnno = exports.validationAnno = exports.getInfoAutenticazioneDispositivaCommon = exports.formattedNumberToNumber = exports.getInformazioniVeicolo = exports.validationIdentificativoF24 = exports.validationTelaio = exports.validationIndirizzo = exports.validationCitta = exports.validationCompleteCharSet = exports.validationAsNumber = exports.validationCFPI = exports.validationBoolean = exports.validationNumeroCellulare = exports.generateDocFileName = exports.download_shareXLS = exports.download_sharePDF = exports.downloadFile = exports.findInArrayRapporti = exports.validationCodiceRav = exports.validationFieldDate = exports.validationIsin = exports.validationCodiceMav = exports.validationCodiceFiscale = exports.validationPartitaIva = exports.validationTarga = exports.validationAltoAdigePass = exports.validationIbanItaliani = exports.validationEmail = exports.validationPassword = exports.validationEqualityCheck = exports.validationField = exports.validationPrezzo = exports.formatError = exports.decodeError = exports.blobDataToString = exports.exists = void 0;
exports.validationFieldIbanSepa = exports.makeid = exports.isValidDate = exports.formatStringDate = exports.isExpiredDocument = exports.getLastBusinessDayOfMonth = exports.hadleExcelResponse = exports.hadlePdfResponse = exports.hadlePdfResponseForDownload = exports.formatStringToNumber = exports.validationNumeroCaratteriDescrizioneCercaTitoli = exports.getValidDayByMonth = exports.extractContentFromHTML = exports.buildRecapito = exports.convertToFormRecapitiPass = exports.convertToFormRecapitiTarga = exports.convertToFormRecapitiTelefono = exports.convertToFormRecapitiIban = exports.convertToFormRecapitiEmail = exports.rimuoviPrimoCarattereMercato = exports.validationIsinMercatoItaliano = exports.b64toBlob = exports.validateNumericField = exports.getRandomCode = exports.getLastDayOfMonth = exports.getFirstDayOfMonth = exports.validationPIN = exports.validateDescription = exports.validateCodiceISIN = exports.flattenObject = exports.validationTransazione = exports.validationImportoRicaricaCarta = exports.validationImportiMultipli = exports.validateRigheSezioneAltroErario = exports.mapRigheSezioneAltroErario = exports.validateRigheSezioneAccise = exports.mapRigheSezioneAccise = exports.validateRigheSezioneMotivoPagamento = exports.mapRigheSezioneMotivoPagamento = exports.validateRigheSezioneAltriEntiMN = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const IBAN = require("iban");
const moment = require("moment");
const f24_types_1 = require("./f24/f24.types");
const rubrica_types_1 = require("./rubrica/rubrica.types");
const exists = (json, key) => {
  const value = json[key];
  return value !== null && value !== undefined;
};
exports.exists = exists;
const Utf8ArrayToStr = array => {
  var out, i, len, c;
  var char2, char3;
  out = '';
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode((c & 0x1f) << 6 | char2 & 0x3f);
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode((c & 0x0f) << 12 | (char2 & 0x3f) << 6 | (char3 & 0x3f) << 0);
        break;
    }
  }
  return out;
};
const blobDataToString = async blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const dataUrl = reader.result;
      if (!dataUrl) {
        reject();
        return;
      }
      var base64 = '';
      if (dataUrl instanceof ArrayBuffer) {
        base64 = btoa(String.fromCharCode(...new Uint8Array(dataUrl)));
      } else {
        base64 = dataUrl;
      }
      const pureBase64 = base64.substr(base64.indexOf(',') + 1);
      resolve(pureBase64);
    };
    reader.onerror = error => reject(error);
    reader.readAsDataURL(blob);
  });
};
exports.blobDataToString = blobDataToString;
const blobToString = async blob => {
  return new Promise((resolve, reject) => {
    let content = '';
    const reader = new FileReader();
    // Wait till complete
    reader.onloadend = function (e) {
      content = e.target.result;
      // const result = content.split(/\r\n|\n/);
      resolve(content);
    };
    // Make sure to handle error states
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsText(blob);
  });
};
const JSONTryParse = str => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {
      error: `JSONTryParse ${e}`,
      error_code: str,
      error_description: str
    };
  }
};
const decodeError = async error => {
  var _a, _b;
  console.error('error:\n', error);
  let errorText;
  if (typeof error === 'string') {
    let errorMessage = error || 'GENERIC_ERROR';
    if (error.startsWith('Certificate pinning failure') || error.startsWith('Il certificato di questo server non è valido')) {
      errorMessage = 'SSL_PINNING_ERROR';
    }
    return {
      status: 999,
      message: errorMessage
    };
  } else if (error.bodyString) {
    errorText = error.bodyString;
  } else if (error._bodyBlob) {
    if (error._bodyBlob.text) {
      errorText = await error._bodyBlob.text();
    } else {
      errorText = await blobToString(error._bodyBlob);
    }
  } else if (error.body) {
    const errorTextReadable = await error.body.getReader().read();
    errorText = errorTextReadable.value ? Utf8ArrayToStr(errorTextReadable.value) : '';
    console.debug('error body:\n' + JSON.stringify(JSONTryParse(errorText), null, '\t'));
  }
  console.error('errorText', errorText);
  let message = (error === null || error === void 0 ? void 0 : error.statusText) || 'GENERIC_ERROR';
  const errorTextObject = errorText ? JSONTryParse(errorText) : null;
  if (errorTextObject) {
    if (!errorTextObject.tppMessages || errorTextObject.tppMessages.length <= 0) {
      console.debug('object error:\n', errorTextObject);
      return {
        ...errorTextObject,
        status: errorTextObject.status || (error === null || error === void 0 ? void 0 : error.status) || 999,
        message: errorTextObject.descrizioneErrore || errorTextObject.codiceErrore || errorTextObject.message || message
      };
    }
    message = ((_b = (_a = errorTextObject.tppMessages) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.code) || message;
  }
  const decodedError = {
    ...(errorTextObject || {}),
    status: (error === null || error === void 0 ? void 0 : error.status) || 999,
    message: message
  };
  console.debug('decoded error:\n', decodedError);
  return decodedError;
};
exports.decodeError = decodeError;
const formatError = async function (error) {
  let oauth2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let alreadyDecoded = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const errorObject = alreadyDecoded ? error : await (0, exports.decodeError)(error);
  alreadyDecoded && console.debug('already decoded error:\n', errorObject);
  return {
    timestamp: errorObject.timestamp || moment().toISOString(),
    message: oauth2 ? errorObject.error_code || errorObject.message : errorObject.message || errorObject.error_code,
    next_error_will_lock_account: errorObject.next_error_will_lock_account == true,
    details: oauth2 ? errorObject.error_description : errorObject.details || errorObject.error_description,
    globalValidationErrors: errorObject.globalValidationErrors,
    fieldValidationErrors: errorObject.fieldValidationErrors,
    status: errorObject.status || (errorObject.error_code === 'RESTART_SESSION' ? 403 : undefined),
    error: errorObject.error,
    path: errorObject.path
  };
};
exports.formatError = formatError;
const validationPrezzo = (prezzoA, prezzoB, segno, sField) => {
  let sReturn = '';
  let prezzoDaConfrontareA = parseFloat(prezzoA === null || prezzoA === void 0 ? void 0 : prezzoA.toString().replace(',', '.'));
  let prezzoDaConfrontareB = parseFloat(prezzoB === null || prezzoB === void 0 ? void 0 : prezzoB.toString().replace(',', '.'));
  if (segno === 'A' && prezzoDaConfrontareB > prezzoDaConfrontareA) {
    sReturn = `invalid.controlloPrezzo.${segno}`;
  } else if (segno === 'V' && prezzoDaConfrontareA > prezzoDaConfrontareB) {
    sReturn = `invalid.controlloPrezzo.${segno}`;
  }
  sReturn = sField === '' || sField === '0,00' || sField === '0,0000' || sField === '0' || sField === undefined ? 'invalid.required' : sReturn;
  return sReturn;
};
exports.validationPrezzo = validationPrezzo;
const validationField = (sField, fieldMatch, ibanValidation, minLength, maxLength, required, dayValidation) => {
  let sReturn = '';
  let req = required !== false ? true : false;
  if (typeof fieldMatch === 'number') {
    sReturn = sField === '' || sField === '0,00' || sField === '0' || sField === undefined ? req ? 'invalid.required' : '' : '';
  }
  const isFieldNull = sField === '' || sField === ' ' || sField === undefined || sField === null;
  if (typeof fieldMatch === 'string') {
    if (isFieldNull) {
      sReturn = req ? 'invalid.required' : '';
    } else {
      if (sReturn === '' && minLength) {
        sReturn = sField.length < minLength ? 'invalid.format' : '';
      }
      if (sReturn === '' && maxLength) {
        sReturn = sField.length > maxLength ? 'invalid.format' : '';
      }
    }
  }
  if (sReturn === '' && ibanValidation) {
    sReturn = IBAN.isValid(sField) ? '' : 'invalid.format';
  }
  if (dayValidation && (sField < 1 || sField > 31)) {
    sReturn = 'invalid.day';
  }
  return sReturn;
};
exports.validationField = validationField;
const validationEqualityCheck = (aField, bField) => {
  return aField !== bField ? 'invalid.match' : '';
};
exports.validationEqualityCheck = validationEqualityCheck;
const validationPassword = (sField, minLength, maxLength, oldPassword, confirmPassword) => {
  let sReturn = '';
  const pattern = /^[\s0-9a-zA-Z]+$/;
  sReturn = sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
  sReturn = sField === '' ? 'invalid.required' : '';
  if (sReturn === '' && minLength) {
    sReturn = minLength && sField.length < minLength ? 'invalid.format' : '';
  }
  if (sReturn === '' && maxLength) {
    sReturn = maxLength && sField.length > maxLength ? 'invalid.format' : '';
  }
  if (sReturn === '' && oldPassword) {
    sReturn = sField === oldPassword ? 'invalid.notValidPassword' : '';
  }
  if (sReturn === '' && confirmPassword) {
    sReturn = sField !== confirmPassword ? 'invalid.notValidPassword' : '';
  }
  return sReturn;
};
exports.validationPassword = validationPassword;
const validationEmail = (sField, required) => {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationEmail = validationEmail;
// 
const validationIbanItaliani = (sField, required) => {
  const pattern = /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationIbanItaliani = validationIbanItaliani;
const validationAltoAdigePass = (sField, required) => {
  const pattern = /^[0-9A-Za-z]{12}$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationAltoAdigePass = validationAltoAdigePass;
const validationTarga = (sField, required) => {
  const pattern = /^[0-9A-Za-z]{6,8}$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationTarga = validationTarga;
const validationPartitaIva = (sField, required) => {
  const pattern = /^[0-9]{11}$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationPartitaIva = validationPartitaIva;
const validationCodiceFiscale = (sField, required) => {
  const pattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationCodiceFiscale = validationCodiceFiscale;
const validationCodiceMav = (sField, required) => {
  const pattern = /^[0-9]{17,18}$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationCodiceMav = validationCodiceMav;
const validationIsin = (sField, minLength) => {
  return !!sField && sField.length < minLength ? 'invalid.notValidIsin' : '';
};
exports.validationIsin = validationIsin;
const validationFieldDate = (sField, arrayDateDisable, blockWeekEnd, minDate, maxDate, ampiezzaMaggioreUnAnno) => {
  let sReturn = '';
  sReturn = sField === '' || sField === undefined ? 'invalid.required' : '';
  if (sReturn === '') {
    sReturn = moment(sField).isValid() || sField.length === 10 ? '' : 'invalid.format';
  }
  if (sReturn === '') {
    let bMinDate = minDate && minDate !== '' ? moment(sField).isSame(moment(minDate), 'day') ? false : moment(sField).isBefore(moment(minDate), 'day') : false;
    let bMaxDate = maxDate && maxDate !== '' ? moment(sField).isSame(moment(maxDate), 'day') ? false : moment(sField).isAfter(moment(maxDate), 'day') : false;
    let bWeekEnd = blockWeekEnd ? moment(sField).isoWeekday() === 6 || moment(sField).isoWeekday() === 7 : false;
    let bInvalid = arrayDateDisable ? arrayDateDisable.includes(moment(sField).format('DD/MM')) : false;
    // the difference should be 12 months; dal should have a lower value than al
    let datesDifference;
    if (maxDate && sField && !ampiezzaMaggioreUnAnno) {
      const date1 = new Date(sField);
      const date2 = new Date(maxDate);
      const diffTime = Math.abs(date2.getTime() - date1.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      datesDifference = diffDays <= 366 && diffDays > 0 ? false : true;
    }
    sReturn = bInvalid || bWeekEnd || bMinDate || bMaxDate || datesDifference ? 'invalid.notValidDate' : '';
  }
  return sReturn;
};
exports.validationFieldDate = validationFieldDate;
const validationCodiceRav = (sField, required) => {
  const pattern = /\S+[0-9]{16}/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationCodiceRav = validationCodiceRav;
const findInArrayRapporti = (array, id) => {
  let rapportoSelezionato;
  let rapportoCompleto;
  if (array) {
    for (let i = 0; i < array.length; i++) {
      rapportoCompleto = array[i];
      if (rapportoCompleto.rapporto && rapportoCompleto.rapporto.id == id) {
        rapportoSelezionato = array[i];
        break;
      }
    }
    if (!rapportoSelezionato && array.length > 0) {
      rapportoSelezionato = rapportoSelezionato || array[0];
    }
  }
  console.log("rapportoSelezionato", rapportoSelezionato);
  return rapportoSelezionato;
};
exports.findInArrayRapporti = findInArrayRapporti;
const downloadFile = (extraArguments, nameFile, base64) => {
  let RNFetchBlob = extraArguments.fetchBlobRuntime;
  let base64data = base64;
  const dirs = extraArguments.platformMobile.OS === 'ios' ? RNFetchBlob.fs.dirs.DocumentDir : RNFetchBlob.fs.dirs.DownloadDir;
  const fs = RNFetchBlob.fs;
  const filePDF = `${dirs}/${nameFile}`;
  fs.writeFile(filePDF, base64data, 'base64').then(() => {
    if (extraArguments.platformMobile.OS === 'ios') {
      RNFetchBlob.ios.openDocument(filePDF);
    }
    if (extraArguments.platformMobile.OS === 'android') {
      const androidDownloadOption = {
        title: nameFile,
        description: 'SPARKASSE CASSA DI RISPARMIO',
        mime: 'application/pdf',
        path: filePDF,
        showNotification: true
      };
      RNFetchBlob.android.addCompleteDownload(androidDownloadOption).then(() => {
        RNFetchBlob.android.actionViewIntent(filePDF, 'application/pdf');
      });
    }
  }).catch(err => {
    console.log('ERROR', err);
  });
};
exports.downloadFile = downloadFile;
const download_sharePDF = (extraArguments, nameFile, response, share, title, message) => {
  let RNFetchBlob = extraArguments.fetchBlobRuntime;
  let RNShare = extraArguments.share;
  const fileReaderInstance = new FileReader();
  fileReaderInstance.readAsDataURL(response);
  fileReaderInstance.onload = () => {
    let base64data = fileReaderInstance.result;
    const dirs = share || extraArguments.platformMobile.OS === 'ios' ? RNFetchBlob.fs.dirs.DocumentDir : RNFetchBlob.fs.dirs.DownloadDir;
    const fs = RNFetchBlob.fs;
    const filePDF = `${dirs}/${nameFile}`;
    let base64dataString = base64data;
    base64data = base64dataString.substr(base64dataString.indexOf(',') + 1);
    fs.writeFile(filePDF, base64data, 'base64').then(() => {
      if (share) {
        try {
          RNShare.open({
            title: title,
            subject: title,
            message: message,
            type: extraArguments.platformMobile.OS === 'android' ? '' : 'application/pdf',
            url: extraArguments.platformMobile.OS === 'android' ? `data:application/pdf;base64,${base64data}` : filePDF
          });
        } catch (error) {
          alert(error.message);
        }
      } else {
        if (extraArguments.platformMobile.OS === 'ios') {
          RNFetchBlob.ios.openDocument(filePDF);
        }
        if (extraArguments.platformMobile.OS === 'android') {
          const androidDownloadOption = {
            title: nameFile,
            description: 'SPARKASSE CASSA DI RISPARMIO',
            mime: 'application/pdf',
            path: filePDF,
            showNotification: true
          };
          RNFetchBlob.android.addCompleteDownload(androidDownloadOption).then(() => {
            RNFetchBlob.android.actionViewIntent(filePDF, 'application/pdf'); // TODO indagare sul perchè non funziona
          });
        }
      }
    }).catch(err => {
      console.log('ERROR', err);
    });
  };
};
exports.download_sharePDF = download_sharePDF;
const download_shareXLS = (extraArguments, nameFile, response, share) => {
  let RNFetchBlob = extraArguments.fetchBlobRuntime;
  let RNShare = extraArguments.share;
  const fileReaderInstance = new FileReader();
  fileReaderInstance.readAsDataURL(response);
  fileReaderInstance.onload = () => {
    let base64data = fileReaderInstance.result;
    const dirs = RNFetchBlob.fs.dirs.DocumentDir;
    const fs = RNFetchBlob.fs;
    const fileXLS = `${dirs}/${nameFile}`;
    let base64dataString = base64data;
    base64data = base64dataString.substr(base64dataString.indexOf(',') + 1);
    fs.writeFile(fileXLS, base64data, 'base64').then(() => {
      if (share) {
        try {
          RNShare.open({
            message: 'Elenco excel',
            url: `data:application/vnd.ms-excel;base64,${base64data}`
          });
        } catch (error) {
          alert(error.message);
        }
      } else {
        if (extraArguments.platformMobile.OS === 'ios') {
          RNFetchBlob.ios.previewDocument(fileXLS);
        }
        if (extraArguments.platformMobile.OS === 'android') {
          RNFetchBlob.android.actionViewIntent(fileXLS, 'application/vnd.ms-excel');
        }
      }
    }).catch(err => {
      console.log('ERROR', err);
    });
  };
};
exports.download_shareXLS = download_shareXLS;
const generateDocFileName = (ext, prefix) => {
  return `${prefix || 'doc'}_${moment().toISOString()}.${ext || 'pdf'}`;
};
exports.generateDocFileName = generateDocFileName;
const validationNumeroCellulare = (sField, required) => {
  const pattern = /^3\d{8,9}$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationNumeroCellulare = validationNumeroCellulare;
const validationBoolean = sField => {
  return sField ? '' : 'invalid.required';
};
exports.validationBoolean = validationBoolean;
const validationCFPI = (required, sField) => {
  const pattern = /^(([A-Za-z]{6}[A-Za-z0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[A-Za-z0-9]{3}[A-Za-z]{1})|([0-9]{11}))$/;
  return required && !sField ? 'invalid.required' : sField && sField.search && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationCFPI = validationCFPI;
const validationAsNumber = (sField, required, minChar, maxChar) => {
  const pattern = /^[0-9]*$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 || minChar && sField.length < minChar || maxChar && sField.length > maxChar ? 'invalid.format' : '';
};
exports.validationAsNumber = validationAsNumber;
const validationCompleteCharSet = (sField, required) => {
  const pattern = /^[\s0-9a-zA-ZáàäéèíìóòöúùüÀÁÄÈÉÌÍÒÓÖÙÚÜß/?:().,'+\-&]+$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationCompleteCharSet = validationCompleteCharSet;
const validationCitta = (sField, required) => {
  const pattern = /^[\s0-9a-zA-ZáàäéèíìóòöúùüÀÁÄÈÉÌÍÒÓÖÙÚÜß/?:().,/']+$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationCitta = validationCitta;
const validationIndirizzo = (sField, required) => {
  const pattern = /^[\s0-9a-zA-ZáàäéèíìóòöúùüÀÁÄÈÉÌÍÒÓÖÙÚÜß/?:().,'\&]+$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationIndirizzo = validationIndirizzo;
const validationTelaio = (sField, required) => {
  const pattern = /^[0-9A-Za-z]{17,17}$/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationTelaio = validationTelaio;
const validationIdentificativoF24 = sField => {
  const pattern = /^[0-9A-Z]+$/;
  return sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationIdentificativoF24 = validationIdentificativoF24;
const getInformazioniVeicolo = longInfo => {
  const tipoVeicoloId = longInfo.substring(2).charAt(0);
  const targa = longInfo.substring(3);
  return {
    tipoVeicolo: tipoVeicoloId,
    targaVeicolo: targa
  };
};
exports.getInformazioniVeicolo = getInformazioniVeicolo;
const formattedNumberToNumber = num => {
  return num ? Number(String(num).replace(',', '.')) : 0;
};
exports.formattedNumberToNumber = formattedNumberToNumber;
const getInfoAutenticazioneDispositivaCommon = (infoDispositiva, informazioniAutenticazioneDispositiva) => {
  return {
    ...infoDispositiva,
    tempoTotale: informazioniAutenticazioneDispositiva === null || informazioniAutenticazioneDispositiva === void 0 ? void 0 : informazioniAutenticazioneDispositiva.tempoTotale,
    tempoRimanente: informazioniAutenticazioneDispositiva === null || informazioniAutenticazioneDispositiva === void 0 ? void 0 : informazioniAutenticazioneDispositiva.tempoRimanente,
    otpPinRichiesto: (infoDispositiva === null || infoDispositiva === void 0 ? void 0 : infoDispositiva.otpPinRichiesto) || false,
    otpNuovoPinRichiesto: (infoDispositiva === null || infoDispositiva === void 0 ? void 0 : infoDispositiva.otpNuovoPinRichiesto) || false,
    otpNextTokenCodeRichiesto: (infoDispositiva === null || infoDispositiva === void 0 ? void 0 : infoDispositiva.otpNextTokenCodeRichiesto) || false,
    otpLunghezzaTokenCode: (infoDispositiva === null || infoDispositiva === void 0 ? void 0 : infoDispositiva.otpLunghezzaTokenCode) || 0,
    contatoreTentativiErratiTotali: (infoDispositiva === null || infoDispositiva === void 0 ? void 0 : infoDispositiva.contatoreTentativiErratiTotali) || 0,
    lunghezzaMinimaCredenziale: (infoDispositiva === null || infoDispositiva === void 0 ? void 0 : infoDispositiva.lunghezzaMinimaCredenziale) || 0
  };
};
exports.getInfoAutenticazioneDispositivaCommon = getInfoAutenticazioneDispositivaCommon;
const validationAnno = (required, sField) => {
  const pattern = /^[0-9]{4}$/;
  return required && !sField ? 'invalid.required' : sField && sField.search && sField.search(pattern) === -1 ? 'invalid.format' : '';
};
exports.validationAnno = validationAnno;
const validationRangeAnno = (required, sField) => {
  let dataMassima = moment().format('YYYY');
  let dataMinima = '1900';
  if (required && !sField) {
    return 'invalid.required';
  } else if (Number(sField) > Number(dataMassima)) {
    return 'invalid.dataMaxSuperata';
  } else if (Number(sField) < Number(dataMinima)) {
    return 'invalid.dataMinSuperata';
  } else {
    return '';
  }
};
exports.validationRangeAnno = validationRangeAnno;
const validationPeriodo = (required, sField) => {
  const pattern = /^(0[1-9]|1[0-2]|[1-9])\/[0-9]{4}$/;
  return required && !sField ? 'invalid.required' : sField && sField.search && sField.search(pattern) === -1 || sField && (sField === null || sField === void 0 ? void 0 : sField.length) < 7 ? 'invalid.format' : '';
};
exports.validationPeriodo = validationPeriodo;
const validationPeriodoAnnoEAnnoMese = (required, sField) => {
  const pattern_MM_AAAA = /^(0[1-9]|1[0-2]|[1-9])\/[1-2][0-9]{3}$/;
  const pattern_AAAA = /^[1-2][0-9]{3}$/;
  return required && !sField ? 'invalid.required' : sField && sField.search && (sField.search(pattern_MM_AAAA) === -1 && sField.search(pattern_AAAA) === -1 || sField.length > 7 || sField.length < 4) ? 'invalid.format' : '';
};
exports.validationPeriodoAnnoEAnnoMese = validationPeriodoAnnoEAnnoMese;
const mapRigheSezioneErario = (righe, platform) => {
  let returnValue = righe.map((riga, i) => {
    const annoDiRiferimento = !!riga.anno ? riga.anno.toString() : undefined;
    const importoDebito = !!riga.importoDebito ? riga.importoDebito : undefined;
    const importoCredito = !!riga.importoCredito ? riga.importoCredito : undefined;
    const _riga = {
      enabled: i == 0,
      codiceTributo: riga.codiceTributo,
      codiceTributoDirty: false,
      // codiceTributoBoundingBox: riga.codiceTributoBoundingBox,
      rateazione: riga.infoTributo,
      rateazioneError: '',
      rateazioneDirty: false,
      // rateazioneBoundingBox: riga.rateazioneBoundingBox,
      annoDiRiferimento: annoDiRiferimento,
      annoDiRiferimentoError: '',
      annoDiRiferimentoDirty: false,
      // annoDiRiferimentoBoundingBox: riga.annoDiRiferimentoBoundingBox,
      importoDebito: importoDebito,
      importoDebitoError: importoDebito ? (0, exports.validationField)(importoDebito.toString(), 0) : '',
      importoDebitoDirty: false,
      // importoDebitoBoundingBox: riga.importoDebitoBoundingBox,
      importoCredito: importoCredito,
      importoCreditoError: importoCredito ? (0, exports.validationField)(importoCredito.toString(), 0) : '',
      importoCreditoDirty: false
      // importoCreditoBoundingBox: riga.importoCreditoBoundingBox
    };
    return _riga;
  });
  if (platform === 'desktop') {
    for (let i = righe.length; i < f24_types_1.numRigheErario; i++) {
      returnValue.push({
        ...f24_types_1.defaultRigaErario,
        enabled: i == 0
      });
    }
  }
  return (0, exports.validateRigheSezioneErario)(returnValue);
};
exports.mapRigheSezioneErario = mapRigheSezioneErario;
const validateRigheSezioneErario = righe => {
  if (righe.length == 0) {
    console.error('Should not enter here!');
    return [{
      enabled: true,
      rateazioneError: '',
      annoDiRiferimentoError: '',
      importoDebitoError: '',
      importoCreditoError: ''
    }];
  }
  const hasAtLeastOneFieldFilledIn = riga => {
    return !!riga.codiceTributo || !!riga.rateazione || !!riga.annoDiRiferimento || !!riga.importoDebito || riga.importoDebito === 0 || !!riga.importoCredito || riga.importoCredito === 0;
  };
  const isValid = riga => {
    return !riga.rateazioneError && !riga.annoDiRiferimentoError && !riga.importoDebitoError && !riga.importoCreditoError;
  };
  // Validazione di tutte le righe che hanno almeno un campo compilato
  const righeValidate = righe.map(riga => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (!rowHasAtLeastOneFieldFilledIn) {
      return {
        ...riga,
        rateazioneError: '',
        annoDiRiferimentoError: '',
        importoDebitoError: '',
        importoCreditoError: ''
      };
    }
    return {
      ...riga,
      rateazioneError: (0, exports.validationField)(riga.rateazione, '', undefined, 4, 4, false),
      annoDiRiferimentoError: (0, exports.validationAnno)(false, riga.annoDiRiferimento),
      importoDebitoError: (0, exports.validationField)(riga.importoDebito, 0, undefined, undefined, undefined, false),
      importoCreditoError: (0, exports.validationField)(riga.importoCredito, 0, undefined, undefined, undefined, false)
    };
  });
  let lastRowEnabled = -1;
  // Abilito tutte le righe dall'inizio fino all'ultima riga compilata ed in più un'altra riga se l'ultima compilata è valida
  const righeValidateEdAbilitate = righeValidate.map((riga, i) => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (rowHasAtLeastOneFieldFilledIn) {
      lastRowEnabled = i;
    }
    // Aggiungo una riga abilitata se è la prima oppure se la sua precedente è valida ed è riempita
    const extraRow = i == 0 || i > lastRowEnabled && isValid(righeValidate[i - 1]) && hasAtLeastOneFieldFilledIn(righeValidate[i - 1]);
    const isEnabled = i <= lastRowEnabled || rowHasAtLeastOneFieldFilledIn || extraRow;
    return {
      ...riga,
      enabled: isEnabled
    };
  });
  console.log('righe Erario:', righeValidateEdAbilitate);
  return righeValidateEdAbilitate;
};
exports.validateRigheSezioneErario = validateRigheSezioneErario;
const mapRigheSezioneINPS = (righe, platform) => {
  let returnValue = righe.map((riga, i) => {
    const periodoDa = !!(riga === null || riga === void 0 ? void 0 : riga.periodoDaMese) && !!(riga === null || riga === void 0 ? void 0 : riga.periodoDaAnno) ? `${riga.periodoDaAnno}-${riga.periodoDaMese}` : undefined;
    const periodoA = !!(riga === null || riga === void 0 ? void 0 : riga.periodoAMese) && !!(riga === null || riga === void 0 ? void 0 : riga.periodoAAnno) ? `${riga.periodoAAnno}-${riga.periodoAMese}` : undefined;
    const importoDebito = !!riga.importoDebito ? riga.importoDebito : undefined;
    const importoCredito = !!riga.importoCredito ? riga.importoCredito : undefined;
    const _riga = {
      enabled: i == 0,
      codiceSede: riga === null || riga === void 0 ? void 0 : riga.codiceSede,
      codiceSedeDirty: false,
      // codiceSedeBoundingBox: riga.codiceSedeBoundingBox,
      causaleContributo: riga === null || riga === void 0 ? void 0 : riga.causaleContributo,
      causaleContributoDirty: false,
      // causaleContributoBoundingBox: riga.causaleContributoBoundingBox,
      matricolaINPS: riga === null || riga === void 0 ? void 0 : riga.matricolaInps,
      matricolaINPSDirty: false,
      // matricolaINPSBoundingBox: riga.matricolaINPSBoundingBox,
      periodoDa: periodoDa ? moment(periodoDa).format('MM/YYYY') : undefined,
      periodoDaError: periodoDa ? (0, exports.validationFieldDate)(periodoDa, [], false) : '',
      periodoDaDirty: false,
      // periodoDaBoundingBox: riga.periodoDaBoundingBox,
      periodoA: periodoA ? moment(periodoA).format('MM/YYYY') : undefined,
      periodoAError: periodoA ? (0, exports.validationFieldDate)(periodoA, [], false) : '',
      periodoADirty: false,
      // periodoABoundingBox: riga.periodoABoundingBox,
      importoDebito: importoDebito,
      importoDebitoError: importoDebito ? (0, exports.validationField)(importoDebito, 0) : '',
      importoDebitoDirty: false,
      // importoDebitoBoundingBox: riga.importoDebitoBoundingBox,
      importoCredito: importoCredito,
      importoCreditoError: importoCredito ? (0, exports.validationField)(importoCredito, 0) : '',
      importoCreditoDirty: false
      // importoCreditoBoundingBox: riga.importoCreditoBoundingBox
    };
    return _riga;
  });
  if (platform === 'desktop') {
    for (let i = righe.length; i < f24_types_1.numRigheINPS; i++) {
      returnValue.push({
        ...f24_types_1.defaultRigaINPS,
        enabled: i == 0
      });
    }
  }
  return (0, exports.validateRigheSezioneINPS)(returnValue);
};
exports.mapRigheSezioneINPS = mapRigheSezioneINPS;
const validateRigheSezioneINPS = righe => {
  if (righe.length == 0) {
    console.error('Should not enter here!');
    return [{
      enabled: true,
      periodoDaError: '',
      periodoAError: '',
      importoDebitoError: '',
      importoCreditoError: ''
    }];
  }
  const hasAtLeastOneFieldFilledIn = riga => {
    return !!riga.codiceSede || !!riga.causaleContributo || !!riga.matricolaINPS || !!riga.periodoDa || !!riga.periodoA || !!riga.importoDebito || riga.importoDebito === 0 || !!riga.importoCredito || riga.importoCredito === 0;
  };
  const isValid = riga => {
    return !riga.periodoDaError && !riga.periodoAError && !riga.importoDebitoError && !riga.importoCreditoError;
  };
  // Validazione di tutte le righe che hanno almeno un campo compilato
  const righeValidate = righe.map(riga => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (!rowHasAtLeastOneFieldFilledIn) {
      return {
        ...riga,
        periodoDaError: '',
        periodoAError: '',
        importoDebitoError: '',
        importoCreditoError: ''
      };
    }
    return {
      ...riga,
      periodoDaError: (0, exports.validationPeriodo)(false, riga.periodoDa),
      periodoAError: (0, exports.validationPeriodo)(false, riga.periodoA),
      importoDebitoError: (0, exports.validationField)(riga.importoDebito, 0, undefined, undefined, undefined, false),
      importoCreditoError: (0, exports.validationField)(riga.importoCredito, 0, undefined, undefined, undefined, false)
    };
  });
  let lastRowEnabled = -1;
  // Abilito tutte le righe dall'inizio fino all'ultima riga compilata ed in più un'altra riga se l'ultima compilata è valida
  const righeValidateEdAbilitate = righeValidate.map((riga, i) => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (rowHasAtLeastOneFieldFilledIn) {
      lastRowEnabled = i;
    }
    // Aggiungo una riga abilitata se è la prima oppure se la sua precedente è valida ed è riempita
    const extraRow = i == 0 || i > lastRowEnabled && isValid(righeValidate[i - 1]) && hasAtLeastOneFieldFilledIn(righeValidate[i - 1]);
    const isEnabled = i <= lastRowEnabled || rowHasAtLeastOneFieldFilledIn || extraRow;
    return {
      ...riga,
      enabled: isEnabled
    };
  });
  console.log('righe INPS:', righeValidateEdAbilitate);
  return righeValidateEdAbilitate;
};
exports.validateRigheSezioneINPS = validateRigheSezioneINPS;
const mapRigheSezioneRegioni = (righe, platform) => {
  let returnValue = righe.map((riga, i) => {
    const annoRiferimento = !!riga.annoRiferimento ? riga.annoRiferimento.toString() : undefined;
    const importoDebito = !!riga.importoDebito ? riga.importoDebito : undefined;
    const importoCredito = !!riga.importoCredito ? riga.importoCredito : undefined;
    const _riga = {
      enabled: i == 0,
      codiceRegione: riga === null || riga === void 0 ? void 0 : riga.codiceRegione,
      codiceRegioneDirty: false,
      // codiceRegioneBoundingBox: riga.codiceRegioneBoundingBox,
      codiceTributo: riga === null || riga === void 0 ? void 0 : riga.codiceTributo,
      codiceTributoDirty: false,
      // codiceTributoBoundingBox: riga.codiceTributoBoundingBox,
      rateazione: riga === null || riga === void 0 ? void 0 : riga.infoTributo,
      rateazioneError: '',
      rateazioneDirty: false,
      // rateazioneBoundingBox: riga.rateazioneBoundingBox,
      annoRiferimento: annoRiferimento,
      annoRiferimentoError: '',
      annoRiferimentoDirty: false,
      // annoRiferimentoBoundingBox: riga.annoRiferimentoBoundingBox,
      importoDebito: importoDebito,
      importoDebitoError: importoDebito ? (0, exports.validationField)(importoDebito, 0) : '',
      importoDebitoDirty: false,
      // importoDebitoBoundingBox: riga.importoDebitoBoundingBox,
      importoCredito: importoCredito,
      importoCreditoError: importoCredito ? (0, exports.validationField)(importoCredito, 0) : '',
      importoCreditoDirty: false
      // importoCreditoBoundingBox: riga.importoCreditoBoundingBox
    };
    return _riga;
  });
  if (platform === 'desktop') {
    for (let i = righe.length; i < f24_types_1.numRigheRegioni; i++) {
      returnValue.push({
        ...f24_types_1.defaultRigaRegioni,
        enabled: i == 0
      });
    }
  }
  return (0, exports.validateRigheSezioneRegioni)(returnValue);
};
exports.mapRigheSezioneRegioni = mapRigheSezioneRegioni;
const validateRigheSezioneRegioni = righe => {
  if (righe.length == 0) {
    console.error('Should not enter here!');
    return [{
      enabled: true,
      rateazioneError: '',
      annoRiferimentoError: '',
      importoDebitoError: '',
      importoCreditoError: ''
    }];
  }
  const hasAtLeastOneFieldFilledIn = riga => {
    return !!riga.codiceRegione || !!riga.codiceTributo || !!riga.rateazione || !!riga.annoRiferimento || !!riga.importoDebito || riga.importoDebito === 0 || !!riga.importoCredito || riga.importoCredito === 0;
  };
  const isValid = riga => {
    return !riga.rateazioneError && !riga.annoRiferimentoError && !riga.importoDebitoError && !riga.importoCreditoError;
  };
  // Validazione di tutte le righe che hanno almeno un campo compilato
  const righeValidate = righe.map(riga => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (!rowHasAtLeastOneFieldFilledIn) {
      return {
        ...riga,
        rateazioneError: '',
        annoRiferimentoError: '',
        importoDebitoError: '',
        importoCreditoError: ''
      };
    }
    return {
      ...riga,
      rateazioneError: (0, exports.validationField)(riga.rateazione, '', undefined, 4, 4, false),
      annoRiferimentoError: (0, exports.validationAnno)(false, riga.annoRiferimento),
      importoDebitoError: (0, exports.validationField)(riga.importoDebito, 0, undefined, undefined, undefined, false),
      importoCreditoError: (0, exports.validationField)(riga.importoCredito, 0, undefined, undefined, undefined, false)
    };
  });
  let lastRowEnabled = -1;
  // Abilito tutte le righe dall'inizio fino all'ultima riga compilata ed in più un'altra riga se l'ultima compilata è valida
  const righeValidateEdAbilitate = righeValidate.map((riga, i) => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (rowHasAtLeastOneFieldFilledIn) {
      lastRowEnabled = i;
    }
    // Aggiungo una riga abilitata se è la prima oppure se la sua precedente è valida ed è riempita
    const extraRow = i == 0 || i > lastRowEnabled && isValid(righeValidate[i - 1]) && hasAtLeastOneFieldFilledIn(righeValidate[i - 1]);
    const isEnabled = i <= lastRowEnabled || rowHasAtLeastOneFieldFilledIn || extraRow;
    return {
      ...riga,
      enabled: isEnabled
    };
  });
  console.log('righe Regioni:', righeValidateEdAbilitate);
  return righeValidateEdAbilitate;
};
exports.validateRigheSezioneRegioni = validateRigheSezioneRegioni;
const mapRigheSezioneIMU = (righe, platform) => {
  let returnValue = righe.map((riga, i) => {
    const NI = !!riga.numeroImmobili ? riga.numeroImmobili.toString() : undefined;
    const annoRiferimento = !!riga.annoRiferimento ? riga.annoRiferimento.toString() : undefined;
    const importoDebito = !!riga.importoDebito ? riga.importoDebito : undefined;
    const importoCredito = !!riga.importoCredito ? riga.importoCredito : undefined;
    const _riga = {
      enabled: i == 0,
      codiceRegione: riga === null || riga === void 0 ? void 0 : riga.codiceEnte,
      codiceRegioneDirty: false,
      // codiceRegioneBoundingBox: riga.codiceRegioneBoundingBox,
      R: (riga === null || riga === void 0 ? void 0 : riga.ravvedimento) === api_rest_1.RigaSezioneTributiLocaliDtoRavvedimentoEnum.S ? true : false,
      RDirty: false,
      // RBoundingBox: riga.RBoundingBox,
      IV: (riga === null || riga === void 0 ? void 0 : riga.immVariati) === api_rest_1.RigaSezioneTributiLocaliDtoImmVariatiEnum.S ? true : false,
      IVDirty: false,
      // IVBoundingBox: riga.IVBoundingBox,
      A: (riga === null || riga === void 0 ? void 0 : riga.acconto) === api_rest_1.RigaSezioneTributiLocaliDtoAccontoEnum.S ? true : false,
      ADirty: false,
      // ABoundingBox: riga.ABoundingBox,
      S: (riga === null || riga === void 0 ? void 0 : riga.saldo) === api_rest_1.RigaSezioneTributiLocaliDtoSaldoEnum.S ? true : false,
      SDirty: false,
      // SBoundingBox: riga.SBoundingBox,
      NI: NI,
      NIDirty: false,
      // NIBoundingBox: riga.NIBoundingBox,
      codiceTributo: riga === null || riga === void 0 ? void 0 : riga.codiceTributo,
      codiceTributoDirty: false,
      // codiceTributoBoundingBox: riga.codiceTributoBoundingBox,
      rateazione: riga === null || riga === void 0 ? void 0 : riga.infoTributo,
      rateazioneError: '',
      rateazioneDirty: false,
      // rateazioneBoundingBox: riga.rateazioneBoundingBox,
      annoRiferimento: annoRiferimento,
      annoRiferimentoError: '',
      annoRiferimentoDirty: false,
      // annoRiferimentoBoundingBox: riga.annoRiferimentoBoundingBox,
      importoDebito: importoDebito,
      importoDebitoError: importoDebito ? (0, exports.validationField)(importoDebito.toString(), 0) : '',
      importoDebitoDirty: false,
      // importoDebitoBoundingBox: riga.importoDebitoBoundingBox,
      importoCredito: importoCredito,
      importoCreditoError: importoCredito ? (0, exports.validationField)(importoCredito.toString(), 0) : '',
      importoCreditoDirty: false
      // importoCreditoBoundingBox: riga.importoCreditoBoundingBox
    };
    return _riga;
  });
  if (platform === 'desktop') {
    for (let i = righe.length; i < f24_types_1.numRigheAltriIMU; i++) {
      returnValue.push({
        ...f24_types_1.defaultRigaIMU,
        enabled: i == 0
      });
    }
  }
  return (0, exports.validateRigheSezioneIMU)(returnValue);
};
exports.mapRigheSezioneIMU = mapRigheSezioneIMU;
const validateRigheSezioneIMU = righe => {
  if (righe.length == 0) {
    console.error('Should not enter here!');
    return [{
      enabled: true,
      rateazioneError: '',
      annoRiferimentoError: '',
      importoDebitoError: '',
      importoCreditoError: ''
    }];
  }
  const hasAtLeastOneFieldFilledIn = riga => {
    return !!riga.codiceRegione || !!riga.R || !!riga.IV || !!riga.A || !!riga.S || !!riga.NI || !!riga.codiceTributo || !!riga.rateazione || !!riga.annoRiferimento || !!riga.importoDebito || riga.importoDebito === 0 || !!riga.importoCredito || riga.importoCredito === 0;
  };
  const isValid = riga => {
    return !riga.rateazioneError && !riga.annoRiferimentoError && !riga.importoDebitoError && !riga.importoCreditoError;
  };
  // Validazione di tutte le righe che hanno almeno un campo compilato
  const righeValidate = righe.map(riga => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (!rowHasAtLeastOneFieldFilledIn) {
      return {
        ...riga,
        rateazioneError: '',
        annoRiferimentoError: '',
        importoDebitoError: '',
        importoCreditoError: ''
      };
    }
    return {
      ...riga,
      rateazioneError: (0, exports.validationField)(riga.rateazione, '', undefined, 4, 4, false),
      annoRiferimentoError: (0, exports.validationAnno)(false, riga.annoRiferimento),
      importoDebitoError: (0, exports.validationField)(riga.importoDebito, 0, undefined, undefined, undefined, false),
      importoCreditoError: (0, exports.validationField)(riga.importoCredito, 0, undefined, undefined, undefined, false)
    };
  });
  let lastRowEnabled = -1;
  // Abilito tutte le righe dall'inizio fino all'ultima riga compilata ed in più un'altra riga se l'ultima compilata è valida
  const righeValidateEdAbilitate = righeValidate.map((riga, i) => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (rowHasAtLeastOneFieldFilledIn) {
      lastRowEnabled = i;
    }
    // Aggiungo una riga abilitata se è la prima oppure se la sua precedente è valida ed è riempita
    const extraRow = i == 0 || i > lastRowEnabled && isValid(righeValidate[i - 1]) && hasAtLeastOneFieldFilledIn(righeValidate[i - 1]);
    const isEnabled = i <= lastRowEnabled || rowHasAtLeastOneFieldFilledIn || extraRow;
    return {
      ...riga,
      enabled: isEnabled
    };
  });
  console.log('righe IMU:', righeValidateEdAbilitate);
  return righeValidateEdAbilitate;
};
exports.validateRigheSezioneIMU = validateRigheSezioneIMU;
const mapRigheSezioneAltriEntiIL = (righe, platform) => {
  let returnValue = righe.map((riga, i) => {
    const importoDebito = !!riga.importoDebito ? riga.importoDebito : undefined;
    const importoCredito = !!riga.importoCredito ? riga.importoCredito : undefined;
    const _riga = {
      enabled: i == 0,
      codiceSede: riga === null || riga === void 0 ? void 0 : riga.codiceSede,
      codiceSedeDirty: false,
      // codiceSedeBoundingBox: riga.codiceSedeBoundingBox,
      codiceDitta: riga === null || riga === void 0 ? void 0 : riga.codiceDitta,
      codiceDittaDirty: false,
      // codiceDittaBoundingBox: riga.codiceDittaBoundingBox,
      CC: riga === null || riga === void 0 ? void 0 : riga.cc,
      CCDirty: false,
      // CCBoundingBox: riga.CCBoundingBox,
      numeroDiRiferimento: riga === null || riga === void 0 ? void 0 : riga.riferimento,
      numeroDiRiferimentoDirty: false,
      // numeroDiRiferimentoBoundingBox: riga.numeroDiRiferimentoBoundingBox,
      causale: riga === null || riga === void 0 ? void 0 : riga.causale,
      causaleDirty: false,
      // causaleBoundingBox: riga.causaleBoundingBox,
      importoDebito: importoDebito,
      importoDebitoError: importoDebito ? (0, exports.validationField)(importoDebito, 0) : '',
      importoDebitoDirty: false,
      // importoDebitoBoundingBox: riga.importoDebitoBoundingBox,
      importoCredito: importoCredito,
      importoCreditoError: importoCredito ? (0, exports.validationField)(importoCredito, 0) : '',
      importoCreditoDirty: false
      // importoCreditoBoundingBox: riga.importoCreditoBoundingBox
    };
    return _riga;
  });
  if (platform === 'desktop') {
    for (let i = righe.length; i < f24_types_1.numRigheAltriEntiIL; i++) {
      returnValue.push({
        ...f24_types_1.defaultRigaAltriEntiIL,
        enabled: i == 0
      });
    }
  }
  return (0, exports.validateRigheSezioneAltriEntiIL)(returnValue);
};
exports.mapRigheSezioneAltriEntiIL = mapRigheSezioneAltriEntiIL;
const validateRigheSezioneAltriEntiIL = righe => {
  if (righe.length == 0) {
    console.error('Should not enter here!');
    return [{
      enabled: true,
      importoDebitoError: '',
      importoCreditoError: ''
    }];
  }
  const hasAtLeastOneFieldFilledIn = riga => {
    return !!riga.codiceSede || !!riga.codiceDitta || !!riga.CC || !!riga.numeroDiRiferimento || !!riga.causale || !!riga.importoDebito || riga.importoDebito === 0 || !!riga.importoCredito || riga.importoCredito === 0;
  };
  const isValid = riga => {
    return !riga.importoDebitoError && !riga.importoCreditoError;
  };
  // Validazione di tutte le righe che hanno almeno un campo compilato
  const righeValidate = righe.map(riga => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (!rowHasAtLeastOneFieldFilledIn) {
      return {
        ...riga,
        importoDebitoError: '',
        importoCreditoError: ''
      };
    }
    return {
      ...riga,
      importoDebitoError: (0, exports.validationField)(riga.importoDebito, 0, undefined, undefined, undefined, false),
      importoCreditoError: (0, exports.validationField)(riga.importoCredito, 0, undefined, undefined, undefined, false)
    };
  });
  let lastRowEnabled = -1;
  // Abilito tutte le righe dall'inizio fino all'ultima riga compilata ed in più un'altra riga se l'ultima compilata è valida
  const righeValidateEdAbilitate = righeValidate.map((riga, i) => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (rowHasAtLeastOneFieldFilledIn) {
      lastRowEnabled = i;
    }
    // Aggiungo una riga abilitata se è la prima oppure se la sua precedente è valida ed è riempita
    const extraRow = i == 0 || i > lastRowEnabled && isValid(righeValidate[i - 1]) && hasAtLeastOneFieldFilledIn(righeValidate[i - 1]);
    const isEnabled = i <= lastRowEnabled || rowHasAtLeastOneFieldFilledIn || extraRow;
    return {
      ...riga,
      enabled: isEnabled
    };
  });
  console.log('righe IL:', righeValidateEdAbilitate);
  return righeValidateEdAbilitate;
};
exports.validateRigheSezioneAltriEntiIL = validateRigheSezioneAltriEntiIL;
const mapRigheSezioneAltriEntiMN = (righe, platform) => {
  let returnValue = righe.map((riga, i) => {
    const periodoDa = !!(riga === null || riga === void 0 ? void 0 : riga.periodoDaMese) && !!(riga === null || riga === void 0 ? void 0 : riga.periodoDaAnno) ? `${riga.periodoDaAnno}-${riga.periodoDaMese}` : undefined;
    const periodoA = !!(riga === null || riga === void 0 ? void 0 : riga.periodoAMese) && !!(riga === null || riga === void 0 ? void 0 : riga.periodoAAnno) ? `${riga.periodoAAnno}-${riga.periodoAMese}` : undefined;
    const importoDebito = !!riga.importoDebito ? riga.importoDebito : undefined;
    const importoCredito = !!riga.importoCredito ? riga.importoCredito : undefined;
    const _riga = {
      enabled: i == 0,
      codiceEnte: riga === null || riga === void 0 ? void 0 : riga.codiceEnte,
      codiceEnteError: '',
      codiceEnteDirty: false,
      // codiceEnteBoundingBox: undefined,
      codiceSede: riga === null || riga === void 0 ? void 0 : riga.codiceSede,
      codiceSedeDirty: false,
      // codiceSedeBoundingBox: riga.codiceSedeBoundingBox,
      causaleContributo: riga === null || riga === void 0 ? void 0 : riga.causale,
      causaleContributoDirty: false,
      // causaleContributoBoundingBox: riga.causaleContributoBoundingBox,
      codicePosizione: riga === null || riga === void 0 ? void 0 : riga.codicePosizione,
      codicePosizioneDirty: false,
      // codicePosizioneBoundingBox: riga.codicePosizioneBoundingBox,
      periodoDa: periodoDa ? moment(periodoDa).format('MM/YYYY') : undefined,
      periodoDaError: periodoDa ? (0, exports.validationFieldDate)(periodoDa, [], false) : '',
      periodoDaDirty: false,
      // periodoDaBoundingBox: riga.periodoDaBoundingBox,
      periodoA: periodoA ? moment(periodoA).format('MM/YYYY') : undefined,
      periodoAError: periodoA ? (0, exports.validationFieldDate)(periodoA, [], false) : '',
      periodoADirty: false,
      // periodoABoundingBox: riga.periodoABoundingBox,
      importoDebito: importoDebito,
      importoDebitoError: importoDebito ? (0, exports.validationField)(importoDebito, 0) : '',
      importoDebitoDirty: false,
      // importoDebitoBoundingBox: riga.importoDebitoBoundingBox,
      importoCredito: importoCredito,
      importoCreditoError: importoCredito ? (0, exports.validationField)(importoCredito, 0) : '',
      importoCreditoDirty: false
      // importoCreditoBoundingBox: riga.importoCreditoBoundingBox
    };
    return _riga;
  });
  if (platform === 'desktop') {
    for (let i = righe.length; i < f24_types_1.numRigheAltriEntiMN; i++) {
      returnValue.push({
        ...f24_types_1.defaultRigaAltriEntiMN,
        enabled: i == 0
      });
    }
  }
  return (0, exports.validateRigheSezioneAltriEntiMN)(returnValue);
};
exports.mapRigheSezioneAltriEntiMN = mapRigheSezioneAltriEntiMN;
const validateRigheSezioneAltriEntiMN = righe => {
  if (righe.length == 0) {
    console.error('Should not enter here!');
    return [{
      enabled: true,
      codiceEnteError: '',
      periodoDaError: '',
      periodoAError: '',
      importoDebitoError: '',
      importoCreditoError: ''
    }];
  }
  const hasAtLeastOneFieldFilledIn = riga => {
    return !!riga.codiceEnte || !!riga.codiceSede || !!riga.causaleContributo || !!riga.codicePosizione || !!riga.periodoDa || !!riga.periodoA || !!riga.importoDebito || riga.importoDebito === 0 || !!riga.importoCredito || riga.importoCredito === 0;
  };
  const isValid = riga => {
    return !riga.codiceEnteError && !riga.periodoDaError && !riga.periodoAError && !riga.importoDebitoError && !riga.importoCreditoError;
  };
  // Validazione di tutte le righe che hanno almeno un campo compilato
  const righeValidate = righe.map((riga, index) => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (!rowHasAtLeastOneFieldFilledIn) {
      return {
        ...riga,
        codiceEnteError: '',
        periodoDaError: '',
        periodoAError: '',
        importoDebitoError: '',
        importoCreditoError: ''
      };
    }
    return {
      ...riga,
      codiceEnteError: index == 0 ? (0, exports.validationField)(riga.codiceEnte, '') : '',
      periodoDaError: (0, exports.validationPeriodoAnnoEAnnoMese)(false, riga.periodoDa),
      periodoAError: (0, exports.validationPeriodoAnnoEAnnoMese)(false, riga.periodoA),
      importoDebitoError: (0, exports.validationField)(riga.importoDebito, 0, undefined, undefined, undefined, false),
      importoCreditoError: (0, exports.validationField)(riga.importoCredito, 0, undefined, undefined, undefined, false)
    };
  });
  let lastRowEnabled = -1;
  // Abilito tutte le righe dall'inizio fino all'ultima riga compilata ed in più un'altra riga se l'ultima compilata è valida
  const righeValidateEdAbilitate = righeValidate.map((riga, i) => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (rowHasAtLeastOneFieldFilledIn) {
      lastRowEnabled = i;
    }
    // Aggiungo una riga abilitata se è la prima oppure se la sua precedente è valida ed è riempita
    const extraRow = i == 0 || i > lastRowEnabled && isValid(righeValidate[i - 1]) && hasAtLeastOneFieldFilledIn(righeValidate[i - 1]);
    const isEnabled = i <= lastRowEnabled || rowHasAtLeastOneFieldFilledIn || extraRow;
    return {
      ...riga,
      enabled: isEnabled
    };
  });
  console.log('righe MN:', righeValidateEdAbilitate);
  return righeValidateEdAbilitate;
};
exports.validateRigheSezioneAltriEntiMN = validateRigheSezioneAltriEntiMN;
const mapRigheSezioneMotivoPagamento = (righe, platform) => {
  let returnValue = righe.map((riga, i) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const numeroImmobiliari = !!riga.numeroImmobili ? riga.numeroImmobili.toString() : undefined;
    const anno = !!riga.annoRiferimento ? riga.annoRiferimento : undefined;
    const detrazione = !!riga.detrazione ? riga.detrazione : undefined;
    const importoDebito = !!riga.importoDebito ? riga.importoDebito : undefined;
    const importoCredito = !!riga.importoCredito ? riga.importoCredito : undefined;
    const _riga = {
      enabled: i == 0,
      sezione: (_a = riga === null || riga === void 0 ? void 0 : riga.sezione) !== null && _a !== void 0 ? _a : null,
      sezioneError: '',
      sezioneDirty: false,
      codiceTributo: (_b = riga === null || riga === void 0 ? void 0 : riga.codiceTributo) !== null && _b !== void 0 ? _b : null,
      codiceTributoError: '',
      codiceTributoDirty: false,
      codiceEnte: (_c = riga === null || riga === void 0 ? void 0 : riga.codiceEnte) !== null && _c !== void 0 ? _c : null,
      codiceEnteError: '',
      codiceEnteDirty: false,
      ravvedimento: (_d = riga === null || riga === void 0 ? void 0 : riga.ravvedimento) !== null && _d !== void 0 ? _d : null,
      ravvedimentoError: '',
      ravvedimentoDirty: false,
      immobiliareVariati: (_e = riga === null || riga === void 0 ? void 0 : riga.immobiliVariati) !== null && _e !== void 0 ? _e : null,
      immobiliareVariatiError: '',
      immobiliareVariatiDirty: false,
      acconto: (_f = riga === null || riga === void 0 ? void 0 : riga.acconto) !== null && _f !== void 0 ? _f : null,
      accontoError: '',
      accontoDirty: false,
      saldo: (_g = riga === null || riga === void 0 ? void 0 : riga.saldo) !== null && _g !== void 0 ? _g : null,
      saldoError: '',
      saldoDirty: false,
      numeroImmobiliari: numeroImmobiliari,
      numeroImmobiliariError: numeroImmobiliari && (numeroImmobiliari === null || numeroImmobiliari === void 0 ? void 0 : numeroImmobiliari.length) <= 3 ? '' : 'invalid.format',
      numeroImmobiliariDirty: false,
      rate: (riga === null || riga === void 0 ? void 0 : riga.rateazione) ? riga.rateazione : undefined,
      rateError: '',
      rateDirty: false,
      anno: anno,
      annoError: '',
      annoDirty: false,
      detrazione: detrazione,
      detrazioneError: '',
      detrazioneDirty: false,
      importoDebito: importoDebito,
      importoDebitoError: importoDebito ? (0, exports.validationField)(importoDebito.toString(), 0) : '',
      importoDebitoDirty: false,
      importoCredito: importoCredito,
      importoCreditoError: importoCredito ? (0, exports.validationField)(importoCredito.toString(), 0) : '',
      importoCreditoDirty: false
    };
    return _riga;
  });
  if (platform === 'desktop') {
    for (let i = righe.length; i < f24_types_1.numRigheMotivoPagamento; i++) {
      const isRowEnabled = i == 0;
      returnValue.push({
        ...f24_types_1.defaultRigaMotivoPagamento,
        enabled: isRowEnabled
      });
    }
  }
  return (0, exports.validateRigheSezioneMotivoPagamento)(returnValue);
};
exports.mapRigheSezioneMotivoPagamento = mapRigheSezioneMotivoPagamento;
const validateRigheSezioneMotivoPagamento = righe => {
  if (righe.length == 0) {
    console.error('Should not enter here!');
    return [{
      enabled: true,
      sezioneError: 'invalid.required'
    }];
  }
  const hasAtLeastOneFieldFilledIn = riga => {
    return !!riga.sezione || !!riga.codiceTributo || !!riga.codiceEnte || riga.ravvedimento == 'S' || riga.immobiliareVariati == 'S' || riga.acconto == 'S' || riga.saldo == 'S' || !!riga.numeroImmobiliari || !!riga.rate || !!riga.anno || riga.anno === 0 || !!riga.detrazione || riga.detrazione === 0 || !!riga.importoDebito || riga.importoDebito === 0 || !!riga.importoCredito || riga.importoCredito === 0;
  };
  const isValid = riga => {
    return !riga.sezioneError && !riga.codiceTributoError && !riga.codiceEnteError && !riga.numeroImmobiliariError && !riga.annoError && !riga.importoDebitoError;
  };
  // Validazione di tutte le righe che hanno almeno un campo compilato
  const righeValidate = righe.map(riga => {
    var _a;
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (!rowHasAtLeastOneFieldFilledIn) {
      return {
        ...riga,
        sezioneError: '',
        codiceEnteError: '',
        numeroImmobiliariError: '',
        codiceTributoError: '',
        annoError: '',
        importoDebitoError: ''
      };
    }
    const isER = riga.sezione === "ER";
    return {
      ...riga,
      sezioneError: (0, exports.validationField)(riga.sezione, ''),
      codiceEnteError: !isER ? (0, exports.validationField)(riga.codiceEnte, '') : '',
      numeroImmobiliariError: !isER ? (0, exports.validationField)(riga.numeroImmobiliari, '', undefined, undefined, undefined, false) : '',
      codiceTributoError: (0, exports.validationField)(riga.codiceTributo, ''),
      annoError: (0, exports.validationAnno)(true, (_a = riga.anno) === null || _a === void 0 ? void 0 : _a.toString()),
      importoDebitoError: (0, exports.validationField)(riga.importoDebito, 0)
    };
  });
  let lastRowEnabled = -1;
  // Abilito tutte le righe dall'inizio fino all'ultima riga compilata ed in più un'altra riga se l'ultima compilata è valida
  const righeValidateEdAbilitate = righeValidate.map((riga, i) => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (rowHasAtLeastOneFieldFilledIn) {
      lastRowEnabled = i;
    }
    // Aggiungo una riga abilitata se è la prima oppure se la sua precedente è valida ed è riempita
    const extraRow = i == 0 || i > lastRowEnabled && isValid(righeValidate[i - 1]) && hasAtLeastOneFieldFilledIn(righeValidate[i - 1]);
    const isEnabled = i <= lastRowEnabled || rowHasAtLeastOneFieldFilledIn || extraRow;
    return {
      ...riga,
      enabled: isEnabled
    };
  });
  if (lastRowEnabled == -1) {
    console.log('righe Semplificato first row');
    return righeValidateEdAbilitate.map((riga, i) => i == 0 ? {
      ...riga,
      enabled: true,
      sezioneError: 'invalid.required'
    } : riga);
  }
  console.log('righe Semplificato:', righeValidateEdAbilitate);
  return righeValidateEdAbilitate;
};
exports.validateRigheSezioneMotivoPagamento = validateRigheSezioneMotivoPagamento;
const mapRigheSezioneAccise = (righe, platform) => {
  let returnValue = righe.map((riga, i) => {
    const annoRiferimento = !!riga.annoRiferimento ? riga.annoRiferimento : undefined;
    const importoDebito = !!riga.importoDebito ? riga.importoDebito : undefined;
    const _riga = {
      enabled: i == 0,
      codiceEnte: riga === null || riga === void 0 ? void 0 : riga.codiceEnte,
      codiceEnteError: '',
      codiceEnteDirty: false,
      provincia: riga === null || riga === void 0 ? void 0 : riga.provincia,
      provinciaError: '',
      provinciaDirty: false,
      codiceTributo: riga === null || riga === void 0 ? void 0 : riga.codiceTributo,
      codiceTributoError: '',
      codiceTributoDirty: false,
      codiceIdentificativo: riga === null || riga === void 0 ? void 0 : riga.codiceIdentificativo,
      codiceIdentificativoError: '',
      codiceIdentificativoDirty: false,
      rateazione: riga === null || riga === void 0 ? void 0 : riga.rateazione,
      rateazioneError: '',
      rateazioneDirty: false,
      mese: riga === null || riga === void 0 ? void 0 : riga.mese,
      meseError: '',
      meseDirty: false,
      annoRiferimento: annoRiferimento,
      annoRiferimentoError: '',
      annoRiferimentoDirty: false,
      importoDebito: importoDebito,
      importoDebitoError: importoDebito ? (0, exports.validationField)(importoDebito, 0) : '',
      importoDebitoDirty: false
    };
    return _riga;
  });
  if (platform === 'desktop') {
    for (let i = righe.length; i < f24_types_1.numRigheAccise; i++) {
      returnValue.push({
        ...f24_types_1.defaultRigaAccise,
        enabled: i == 0
      });
    }
  }
  return (0, exports.validateRigheSezioneAccise)(returnValue);
};
exports.mapRigheSezioneAccise = mapRigheSezioneAccise;
const validateRigheSezioneAccise = righe => {
  if (righe.length == 0) {
    console.error('Should not enter here!');
    return [{
      enabled: true,
      annoRiferimentoError: '',
      importoDebitoError: ''
    }];
  }
  const hasAtLeastOneFieldFilledIn = riga => {
    return !!riga.codiceEnte || !!riga.provincia || !!riga.codiceTributo || !!riga.codiceIdentificativo || !!riga.rateazione || !!riga.mese || !!riga.annoRiferimento || !!riga.importoDebito || riga.importoDebito === 0;
  };
  const isValid = riga => {
    return !riga.annoRiferimentoError && !riga.importoDebitoError;
  };
  // Validazione di tutte le righe che hanno almeno un campo compilato
  const righeValidate = righe.map(riga => {
    var _a;
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (!rowHasAtLeastOneFieldFilledIn) {
      return {
        ...riga,
        annoRiferimentoError: '',
        importoDebitoError: ''
      };
    }
    return {
      ...riga,
      annoRiferimentoError: (0, exports.validationAnno)(false, (_a = riga.annoRiferimento) === null || _a === void 0 ? void 0 : _a.toString()),
      importoDebitoError: (0, exports.validationField)(riga.importoDebito, 0, undefined, undefined, undefined, false)
    };
  });
  let lastRowEnabled = -1;
  // Abilito tutte le righe dall'inizio fino all'ultima riga compilata ed in più un'altra riga se l'ultima compilata è valida
  const righeValidateEdAbilitate = righeValidate.map((riga, i) => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (rowHasAtLeastOneFieldFilledIn) {
      lastRowEnabled = i;
    }
    // Aggiungo una riga abilitata se è la prima oppure se la sua precedente è valida ed è riempita
    const extraRow = i == 0 || i > lastRowEnabled && isValid(righeValidate[i - 1]) && hasAtLeastOneFieldFilledIn(righeValidate[i - 1]);
    const isEnabled = i <= lastRowEnabled || rowHasAtLeastOneFieldFilledIn || extraRow;
    return {
      ...riga,
      enabled: isEnabled
    };
  });
  console.log('righe Accise: ', righeValidateEdAbilitate);
  return righeValidateEdAbilitate;
};
exports.validateRigheSezioneAccise = validateRigheSezioneAccise;
const mapRigheSezioneAltroErario = (righe, platform) => {
  let returnValue = righe.map((riga, i) => {
    const annoRiferimento = !!riga.annoRiferimento ? riga.annoRiferimento : undefined;
    const importoDebito = !!riga.importoDebito ? riga.importoDebito : undefined;
    const _riga = {
      enabled: i == 0,
      tipoVeicolo: riga === null || riga === void 0 ? void 0 : riga.tipoVeicolo,
      tipoVeicoloError: '',
      tipoVeicoloDirty: false,
      elementoId: riga === null || riga === void 0 ? void 0 : riga.elementoId,
      elementoIdError: '',
      elementoIdDirty: false,
      codiceTributo: riga === null || riga === void 0 ? void 0 : riga.codiceTributo,
      codiceTributoError: '',
      codiceTributoDirty: false,
      annoRiferimento: annoRiferimento,
      annoRiferimentoError: '',
      annoRiferimentoDirty: false,
      importoDebito: importoDebito,
      importoDebitoError: importoDebito ? (0, exports.validationField)(importoDebito, 0) : '',
      importoDebitoDirty: false
    };
    return _riga;
  });
  if (platform === 'desktop') {
    for (let i = righe.length; i < f24_types_1.numRigheAltroErario; i++) {
      returnValue.push({
        ...f24_types_1.defaultRigaAltroErario,
        enabled: i == 0
      });
    }
  }
  return (0, exports.validateRigheSezioneAltroErario)(returnValue);
};
exports.mapRigheSezioneAltroErario = mapRigheSezioneAltroErario;
const validateRigheSezioneAltroErario = righe => {
  if (righe.length == 0) {
    console.error('Should not enter here!');
    return [{
      enabled: true,
      annoRiferimentoError: '',
      importoDebitoError: ''
    }];
  }
  const hasAtLeastOneFieldFilledIn = riga => {
    return !!riga.tipoVeicolo || !!riga.elementoId || !!riga.annoRiferimento || !!riga.codiceTributo || !!riga.importoDebito || riga.importoDebito === 0;
  };
  const isValid = riga => {
    return !riga.annoRiferimentoError && !riga.importoDebitoError;
  };
  // Validazione di tutte le righe che hanno almeno un campo compilato
  const righeValidate = righe.map(riga => {
    var _a;
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (!rowHasAtLeastOneFieldFilledIn) {
      return {
        ...riga,
        annoRiferimentoError: '',
        importoDebitoError: ''
      };
    }
    return {
      ...riga,
      annoRiferimentoError: (0, exports.validationAnno)(false, (_a = riga.annoRiferimento) === null || _a === void 0 ? void 0 : _a.toString()),
      importoDebitoError: (0, exports.validationField)(riga.importoDebito, 0, undefined, undefined, undefined, false)
    };
  });
  let lastRowEnabled = -1;
  // Abilito tutte le righe dall'inizio fino all'ultima riga compilata ed in più un'altra riga se l'ultima compilata è valida
  const righeValidateEdAbilitate = righeValidate.map((riga, i) => {
    const rowHasAtLeastOneFieldFilledIn = hasAtLeastOneFieldFilledIn(riga);
    if (rowHasAtLeastOneFieldFilledIn) {
      lastRowEnabled = i;
    }
    // Aggiungo una riga abilitata se è la prima oppure se la sua precedente è valida ed è riempita
    const extraRow = i == 0 || i > lastRowEnabled && isValid(righeValidate[i - 1]) && hasAtLeastOneFieldFilledIn(righeValidate[i - 1]);
    const isEnabled = i <= lastRowEnabled || rowHasAtLeastOneFieldFilledIn || extraRow;
    return {
      ...riga,
      enabled: isEnabled
    };
  });
  console.log('righe Altro Erario:', righeValidateEdAbilitate);
  return righeValidateEdAbilitate;
};
exports.validateRigheSezioneAltroErario = validateRigheSezioneAltroErario;
/**
 *
 * @param importo importo su cui fare il controllo
 * @param multiplo valore del multiplo su cui eseguire il controllo
 * @example importo = 2000, multiplo = 1000 --> return '' --> importo%multiplo === 0
 */
const validationImportiMultipli = (importo, multiplo, massimo) => {
  if (massimo == undefined) {
    return importo % multiplo == 0 ? '' : 'invalid.format';
  } else if (massimo == 0) {
    return 'invalid.required';
  } else {
    if (importo > massimo) {
      return 'invalid.required';
    }
    if (importo % multiplo != 0) return 'invalid.format';
    if (massimo == 0) {
      return 'invalid.format';
    }
  }
  return '';
};
exports.validationImportiMultipli = validationImportiMultipli;
const validationImportoRicaricaCarta = (sField, max, min) => {
  let sReturn = '';
  let _importo = parseFloat(sField.toString());
  if (!!min && Number(_importo) < Number(min)) {
    sReturn = 'invalid.format.min';
  } else if (!!max && Number(_importo) > Number(max)) {
    sReturn = 'invalid.format.max';
  }
  sReturn = sField === '' || sField === '0,00' || sField === '0,0000' || sField === '0' || sField === undefined ? 'invalid.required' : sReturn;
  return sReturn;
};
exports.validationImportoRicaricaCarta = validationImportoRicaricaCarta;
/**
 *
 * @param massimale valore massimale
 * @param transazione valore su cui fare il controllo
 */
const validationTransazione = (transazione, massimale, minimo, step) => {
  let minimale = minimo ? minimo : 0;
  if (Number(transazione) > Number(massimale)) {
    return 'invalid.max';
  } else if (Number(transazione) < Number(minimale)) {
    return 'invalid.min';
  } else if (!!step && Number(transazione) % step !== 0) {
    return 'invalid.step';
  } else {
    return '';
  }
};
exports.validationTransazione = validationTransazione;
/**
 * Metodo per rendere flat un oggetto avendo chiavi di string solo di primo livello.
 *
 * es. { 'chiave1': 'valore1', 'chiave2': { 'sottochiave1': 'sottovalore' } }
 *     => { 'chiave1': 'valore1, 'chiave2.sottochiave1': 'sottovalore' }
 *
 * @param object oggetto da rendere flat
 */
const flattenObject = object => {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++) {
        recurse(cur[i], prop + '[' + i + ']');
      }
      if (l == 0) {
        result[prop] = [];
      }
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + '.' + p : p);
      }
      if (isEmpty && prop) {
        result[prop] = {};
      }
    }
  }
  recurse(object, '');
  return result;
};
exports.flattenObject = flattenObject;
const validateCodiceISIN = (sField, required, minChar) => {
  const pattern = /^[0-9A-z]/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 || minChar && sField.length < minChar ? 'invalid.format' : '';
};
exports.validateCodiceISIN = validateCodiceISIN;
const validateDescription = (sField, required, minChar) => {
  const pattern = /^[0-9A-zÀ-ú]/;
  return required && !sField ? 'invalid.required' : sField && sField.search(pattern) === -1 || minChar && sField.length < minChar ? 'invalid.format' : '';
};
exports.validateDescription = validateDescription;
const validationPIN = (sField, minLength, oldPIN, confirmPIN) => {
  let sReturn = '';
  const pattern = /^[0-9]{5}$/;
  sReturn = sField && sField.search(pattern) === -1 ? 'invalid.format' : '';
  sReturn = sField === '' ? 'invalid.required' : '';
  if (sReturn === '' && minLength) {
    sReturn = minLength && sField.length < minLength ? 'invalid.format' : '';
  }
  if (sReturn === '' && oldPIN) {
    sReturn = sField === oldPIN ? 'invalid.notValidPIN' : '';
  }
  if (sReturn === '' && confirmPIN) {
    sReturn = sField !== confirmPIN ? 'invalid.notValidPIN' : '';
  }
  return sReturn;
};
exports.validationPIN = validationPIN;
const getFirstDayOfMonth = inputDate => {
  let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  let date = inputDate ? inputDate : new Date();
  return new Date(new Date(date.getFullYear(), date.getMonth(), 1).getTime() - tzoffset);
};
exports.getFirstDayOfMonth = getFirstDayOfMonth;
const getLastDayOfMonth = inputDate => {
  let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  let date = inputDate ? inputDate : new Date();
  return new Date(new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime() - tzoffset);
};
exports.getLastDayOfMonth = getLastDayOfMonth;
const getRandomCode = length => {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(length || 10);
  (window.crypto || window.msCrypto).getRandomValues(array);
  return String.fromCharCode.apply(null, Array.from(array.map(x => validChars.charCodeAt(x % validChars.length))));
};
exports.getRandomCode = getRandomCode;
/**
 *
 * @returns true if valid, false if not valid
 */
const validateNumericField = (value, operatoreConfronto, importoConfronto, obbligatorio) => {
  let valid = true;
  if (!!value && obbligatorio || !obbligatorio) {
    switch (operatoreConfronto) {
      case 'Nessuno':
        break;
      case 'Minore':
        valid = parseFloat(value !== null && value !== void 0 ? value : '0') < parseFloat(importoConfronto !== null && importoConfronto !== void 0 ? importoConfronto : '0');
        break;
      case 'Minore o Uguale':
        valid = parseFloat(value !== null && value !== void 0 ? value : '0') <= parseFloat(importoConfronto !== null && importoConfronto !== void 0 ? importoConfronto : '0');
        break;
      case 'Maggiore':
        valid = parseFloat(value !== null && value !== void 0 ? value : '0') > parseFloat(importoConfronto !== null && importoConfronto !== void 0 ? importoConfronto : '0');
        break;
      case 'Maggiore o Uguale':
        valid = parseFloat(value !== null && value !== void 0 ? value : '0') >= parseFloat(importoConfronto !== null && importoConfronto !== void 0 ? importoConfronto : '0');
        break;
    }
  } else {
    valid = false;
  }
  return valid;
};
exports.validateNumericField = validateNumericField;
function b64toBlob(dataURI, contentType) {
  let base64str = dataURI;
  // decode base64 string, remove space for IE compatibility
  let binary = atob(base64str.replace(/\s/g, ''));
  let len = binary.length;
  let buffer = new ArrayBuffer(len);
  let view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return new Blob([view], {
    type: contentType
  });
}
exports.b64toBlob = b64toBlob;
;
const validationIsinMercatoItaliano = sField => {
  const pattern = /^(it|IT)[0-9a-zA-Z]{10}$/;
  return sField && sField.search(pattern) === -1 ? false : true;
};
exports.validationIsinMercatoItaliano = validationIsinMercatoItaliano;
const rimuoviPrimoCarattereMercato = sField => {
  if (sField.length == 4) {
    return sField.substring(1, sField.length);
  } else {
    return sField;
  }
};
exports.rimuoviPrimoCarattereMercato = rimuoviPrimoCarattereMercato;
const convertToFormRecapitiEmail = recapiti => {
  if (recapiti.length === 0) {
    return [(0, rubrica_types_1.FormRecapitoFromJSON)()];
  }
  const retVal = [];
  for (let i = 0; i < recapiti.length; i++) {
    retVal.push({
      dispensaCredenziale: false,
      etichetta: recapiti[i].etichetta || '',
      etichettaDirty: false,
      etichettaError: '',
      recapito: recapiti[i].indirizzo || '',
      recapitoDirty: false,
      recapitoError: ''
    });
  }
  return retVal;
};
exports.convertToFormRecapitiEmail = convertToFormRecapitiEmail;
const convertToFormRecapitiIban = recapiti => {
  if (recapiti.length === 0) {
    return [(0, rubrica_types_1.FormRecapitoFromJSON)()];
  }
  const retVal = [];
  for (let i = 0; i < recapiti.length; i++) {
    retVal.push({
      dispensaCredenziale: recapiti[i].dispensaCredenziale || false,
      etichetta: recapiti[i].etichetta || '',
      etichettaDirty: false,
      etichettaError: '',
      recapito: recapiti[i].iban || '',
      recapitoDirty: false,
      recapitoError: ''
    });
  }
  return retVal;
};
exports.convertToFormRecapitiIban = convertToFormRecapitiIban;
const convertToFormRecapitiTelefono = recapiti => {
  if (recapiti.length === 0) {
    return [(0, rubrica_types_1.FormRecapitoFromJSON)()];
  }
  const retVal = [];
  for (let i = 0; i < recapiti.length; i++) {
    retVal.push({
      dispensaCredenziale: recapiti[i].dispensaCredenziale || false,
      etichetta: recapiti[i].etichetta || '',
      etichettaDirty: false,
      etichettaError: '',
      recapito: recapiti[i].numero || '',
      recapitoDirty: false,
      recapitoError: ''
    });
  }
  return retVal;
};
exports.convertToFormRecapitiTelefono = convertToFormRecapitiTelefono;
const convertToFormRecapitiTarga = recapiti => {
  if (recapiti.length === 0) {
    return [(0, rubrica_types_1.FormRecapitoFromJSON)()];
  }
  const retVal = [];
  for (let i = 0; i < recapiti.length; i++) {
    retVal.push({
      dispensaCredenziale: recapiti[i].dispensaCredenziale || false,
      etichetta: recapiti[i].etichetta || '',
      etichettaDirty: false,
      etichettaError: '',
      recapito: recapiti[i].targa || '',
      recapitoDirty: false,
      recapitoError: ''
    });
  }
  return retVal;
};
exports.convertToFormRecapitiTarga = convertToFormRecapitiTarga;
const convertToFormRecapitiPass = recapiti => {
  if (recapiti.length === 0) {
    return [(0, rubrica_types_1.FormRecapitoFromJSON)()];
  }
  const retVal = [];
  for (let i = 0; i < recapiti.length; i++) {
    retVal.push({
      dispensaCredenziale: recapiti[i].dispensaCredenziale || false,
      etichetta: recapiti[i].etichetta || '',
      etichettaDirty: false,
      etichettaError: '',
      recapito: recapiti[i].pan || '',
      recapitoDirty: false,
      recapitoError: ''
    });
  }
  return retVal;
};
exports.convertToFormRecapitiPass = convertToFormRecapitiPass;
const buildRecapito = (recapiti, type) => {
  let returnValue = [];
  for (let i = 0; i < recapiti.length; i++) {
    switch (type) {
      case 'email':
        if (recapiti[i].recapito && !recapiti[i].recapitoError) {
          returnValue.push({
            etichetta: recapiti[i].etichetta,
            dispensaCredenziale: recapiti[i].dispensaCredenziale,
            indirizzo: recapiti[i].recapito
          });
        }
        break;
      case 'iban':
        if (recapiti[i].recapito && !recapiti[i].recapitoError) {
          returnValue.push({
            etichetta: recapiti[i].etichetta,
            dispensaCredenziale: recapiti[i].dispensaCredenziale,
            iban: recapiti[i].recapito.toUpperCase()
          });
        }
        break;
      case 'telefono':
        if (recapiti[i].recapito && !recapiti[i].recapitoError) {
          returnValue.push({
            etichetta: recapiti[i].etichetta,
            dispensaCredenziale: recapiti[i].dispensaCredenziale,
            numero: recapiti[i].recapito
          });
        }
        break;
      case 'mezzo':
        if (recapiti[i].recapito && !recapiti[i].recapitoError) {
          returnValue.push({
            etichetta: recapiti[i].etichetta,
            dispensaCredenziale: recapiti[i].dispensaCredenziale,
            targa: recapiti[i].recapito
          });
        }
        break;
      case 'pass':
        if (recapiti[i].recapito && !recapiti[i].recapitoError) {
          returnValue.push({
            etichetta: recapiti[i].etichetta,
            dispensaCredenziale: recapiti[i].dispensaCredenziale,
            pan: recapiti[i].recapito
          });
        }
        break;
      default:
        break;
    }
  }
  return returnValue;
};
exports.buildRecapito = buildRecapito;
const extractContentFromHTML = htmlString => {
  return htmlString.replace(/\<.*?\>/g, '').replace(/&nbsp;/g, ' ').replace(/(\r\n|\n|\r)/gm, "");
};
exports.extractContentFromHTML = extractContentFromHTML;
const getValidDayByMonth = (selectedDay, month, year, festivi) => {
  return getValidBusinessDay(selectedDay, month, year, festivi);
};
exports.getValidDayByMonth = getValidDayByMonth;
const getValidBusinessDay = (selectedDay, month, year, festivi) => {
  let selDay = selectedDay;
  for (let e of festivi) {
    const splittedDate = e.split("/");
    if (splittedDate.map(e => parseInt(e)).indexOf(month) > -1 && parseInt(splittedDate[0]) === selDay) {
      selDay = parseInt(splittedDate[0]) + 1;
      break;
    }
  }
  if (!isBusinessDay(selDay, month, year)) {
    do {
      selDay = selDay + 1;
    } while (!isBusinessDay(selDay, month, year));
  }
  return selDay;
};
const isBusinessDay = (day, month, year) => {
  let isValidBusinessDay = true;
  let date = new Date(year, month - 1, day);
  if (moment.utc(date).isoWeekday() === 5 || moment.utc(date).isoWeekday() === 6) {
    isValidBusinessDay = false;
  }
  return isValidBusinessDay;
};
const validationNumeroCaratteriDescrizioneCercaTitoli = (sField, max) => {
  let sReturn = '';
  if (sField != '' && sField.length > max) {
    sReturn = 'invalid.numeroCaratteriMassimoDescrizione';
  }
  return sReturn;
};
exports.validationNumeroCaratteriDescrizioneCercaTitoli = validationNumeroCaratteriDescrizioneCercaTitoli;
const formatStringToNumber = string => {
  if (!string) {
    return undefined;
  }
  const num = parseFloat(string);
  return isNaN(num) ? undefined : num;
};
exports.formatStringToNumber = formatStringToNumber;
/** WEB-ONLY
 * Opens a new window/tab that works also on iOS
 * @param url the url of the new window to be opened
 * @param fileName the optional file name
 */
const openFileWeb = (url, callbackPopUpBlocked) => {
  console.log('openFile', url);
  const newWin = window.open(url, '_blank');
  if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
    // Pop-up blocked, showing an application pop-up that requests the user to click on the pop-up
    console.log('newWin blocked', newWin);
    callbackPopUpBlocked(url);
  }
};
/** WEB-ONLY
 * Downloads the file for web
 * @param url the url of the new window to be downloaded
 * @param fileName the optional file name
 */
const downloadFileWeb = (url, fileName) => {
  const a = document.createElement("a");
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  a.click();
};
/** WEB-ONLY
 * Handle the blob pdf response and downloads the pdf
 * @param response blob data of a pdf
 * @param fileName the optional file name of the pdf
 */
const hadlePdfResponseForDownload = (response, fileName) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([response], {
    type: "application/pdf"
  });
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  // @ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = URL.createObjectURL(newBlob);
  downloadFileWeb(data, fileName);
};
exports.hadlePdfResponseForDownload = hadlePdfResponseForDownload;
/** WEB-ONLY
 * Handle the blob pdf response and opens the pdf in a new window/tab
 * @param response blob data of a pdf
 * @param callbackPopUpBlocked callback when the browser blocks the pop-up (evil Safari)
 */
const hadlePdfResponse = (response, callbackPopUpBlocked) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([response], {
    type: "application/pdf"
  });
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  // @ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = URL.createObjectURL(newBlob);
  openFileWeb(data, callbackPopUpBlocked);
};
exports.hadlePdfResponse = hadlePdfResponse;
/**
 * Handle the blob excel response and downloads the excel
 * @param response blob data of an excel
 * @param fileName the optional file name of the excel
 */
const hadleExcelResponse = (response, fileName) => {
  var newBlob = new Blob([response], {
    type: "application/excel"
  });
  // @ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  const data = window.URL.createObjectURL(newBlob);
  downloadFileWeb(data, fileName);
};
exports.hadleExcelResponse = hadleExcelResponse;
const getLastBusinessDayOfMonth = (lastDay, month, year, festivi) => {
  var giornoValido = lastDay;
  for (var i = 0; i < lastDay; i++) {
    if (isBusinessDay(giornoValido, month, year) && !(0, exports.validationFieldDate)(moment(new Date(year, month - 1, giornoValido)).format('YYYY-MM-DD'), !!festivi ? festivi : [], true)) {
      return giornoValido;
    } else {
      giornoValido--;
    }
  }
};
exports.getLastBusinessDayOfMonth = getLastBusinessDayOfMonth;
const isExpiredDocument = date => {
  const today = new Date();
  const documentDate = new Date(date);
  return documentDate < today;
};
exports.isExpiredDocument = isExpiredDocument;
const formatStringDate = date => {
  if (!!date && date !== '') {
    if ((0, exports.isValidDate)(date)) {
      return date;
    } else {
      return '';
    }
  } else {
    return '';
  }
};
exports.formatStringDate = formatStringDate;
const isValidDate = date => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (date.match(regex) === null) {
    return false;
  } else {
    return true;
  }
};
exports.isValidDate = isValidDate;
const makeid = length => {
  var result = '';
  var characters = '0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
exports.makeid = makeid;
const validationFieldIbanSepa = (sField, fieldMatch, ibanValidation, required) => {
  let sReturn = '';
  let req = required !== false ? true : false;
  const isFieldNull = sField === '' || sField === ' ' || sField === undefined || sField === null;
  if (isFieldNull) {
    sReturn = req ? 'invalid.required' : '';
  }
  if (sReturn === '' && ibanValidation) {
    sReturn = IBAN.isValid(sField) ? '' : 'invalid.format';
  }
  if (sReturn === '' && sField.slice(0, 2) !== fieldMatch) {
    sReturn = 'invalid.ibanNonCombaciaConPaese';
  }
  return sReturn;
};
exports.validationFieldIbanSepa = validationFieldIbanSepa;