"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.copyPanViewSuccessNexi = exports.getPanViewResponseNexi = exports.startPanViewResponseNexi = exports.getPinViewResponseNexi = exports.startPinViewResponseNexi = exports.nxsrv = exports.formNexi = exports.parametriNexi = exports.fullUrlNexi = exports.movimentiNexi = exports.consensoNexi = exports.tokenSSONexi = void 0;
const reselect_1 = require("reselect");
const nexiReducer = state => state;
exports.tokenSSONexi = (0, reselect_1.createSelector)(nexiReducer, state => {
  return state.tokenSSO;
});
exports.consensoNexi = (0, reselect_1.createSelector)(nexiReducer, state => {
  return state.verificaConsenso;
});
exports.movimentiNexi = (0, reselect_1.createSelector)(nexiReducer, state => {
  return state.movimenti;
});
exports.fullUrlNexi = (0, reselect_1.createSelector)(nexiReducer, state => {
  var _a, _b, _c;
  return `${(_a = state.tokenSSO) === null || _a === void 0 ? void 0 : _a.urlCarteCredito}${(_b = state.tokenSSO) === null || _b === void 0 ? void 0 : _b.url}/${(_c = state.tokenSSO) === null || _c === void 0 ? void 0 : _c.token}`;
});
exports.parametriNexi = (0, reselect_1.createSelector)(nexiReducer, state => {
  return state.parametri;
});
exports.formNexi = (0, reselect_1.createSelector)(nexiReducer, state => {
  return state.form;
});
exports.nxsrv = (0, reselect_1.createSelector)(nexiReducer, state => {
  return state.nxsrv;
});
exports.startPinViewResponseNexi = (0, reselect_1.createSelector)(nexiReducer, state => {
  return state.startPinViewResponse;
});
exports.getPinViewResponseNexi = (0, reselect_1.createSelector)(nexiReducer, state => {
  return state.getPinViewResponse;
});
exports.startPanViewResponseNexi = (0, reselect_1.createSelector)(nexiReducer, state => {
  return state.startPanViewResponse;
});
exports.getPanViewResponseNexi = (0, reselect_1.createSelector)(nexiReducer, state => {
  return state.getPanViewResponse;
});
exports.copyPanViewSuccessNexi = (0, reselect_1.createSelector)(nexiReducer, state => {
  return state.copyPanViewResponse;
});