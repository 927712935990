"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RicercaAtmControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class RicercaAtmControllerApi extends runtime.BaseAPI {
  /**
   * Restituisce la lista di tutti gli atm per il relativo codice istituto
   */
  listaAtmRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.lingua === null || requestParameters.lingua === undefined) {
        throw new runtime.RequiredError('lingua', 'Required parameter requestParameters.lingua was null or undefined when calling listaAtm.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/public/ricercaatm/lista/{lingua}`.replace(`{${"lingua"}}`, encodeURIComponent(String(requestParameters.lingua))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.AtmDtoFromJSON));
    });
  }
  /**
   * Restituisce la lista di tutti gli atm per il relativo codice istituto
   */
  listaAtm(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.listaAtmRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Ricerca gli ATM per l\'istituto in un raggio di X km da una posizione di cui vengono passate le coordinate: latitudine e longitudine.
   */
  listaAtmByRadiusRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.ricercaAtmFiltriInDto === null || requestParameters.ricercaAtmFiltriInDto === undefined) {
        throw new runtime.RequiredError('ricercaAtmFiltriInDto', 'Required parameter requestParameters.ricercaAtmFiltriInDto was null or undefined when calling listaAtmByRadius.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/public/ricercaatm`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.RicercaAtmFiltriInDtoToJSON(requestParameters.ricercaAtmFiltriInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.AtmDtoFromJSON));
    });
  }
  /**
   * Ricerca gli ATM per l\'istituto in un raggio di X km da una posizione di cui vengono passate le coordinate: latitudine e longitudine.
   */
  listaAtmByRadius(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.listaAtmByRadiusRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.RicercaAtmControllerApi = RicercaAtmControllerApi;