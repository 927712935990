"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BollettinoPostaleControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class BollettinoPostaleControllerApi extends runtime.BaseAPI {
  /**
   * Salva una nuova bozza di Bollettino Postale
   */
  addBozzaBollettinoPostaleRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.bollettinoPostaleDto === null || requestParameters.bollettinoPostaleDto === undefined) {
        throw new runtime.RequiredError('bollettinoPostaleDto', 'Required parameter requestParameters.bollettinoPostaleDto was null or undefined when calling addBozzaBollettinoPostale.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bollettinopostale/salvabozza`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BollettinoPostaleDtoToJSON(requestParameters.bollettinoPostaleDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BollettinoPostaleDtoFromJSON(jsonValue));
    });
  }
  /**
   * Salva una nuova bozza di Bollettino Postale
   */
  addBozzaBollettinoPostale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.addBozzaBollettinoPostaleRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione di Bollettino Postale
   */
  autorizzaBollettinoPostaleRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaBollettinoPostale.');
      }
      if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
        throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaBollettinoPostale.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bollettinopostale/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di Bollettino Postale
   */
  autorizzaBollettinoPostale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.autorizzaBollettinoPostaleRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Controlla la validità di un bollettino postale
   */
  checkBollettinoPostaleRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.bollettinoPostaleDto === null || requestParameters.bollettinoPostaleDto === undefined) {
        throw new runtime.RequiredError('bollettinoPostaleDto', 'Required parameter requestParameters.bollettinoPostaleDto was null or undefined when calling checkBollettinoPostale.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bollettinopostale/controllo`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BollettinoPostaleDtoToJSON(requestParameters.bollettinoPostaleDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BollettinoPostaleDtoFromJSON(jsonValue));
    });
  }
  /**
   * Controlla la validità di un bollettino postale
   */
  checkBollettinoPostale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.checkBollettinoPostaleRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Elimina una bozza Bollettino Postale
   */
  deleteBozzaBollettinoPostaleRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteBozzaBollettinoPostale.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bollettinopostale/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.VoidApiResponse(response);
    });
  }
  /**
   * Elimina una bozza Bollettino Postale
   */
  deleteBozzaBollettinoPostale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.deleteBozzaBollettinoPostaleRaw(requestParameters);
    });
  }
  /**
   * Lettura di una singola bozze Bollettino Postale
   */
  getBozzaBollettinoPostaleRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getBozzaBollettinoPostale.');
      }
      if (requestParameters.tipologiaBollettino === null || requestParameters.tipologiaBollettino === undefined) {
        throw new runtime.RequiredError('tipologiaBollettino', 'Required parameter requestParameters.tipologiaBollettino was null or undefined when calling getBozzaBollettinoPostale.');
      }
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bollettinopostale/{tipologiaBollettino}/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"tipologiaBollettino"}}`, encodeURIComponent(String(requestParameters.tipologiaBollettino))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BollettinoPostaleDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura di una singola bozze Bollettino Postale
   */
  getBozzaBollettinoPostale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getBozzaBollettinoPostaleRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura bozze Bollettino Postale
   */
  getBozzeBollettinoPostaleRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
        throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling getBozzeBollettinoPostale.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bollettinopostale/bozze`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(models_1.BollettinoPostaleDtoFromJSON));
    });
  }
  /**
   * Lettura bozze Bollettino Postale
   */
  getBozzeBollettinoPostale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.getBozzeBollettinoPostaleRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneBollettinoPostaleRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneBollettinoPostale.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bollettinopostale/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva
   */
  informazioniAutenticazioneBollettinoPostale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.informazioniAutenticazioneBollettinoPostaleRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva per la revoca
   */
  informazioniAutenticazioneRevocaBollettinoPostaleRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
        throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneRevocaBollettinoPostale.');
      }
      const queryParameters = {};
      if (requestParameters.chiamataUscente !== undefined) {
        queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
      }
      if (requestParameters.eseguiInit !== undefined) {
        queryParameters['eseguiInit'] = requestParameters.eseguiInit;
      }
      if (requestParameters.offline !== undefined) {
        queryParameters['offline'] = requestParameters.offline;
      }
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bollettinopostale/infoautenticazione/{idDispositiva}/revoca`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
    });
  }
  /**
   * Informazioni sull\'autenticazione dispositiva per la revoca
   */
  informazioniAutenticazioneRevocaBollettinoPostale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.informazioniAutenticazioneRevocaBollettinoPostaleRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Autorizzazione di una disposizione di revoca Bollettino Postale
   */
  operazioneAutenticataBollettinoPostaleRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.bollettinoPostaleRevocaIn === null || requestParameters.bollettinoPostaleRevocaIn === undefined) {
        throw new runtime.RequiredError('bollettinoPostaleRevocaIn', 'Required parameter requestParameters.bollettinoPostaleRevocaIn was null or undefined when calling operazioneAutenticataBollettinoPostale.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bollettinopostale/autorizza/revoca`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BollettinoPostaleRevocaInToJSON(requestParameters.bollettinoPostaleRevocaIn)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSON(jsonValue));
    });
  }
  /**
   * Autorizzazione di una disposizione di revoca Bollettino Postale
   */
  operazioneAutenticataBollettinoPostale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.operazioneAutenticataBollettinoPostaleRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Restituisce le operazioni per Bollettino Postale
   */
  operazioniOneClickBollettinoPostaleRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.operazioniOneClickInputDto === null || requestParameters.operazioniOneClickInputDto === undefined) {
        throw new runtime.RequiredError('operazioniOneClickInputDto', 'Required parameter requestParameters.operazioniOneClickInputDto was null or undefined when calling operazioniOneClickBollettinoPostale.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bollettinopostale/oneclick`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.OperazioniOneClickInputDtoToJSON(requestParameters.operazioniOneClickInputDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.OperazioniOneClickDtoFromJSON(jsonValue));
    });
  }
  /**
   * Restituisce le operazioni per Bollettino Postale
   */
  operazioniOneClickBollettinoPostale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.operazioniOneClickBollettinoPostaleRaw(requestParameters);
      return yield response.value();
    });
  }
  /**
   * Lettura parametri bollettino postale
   */
  parametriBollettinoPostaleRaw() {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParameters = {};
      const headerParameters = {};
      const response = yield this.request({
        path: `/private/bollettinopostale/parametri`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.ParametriBollettiniPostaliDtoFromJSON(jsonValue));
    });
  }
  /**
   * Lettura parametri bollettino postale
   */
  parametriBollettinoPostale() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.parametriBollettinoPostaleRaw();
      return yield response.value();
    });
  }
  /**
   * Modifica una bozza Bollettino Postale
   */
  updateBozzaBollettinoPostaleRaw(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateBozzaBollettinoPostale.');
      }
      if (requestParameters.bollettinoPostaleDto === null || requestParameters.bollettinoPostaleDto === undefined) {
        throw new runtime.RequiredError('bollettinoPostaleDto', 'Required parameter requestParameters.bollettinoPostaleDto was null or undefined when calling updateBozzaBollettinoPostale.');
      }
      const queryParameters = {};
      const headerParameters = {};
      headerParameters['Content-Type'] = 'application/json';
      const response = yield this.request({
        path: `/private/bollettinopostale/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: models_1.BollettinoPostaleDtoToJSON(requestParameters.bollettinoPostaleDto)
      });
      return new runtime.JSONApiResponse(response, jsonValue => models_1.BollettinoPostaleDtoFromJSON(jsonValue));
    });
  }
  /**
   * Modifica una bozza Bollettino Postale
   */
  updateBozzaBollettinoPostale(requestParameters) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield this.updateBozzaBollettinoPostaleRaw(requestParameters);
      return yield response.value();
    });
  }
}
exports.BollettinoPostaleControllerApi = BollettinoPostaleControllerApi;